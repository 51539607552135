import { BASE_URL } from "../../../Constants/baseConstant";

export const RequestAdminEmailTemplate = ({ expiry, workspace }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Greetings
            </span>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
        <tr style={{ textAlign: "left" }}>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    A new workspace expiry extension request has been submitted. Please find the related information below.
                </span>
                <br />
                <br />
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                    <span style={{ width: "40%", textAlign: "left", fontWeight: "bold" }}>Workspace Name </span>
                    <span style={{ width: "60%", textAlign: "left", }}> : {workspace}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                    <span style={{ width: "40%", textAlign: "left", fontWeight: "bold" }}>Current Expiry Date  </span>
                    <span style={{ width: "60%", textAlign: "left", }}> : {expiry}</span>
                </div>
                <br />
                <br />
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    Your review and approval of this request are crucial to ensure a smooth experience for the user. Kindly log in to the admin portal to assess the request by clicking the button below.
                </span>
                <br />
                <br />
                <div style={{ align: "center", textAlign: "center", }}>
                    <a href={`${BASE_URL}/admin-signin`} style={{
                        background: "#20e277",
                        textDecoration: "none !important", fontWeight: 500, marginTop: "35px",
                        color: "#fff", textTransform: "uppercase", fontSize: "14px",
                        padding: "10px 24px", display: "inlineBlock", borderRadius: "50px"
                    }}>
                        Click Here
                    </a>
                </div>
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)