import * as ActionTypes from '../ActionTypes';


export const GetEmployeeTemplateAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_TEMPLATE_GET_REQUEST,
        params
    }
}

export const AddEmployeeTemplateAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdateEmployeeTemplateAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_REQUEST,
        params
    }
}

export const CalculateEmployeeSalaryTemplateAction = (params) => {
    return {
        type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_REQUEST,
        params
    }
}

export const AddCalculateEmployeeSalaryTemplateAction = (params) => {
    return {
        type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const GetCalculateEmployeeSalaryTemplateAction = (params) => {
    return {
        type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_GET_REQUEST,
        params
    }
}
export const GetPayrollWorkLocationAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_WORK_LOCATION_GET_REQUEST,
        params
    }
}