import * as ActionTypes from '../ActionTypes';

const componentInitialState = {
    isLoading: false,
    success: false,
    error: false,
    pay_date_data: [],
}

export const PayDateReducer = function (state = componentInitialState, action) {
    switch (action.type) {
        case ActionTypes.PAY_DATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.PAY_DATE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                pay_date_data: action.payload?.data?.data
            };
        case ActionTypes.PAY_DATE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            };
        case ActionTypes.PAY_DATE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.PAY_DATE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                pay_date_data: action.payload?.data?.data
            };
        case ActionTypes.PAY_DATE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        
        default:
            return state;
    }
}