import * as ActionTypes from '../ActionTypes';

export const GetEmployeePayrollProfileDetailAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_GET_REQUEST,
        params
    }
}

export const UpdateEmployeePayrollProfileDetailAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_UPDATE_REQUEST,
        params
    }
}
