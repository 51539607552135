import { useEffect } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Grid,
    FormControl
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ConvertDate } from "../../../Base/helper/baseFunctions";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { GetEmployeeTimeAction } from "../../../../../store/Finance/Profile/Action";

const EmployeeTime = ({ }) => {
    const dispatch = useDispatch();
    const { employee_time_data } = useSelector(
        (state) => state?.FinanceReducer.EmployeeProfileReducer
    );
    const date = new Date();
    const [filters, setFilters] = useState({
        month_year: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    });
    useEffect(() => {
        const [f_year, month] = filters.month_year.split("-");
        const f_month = month.padStart(2, "0");
        dispatch(GetEmployeeTimeAction({
            month: f_month,
            year: f_year
        }));
    }, [filters]);
    const handleFilter = (event) => {
        if (["month_year"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value });
        }
    };

    const handleExportAll = () => {
        const headers = [
            { label: "Name", key: "name" },
            { label: "Mail", key: "email" },
            { label: "Due Date", key: "due_date" },
            { label: "Total Hours", key: "total_hours" },
        ];

        const csvData = [headers.map(header => header.label)];
        employee_time_data.forEach(row => {
            const rowData = headers.map(header => {
                let value = row[header.key];
                    if (value === null || value === undefined) {
                    value = "";
                    }
                    if (typeof value === 'string' && value.includes(',')) {
                    value = `"${value}"`;
                    }
                return value;
            });
            csvData.push(rowData);
        });
        
        const csv = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "Employee Time.csv";
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <Box sx={{ m: 1 }}>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }} container columnSpacing={1} mb={1}>
                    <FormControl>
                        <CommonDatePicker
                            name="month_year"
                            openTo="month"
                            views={['year', 'month']}
                            label="Year and Month"
                            pickerType="date"
                            size="small"
                            onKeyDown={(e) => e.preventDefault()}
                            onKeyPress={(e) => e.preventDefault()}
                            pickerFormat="MM/yyyy"
                            DefaultDTvalue={filters.month_year}
                            maxDate={new Date()}
                            handleChange={(value) =>
                                handleFilter({
                                    target: {
                                        name: "month_year",
                                        value: value
                                            ? `${value?.getFullYear()}-${("0" + (value?.getMonth() + 1)).slice(-2)}-${("0" + value?.getDate()).slice(-2)}`
                                            : "",
                                    },
                                })
                            }
                        />
                    </FormControl>
                </Grid>
            </Box>
            <MaterialTable
                columns={[
                    {
                        title: "Name",
                        cellStyle: { padding: "10px 10px 10px 13px" },
                        field: "name",
                        grouping: false,
                    },
                    {
                        title: "Email",
                        cellStyle: { padding: "10px 10px 10px 13px" },
                        field: "email",
                        grouping: false,
                    },
                    {
                        title: "Due Date",
                        cellStyle: { padding: "10px 10px 10px 13px" },
                        field: "due_date",
                    },
                    {
                        title: "Total Hours",
                        cellStyle: { padding: "10px 10px 10px 40px" },
                        field: "total_hours",
                        grouping: false,
                    },
                ]}
                data={employee_time_data}
                options={{
                    maxBodyHeight: "54vh",
                    minBodyHeight: "54vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: true,
                    exportFileName: "Employee Time",
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,
                }}
                actions={[
                    {
                        icon: () => <CloudDownloadIcon />,
                        tooltip: 'Export as CSV',
                        onClick: handleExportAll,
                        isFreeAction: true,
                        hidden: employee_time_data.length === 0,
                    },

                ]}

                title="Employee Time"
            />

        </>
    );
};

export default EmployeeTime;