import { useState, useEffect } from "react";
import Popup from "../../../Base/views/Popup"
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog"
import { useDispatch, useSelector } from "react-redux";
import { DeleteEmployeeWorkExperienceAction, GetEmployeeWorkExperienceAction } from "../../../../../store/Employee/ProfessionalSummary/Action";
import WorkExperienceModalForm from "./WorkExperienceModalForm";
import MaterialTable from "material-table"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { toast } from "react-toastify";
import { s3Client } from "../../../Base/helper/baseFunctions";
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ConvertDate, getUserData } from "../../../Base/helper/baseFunctions";

export default function WorkExperiencePage({ disableEdit, OnboardingEmpData, newdata, currentMode }) {
    const userData = getUserData()
    const dispatch = useDispatch()
    const { workExperienceData } = useSelector(state => state?.EmployeeReducer.EmployeeProfessionalSummaryReducer);
    const initialValues = {
        previous_employer: "",
        dojoining: "",
        doleaving: "",
        role_played: ""
    };
    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const openMoreVertIcon = Boolean(anchorEl);
    const handleMoreVertIconClose = () => {
        setAnchorEl(null);
    };
    const handleMoreVertIconClick = (event, row) => {
        setCurrentData(row)
        setAnchorEl(event.currentTarget);
    };

    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };

    const handleEdit = (rowData) => {
        setAnchorEl(false);
        if (currentMode === "editemployee" && OnboardingEmpData) {
            setEditedRecord({ ...rowData, domain: userData?.domain, employee_id: OnboardingEmpData?.employee_id });
        }
        else if (currentMode === "addemployee") {
            setEditedRecord({ ...rowData, domain: userData?.domain, employee_id: newdata?.employee_id });
        }
        else {
            setEditedRecord({ ...rowData, domain: userData?.domain, employee_id: userData?.employee_id });
        }

        setOpenPopup(true);
    };
    const handleView = async (event, rowData) => {
        event.stopPropagation();
        const bucketParams = {
            Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
            Key: rowData?.proof,
        };

        try {
            const url = await getSignedUrl(
                s3Client,
                new GetObjectCommand(bucketParams),
                { expiresIn: 3 * 60 }
            );

            if (url) {
                window.open(url, "_blank");
            }
        } catch (err) {
            toast.error(err.message || "An error occurred while opening the document.");
        }
    };

    const handleConfirmDelete = (rowData) => {
        setAnchorEl(false);
        setOpenDialog(true);
        setCurrentData(rowData)
    };

    const handleDelete = () => {
        setOpenDialog(false);
        var id = [];
        if (Array.isArray(currentData)) {
            currentData.forEach(item => {
                id.push(item.emp_workexp_id);
            })
            if (currentMode === "addemployee") {
                dispatch(DeleteEmployeeWorkExperienceAction({ id: `{${id}}`, employee_id: newdata?.employee_id }))
            }
            else {
                dispatch(DeleteEmployeeWorkExperienceAction({ id: `{${id}}`, employee_id: OnboardingEmpData?.employee_id }))
            }
        }
        else if (currentMode === "addemployee") {
            dispatch(DeleteEmployeeWorkExperienceAction({ id: `{${currentData?.emp_workexp_id}}`, employee_id: newdata?.employee_id }))
        }
        else {
            dispatch(DeleteEmployeeWorkExperienceAction({ id: `{${currentData?.emp_workexp_id}}`, employee_id: OnboardingEmpData?.employee_id }))
        }
    };

    const handleBulkDelete = (selectedRow) => {
        setCurrentData(selectedRow);
    };
    const handleAdd = () => {
        setEditedRecord()
        if (currentMode === "editemployee" && OnboardingEmpData) {
            setEditedRecord({ ...initialValues, domain: userData?.domain, employee_id: OnboardingEmpData?.employee_id });
        }
        else if (currentMode === "addemployee") {
            setEditedRecord({ ...initialValues, domain: userData?.domain, employee_id: newdata?.employee_id, });
        }
        else {
            setEditedRecord({ ...initialValues, domain: userData?.domain, employee_id: userData?.employee_id });
        }
        setOpenPopup(true);
    };

    useEffect(() => {
        if (currentMode === "editemployee" && OnboardingEmpData) {
            dispatch(GetEmployeeWorkExperienceAction({ domain: userData?.domain, employee_id: OnboardingEmpData?.employee_id }))
        }
        else if (currentMode === "addemployee") {
            dispatch(GetEmployeeWorkExperienceAction({ domain: userData?.domain, employee_id: newdata?.employee_id }))
        }
        else {
            dispatch(GetEmployeeWorkExperienceAction({ domain: userData?.domain, employee_id: userData?.employee_id }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const addButton = <Button onClick={handleAdd} variant="contained" startIcon={<AddIcon />} className="mpl-primary-btn" >
        Add
    </Button>


    return (
        <Box>
            <MaterialTable
                columns={[
                    { title: "Name of the Previous Employer", field: "previous_employer", cellStyle: { padding: "10px" }, },
                    {
                        title: "Date of Joining", field: "dojoining", cellStyle: { padding: "10px" },
                        render: rowData => { return ConvertDate(rowData?.dojoining) }
                    },
                    {
                        title: "Last Working Day", field: "doleaving", cellStyle: { padding: "10px" },
                        render: rowData => { return ConvertDate(rowData?.doleaving) }
                    },
                    { title: "Role Played", field: "role_played", cellStyle: { padding: "10px" }, },
                    {
                        title: "Document",
                        render: rowData => (
                            <div>
                                {rowData.proof ? (
                                    <IconButton
                                        onClick={(e) => handleView(e, rowData)}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton disabled>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                )}
                            </div>
                        ),
                        cellStyle: { padding: "0px" },
                    },
                ]}
                data={workExperienceData}
                onSelectionChange={(selectedRow) => handleBulkDelete(selectedRow)}
                options={{
                    maxBodyHeight: OnboardingEmpData ? "44vh" : "52vh",
                    minBodyHeight: OnboardingEmpData ? "44vh" : "52vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px", fontWeight: "bold", paddingRight: "10px" },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: "Work Experience",
                    addRowPosition: "first",
                    selection: disableEdit ? false : true,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    selectionProps: (rowData) => ({
                        disabled: rowData.age < 18 ? true : false,
                        color: "secondary",
                    }),
                    grouping: false,
                    columnsButton: true,
                }}
                actions={[
                    !disableEdit && {
                        icon: () => <MoreVertIcon />,
                        position: "row",
                        tooltip: "Setting",
                        onClick: (_event, rowData) => handleMoreVertIconClick(_event, rowData),
                    },
                    !disableEdit && {
                        icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                        tooltip: "Delete",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                ]}
                title={disableEdit ? "" : addButton}
            />
            <Popup
                title={editedRecord?.emp_workexp_id ? "Edit Work Experience" : "Add Work Experience"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <WorkExperienceModalForm editedRecord={editedRecord} addMode={addMode} setOpenPopup={setOpenPopup} />
            </Popup>
            <ConfirmationDialog
                title="Do you wish to proceed?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Work Experience Management"
                OkButtonText="OK"
                onSubmit={handleDelete}
            />
            {

                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={openMoreVertIcon}
                    onClose={handleMoreVertIconClose}
                >
                    {

                        <MenuItem key="edit" onClick={() => (handleEdit(currentData))}>
                            <EditIcon sx={{ fontSize: "15px" }} /> &nbsp; <Typography sx={{ fontSize: "15px" }}>Edit</Typography>
                        </MenuItem>
                    }
                    <MenuItem key="delete" onClick={() => (handleConfirmDelete(currentData))}>
                        <DeleteIcon sx={{ fontSize: "15px" }} /> &nbsp; <Typography sx={{ fontSize: "15px" }}>Delete</Typography>
                    </MenuItem>
                </Menu>
            }
        </Box>
    );
}
