import { put, call } from 'redux-saga/effects';
import { GetEmployeeFaceTimeHelper, GetEmployeeProfileHelper, GetEmployeeTimeHelper } from '../../app/Components/Finance/helper/ProfileHelper';
import { AddTaxDeclarationHelper, GetTaxDeclarationHelper, UpdateTaxDeclarationHelper } from '../../app/Components/Finance/helper/TaxDeclarationHelper';
import * as ActionTypes from './ActionTypes';
import {
    AddOverTimeHelper,
    AddSalaryComponentHelper,
    AddSalaryStructureHelper,
    DeleteOverTimeHelper,
    DeleteSalaryComponentHelper,
    DeleteSalaryStructureHelper,
    GetOverTimeHelper,
    GetPayDateHelper,
    GetSalaryComponentHelper,
    GetSalaryComponentTypeHelper,
    GetSalaryStructureHelper,
    UpdateOverTimeHelper,
    UpdatePayDateHelper,
    UpdateSalaryComponentHelper,
    UpdateSalaryStructureHelper
} from '../../app/Components/FinanceSettings/helper/finance';
import { GetDeductionHelper, GetEarningHelper } from '../../app/Components/Finance/helper/Component/ComponentHelper';
import { AddLWFHelper, AddPayrollTemplateHelper, DeleteLWFHelper, DeletePayrollTemplateHelper, GetPayrollSettingsHelper, GetPayrollTemplateHelper, UpdateLWFHelper, UpdatePayrollTemplateHelper } from '../../app/Components/FinanceSettings/helper/PayRollTemplateHelper';
import { AddCalculateEmployeeSalaryTemplateHelper, AddEmployeeTemplateHelper, CalculateEmployeeSalaryTemplateHelper, GetCalculateEmployeeSalaryTemplateHelper, GetEmployeeTemplateHelper, GetPayrollWorkLocationHelper, UpdateEmployeeTemplateHelper } from '../../app/Components/Finance/helper/Employee/EmployeeTemplateHelper';
import { GetWageReportHelper } from '../../app/Components/Finance/helper/Reports/GetWageReportHelper';


export function* GetTaxDeclaration() {
    const response = yield call(GetTaxDeclarationHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TAX_DECLARATION_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.TAX_DECLARATION_GET_ERROR, payload: response })
    }
}

export function* AddTaxDeclaration(payload) {
    const response = yield call(AddTaxDeclarationHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TAX_DECLARATION_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.TAX_DECLARATION_ADD_ERROR, payload: response })
    }
}

export function* UpdateTaxDeclaration(payload) {
    const response = yield call(UpdateTaxDeclarationHelper, payload?.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.TAX_DECLARATION_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.TAX_DECLARATION_UPDATE_ERROR, payload: response })
    }
}

export function* GetEmployeeProfile() {
    const response = yield call(GetEmployeeProfileHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_ERROR, payload: response })
    }
}

export function* GetSalaryStructure() {
    const response = yield call(GetSalaryStructureHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_GET_ERROR, payload: response })
    }
}

export function* AddSalaryStructure(payload) {
    const response = yield call(AddSalaryStructureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_ADD_ERROR, payload: response })
    }
}

export function* UpdateSalaryStructure(payload) {
    const response = yield call(UpdateSalaryStructureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteSalaryStructure(payload) {
    const response = yield call(DeleteSalaryStructureHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_STRUCTURE_DELETE_ERROR, payload: response })
    }
}

export function* GetSalaryComponent(payload) {
    const response = yield call(GetSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_GET_ERROR, payload: response })
    }
}

export function* AddSalaryComponent(payload) {
    const response = yield call(AddSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_ADD_ERROR, payload: response })
    }
}

export function* UpdateSalaryComponent(payload) {
    const response = yield call(UpdateSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteSalaryComponent(payload) {
    const response = yield call(DeleteSalaryComponentHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_DELETE_ERROR, payload: response })
    }
}

export function* GetSalaryComponentType() {
    const response = yield call(GetSalaryComponentTypeHelper)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.SALARY_COMPONENT_TYPE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.SALARY_COMPONENT_TYPE_GET_ERROR, payload: response })
    }
}


export function* GetEarningComponent(payload) {
    const response = yield call(GetEarningHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_EARNING_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_EARNING_GET_ERROR, payload: response })
    }
}

export function* GetDeductionComponent(payload) {
    const response = yield call(GetDeductionHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_DEDUCTION_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_DEDUCTION_GET_ERROR, payload: response })
    }
}

export function* GetPayrollTemplate(payload) {
    const response = yield call(GetPayrollTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_GET_ERROR, payload: response })
    }
}

export function* AddPayrollTemplate(payload) {
    const response = yield call(AddPayrollTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_ADD_ERROR, payload: response })
    }
}

export function* UpdatePayrollTemplate(payload) {
    const response = yield call(UpdatePayrollTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_UPDATE_ERROR, payload: response })
    }
}

export function* DeletePayrollTemplate(payload) {
    const response = yield call(DeletePayrollTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_TEMPLATE_DELETE_ERROR, payload: response })
    }
}

export function* GetEmployeeTemplate(payload) {
    const response = yield call(GetEmployeeTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_TEMPLATE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_TEMPLATE_GET_ERROR, payload: response })
    }
}

export function* AddEmployeeTemplate(payload) {
    const response = yield call(AddEmployeeTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_TEMPLATE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_TEMPLATE_ADD_ERROR, payload: response })
    }
}

export function* UpdateEmployeeTemplate(payload) {
    const response = yield call(UpdateEmployeeTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_ERROR, payload: response })
    }
}

export function* GetWageReport(payload) {
    const response = yield call(GetWageReportHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.WAGE_REPORT_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.WAGE_REPORT_GET_ERROR, payload: response })
    }
}


export function* GetEmployeeTime(payload) {
    const response = yield call(GetEmployeeTimeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_TIME_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_TIME_GET_ERROR, payload: response })
    }
}

export function* GetEmployeeFaceTime(payload) {
    const response = yield call(GetEmployeeFaceTimeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.EMPLOYEE_FACE_TIME_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.EMPLOYEE_FACE_TIME_GET_ERROR, payload: response })
    }
}

export function* GetPayrollSettings(payload) {
    const response = yield call(GetPayrollSettingsHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_SETTINGS_COMPONENT_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_SETTINGS_COMPONENT_GET_ERROR, payload: response })
    }
}

export function* AddLWF(payload) {
    const response = yield call(AddLWFHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LWF_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LWF_ADD_ERROR, payload: response })
    }
}

export function* UpdateLWF(payload) {
    const response = yield call(UpdateLWFHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LWF_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LWF_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteLWF(payload) {
    const response = yield call(DeleteLWFHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.LWF_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.LWF_DELETE_ERROR, payload: response })
    }
}

export function* GetOT(payload) {
    const response = yield call(GetOverTimeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.OT_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.OT_GET_ERROR, payload: response })
    }
}

export function* AddOT(payload) {
    const response = yield call(AddOverTimeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.OT_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.OT_ADD_ERROR, payload: response })
    }
}

export function* UpdateOT(payload) {
    const response = yield call(UpdateOverTimeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.OT_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.OT_UPDATE_ERROR, payload: response })
    }
}

export function* DeleteOT(payload) {
    const response = yield call(DeleteOverTimeHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.OT_DELETE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.OT_DELETE_ERROR, payload: response })
    }
}

export function* CalculateEmployeeSalaryTemplate(payload) {
    const response = yield call(CalculateEmployeeSalaryTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ERROR, payload: response })
    }
}

export function* AddCalculateEmployeeSalaryTemplate(payload) {
    const response = yield call(AddCalculateEmployeeSalaryTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ADD_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ADD_ERROR, payload: response })
    }
}

export function* GetCalculateEmployeeSalaryTemplate(payload) {
    const response = yield call(GetCalculateEmployeeSalaryTemplateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_GET_ERROR, payload: response })
    }
}

export function* GetPayrollWorkLocation(payload) {
    const response = yield call(GetPayrollWorkLocationHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAYROLL_WORK_LOCATION_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAYROLL_WORK_LOCATION_GET_ERROR, payload: response })
    }
}

export function* GetPayDate(payload) {
    const response = yield call(GetPayDateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAY_DATE_GET_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAY_DATE_GET_ERROR, payload: response })
    }
}

export function* UpdatePayDate(payload) {
    const response = yield call(UpdatePayDateHelper, payload.params)
    if (response?.data?.statuscode === 200) {
        yield put({ type: ActionTypes.PAY_DATE_UPDATE_SUCCESS, payload: response })
    } else {
        yield put({ type: ActionTypes.PAY_DATE_UPDATE_ERROR, payload: response })
    }
}
