import {
    Box,
    Button,
    Divider,
    InputBase,
    Typography,
    CircularProgress
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import React, { useState } from "react";
import { grey } from "@mui/material/colors";
import { convertBase64 } from "../../../Base/helper/baseFunctions";
import { ShareAttachHelper } from "../../helper/AttachmentHelper";
import NewPaySlip from "../Employee/NewPaySlip";

const PaySlipEmailPopup = ({ setOpenPopup, name, currentData, payData, imageUrl }) => {

    const initialValues = {
        to: currentData?.office_email,
        subject: `Pay Slip of ${name}`,
        content: `Check Your ${name} Pay Slip`
    };
    const [inputData, setInputData] = useState(initialValues);
    const [isSending, setIsSending] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [errors, setErrors] = useState({});

    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("subject" in fieldValues) {
            temp.subject =
                fieldValues.subject?.trim() === "" ? "Subject is required" : "";
        }
        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };


    const handleInputChange = (event) => {
        setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
            validate({ ...inputData, [event.target.name]: event.target.value });
    };

    const Base64 = async (blob) => {
        return await convertBase64(blob);
    };
    const handleSend = async () => {
        if (validate({ ...inputData })) {
            setIsSending(true); // Start loading
            try {
                const MyDocument = <NewPaySlip data={payData} imageUrl={imageUrl} />;
                const pdfBlob = await pdf(MyDocument).toBlob();
                const base64Pdf = await Base64(pdfBlob);
                await ShareAttachHelper({
                    ...inputData,
                    invoice_file: base64Pdf,
                    file_name: `${currentData?.employee_no} - ${name}.pdf`,
                    csv_file: "",
                    content: inputData?.content,
                    csv_file_name: "",
                    message: "Pay Slip Sent Successfully",
                });
                setOpenPopup(false); // Close popup on success
            } catch (error) {
                console.error("Error sending email:", error);
            } finally {
                setIsSending(false); // Stop loading
            }
        }
    };

    const handleExportAll = async () => {
        setIsDownloading(true); // Start loading
        try {
            const MyDocument = <NewPaySlip data={payData} imageUrl={imageUrl} />;
            const blob = await pdf(MyDocument).toBlob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${currentData?.employee_no} - ${name}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the attachment:", error);
        } finally {
            setIsDownloading(false); // Stop loading
        }
    };

    return (
        <div>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{ width: "100%" }}>
                    <InputBase
                        disabled
                        value={inputData?.to}
                        multiline
                        fullWidth
                        name="to"
                        onChange={handleInputChange}
                        sx={{ padding: "5px 0px" }}
                        placeholder="To"
                    />
                </Box>
            </Box>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Typography color="error">{errors.mentionTo}</Typography>
            <InputBase
                fullWidth
                name="subject"
                value={inputData?.subject}
                onChange={handleInputChange}
                sx={{ padding: "5px 0px" }}
                placeholder="Subject"
            />
            <Divider sx={{ borderStyle: "dashed" }} />
            <Typography color="error">{errors.subject}</Typography>
            <Divider sx={{ borderStyle: "dashed" }} />
            <InputBase
                fullWidth
                name="content"
                value={inputData?.content}
                onChange={handleInputChange}
                minRows={4}
                multiline
                sx={{ minHeight: "10px", adding: "5px 0px" }}
                placeholder="Content"
            />
            <Typography variant="h5">Attachment </Typography>
            <Box marginTop={2} marginBottom={2} display="flex" alignItems={"center"}>
                <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
                    Attachment :&nbsp;
                </Typography>
                <Typography
                    className="file-name"
                    sx={{ color: "#1155cc" }}
                    onClick={() => handleExportAll()}
                    style={{ cursor: "pointer" }}
                >
                    {isDownloading ? (
                        <CircularProgress size={20} sx={{ color: "#1155cc" }} />
                    ) : (
                        `${currentData?.employee_no} - ${name}.pdf`
                    )}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    className="mpl-secondary-btn"
                    sx={{ ":hover": { color: grey[50] } }}
                    size="small"
                    variant="text"
                    onClick={() => setOpenPopup(false)}
                >
                    Cancel
                </Button>
                {
                    isSending ?
                        <CircularProgress size={20} disableShrink />
                        :
                        <Button
                            sx={{
                                ml: 1,
                                color: grey[50],
                                ":hover": { backgroundColor: "#f9c307", color: "#000000" },
                            }}
                            size="small"
                            variant="contained"
                            className="mpl-primary-btn"
                            onClick={handleSend}
                            disabled={isSending}
                        >
                            Send
                        </Button>
                }

            </Box>
        </div>
    );
};

export default PaySlipEmailPopup;
