import MaterialTable, { MTableToolbar } from 'material-table';
import { useEffect } from "react";
import { useState } from "react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { GetTeamBalanceAction } from '../../../../../store/Leave/MyTeam/Action';
import Popup from '../../../Base/views/Popup';
// import { UpdateTeamBalanceHelper } from '../../helper/TimeOff/TimeOffHelper';

const TeamBalanceGrid = () => {
    const dispatch = useDispatch();
    const [currentData, setCurrentData] = useState({});
    const [openPopup, setOpenPopup] = useState(false);
    const { TeamBalanceData } = useSelector(state => state?.LeaveReducer.TimeSheetApprovalReducer);
    useEffect(() => {
        dispatch(GetTeamBalanceAction());
        //eslint-disable-next-line
    }, []);
    const leaveTypes = new Set();
    TeamBalanceData?.forEach(employee => {
        employee?.leave_balances?.forEach(leave => {
            leaveTypes.add(leave?.name);
        });
    });

    const columns = [
        { title: "Name", field: "employee_name", cellStyle: { padding: "10px" } },
        ...Array.from(leaveTypes).map(leaveType => ({
            title: leaveType,
            field: leaveType,
            cellStyle: { padding: "10px", textAlign: "center" },
            headerStyle: { textAlign: "center" }
        })),
    ];

    const transformedData = TeamBalanceData?.map(employee => {
        const leaveBalanceData = {};
        employee?.leave_balances?.forEach(leave => {
            leaveBalanceData[leave?.name] = leave?.leavebalance;
        });
        return {
            employee_name: employee.employee_name,
            ...leaveBalanceData
        };
    });
    // const handleEdit = (event, row) => {
    //     setCurrentData(row)
    //     setOpenPopup(true);
    // };
    // const handleUpdate = () => {
    //     setOpenPopup(false);
    //     UpdateTeamBalanceHelper({
    //         currentData: currentData
    //     }).then((res) => {
    //         if (res?.data?.statuscode === 200) {
    //             // dispatch(GetEmployeesAction({ is_active: true }));
    //             setOpenPopup(false);
    //         }
    //     })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };
    return (
        <>
            <MaterialTable
                columns={columns}
                data={transformedData}
                options={{
                    maxBodyHeight: "50vh",
                    minBodyHeight: "50vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                    sorting: false,
                    search: true,
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportFileName: "Team Leave Balance",
                    exportAllData: true,
                    exportButton: true
                }}
                title="Team Balance"
                components={{
                    Toolbar: (props) => (
                        <Box sx={{ minHeight: "5px" }}>
                            <MTableToolbar {...props} />
                        </Box>
                    ),
                }}
            />
            <Popup
                title="Edit Team Balance"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                fullWidth={true}
                maxWidth="sm"
            >
                <Box>
                    <Typography sx={{ mb: 2 }} variant="h5"> Employee Name : {currentData?.employee_name}</Typography>
                    <Grid container spacing={2}>
                        {Object.entries(currentData)
                            .filter(([key]) => (key !== "employee_name" && key !== "tableData"))
                            .map(([key, value], index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <TextField
                                        label={key}
                                        type="number"
                                        value={value}
                                        size='small'
                                        onChange={(e) =>
                                            setCurrentData({
                                                ...currentData,
                                                [key]: e.target.value,
                                            })
                                        }
                                        fullWidth
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                        <Button
                            className="mpl-primary-btn"
                            onClick={() => setOpenPopup(false)}
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                        <Button className="mpl-primary-btn"
                        // onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            </Popup>
        </>
    );
};

export default TeamBalanceGrid;