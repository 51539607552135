/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, FormControl, Grid, TextField } from "@mui/material";
import { Form } from "../../../Base/views/ModalPopUpForm"
import SelectableSearch from '../../../Base/views/SelectableSearch';
import { useState } from 'react';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddEmployeeBankAction, UpdateEmployeeBankAction } from '../../../../../store/Employee/Bank/Action';
import { GetBankAction } from '../../../../../store/Ancillary/Bank/Action';

const BankModal = ({
    data,
    newdata,
    currentMode,
    OnboardingEmpData,
    editedRecord,
    setOpenPopup,
    empBankData
}) => {
    const dispatch = useDispatch();
    const { bank_options } = useSelector(state => state.AncillaryReducer?.BankReducer)
    const [bankData, setBankData] = useState({
        account_number: "",
        account_type: "",
        account_holder: "",
        bank_branch: "",
        ifsc_code: "",
        bank_name: "",
    });
    const [errors, setErrors] = useState({});
    const [accountType, setAccountType] = useState([]);
    let account_type_options = []
    let result = empBankData.map(({ account_type }) => account_type)

    if (editedRecord) {
        account_type_options = [
            { id: "1", name: "Savings Account" },
            { id: "2", name: "Current Account" }
        ]
    }
    else if (result.length === 1 && result.includes("Savings Account")) {
        account_type_options = [
            { id: "2", name: "Current Account" }
        ]
    } else if (result.length === 1 && result.includes("Current Account")) {
        account_type_options = [
            { id: "1", name: "Savings Account" },
        ]
    } else {
        account_type_options = [
            { id: "1", name: "Savings Account" },
            { id: "2", name: "Current Account" }
        ]
    }

    useEffect(() => {
        if (editedRecord != null) {
            var ac_type = account_type_options?.find(e => e.name === editedRecord?.account_type)
            setBankData({ ...editedRecord, account_type: ac_type, bank_id: { bank_id: editedRecord?.bank_id, name: editedRecord?.bank } });
        }
        dispatch(GetBankAction());
    }, [editedRecord, !bank_options])

    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("account_number" in fieldValues) {
            temp.account_number = fieldValues.account_number === "" ? "Account Number is required" :
                fieldValues?.account_number?.toString().match(/^[0-9]{9,16}$/)
                    ? ""
                    : "Invalid Account Number.";
        }
        if ("account_type" in fieldValues) {
            temp.account_type = fieldValues.account_type === "" ? "Account Type is required" : "";
        }
        if ("account_holder" in fieldValues) {
            temp.account_holder = fieldValues.account_holder === "" ? "Account Holder is required" : "";
        }
        if ("bank_branch" in fieldValues) {
            temp.bank_branch = fieldValues.bank_branch === "" ? "Bank Branch is required" : "";
        }
        if ("bank_name" in fieldValues) {
            temp.bank_name = fieldValues.bank_name === "" ? "Bank Name is required" : "";
        }
        if ("ifsc_code" in fieldValues) {
            temp.ifsc_code = fieldValues.ifsc_code === "" ? "IFSC Code is required" : "";
        }

        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };

    const handleInputChange = (event, value) => {
        if (["account_type", "bank_id"].includes(event)) {
            if (value) {
                setBankData({ ...bankData, [event]: value })
            } else {
                setBankData({ ...bankData, [event]: "" })
            }
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...bankData, [event]: value })
        } else {
            setBankData({ ...bankData, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...bankData, [event.target.name]: event.target.value })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (event.type === "submit" || event.which === 13) {
            if (validate(bankData)) {
                // if (bankData?.emp_bankdet_id) dispatch(UpdateEmployeeBankAction(bankData));
                // else dispatch(AddEmployeeBankAction(bankData));

                if (bankData?.emp_bankdet_id) {
                    if (currentMode === "addemployee") {
                        dispatch(UpdateEmployeeBankAction({
                            ...bankData,
                            employee_id: newdata?.employee_id,
                        }))
                    }
                    else {
                        dispatch(UpdateEmployeeBankAction({
                            ...bankData,
                            employee_id: OnboardingEmpData ? OnboardingEmpData?.employee_id : data?.employee_id,
                        }))
                    }

                } else {
                    if (currentMode === "addemployee") {
                        dispatch(AddEmployeeBankAction({
                            ...bankData,
                            employee_id: newdata?.employee_id,
                        }))
                    }
                    else {
                        dispatch(AddEmployeeBankAction({
                            ...bankData,
                            employee_id: OnboardingEmpData ? OnboardingEmpData?.employee_id : data?.employee_id,
                        }))
                    }

                }
                setOpenPopup(false);
            }
        }
    }

    return (
        <Form onSubmit={(e) => handleSubmit(e)}>
            <Grid container>
                <Grid container>
                    <Grid key="account_number" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={bankData?.account_number}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                required
                                autoFocus={true}
                                id="name"
                                label="Account Number"
                                name="account_number"
                                autoComplete="account_number"
                                {...(errors.account_number && {
                                    error: true,
                                    helperText: errors.account_number,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="account_holder" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                required
                                value={bankData?.account_holder}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Account Holder Name"
                                name="account_holder"
                                autoComplete="account_holder"
                                {...(errors.account_holder && {
                                    error: true,
                                    helperText: errors.account_holder,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="bank_name" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                required
                                value={bankData?.bank_name}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Bank Name"
                                name="bank_name"
                                autoComplete="bank_name"
                                {...(errors.bank_name && {
                                    error: true,
                                    helperText: errors.bank_name,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="bank_branch" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                value={bankData?.bank_branch}
                                onChange={handleInputChange}
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Bank Branch"
                                name="bank_branch"
                                autoComplete="bank_branch"
                                {...(errors.bank_branch && {
                                    error: true,
                                    helperText: errors.bank_branch,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="ifsc_code" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                required
                                value={bankData?.ifsc_code}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="phone"
                                label="IFSC Code"
                                name="ifsc_code"
                                autoComplete="ifsc_code"
                                {...(errors.ifsc_code && {
                                    error: true,
                                    helperText: errors.ifsc_code,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="account_type" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                disabled={editedRecord ? true : false}
                                label="Account Type"
                                value={bankData?.account_type}
                                fieldLabel="name"
                                required
                                loading={() => { setAccountType({ load: false, values: account_type_options }); }}
                                variant={"outlined"}
                                name="account_type"
                                size="small"
                                onChangeEvent={handleInputChange}
                                data={accountType}
                                {...(errors.account_type && {
                                    error: true,
                                    helperText: errors.account_type,
                                })}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Button
                        sx={{ marginTop: "1rem", marginRight: "1rem", ":hover": { color: grey[50] } }}
                        variant="outlined"
                        onClick={() => setOpenPopup(false)}
                        className="mpl-secondary-btn"
                    >
                        cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1rem", marginRight: "1rem" }}>
                        {editedRecord ? "Update" : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default BankModal;