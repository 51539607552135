import axiosInstance from "../../../../../axiosInstance";
import { HRMS_EMPLOYEE_API_URL, HRMS_FINANCE_API_URL, HRMS_LMS_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

//Employee
export const AddEmployeeStagetoMainHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/employee/addEmployeeStagetoMain`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable", hasError: false, data: err?.response?.data }
        })
}
export const GetEmployeeStageHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/employee/getEmployeeStage`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Update Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to update", hasError: false, data: err?.response?.data }
        })
}

export const UpdateEmployeeStageHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/employee/updateEmployeeStage`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            stage_employee_id: params?.stage_employee_id,
            employee_details: params?.employee_details
        }
    })
        .then((res) => {
            return { message: "Update Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to update", hasError: false, data: err?.response?.data }
        })
}

export const DeleteEmployeeStageHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/employee/deleteEmployeeStage`, {
        method: "DELETE",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_id: data.employee_id,
            stage_employee_id: params?.stage_employee_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}

//PAYROLL

export const AddPayrollStagetoMainHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/finance/addPayrollStagetoMain`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable", hasError: false, data: err?.response?.data }
        })
}
export const GetPayrollStageHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/finance/getPayrollStage`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Update Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to update", hasError: false, data: err?.response?.data }
        })
}

export const UpdatePayrollStageHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/updatePayrollStage`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            stage_payroll_id: params?.stage_payroll_id,
            payroll_details: params?.payroll_details
        }
    })
        .then((res) => {
            return { message: "Update Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to update", hasError: false, data: err?.response?.data }
        })
}

export const DeletePayrollStageHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/finance/deletePayrollStage`, {
        method: "DELETE",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data.domain,
            user_id: data.employee_id,
            stage_payroll_id: params?.stage_payroll_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}

//LMS

export const GetLeaveBalanceStageHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/lms/getLeaveBalanceStage`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Update Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to update", hasError: false, data: err?.response?.data }
        })
}

export const UpdateLeaveBalanceStageHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/lms/updateLeaveBalanceStage`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            stage_leave_balance_id: params?.stage_leave_balance_id,
            leave_balance_details: params?.leave_balance_details
        }
    })
        .then((res) => {
            return { message: "Update Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to update", hasError: false, data: err?.response?.data }
        })
}

export const DeleteLeaveBalanceStageHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/lms/deleteLeaveBalanceStage`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_id: data.employee_id,
            stage_leave_balance_id: params?.stage_leave_balance_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}