import * as ActionTypes from '../ActionTypes';

export const GetPayrollTemplateAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_GET_REQUEST,
        params
    }
}

export const AddPayrollTemplateAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdatePayrollTemplatetAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_UPDATE_REQUEST,
        params
    }
}

export const DeletePayrollTemplateAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_DELETE_REQUEST,
        params
    }
}

export const GetPayrollSettingsAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_SETTINGS_COMPONENT_GET_REQUEST,
        params
    }
}

export const AddLWFAction = (params) => {
    return {
        type: ActionTypes.LWF_ADD_REQUEST,
        params
    }
}

export const UpdateLWFAction = (params) => {
    return {
        type: ActionTypes.LWF_UPDATE_REQUEST,
        params
    }
}

export const DeleteLWFAction = (params) => {
    return {
        type: ActionTypes.LWF_DELETE_REQUEST,
        params
    }
}

export const GetOTAction = (params) => {
    return {
        type: ActionTypes.OT_GET_REQUEST,
        params
    }
}

export const AddOTAction = (params) => {
    return {
        type: ActionTypes.OT_ADD_REQUEST,
        params
    }
}

export const UpdateOTAction = (params) => {
    return {
        type: ActionTypes.OT_UPDATE_REQUEST,
        params
    }
}

export const DeleteOTAction = (params) => {
    return {
        type: ActionTypes.OT_DELETE_REQUEST,
        params
    }
}