/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import {
  UpdateRoleAccessHelper,
} from "../../helper/EmployeeHelper";
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import { getUserData } from "../../../Base/helper/baseFunctions";

export default function RoleandAccess({
  setCurrentMode,
  currentMode,
  disableEdit,
  OnboardingEmpData,

}) {
   const userData = getUserData();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
      },
    })
  );
  const classes = useStyles();
  const initialValues = {
    name: "",
    core_hr_user_type: "",
    personal_mobile: "",
    office_email: "",
    enable_corehr: true,
    enable_ats: false,
  };
  const [roleData, setRoleData] = useState(initialValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (currentMode === "edit" && OnboardingEmpData) {
      setRoleData(OnboardingEmpData);
    } 
  }, []);
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("user_type" in fieldValues) {
      temp.user_type =
        fieldValues.user_type === "" ? "User Type is required" : "";
    }

    setErrors({
      ...temp,
    });
    const temp1 = { ...temp };
    return Object.values(temp1).every((x) => x === "");
  };

  const handleInputChange = (event, value) => {
    if (event?.target?.name === "core_hr_user_type") {
      setRoleData({ ...roleData, [event?.target?.name]: event?.target?.value });
    } else if (["enable_ats"].includes(event?.target?.name)) {
      setRoleData({ ...roleData, [event.target.name]: event.target.checked });
    } else if (event?.target?.value === "Admin") {
      setRoleData({
        ...roleData,
        [event?.target?.name]: event?.target?.value,
        enable_ats: true,
      });
    } else {
      setRoleData({ ...roleData, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...roleData, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = () => {
    if (validate(roleData)) {
        if (roleData?.emp_users || roleData?.emp_users_pk) {
          UpdateRoleAccessHelper({ ...roleData }).then((res) => {
            dispatch(GetEmployeesAction({ is_active: true }));
            setCurrentMode("grid");
          });
          
        } 
    }
  };
  const handleBack = () => {
    setCurrentMode("grid");
  }
 
  return (
    <>
      <>
        <Box sx={{ width: "100%", padding: "0px 30px 20px 30px" }}>
          <Grid container sx={{ overflowY: "auto", pb: 1 }}>
            
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent:
                    roleData?.core_hr_user_type === "Owner"
                      ? "flex-end"
                      : "space-between",
                  alignItems: "center",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                <Button
                  sx={{ marginRight: "10px" }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  className="mpl-primary-btn"
                  onClick={handleBack}
                >
                  Back
                </Button>
             
                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: currentMode !== undefined ? "center" : "center",
                  }}
                  variant="h6"
                >
                  Role & Access
                </Typography>

                {roleData?.core_hr_user_type !== "Owner" &&
                  (roleData?.emp_users || roleData?.emp_users_pk) && (
                    <>
                      <Button
                        variant="contained"
                        size="medium"
                        onClick={() => handleSubmit()}
                        sx={{
                          mt: 2,
                        }}
                        className="mpl-primary-btn"
                      >
                        {"Update user"}
                      </Button>
                    </>
                  )}
              </Grid>
            
            <Grid item xs={12} md={12} sx={{ marginTop: "1.5rem" }}>
              <Paper className={classes.paper}>
                <Typography variant="h6" gutterBottom>
                  Role & Access
                </Typography>
                <Grid pt={2.5} container spacing={3}>
                  {["Owner"].includes(roleData?.core_hr_user_type) ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          disabled
                          label="User Type"
                          name="core_hr_user_type"
                          onChange={handleInputChange}
                          value={roleData?.core_hr_user_type}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl
                        required
                        fullWidth
                        error={errors?.core_hr_user_type ? true : false}
                        size="small"
                      >
                        <InputLabel id="recruiter-type-select-label">
                          User Type
                        </InputLabel>
                        <Select
                          name="core_hr_user_type"
                          labelId="recruiter-type-select-label"
                          id="recruiter-type-select"
                          value={roleData?.core_hr_user_type}
                          label="User Type"
                          onChange={handleInputChange}
                        >
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                          <MenuItem value={"Employee"}>Employee</MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors?.core_hr_user_type}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="Name"
                        name="name"
                        onChange={handleInputChange}
                        value={roleData?.name || ""}
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="Contact"
                        name="personal_mobile"
                        onChange={handleInputChange}
                        value={roleData?.personal_mobile || ""}
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        disabled
                        label="Email"
                        name="office_email"
                        onChange={handleInputChange}
                        value={roleData?.office_email || ""}
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  {["Employee"].includes(roleData?.core_hr_user_type) && (
                    <>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              name="enable_corehr"
                              checked={roleData?.enable_corehr || false}
                              onChange={handleInputChange}
                            />
                          }
                          label={userData?.f_country_code === "IND" ? "Enable Core HR & LMS" : "Enable Core HR"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={disableEdit}
                              name="enable_ats"
                              checked={roleData?.enable_ats || false}
                              onChange={handleInputChange}
                            />
                          }
                          label="Enable ATS"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </>  
    </>
  );
}
