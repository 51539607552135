import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Paper,
  Slider,
  Modal,
  IconButton,
  FormLabel

} from "@mui/material";
import * as faceapi from "face-api.js";
import React, { useRef } from "react";
import { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonDatePicker from "../../Base/views/CommonDatePicker";
import { AddEmailTokenHelper } from "../../Ancillary/EmailToken/helper/EmailTokenHelper";
import {
  convertBase64,
  getUserData,
  s3Client,
} from "../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import SelectableSearch from "../../Base/views/SelectableSearch";
import { GetDesignationAction } from "../../../../store/Ancillary/Designation/Action";
import { GetWorkLocationAction } from "../../../../store/Ancillary/WorkLocation/Action";
import { GetEmployeesAction } from "../../../../store/Employee/Employee/Action";
import Popup from "../../Base/views/Popup";
import Success from "../Image/check.png";
import {
  AddEmployeeHelper,
  UpdateEmployeePopupHelper,
} from "../../Employee/helper/EmployeeHelper";
import { validateEmailHelper } from "../../SignIn/helper/SigninHelper";
import CreatableSearch from "../../Base/views/CreatableSearch";
import { AddDesignationHelper } from "../../Ancillary/Designation/helper/DesignationHelper";
import { AddWorkLocationHelper } from "../../Ancillary/WorkLocation/helper/WorkLocation";
import PayrollSetupPopup from "../../Finance/views/Employee/PayrollSetupPopup";
import { GetBusinessUnitAction } from "../../../../store/Ancillary/BusinessUnit/Action";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import CloseIcon from "@mui/icons-material/Close";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { GetEmpIDHelper } from "../../Ancillary/CompanyDetails/helper/CompanyDetailsHelper";
import Note from "../Image/alert.png";
import EmployeeID from "../../AdminSettings/views/Employee/EmployeeID";

const AddEmployeePopup = ({ setOpenPopup, email, editRecord }) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
      },
      comingSoon: {
        fontWeight: 600,
        fontSize: "18px",
        marginTop: "30px",
        color: "white",
      },
    })
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  let userData = getUserData();
  const date = new Date();
  const default_date = `${date?.getFullYear() +
    "-" +
    ("0" + (date?.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date?.getDate()).slice(-2)
    }`;
  const initialFValues = {
    previous_employee_id: "",
    first_name: "",
    last_name: "",
    dob: null,
    isactive: true,
    _designation_id: "",
    office_email: email || "",
    ats_work_location_id: "",
    reportingmanager_id: "",
    core_hr_user_type: "Employee",
    enable_corehr: true,
    enable_ats: false,
    joining_date: default_date,
    image: "",
    businessUnit: "",
    uan_no: "",
    pan: "",
    title: "",
    work_hour_configuration_id: ""
  };
  const [currentData, setCurrentData] = useState("");
  const [errors, setErrors] = useState({});
  const [imgfiles, setimgFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [employeeModalData, setEmployeeModalData] = useState(initialFValues);
  const [successPopup, setSuccessPopup] = useState(false);
  const [payroll, setPayroll] = useState(false);
  const [image, setImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const cropperRef = useRef(null);
  const [warning, setWarning] = useState(false);
  const [empno, setEmpNo] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [designationData, setDesignationData] = useState({
    load: false,
    values: [],
  });
  const [worklocationData, setWorklocationData] = useState({
    load: false,
    values: [],
  });
  const [workhrData, setWorkhrData] = useState({
    load: false,
    values: [],
  });
  const [step, setStep] = useState(1);
  const { designation_options } = useSelector(
    (state) => state?.AncillaryReducer?.DesignationReducer
  );
  const { workLocation_options } = useSelector(
    (state) => state?.AncillaryReducer?.WorkLocationReducer
  );

  const { employees_options } = useSelector(
    (state) => state.EmployeeReducer.EmployeesReducer
  );
  const { businessunitData } = useSelector(
    (state) => state.AncillaryReducer?.BusinessUnitReducer
  );
  const { work_hours_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
  const [employeesData, setEmployeesData] = useState({
    load: true,
    values: [],
  });
  const [businessUnitData, setBusinessUnitData] = useState({
    load: true,
    values: [],
  });
  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
      await faceapi.loadFaceLandmarkModel(MODEL_URL);
      await faceapi.loadFaceRecognitionModel(MODEL_URL);
    };
    loadModels();
  }, []);
  useEffect(() => {
    if (!openConfirmation) {
      GetEmpIDHelper().then((res) => {
        setEmpNo(res?.data?.data?.length ? res.data.data[0] : null);
      });
    }
  }, [openConfirmation]);
  useEffect(() => {
    dispatch(GetEmployeesAction({ is_active: true }));
    designation_options?.length === 0 && dispatch(GetDesignationAction());
    workLocation_options?.length === 0 && dispatch(GetWorkLocationAction());
    businessunitData?.length === 0 && dispatch(GetBusinessUnitAction());
    GetEmpIDHelper().then((res) => {
      setEmpNo(res?.data?.data?.length ? res.data.data[0] : null);
    })
    if (!editRecord?.employee_id) {
      const defaultWorkHour = work_hours_data?.find(wo => wo.is_default);
      setEmployeeModalData({
        ...employeeModalData,
        work_hour_configuration_id: defaultWorkHour?.work_hour_configuration_pk
          ? {
            work_hour_configuration_id: defaultWorkHour?.work_hour_configuration_pk,
            work_hour_code: defaultWorkHour?.work_hour_code,
          }
          : "",
      });
    }
    if (editRecord?.employee_id) {
      setEmployeeModalData({
        ...editRecord,
        dob: editRecord?.date_of_birth,
        joining_date: editRecord?.doj,
        _designation_id: editRecord?.designation_id
          ? {
            designation_id: editRecord?.designation_id,
            name: editRecord?.designation,
          }
          : "",
        ats_work_location_id: editRecord?.wl_id
          ? {
            ats_work_location_id: editRecord?.wl_id,
            work_location_name: editRecord?.work_location,
          }
          : "",
        reportingmanager_id: editRecord?.reporting_manager_id
          ? {
            employee_id: editRecord?.reporting_manager_id,
            name: editRecord?.reporting_manager,
          }
          : "",
        businessUnit: editRecord?.businessunit
          ? {
            businessUnit: editRecord?.businessunit_fk,
            name: editRecord?.businessunit,
          }
          : "",
        work_hour_configuration_id: editRecord?.work_hour_configuration_id
          ? {
            work_hour_configuration_id: editRecord?.work_hour_configuration_id,
            work_hour_code: editRecord?.work_hour_code,
          }
          : "",
      });
      editRecord?.image && getImageSignedUrl(editRecord);
    }
  }, [
    dispatch,
    businessunitData?.length,
    designation_options?.length,
    workLocation_options?.length,
    editRecord,
  ]);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData?.image;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name?.trim() === "" ? "First Name is required" : "";
    }
    if ("last_name" in fieldValues) {
      temp.last_name =
        fieldValues.last_name?.trim() === "" ? "Last Name is required" : "";
    }
    if ("dob" in fieldValues) {
      temp.dob =
        fieldValues.dob === "" || fieldValues.dob === null
          ? "Date of Birth is required"
          : "";
    }
    if ("phone" in fieldValues) {
      temp.phone =
        fieldValues.phone === "" || fieldValues.phone === null
          ? "Mobile No is required"
          : fieldValues?.phone.toString() !== "" &&
            !fieldValues?.phone
              ?.toString()
              ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
            ? "Invalid Mobile Number."
            : "";
    }
    if ("office_email" in fieldValues) {
      temp.office_email =
        fieldValues.office_email === "" || null
          ? "Email is required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
            fieldValues.office_email
          )
            ? ""
            : "Invalid Email.";
    }
    if ("_designation_id" in fieldValues) {
      temp._designation_id =
        fieldValues._designation_id === "" || null
          ? "Designation is required"
          : "";
    }
    if ("ats_work_location_id" in fieldValues) {
      temp.ats_work_location_id =
        fieldValues.ats_work_location_id === "" || null
          ? "Work Location is required"
          : "";
    }
    if ("work_hour_configuration_id" in fieldValues) {
      temp.work_hour_configuration_id =
        fieldValues.work_hour_configuration_id === "" || null
          ? "Work Hour"
          : "";
    }
    if ("reportingmanager_id" in fieldValues) {
      temp.reportingmanager_id =
        fieldValues.reportingmanager_id === "" || null
          ? "Reporting Manager is required"
          : "";
    }
    if ("joining_date" in fieldValues) {
      temp.joining_date =
        fieldValues.joining_date === "" || fieldValues.joining_date === null
          ? "Date of Joining is required"
          : "";
    }
    if ("uan_no" in fieldValues) {
      temp.uan_no =
        fieldValues.uan_no === "" || fieldValues.uan_no === null
          ? ""
          : fieldValues?.uan_no.toString() !== "" &&
            !fieldValues?.uan_no?.toString()?.match(/^\(?[0-9]{12}$/)
            ? "Invalid UAN Number."
            : "";
    }
    if ("pan" in fieldValues) {
      temp.pan =
        fieldValues.pan === "" || fieldValues.pan === null
          ? ""
          : fieldValues?.pan.toString() !== "" &&
            !fieldValues?.pan
              ?.toString()
              ?.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
            ? "Invalid PAN Number."
            : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleEmployees = () => {
    const filteredData = employees_options.filter((employee) => {
      return (
        (employee.employee_id !== userData?.employee_id &&
          employee.core_hr_user_type !== "Employee") ||
        employee.core_hr_user_type === "Owner"
      );
    });
    employees_options &&
      setEmployeesData({ load: false, values: filteredData });
  };
  const handleInputChange = (event, value) => {
    if (["reportingmanager_id", "work_hour_configuration_id"].includes(event)) {
      if (value) {
        setEmployeeModalData({ ...employeeModalData, [event]: value });
      } else {
        setEmployeeModalData({ ...employeeModalData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...employeeModalData, [event]: value ? value : "" });
    } else if (["_designation_id"].includes(event)) {
      if (value?.id === null) {
        AddDesignationHelper({ name: value?.name }).then((res) => {
          setDesignationData({ load: false, values: res?.data?.data });
          setEmployeeModalData({
            ...employeeModalData,
            [event]: res?.data?.data?.filter(
              (resp) => resp?.name === value?.name
            )?.[0],
          });
        });
      } else setEmployeeModalData({ ...employeeModalData, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...employeeModalData, [event]: value ? value : "" });
    } else if (["ats_work_location_id"].includes(event)) {
      if (value?.id === null) {
        AddWorkLocationHelper({ name: value?.work_location_name }).then(
          (res) => {
            setWorklocationData({ load: false, values: res?.data?.data });
            setEmployeeModalData({
              ...employeeModalData,
              [event]: res?.data?.data?.filter(
                (resp) => resp?.work_location_name === value?.work_location_name
              )?.[0],
            });
          }
        );
      } else setEmployeeModalData({ ...employeeModalData, [event]: value });

      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...employeeModalData, [event]: value ? value : "" });
    } else if (event === "businessUnit") {
      if (value) {
        setEmployeeModalData({ ...employeeModalData, [event]: value });
      } else {
        setEmployeeModalData({ ...employeeModalData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...employeeModalData, [event]: value ? value : "" });
    } else if (event?.target?.name === "core_hr_user_type") {
      setEmployeeModalData({
        ...employeeModalData,
        [event?.target?.name]: event?.target?.value,
      });
    } else if (["enable_ats"].includes(event?.target?.name)) {
      setEmployeeModalData({
        ...employeeModalData,
        [event.target.name]: event.target.checked,
      });
    } else if (event?.target?.value === "Admin") {
      setEmployeeModalData({
        ...employeeModalData,
        [event?.target?.name]: event?.target?.value,
        enable_ats: true,
      });
    } else {
      setEmployeeModalData({
        ...employeeModalData,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...employeeModalData,
          [event.target.name]: event.target.value,
        });
    }
  };

  const handleNext = (e) => {
    if (validate(employeeModalData)) {
      if (editRecord?.employee_id) {
        Update();
      } else {
        if (step === 1) {
          setStep(2);
        }
      }
    }
  };
  const HandleClose = () => {
    if (
      [
        "cvtmeepl",
        "xpohrmeepl",
        "altiio",
        "adobemeepl",
        "kmkmeepl",
        "paycmeepl",
        "bpmeepl",
        "nirahameepl",
      ].includes(userData?.domain) &&
      userData?.f_country_code === "IND"
    ) {
      setPayroll(true);
    } else {
      setSuccessPopup(false);
      setOpenPopup(false);
    }
  };
  const handleSubmit = async (e) => {
    if (empno) {
      e.preventDefault();
      if (validate(employeeModalData)) {
        let token = uuidv4() + `=${userData?.domain}`;
        let img_filename = "";
        let img_b64string = "";
        let detections;
        if (imgfiles?.[0]) {
          img_b64string = await convertBase64(imgfiles?.[0]);
          let ext = imgfiles?.[0]?.name?.split(".").at(-1);
          img_filename =
            `${userData?.domain}/CoreHr/Employee/Profile/` + uuidv4() + "." + ext;
          const img = await faceapi.bufferToImage(imgfiles?.[0]);
          detections = await faceapi
            .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceDescriptor();
        }
        const descriptorArray = detections?.descriptor
          ? Array.from(detections.descriptor)
          : "";
        AddEmployeeHelper({
          ...employeeModalData,
          image: img_filename,
          image_name: img_b64string,
          descriptor: detections?.descriptor
            ? JSON.stringify(descriptorArray)
            : "",
        })
          .then((res) => {
            if (res?.data?.statuscode === 200) {
              setCurrentData(res?.data?.data?.[0]);
              setSuccessPopup(true);
              AddEmailTokenHelper({
                token: token,
                email_id: employeeModalData?.office_email,
                json_data: JSON.stringify([{ ...employeeModalData }]),
              });
              validateEmailHelper({
                domain: userData?.domain,
                email: employeeModalData?.office_email,
              });
              dispatch(GetEmployeesAction({ is_active: true }));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    else {
      setWarning(true);
    }

  };
  const Update = async () => {
    if (validate(employeeModalData)) {
      let img_filename = "";
      let img_b64string = "";
      let old_image = "";
      let detections;
      let descriptor;
      if (imgfiles?.[0]) {
        img_b64string = await convertBase64(imgfiles?.[0]);
        let ext = imgfiles?.[0]?.name?.split(".").at(-1);
        img_filename =
          `${userData?.domain}/CoreHr/Employee/Profile/` + uuidv4() + "." + ext;
        const img = await faceapi.bufferToImage(imgfiles?.[0]);
        detections = await faceapi
          .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withFaceDescriptor();
        const descriptorArray = detections?.descriptor
          ? Array.from(detections.descriptor)
          : "";
        descriptor = detections?.descriptor
          ? JSON.stringify(descriptorArray)
          : "";
        if (employeeModalData?.image) {
          old_image = employeeModalData?.image;
        }
      } else {
        img_filename = employeeModalData?.image;
        descriptor = employeeModalData?.descriptor;
      }
      UpdateEmployeePopupHelper({
        ...employeeModalData,
        image: img_filename,
        image_name: img_b64string,
        old_image: old_image,
        descriptor: descriptor,
      })
        .then((res) => {
          if (res?.data?.statuscode === 200) {
            dispatch(GetEmployeesAction({ is_active: true }));
            setOpenPopup(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 2000000,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setimgFiles(acceptedFiles.map((file) => Object.assign(file)));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setOpenModal(true);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    onDropRejected: () => {
      toast.error("You can only upload image with maximum size of 2 MB.", {
        duration: 2000,
      });
    },
  });
  const img = imgfiles?.map((file) => (
    <img
      style={{ borderRadius: "50%", padding: "2px" }}
      width="100%"
      height="100%"
      key={file.name}
      alt={file.name}
      className="single-file-image"
      src={URL.createObjectURL(file)}
    />
  ));
  const handleDelete = (e) => {
    e.preventDefault();
    setimgFiles([]);
    setImageUrl("");
    setEmployeeModalData((prevData) => ({
      ...prevData,
      image: "", // Setting image to an empty string
    }));
  };

  const handleRotationChange = (event, newValue) => {
    setRotation(newValue);
    if (cropperRef.current?.cropper) {
      cropperRef.current.cropper.rotateTo(newValue);
    }
  };
  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      const fileName = imgfiles?.[0]?.name;
      const fileExtension = fileName?.split(".").pop();

      croppedCanvas.toBlob((blob) => {
        const file = new File([blob], fileName, {
          type: `image/${fileExtension}`,
        });
        setimgFiles([file]);
        setOpenModal(false);
      }, `image/${fileExtension}`);
    }
  };
  const handleNavigate = (menu, value) => {
    setOpenConfirmation(true);
    setWarning(false);

  };
  return (
    <>
      {step === 1 ? (
        <>
          <Grid container>
            <Grid container>
              <Grid
                key="first_name"
                item
                xs={12}
                md={12}
                sx={{ padding: "0.75rem", display: "flex", justifyContent: "space-between" }}

              >
                <FormControl size="small" sx={{ width: "30%", mr: 1 }}>
                  <InputLabel id="recruiter-type-select-label">Title</InputLabel>
                  <Select
                    label="Title"
                    name='title'
                    id="title"
                    value={employeeModalData?.title}
                    onChange={(e) => setEmployeeModalData({ ...employeeModalData, title: e.target.value })}
                  >
                    <MenuItem value={"Mr"}>Mr</MenuItem>
                    <MenuItem value={"Mrs"}>Mrs</MenuItem>
                    <MenuItem value={"Ms"}>Ms</MenuItem>
                    <MenuItem value={"Miss"}>Miss</MenuItem>
                    <MenuItem value={"Dr"}>Dr</MenuItem>
                    <MenuItem value={"Mx"}>Mx</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    autoFocus={true}
                    required
                    size="small"
                    value={employeeModalData.first_name}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    onKeyPress={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    onPaste={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    autoComplete="first_name"
                    {...(errors.first_name && {
                      error: true,
                      helperText: errors.first_name,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid
                key="last_name"
                item
                xs={12}
                md={12}
                sx={{ padding: "0.75rem" }}
              >
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    required
                    value={employeeModalData.last_name}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    onKeyPress={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    onPaste={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    name="last_name"
                    autoComplete="last_name"
                    {...(errors.last_name && {
                      error: true,
                      helperText: errors.last_name,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <TextField
                  label=" Email"
                  fullWidth
                  name="office_email"
                  required
                  onChange={handleInputChange}
                  value={employeeModalData?.office_email || ""}
                  size="small"
                  {...(errors.office_email && {
                    error: true,
                    helperText: errors.office_email,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <CommonDatePicker
                    required
                    size="small"
                    name="dob"
                    label="Birth Date"
                    pickerType="date"
                    onKeyPress={(e) => e.preventDefault()}
                    onKeyDown={(e) => e.preventDefault()}
                    maxDate={new Date().setFullYear(
                      new Date().getFullYear() - 18
                    )}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={employeeModalData?.dob}
                    handleChange={(value) =>
                      handleInputChange({
                        target: {
                          name: "dob",
                          value: value
                            ? `${value?.getFullYear() +
                            "-" +
                            ("0" + (value.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value.getDate()).slice(-2)
                            }`
                            : "",
                        },
                      })
                    }
                    {...(errors.dob && {
                      error: true,
                      helperText: errors.dob,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <CreatableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option._designation_id === +value._designation_id
                  }
                  label="Designation"
                  value={employeeModalData?._designation_id}
                  loading={() =>
                    setDesignationData({
                      load: false,
                      values: designation_options,
                    })
                  }
                  fieldLabel="name"
                  required={true}
                  variant={"contained"}
                  name="_designation_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={designationData}
                  showAddOption={true}
                  OptionName="Type to add new Designation"
                  {...(errors._designation_id && {
                    error: true,
                    helperText: errors._designation_id,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <CreatableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option.ats_work_location_id === +value.ats_work_location_id
                  }
                  label="Work Location"
                  value={employeeModalData?.ats_work_location_id}
                  loading={() =>
                    setWorklocationData({
                      load: false,
                      values: workLocation_options,
                    })
                  }
                  fieldLabel="work_location_name"
                  required={true}
                  variant={"contained"}
                  name="ats_work_location_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={worklocationData}
                  showAddOption={true}
                  OptionName="Type to add new Work Location"
                  {...(errors.ats_work_location_id && {
                    error: true,
                    helperText: errors.ats_work_location_id,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <SelectableSearch
                    label="Reporting Manager"
                    value={employeeModalData?.reportingmanager_id}
                    loading={() => handleEmployees()}
                    fieldLabel="name"
                    required={true}
                    variant={"outlined"}
                    name="reportingmanager_id"
                    size="small"
                    onChangeEvent={handleInputChange}
                    data={employeesData}
                    {...(errors.reportingmanager_id && {
                      error: true,
                      helperText: errors.reportingmanager_id,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <CommonDatePicker
                    required
                    size="small"
                    name="joining_date"
                    label="Date of Joining"
                    pickerType="date"
                    onKeyPress={(e) => e.preventDefault()}
                    onKeyDown={(e) => e.preventDefault()}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={employeeModalData?.joining_date}
                    handleChange={(value) =>
                      handleInputChange({
                        target: {
                          name: "joining_date",
                          value: value
                            ? `${value?.getFullYear() +
                            "-" +
                            ("0" + (value?.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value?.getDate()).slice(-2)
                            }`
                            : "",
                        },
                      })
                    }
                    {...(errors.joining_date && {
                      error: true,
                      helperText: errors.joining_date,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <SelectableSearch
                    label="Business Unit"
                    value={employeeModalData?.businessUnit}
                    loading={() =>
                      setBusinessUnitData({
                        load: false,
                        values: businessunitData,
                      })
                    }
                    fieldLabel="name"
                    variant={"outlined"}
                    name="businessUnit"
                    size="small"
                    onChangeEvent={handleInputChange}
                    data={businessUnitData}
                    {...(errors.businessUnit && {
                      error: true,
                      helperText: errors.businessUnit,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <TextField
                  label="Previous Employee ID"
                  fullWidth
                  name="previous_employee_id"
                  onChange={handleInputChange}
                  value={employeeModalData?.previous_employee_id || ""}
                  size="small"
                />
              </Grid>
              {userData?.f_country_code === "IND" && (
                <Grid
                  key="uan_no"
                  item
                  xs={12}
                  md={12}
                  sx={{ padding: "0.75rem" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      value={employeeModalData.uan_no}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      id="uan_no"
                      label="UAN Number"
                      name="uan_no"
                      placeholder="123456789012"
                      sx={{
                        "& input::placeholder": {
                          color: "#B0B0B0",
                        },
                      }}
                      {...(errors.uan_no && {
                        error: true,
                        helperText: errors.uan_no,
                      })}
                    />
                  </FormControl>
                </Grid>
              )}
              {userData?.f_country_code === "IND" && (
                <Grid
                  key="pan"
                  item
                  xs={12}
                  md={12}
                  sx={{ padding: "0.75rem" }}
                >
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      value={employeeModalData.pan}
                      onChange={handleInputChange}
                      variant="outlined"
                      fullWidth
                      id="pan"
                      label="PAN Number"
                      name="pan"
                      placeholder="AAAAA9999A"
                      sx={{
                        "& input::placeholder": {
                          color: "#B0B0B0",
                        },
                      }}
                      {...(errors?.pan && {
                        error: true,
                        helperText: errors?.pan,
                      })}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <SelectableSearch
                    label="Working Hour Code"
                    value={employeeModalData?.work_hour_configuration_id}
                    loading={() =>
                      setWorkhrData({
                        load: false,
                        values: work_hours_data,
                      })
                    }
                    fieldLabel="work_hour_code"
                    required={true}
                    variant={"outlined"}
                    name="work_hour_configuration_id"
                    size="small"
                    onChangeEvent={handleInputChange}
                    data={workhrData}
                    {...(errors.work_hour_configuration_id && {
                      error: true,
                      helperText: errors.work_hour_configuration_id,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <Box>
                  <Paper
                    className={classes.paper}
                    sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
                  >
                    <Box
                      sx={{
                        marginBottom: "5px",
                        border: "none !important",
                        cursor: "default",
                      }}
                      {...getRootProps({ className: "dropzone" })}
                    >
                      <input {...getInputProps()} />
                      <div className="presentation">
                        <div className="placeholder">
                          {imgfiles.length ? (
                            img
                          ) : employeeModalData?.image ? (
                            <img
                              style={{ borderRadius: "50%", padding: "2px" }}
                              alt="Nothing"
                              width="100%"
                              height="100%"
                              src={imageUrl}
                            />
                          ) : (
                            <>
                              <AddPhotoAlternateIcon />
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                              >
                                Upload Photo
                              </Typography>
                            </>
                          )}
                        </div>
                      </div>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {(imgfiles?.length > 0 || employeeModalData?.image) && (
                        <Tooltip title="Delete Image">
                          <DeleteIcon
                            sx={{ cursor: "pointer" }}
                            onClick={handleDelete}
                            color="error"
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Typography
                      sx={{ margin: "5px auto 0px" }}
                      variant="caption"
                      className="content-sec"
                    >
                      File formats allowed *.jpeg, *.jpg, *.png
                      <br /> max size of 2 MB
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 1.5 }}>
            <Button
              className="mpl-primary-btn"
              variant="contained"
              size="medium"
              onClick={handleNext}
            >
              {editRecord?.employee_id ? "Update" : "Next"}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
              <FormControl
                required
                fullWidth
                error={errors?.core_hr_user_type ? true : false}
                size="small"
              >
                <InputLabel id="recruiter-type-select-label">
                  User Type
                </InputLabel>
                <Select
                  name="core_hr_user_type"
                  labelId="recruiter-type-select-label"
                  id="recruiter-type-select"
                  value={employeeModalData?.core_hr_user_type}
                  label="User Type"
                  onChange={handleInputChange}
                >
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                  <MenuItem value={"Employee"}>Employee</MenuItem>
                </Select>
                <FormHelperText>{errors?.core_hr_user_type}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="Name"
                  name="name"
                  onChange={handleInputChange}
                  value={
                    employeeModalData?.first_name +
                    " " +
                    employeeModalData?.last_name || ""
                  }
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="Email"
                  name="office_email"
                  onChange={handleInputChange}
                  value={employeeModalData?.office_email || ""}
                  size="small"
                />
              </FormControl>
            </Grid>
            {["Employee"].includes(employeeModalData?.core_hr_user_type) && (
              <>
                <Grid item xs={12} md={7} sx={{ padding: "0.75rem" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        name="enable_corehr"
                        checked={employeeModalData?.enable_corehr || false}
                        onChange={handleInputChange}
                      />
                    }
                    label={userData?.f_country_code === "IND" ? "Enable Core HR & LMS" : "Enable Core HR"}
                  />
                </Grid>
                <Grid item xs={12} md={5} sx={{ padding: "0.75rem" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enable_ats"
                        checked={employeeModalData?.enable_ats || false}
                        onChange={handleInputChange}
                      />
                    }
                    label="Enable ATS"
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", mr: 1.5 }}
          >
            <Button
              className="mpl-primary-btn"
              variant="contained"
              size="medium"
              onClick={() => setStep(1)}
            >
              Back
            </Button>
            <Button
              className="mpl-primary-btn"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </>
      )}
      <Popup
        openPopup={successPopup}
        setOpenPopup={setSuccessPopup}
        fullWidth={false}
        maxWidth="md"
        close={false}
        hideDividers={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
            <img src={Success} alt="Success" width={"15%"} />
          </Box>
          <Typography variant="h6"> Employee Created Successfully. </Typography>
          <Typography variant="h6">
            {" "}
            Employee Password Setup Instructions have been sent successfully to{" "}
            <span style={{ color: "#883bd5" }}>
              {employeeModalData?.office_email}
            </span>
          </Typography>
          <Typography variant="h6">
            {" "}
            For any updates on the onboarded employee, please visit My Employee
            Section.{" "}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Button
              onClick={HandleClose}
              variant="contained"
              type="submit"
              className="mpl-primary-btn profile-btn"
              size="small"
              sx={{
                margin: "10px",
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Popup>
      <PayrollSetupPopup
        emp={true}
        payroll={payroll}
        setPayroll={setPayroll}
        currentData={currentData}
        setSuccessPopup={setSuccessPopup}
        setOpenPopup={setOpenPopup}
      />
      {/* Modal for Cropping Image */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 4,
            boxShadow: 24,
            maxWidth: 500,
            width: "100%",
            textAlign: "center",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">Crop Profile Image</Typography>
          {image && (
            <Box sx={{ marginTop: 2 }}>
              <Cropper
                ref={cropperRef}
                src={image}
                style={{
                  height: 300,
                  width: "100%",
                }}
                initialAspectRatio={1}
                aspectRatio={1}
                guides={false}
                movable={true}
                zoomable={true}
                scalable={true}
                cropBoxResizable={false}
                cropBoxMovable={true}
                minCropBoxWidth={200}
                minCropBoxHeight={200}
                background={true}
              />

              <Box sx={{ marginTop: 2 }}>
                <Typography variant="body1">Rotate Image</Typography>
                <Slider
                  value={rotation}
                  min={-180}
                  max={180}
                  step={1}
                  onChange={handleRotationChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}°`}
                />
              </Box>
              <Button
                onClick={handleCrop}
                className="mpl-primary-btn"
                variant="contained"
                size="medium"
              >
                Crop Image
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
      <Popup
        maxWidth="sm"
        openPopup={warning}
        setOpenPopup={setWarning}
        title="Note!"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
            <img src={Note} width={"25%"} alt="note_img" />
          </Box>
          <FormControl>
            <FormLabel id="gender-label">
              <Typography variant="h5">
                Employee No Configuration is incomplete. Please navigate to
                Settings &gt; General &gt; Organization &gt; Employee ID
                Configuration
              </Typography>
            </FormLabel>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <Button
            onClick={() => handleNavigate("General", "4")}
            variant="contained"
            type="submit"
            className="mpl-primary-btn profile-btn"
            size="small"
            sx={{
              margin: "10px",
            }}
          >
            Click Here
          </Button>
        </Box>
      </Popup>
      <Popup
        title={"Employee ID Configuration"}
        openPopup={openConfirmation}
        setOpenPopup={setOpenConfirmation}
        maxWidth="md"
        fullWidth={true}
      >
        <EmployeeID validateOnly={true} setOpenConfirmation={setOpenConfirmation} />
      </Popup>
    </>
  );
};
export default AddEmployeePopup;
