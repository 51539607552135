import React, { useState } from 'react';
import { Typography, Box, Card, CardContent, List, ListItem, ListItemText, Divider, FormControl, Select, MenuItem, Tooltip, IconButton } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from "@mui/material/styles";
import { ConvertDate, ConvertDateTime, getUserData } from '../../../Base/helper/baseFunctions';
import MyTaskPopup from './MyTaskPopup';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetMyTaskAction, UpdateMyTaskAction } from '../../../../../store/ATS/MyTask/Action';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const MyTasks = ({ mode, setMode, openPopup, setOpenPopup, popupData, setPopupData }) => {
    const theme = useTheme();
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const today = year + '-' + month.toString().padStart(2, '0') + '-' + day;
    const userData = getUserData();
    const dispatch = useDispatch()
    const { taskData } = useSelector((state) => state.AtsReducer?.AtsTaskReducer);
    const [sort,setSort]= useState(false);
    const [sorttaskdata,setsorttaskdata] = useState(taskData);

    useEffect(() => {
        if(userData){
            dispatch(GetMyTaskAction({ type: "assigned_to" }))
        
        }
       
        //eslint-disable-next-line
    },[])
    useEffect(()=>{
        setsorttaskdata(taskData);
    },[taskData])

    const handleAdd = () => {
        setPopupData([]);
        setMode("Add")
        setOpenPopup(true);
    };
    const handleSort =()=>{
        setSort(!sort);
        sorting();
    }
    const sorting = (e)=>{
        
         const sortedData = [...taskData].sort((a, b) => {
            return !sort ? new Date(a.due_date) - new Date(b.due_date) : 0;
          });
          setsorttaskdata(sortedData);
             
         }

    const handleEdit = (task) => {
        setPopupData(task);
        setMode("Edit")
        setOpenPopup(true);
    };


    const getRatingIcon = (priority) => {
        if (priority === 'High') return <KeyboardDoubleArrowUpIcon style={{ color: 'red' }} fontSize="small" />;
        if (priority === 'Medium') return <DragHandleIcon style={{ color: 'green' }} fontSize="small" />;
        if (priority === 'Low') return <KeyboardDoubleArrowDownIcon style={{ color: 'blue' }} fontSize="small" />;
        return null;
    };

    const handlePriorityClick = (e, id) => {
        e.stopPropagation();
        let mytask = taskData?.filter(res => +res?.task_id === +id)[0]
        dispatch(UpdateMyTaskAction({
            assigned_to: mytask.assigned_to,
            title: mytask?.title,
            task: mytask.task,
            task_id: mytask?.task_id,
            status: mytask.status,
            due_date: mytask.due_date,
            priority: mytask.priority === "Low" ? "Medium" : mytask.priority === "Medium" ? "High" : mytask.priority === "High" && "Low",
            proof: null,
            proof_name: mytask.proof || null,
            comments: mytask.comments,
            completed_date: mytask.completed_date || null,
            remainder_date: null,
            is_my_task: true,
            message: "Priority Updated Successfully"
        }))
    }


    const handleInputChange = (e, id) => {
        e.stopPropagation();
        let mytask = taskData?.filter(res => +res?.task_id === +id)[0]
        dispatch(UpdateMyTaskAction({
            assigned_to: mytask.assigned_to,
            title: mytask?.title,
            task: mytask.task,
            task_id: mytask?.task_id,
            status: e.target.value,
            due_date: mytask.due_date,
            priority: mytask.priority,
            proof: null,
            proof_name: mytask.proof || null,
            comments: mytask.comments,
            completed_date: mytask.completed_date || null,
            remainder_date: null,
            is_my_task: true,
            message: "Priority Updated Successfully"
        }))
    }
   
    return (
        <Box sx={{ display: "block", minHeight: "39vh" }}>
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    py: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 2,
                    }}
                >
                    <Typography sx={{ pl: 2 }} variant="h6">To-Do List</Typography>
                    <Tooltip title="Add Task">
                        <IconButton onClick={handleAdd}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <List sx={{ px: 2 }}>
                    <ListItem sx={{ height: "12px" }}>
                        <ListItemText primary="Priority" sx={{ flex: '0 0 10%' }} />
                        <ListItemText primary="Task" sx={{ flex: '0 0 40%' }} />
                        <ListItemText primary="Assigned By" sx={{ flex: '0 0 20%' }} />
                        <ListItemText primary="Due Date"  secondary={sort?  <ArrowUpwardIcon onClick={()=>(handleSort())} fontSize='inherit' sx={{width:'1rem',height:'1rem', marginTop:'0.3rem',marginLeft:'0.2rem',cursor:'pointer'}}/> :<ArrowDownwardIcon onClick={()=>(handleSort())}  fontSize='inherit' sx={{width:'1rem',height:'1rem', marginTop:'0.4rem',marginLeft:'0.2rem',cursor:'pointer'}}/>} sx={{ flex: '0 0 20%' }} style={{display:'flex',flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}} />
                        <ListItemText primary="Status" sx={{ flex: '0 0 10%' }} style={{display:'flex',justifyContent : 'flex-start', alignItems:'center'}} />
                    </ListItem>
                </List>
                <Divider sx={{ mb: -1 }} />
            </Box>
            <Box sx={{ maxHeight: "30vh", overflowY: "scroll", backgroundColor: "white" }}>
                <Card sx={{ mt: -3, borderRadius: "0px" }}>
                    <CardContent >
                        {taskData.length === 0 ? (
                            <Typography variant="body1" align="center" p={10}>
                                Well done. All tasks has been catched up.
                            </Typography>
                        ) : (
                            <List>
                                {sorttaskdata.map(task => (
                                    <Card key={task?.task_id} sx={{ mb: 1, height: "33px" }}>
                                        <ListItem
                                            sx={{
                                                '&:hover': {
                                                    cursor: 'pointer'
                                                },
                                                mt: -1,
                                                backgroundColor: task.due_date < today ? theme?.palette?.primary?.bg : "",
                                                fontSize:"13px"
                                            }}
                                            onClick={() => handleEdit(task)}
                                        >
                                            <ListItemText sx={{ flex: '0 0 10%' }} onClick={e => handlePriorityClick(e, task.task_id)}>
                                                {getRatingIcon(task.priority)}
                                            </ListItemText>
                                            <ListItemText primary={task.title} sx={{ flex: '0 0 40%' }} />
                                            <ListItemText primary={task.name} sx={{ flex: '0 0 20%' }} />
                                            <ListItemText primary={ConvertDateTime(task.due_date)} sx={{ flex: '0 0 15%' }} />
                                            <ListItemText sx={{ flex: '0 0 15%' }} onClick={e => e.stopPropagation()}>
                                                {true ? <FormControl fullWidth size="small">
                                                    <Select
                                                        labelId="status"
                                                        id="status"
                                                        value={task.status}
                                                        variant='standard'
                                                        label="Status"
                                                        onChange={(e) => handleInputChange(e, task.task_id)}
                                                    >
                                                        <MenuItem value={'Yet to Start'}>Yet to Start</MenuItem>
                                                        <MenuItem value={'Started'}>Started</MenuItem>
                                                        <MenuItem value={'Completed'}>Completed</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                    : task.status}
                                            </ListItemText>
                                        </ListItem>
                                    </Card>
                                ))}
                            </List>
                        )}
                    </CardContent>
                </Card>
            </Box>
            <MyTaskPopup my_task={true} mode={mode} setMode={setMode} openPopup={openPopup} setOpenPopup={setOpenPopup} data={popupData} setData={setPopupData} />
        </Box >
    );
};

export default MyTasks;