import MaterialTable from "material-table";
import { useEffect } from "react";
import {
    Box,
    Button,
    Select,
    Typography,
    MenuItem,
    Grid,
    CircularProgress,
    IconButton,
    TextField,
    FormControl,
    FormLabel
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import Base from "../../../Base/views/Base";
import { getUserData } from "../../../Base/helper/baseFunctions";
import Popup from "../../../Base/views/Popup";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_EMPLOYEE_API_URL } from "../../../../Constants/baseConstant";
import { AddEmployeeStagetoMainHelper, DeleteEmployeeStageHelper, GetEmployeeStageHelper, UpdateEmployeeStageHelper } from "../../../Finance/helper/BulkUpload/BulkUploadHelper";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import EmployeePicUpload from "./EmployeePicUpload";
import { GetEmpIDHelper } from "../../../Ancillary/CompanyDetails/helper/CompanyDetailsHelper";
import Note from "../../Image/alert.png";
import EmployeeID from "../../../AdminSettings/views/Employee/EmployeeID";
const EmployeeBulkUpload = () => {
    let userData = getUserData();
    const [, setOpenBulk] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [editedRecord, setEditedRecord] = useState(null);
    const [step, setStep] = useState(1);
    const [id, setId] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [errors, setErrors] = useState({});
    const [editerrors, setEditErrors] = useState({});
    const [empdata, setEmpData] = useState([]);
    const [file, setFile] = useState(null);
    const [warning, setWarning] = useState(false);
    const initialColMapping = [
        { excel: "", db: "previous_employee_id", label: "Prev.Emp ID", required: true },
        { excel: "", db: "first_name", label: "First Name", required: true },
        { excel: "", db: "last_name", label: "Last Name", required: true },
        { excel: "", db: "ats_work_location_id", label: "Work Location", required: true },
        { excel: "", db: "reportingmanager_id", label: "Reporting Manager", required: true },
        { excel: "", db: "joining_date", label: "Date of Joining", required: true },
        { excel: "", db: "office_email", label: "Email", required: false },
        { excel: "", db: "dob", label: "Date of Birth", required: false },
        { excel: "", db: "_designation_id", label: "Designation", required: false },
        { excel: "", db: "businessUnit", label: "Business Unit", required: false },
    ];
    if (userData?.f_country_code === "IND") {
        initialColMapping.push(
            { excel: "", db: "pan_no", label: "PAN Number", required: false },
            { excel: "", db: "uan_no", label: "UAN Number", required: false }
        );
    }
    const [excelDbColMapping, setExcelDbColMapping] = useState(initialColMapping);
    const [rowHeaders, setRowHeaders] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [empno, setEmpNo] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false)
    useEffect(() => {
        GetEmployeeStageHelper().then((res) => {
            setEmpData(res?.data?.data?.[0]?.employee_details);
            setId(res?.data?.data?.[0]?.stage_employee_pk)
        });
    }, []);
    useEffect(() => {
        if (!openConfirmation) {
            GetEmpIDHelper().then((res) => {
                setEmpNo(res?.data?.data?.length ? res.data.data[0] : null);
            });
        }
    }, [openConfirmation]);
    const handleSelectFileClick = () => {
        if (empdata?.length > 0) {
            setShowConfirmPopup(true); // Show confirmation popup if data exists
        } else {
            document.getElementById("contained-button-file").click(); // Directly trigger file selection if no data exists
        }
    };
    const fileSelected = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;
        processFile(selectedFile, e);
    };
    const processFile = (selectedFile, event) => {
        setFile(selectedFile);
        setExcelDbColMapping(initialColMapping);
        setUploadProgress(0);
        const fileReader = new FileReader();
        toast.info("Reading File. Please wait");
        fileReader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const firstRowData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                range: 0,
            });
            setRowHeaders(firstRowData[0]);
            toast.success("File imported");
        };
        fileReader.readAsArrayBuffer(selectedFile);
        if (event) event.target.value = ""; // Reset the input
    };
    function setColMapping(value, dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = value;
            });
            return [...prev];
        });
    }
    function clearColMapping(dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = "";
            });
            return [...prev];
        });
    }
    const validate = () => {
        const temp = {};
        if (!file) temp.file = "File is required";
        const unmappedRequiredFields = excelDbColMapping.filter(
            (col) => col.required && !col.excel
        );
        if (unmappedRequiredFields.length > 0) {
            toast.error("Please map all required fields.")
            temp.columnMapping = "Please map all required fields.";
        }
        setErrors(temp);
        return Object.keys(temp).length === 0; // Return true if no errors
    };
    async function addEmployeeStage(e) {
        if (empno) {
            if (!validate()) return;
            e.preventDefault();
            try {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("user_id", userData?.employee_id);
                formData.append("domain_name", userData?.domain);
                formData.append("column_mapping", JSON.stringify(excelDbColMapping));
                toast.info("Uploading file");
                try {
                    setUploadProgress(0);
                    await axiosInstance
                        .post("/employee/uploadEmployeeFile", formData, {
                            baseURL: HRMS_EMPLOYEE_API_URL,
                            headers: {
                                Accept: "multipart/form-data",
                                "Content-Type": "multipart/form-data",
                            },
                            onUploadProgress: (progress) => {
                                const percentCompleted = Math.round(
                                    (progress.loaded * 90) / progress.total
                                );
                                setUploadProgress(percentCompleted);
                                if (percentCompleted > 89) {
                                    toast.info("Processing...");
                                }
                            },
                        })
                        .then((response) => {
                            const result = response?.data?.statuscode;
                            setEmpData(response?.data?.data?.[0]?.employee_details)
                            setId(response?.data?.data?.[0]?.stage_employee_pk)
                            if (result) {
                                toast.success("File processed successfully!");
                            }
                        });

                    setUploadProgress(100);
                    setFile(null);
                    // refreshCatalog();
                } catch (error) {
                    setUploadProgress(0);
                    toast.warning(error.response?.data?.message || error.message);
                }
            } catch (error) {
                console.error("Unexpected error", error);
            }
        }
        else {
            setWarning(true);
        }
    }
    async function handleSubmit(e) {
        AddEmployeeStagetoMainHelper().then((res) => {
            if (res.data.statuscode === 200) {
                setStep(2);
                setEmpData([]);
            }

        });
    }
    const handleNavigate = (menu, value) => {
        setOpenConfirmation(true);
        setWarning(false);
    };
    const handleCancel = () => {
        setShowConfirmPopup(false);
        DeleteEmployeeStageHelper({ stage_employee_id: id }).then((res) => {
            setEmpData([]); // Clear table data
            setFile(null); // Allow new file selection
            document.getElementById("contained-button-file").click();
        });
    };
    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };
    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleDelete = () => {
        const updatedData = empdata?.filter(emp => emp?.previous_employee_id !== currentData?.previous_employee_id); // Use unique field
        setEmpData(updatedData); // Update state
        toast.success("Record deleted successfully!");
        setOpenDialog(false);
        UpdateEmployeeStageHelper({ stage_employee_id: id, employee_details: JSON.stringify(updatedData) }).then((res) => {
        });
    };
    const handleEditSubmit = (updatedRecord) => {
        if (!validateFields()) return;
        const updatedData = empdata?.map(emp =>
            emp?.tableData?.id === updatedRecord?.tableData?.id ? updatedRecord : emp // Replace edited record
        );
        setEmpData(updatedData); // Update state
        UpdateEmployeeStageHelper({ stage_employee_id: id, employee_details: JSON.stringify(updatedData) }).then((res) => {
        });
        setOpenPopup(false); // Close popup
        toast.success("Record updated successfully!");
    };
    const validateFields = () => {
        const tempErrors = {};
        if (!editedRecord?.previous_employee_id) tempErrors.previous_employee_id = "Prev.Emp ID is required.";
        if (!editedRecord?.first_name) tempErrors.first_name = "First Name is required.";
        if (!editedRecord?.last_name) tempErrors.last_name = "Last Name is required.";
        if (!editedRecord?.ats_work_location_id) tempErrors.ats_work_location_id = "Work Location is required.";
        if (!editedRecord?.reportingmanager_id) tempErrors.reportingmanager_id = "Reporting Manager is required.";
        if (!editedRecord?.joining_date) tempErrors.joining_date = "Date of Joining is required.";
        setEditErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // Return true if no errors
    };
    return (
        <>
            <Base>
                {step === 1 ? (
                    <>
                        <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
                            <Box>
                                <form
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        gap: "16px", // Adjust gap between elements
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {file ? (
                                            <p style={{ margin: 0 }}>{file.name.substring(0, 20)}...</p>
                                        ) : errors.selectedFile ? (
                                            <p style={{ color: "red", margin: 0 }}>No file chosen</p>
                                        ) : (
                                            <p style={{ margin: 0 }}>No file chosen</p>
                                        )}
                                    </div>
                                    <label htmlFor="contained-button-file" style={{ display: "none" }}>
                                        <input
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            id="contained-button-file"
                                            type="file"
                                            style={{ display: "none" }} // Hidden input
                                            multiple={false}
                                            onChange={fileSelected} // Handle file selection
                                        />
                                    </label>
                                    <Button
                                        variant="contained"
                                        className="mpl-primary-btn"
                                        component="span"
                                        sx={{ marginBottom: "5px" }}
                                        onClick={handleSelectFileClick} // Trigger confirmation or file input
                                    >
                                        Select File
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="mpl-primary-btn"
                                        sx={{ marginBottom: "5px", marginRight: "5px" }}
                                        disabled={empdata?.length === 0 || empdata === undefined}
                                        onClick={handleSubmit}
                                    >
                                        Next
                                    </Button>
                                </form>
                            </Box>

                            <MaterialTable
                                columns={[
                                    {
                                        title: "Prev.Emp ID",
                                        cellStyle: { padding: "10px 10px 10px 13px" },
                                        field: "previous_employee_id",
                                        grouping: false,
                                    },
                                    {
                                        title: "First Name",
                                        cellStyle: { padding: "10px 10px 10px 13px" },
                                        field: "first_name",
                                        grouping: false,
                                    },
                                    {
                                        title: "Last Name",
                                        cellStyle: { padding: "10px 10px 10px 13px" },
                                        field: "last_name",
                                        grouping: false,
                                    },
                                    {
                                        title: "Email",
                                        cellStyle: { padding: "10px 10px 10px 13px" },
                                        field: "office_email",
                                        grouping: false,
                                    },
                                    {
                                        title: "D.O.J",
                                        cellStyle: { padding: "10px 10px 10px 13px" },
                                        field: "joining_date",
                                    },
                                    {
                                        title: "Work Location",
                                        cellStyle: { padding: "10px 10px 10px 13px" },
                                        field: "ats_work_location_id",
                                    },
                                    {
                                        title: "R.Manager",
                                        cellStyle: { padding: "10px 10px 10px 13px" },
                                        field: "reportingmanager_id",
                                    },
                                ]}
                                data={empdata}
                                options={{
                                    maxBodyHeight: "65vh",
                                    minBodyHeight: "65vh",
                                    rowStyle: { fontSize: "13px" },
                                    headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                                    sorting: true,
                                    search: true,
                                    searchText: "",
                                    searchFieldAlignment: "right",
                                    searchAutoFocus: true,
                                    searchFieldVariant: "standard",
                                    filtering: false,
                                    paging: true,
                                    pageSizeOptions: [25, 50],
                                    pageSize: 25,
                                    paginationType: "stepped",
                                    showFirstLastPageButtons: false,
                                    paginationPosition: "bottom",
                                    exportButton: false,
                                    exportAllData: false,
                                    exportFileName: "Employees",
                                    addRowPosition: "first",
                                    selection: false,
                                    actionsColumnIndex: -1,
                                    showSelectAllCheckbox: true,
                                    showTextRowsSelected: true,
                                    grouping: false,
                                    columnsButton: false,
                                }}
                                actions={[
                                    {
                                        icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                                        tooltip: "Edit",
                                        onClick: (_event, rowData) => { handleEdit(rowData) },
                                    },
                                    {
                                        icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                                        tooltip: "Delete",
                                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                                    },
                                ]}
                                title="Employee Bulk Upload"
                            />
                            <Popup
                                maxWidth="sm"
                                fullWidth={true}
                                title="Pls map the column headers from your sheet & Meepl Fields"
                                openPopup={file}
                                close={false}
                                setOpenPopup={setOpenBulk}
                            >
                                <Box>
                                    <Box
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 1fr",
                                            alignItems: "center",
                                            padding: 5,
                                            borderBottom: "1px solid gray",
                                        }}
                                    >
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            Imported Headers
                                        </Typography>
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            Meepl Fields
                                        </Typography>
                                    </Box>
                                    {excelDbColMapping.map((col) => (
                                        <Grid
                                            key={col.db}
                                            container
                                            spacing={2}
                                            style={{
                                                padding: 5,
                                                alignItems: "center",
                                            }}
                                        >
                                            <Grid sx={{ mt: 2 }} item xs={6} container alignItems="center" spacing={1}>
                                                <Grid item xs>
                                                    <Select
                                                        onChange={(e) => setColMapping(e.target.value, col.db)}
                                                        value={col.excel}
                                                        fullWidth
                                                        required={col.required}
                                                        variant="standard"
                                                    >
                                                        {rowHeaders.map((header) => (
                                                            <MenuItem key={header} value={header}>
                                                                {header}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Grid>
                                                {col.excel && (
                                                    <Grid item>
                                                        <IconButton onClick={() => clearColMapping(col.db)}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid sx={{ mt: 2 }} item xs={6}>
                                                <Typography>
                                                    {col.label || col.db}
                                                    {col.required && " *"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>

                                <Box sx={{ display: "flex", justifyContent: "space-between", m: 2 }}>
                                    <Button
                                        onClick={() => {
                                            setFile(null);
                                            setRowHeaders([]);
                                            setOpenBulk(false);
                                        }}
                                        className="mpl-primary-btn"
                                        disabled={uploadProgress > 0 && uploadProgress < 100}
                                    >
                                        Close
                                    </Button>

                                    <div>
                                        {uploadProgress > 0 && uploadProgress < 100 ? (
                                            <CircularProgress disableShrink />
                                        ) : (
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                className="mpl-primary-btn"
                                                loading={uploadProgress > 0 && uploadProgress < 100}
                                                onClick={addEmployeeStage}
                                            >
                                                Upload
                                            </Button>
                                        )}
                                    </div>

                                </Box>
                            </Popup>
                            <Popup
                                maxWidth="xs"
                                fullWidth={true}
                                title="Attention Needed!"
                                openPopup={showConfirmPopup}
                                close={false}
                                setOpenPopup={setShowConfirmPopup}
                            >
                                <Typography variant="h5"> You will lose the imported data. Do you want to submit it before proceeding? </Typography>
                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                    <Button
                                        onClick={handleCancel}
                                        className="mpl-primary-btn"
                                    >
                                        No
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            setShowConfirmPopup(false); // Close popup and retain existing data
                                        }}
                                        className="mpl-primary-btn"
                                    >
                                        Yes
                                    </Button>

                                </Box>
                            </Popup>
                            <Popup
                                title={"Edit Employee"}
                                openPopup={openPopup}
                                setOpenPopup={setOpenPopup}
                            >
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleEditSubmit(editedRecord); // Call edit submit with updated record
                                }}>
                                    <TextField
                                        size="small"
                                        label="Previous Employee ID"
                                        value={editedRecord?.previous_employee_id || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, previous_employee_id: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        required
                                        {...(editerrors.previous_employee_id && {
                                            error: true,
                                            helperText: editerrors.previous_employee_id,
                                        })}
                                    />
                                    <TextField
                                        size="small"
                                        label="First Name"
                                        value={editedRecord?.first_name || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, first_name: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        required
                                        {...(editerrors.first_name && {
                                            error: true,
                                            helperText: editerrors.first_name,
                                        })}
                                    />
                                    <TextField
                                        size="small"
                                        label="Last Name"
                                        value={editedRecord?.last_name || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, last_name: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        required
                                        {...(editerrors.last_name && {
                                            error: true,
                                            helperText: editerrors.last_name,
                                        })}
                                    />

                                    <TextField
                                        size="small"
                                        label="Work Location"
                                        value={editedRecord?.ats_work_location_id || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, ats_work_location_id: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        required
                                        {...(editerrors.ats_work_location_id && {
                                            error: true,
                                            helperText: editerrors.ats_work_location_id,
                                        })}
                                    />
                                    <TextField
                                        size="small"
                                        label="Reporting Manager"
                                        value={editedRecord?.reportingmanager_id || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, reportingmanager_id: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                        required
                                        {...(editerrors.reportingmanager_id && {
                                            error: true,
                                            helperText: editerrors.reportingmanager_id,
                                        })}
                                    />
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <CommonDatePicker
                                            required
                                            size="small"
                                            name="joining_date"
                                            label="Date of Joining"
                                            pickerType="date"
                                            onKeyPress={(e) => e.preventDefault()}
                                            onKeyDown={(e) => e.preventDefault()}
                                            pickerFormat="dd/MM/yyyy"
                                            DefaultDTvalue={editedRecord?.joining_date}
                                            handleChange={(value) =>
                                                setEditedRecord({
                                                    ...editedRecord,
                                                    joining_date: value
                                                        ? `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}`
                                                        : "",
                                                })
                                            }
                                            {...(editerrors.joining_date && {
                                                error: true,
                                                helperText: editedRecord.joining_date,
                                            })}
                                        />
                                    </FormControl>
                                    <TextField
                                        size="small"
                                        label="Email"
                                        value={editedRecord?.office_email || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, office_email: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                    />
                                    <FormControl fullWidth sx={{ mb: 3 }}>
                                        <CommonDatePicker
                                            size="small"
                                            name="dob"
                                            label="Date of Birth"
                                            pickerType="date"
                                            onKeyPress={(e) => e.preventDefault()}
                                            onKeyDown={(e) => e.preventDefault()}
                                            maxDate={new Date().setFullYear(
                                                new Date().getFullYear() - 18
                                            )}
                                            pickerFormat="dd/MM/yyyy"
                                            DefaultDTvalue={editedRecord?.dob}
                                            handleChange={(value) =>
                                                setEditedRecord({
                                                    ...editedRecord,
                                                    dob: value
                                                        ? `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}`
                                                        : "",
                                                })
                                            }
                                            sx={{ mb: 3 }}
                                        />
                                    </FormControl>
                                    <TextField
                                        size="small"
                                        label="Designation"
                                        value={editedRecord?._designation_id || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, _designation_id: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                    />
                                    <TextField
                                        size="small"
                                        label="Business Unit"
                                        value={editedRecord?.businessUnit || ""}
                                        onChange={(e) => setEditedRecord({ ...editedRecord, businessUnit: e.target.value })}
                                        fullWidth
                                        sx={{ mb: 3 }}
                                    />
                                    {userData?.f_country_code === "IND" && (
                                        <TextField
                                            size="small"
                                            label="PAN Number"
                                            value={editedRecord?.pan_no || ""}
                                            onChange={(e) => setEditedRecord({ ...editedRecord, pan_no: e.target.value })}
                                            fullWidth
                                            sx={{ mb: 3 }}
                                        />
                                    )}
                                    {userData?.f_country_code === "IND" && (
                                        <TextField
                                            size="small"
                                            label="UAN Number"
                                            value={editedRecord?.uan_no || ""}
                                            onChange={(e) => setEditedRecord({ ...editedRecord, uan_no: e.target.value })}
                                            fullWidth
                                            sx={{ mb: 3 }}
                                        />
                                    )}

                                    {/* Add other fields similarly */}
                                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                        <Button onClick={() => setOpenPopup(false)} className="mpl-primary-btn">Cancel</Button>
                                        <Button type="submit" variant="contained" className="mpl-primary-btn">
                                            Save
                                        </Button>
                                    </Box>
                                </form>
                            </Popup>
                            <ConfirmationDialog
                                title="Do you wish to proceed?"
                                openDialog={openDialog}
                                closeDialog={setOpenDialog}
                                OkButtonText="Delete"
                                onSubmit={handleDelete}
                            />
                            <Popup
                                title={"Employee ID Configuration"}
                                openPopup={openConfirmation}
                                setOpenPopup={setOpenConfirmation}
                                maxWidth="md"
                                fullWidth={true}
                            >
                                <EmployeeID validateOnly={true} setOpenConfirmation={setOpenConfirmation} />
                            </Popup>
                            <Popup
                                maxWidth="sm"
                                openPopup={warning}
                                setOpenPopup={setWarning}
                                title="Note!"
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
                                        <img src={Note} width={"25%"} alt="note_img" />
                                    </Box>
                                    <FormControl>
                                        <FormLabel id="gender-label">
                                            <Typography variant="h5">
                                                Employee No Configuration is incomplete. Please navigate to Settings &gt; General  &gt; Organization &gt; Employee ID Configuration
                                            </Typography>
                                        </FormLabel>
                                    </FormControl>

                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <Button
                                        onClick={() => handleNavigate("General", "4")}
                                        variant="contained"
                                        type="submit"
                                        className="mpl-primary-btn profile-btn"
                                        size="small"
                                        sx={{
                                            margin: "10px",
                                        }}
                                    >
                                        Click Here
                                    </Button>
                                </Box>
                            </Popup>
                        </Box>
                    </>
                ) : (
                    <>
                        <EmployeePicUpload setStep={setStep} />
                    </>
                )}
            </Base>
        </>
    );
};

export default EmployeeBulkUpload;