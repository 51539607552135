
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddWorkHoursAction, DeleteWorkHoursAction } from "../../../../../store/Leave/WeekOff/Action";

const WorkingHours = () => {
    const dispatch = useDispatch()
    const { work_hours_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);

    return (
        <Box>
            <MaterialTable
                columns={[
                    {
                        title: "Work Hour Code", cellStyle: { padding: "0px 10px" }, field: "work_hour_code",
                        editComponent: props => (
                            <TextField
                                required={true}
                                variant="standard"
                                disabled
                                value={props?.value}
                                onChange={e => props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        )
                    },
                    {
                        title: "Work Hour", cellStyle: { padding: "0px 10px" }, field: "working_hours", validate: rowData => rowData?.working_hours === '' || rowData?.working_hours === undefined ? 'Working Hours cannot be empty' : '',
                        editComponent: props => (
                            <TextField
                                required={true}
                                variant="standard"
                                value={props?.value}
                                onChange={e => props.onChange(e.target.value)}
                                error={props?.error}
                                helperText={props?.helperText}
                            />
                        )
                    },
                ]}
                data={work_hours_data || []}
                icons={{
                    Add: () => <AddIcon />,
                    Delete: () => <DeleteIcon />
                }}
                editable={{
                    isEditable: rowData => false,
                    isDeletable: rowData => !rowData?.is_default,
                    onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(AddWorkHoursAction(newRow))
                            setTimeout(() => resolve(), 500);
                        }),
                    onRowDelete: (selectedRow) =>
                        new Promise((resolve, reject) => {
                            dispatch(DeleteWorkHoursAction({ work_hour_configuration_id: selectedRow?.work_hour_configuration_pk }))
                            setTimeout(() => resolve(), 1000);
                        }),
                }}
                options={{
                    maxBodyHeight: 250,
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                    sorting: true,
                    search: false,
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [5, 25],
                    pageSize: 5,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    selection: false,
                    actionsColumnIndex: -1,
                    grouping: false,
                    addRowPosition: "first",
                }}
                title=""

            />
        </Box>
    );
}
export default WorkingHours;