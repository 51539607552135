import React from 'react';
import Popup from "../../../../Base/views/Popup";
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react'
import { GetUpcomingHolidaysAction } from '../../../../../../store/Leave/HolidayCalendar/Action';
import MaterialTable, { MTableToolbar } from 'material-table';
import { ConvertDate } from "../../../../Base/helper/baseFunctions";
import { GetYearlyHolidayCalendarAction } from '../../../../../../store/Leave/YearlyHolidayCalendar/Action';

const ViewHolidayCalendar = ({
    openPopup,
    setOpenPopup,
    Holiday
}) => {
    const dispatch = useDispatch();
    const { upcomingHolidaysData } = useSelector(state => state?.LeaveReducer.HolidayCalendarReducer);
    const { holidayData } = useSelector(state => state?.LeaveReducer?.YearlyHolidayCalendarReducer)
    useEffect(() => {
        if (Holiday) {
            dispatch(GetYearlyHolidayCalendarAction({ year: 0 }));
        }
        else {
            dispatch(GetUpcomingHolidaysAction());
        }
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <Popup
                title={Holiday ? "Holiday Calendar" : "Upcoming Holidays"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                fullWidth={true}
            >
                <Box margin="0px">
                    <MaterialTable
                        columns={[
                            {
                                title: "Date", field: "date", cellStyle: { padding: "10px" },
                                render: rowData => {
                                    return rowData?.date ? ConvertDate(rowData.date) : ""
                                }
                            },
                            { title: "Name", field: "name", cellStyle: { padding: "10px" }, },
                        ]}
                        data={Holiday ? holidayData : upcomingHolidaysData}
                        options={{
                            maxBodyHeight: 265,
                            rowStyle: { fontSize: "13px", border: "1px" },
                            headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                            sorting: true,
                            search: true,
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            paging: true,
                            pageSizeOptions: [25, 50],
                            pageSize: 25,
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            paginationPosition: "bottom",
                        }}
                        title=""
                        components={{
                            Toolbar: (props) => (
                                <Box sx={{ minHeight: "5px" }}>
                                    <MTableToolbar {...props} />
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </Popup>
        </>
    )
}

export default ViewHolidayCalendar;