/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import Popup from "../../../Base/views/Popup";
import BankModal from "./BankModal";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Success from "../../../MyEmployee/Image/check.png";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  DeleteEmployeeBankAction,
  GetEmployeeBankAction,
} from "../../../../../store/Employee/Bank/Action";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid,
  Paper,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import { getUserData } from "../../../Base/helper/baseFunctions";
import history from "../../../../../history";
import { UpdateEmployeeOnboardStatusHelper } from "../../../Ancillary/ConfigureStatus/helper/ConfigureStatusHelper";
import { AddMenuHeadingAction } from "../../../../../store/Menu/Action";
import { createStyles, makeStyles } from "@mui/styles";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetEmployeePayrollProfileDetailAction, UpdateEmployeePayrollProfileDetailAction } from "../../../../../store/Employee/PayrollProfile/Action";
import { GetBusinessUnitAction } from "../../../../../store/Ancillary/BusinessUnit/Action";
import { EmployeeValidateHelper } from "../../helper/EmployeeHelper";

const BankDetails = ({
  setCurrentMode,
  OnboardingEmpData,
  disableEdit,
  newdata,
  currentMode,
  setActiveStep,
  OnboardEmployee,
}) => {
  const data = getUserData();
  const [editedRecord, setEditedRecord] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMoreVertIcon = Boolean(anchorEl);
  const handleMoreVertIconClose = () => {
    setAnchorEl(null);
  };
  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
      },
    })
  );
  const classes = useStyles();

  const initialValues = {
    bu_id: "",
    pan: "",
    uan_no: "",
    pf_no: "",
    esi_no: "",
    international_worker: false,
  };
  const dispatch = useDispatch();
  const { empBankData } = useSelector(
    (state) => state?.EmployeeReducer?.EmployeeBankReducer
  );
  const { payrollProfileData, isLoading, success } = useSelector(
    (state) => state?.EmployeeReducer?.EmployeePayrollProfileDetailReducer);
  const { businessunitData } = useSelector(
    (state) => state.AncillaryReducer?.BusinessUnitReducer
  );
  const [workDetails, setWorkDetails] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [disableWorkEdit, setdisableWorkEdit] = useState(currentMode === "add" ? false : true)
  const [businessUnitData, setBusinessUnitData] = useState({
    load: true,
    values: []
  })
  const handleConfirmDelete = (rowData) => {
    setAnchorEl(false);
    setOpenDialog(true);
    setCurrentData(rowData);
  };

  const handleAdd = () => {
    setEditedRecord();
    setOpenPopup(true);
  };
  const handleEdit = (bankDetail) => {
    setEditedRecord(bankDetail);
    setOpenPopup(true);
  }
  const handleDelete = () => {
    setOpenDialog(false);
    var id = [];
    if (Array.isArray(currentData)) {
      currentData.forEach((item) => {
        id.push(item.emp_bankdet_id);
      });

      if (currentMode === "addemployee") {
        dispatch(
          DeleteEmployeeBankAction({
            emp_bankdet_id: `{${id}}`,
            employee_id: newdata?.employee_id,
          })
        );
      } else {
        dispatch(
          DeleteEmployeeBankAction({
            emp_bankdet_id: `{${id}}`,
            employee_id: OnboardingEmpData
              ? OnboardingEmpData?.employee_id
              : data?.employee_id,
          })
        );
      }
    } else if (currentMode === "addemployee") {
      dispatch(
        DeleteEmployeeBankAction({
          emp_bankdet_id: `{${currentData?.emp_bankdet_id}}`,
          employee_id: newdata?.employee_id,
        })
      );
    } else {
      dispatch(
        DeleteEmployeeBankAction({
          emp_bankdet_id: `{${currentData?.emp_bankdet_id}}`,
          employee_id: OnboardingEmpData
            ? OnboardingEmpData?.employee_id
            : data?.employee_id,
        })
      );
    }
  };

  useEffect(() => {
    if (currentMode !== "addemployee") {
      if (payrollProfileData?.[0]) {
        setWorkDetails({
          ...payrollProfileData?.[0],
          domain: data?.domain,
          employee_id: payrollProfileData?.[0]?.employee_id,
          employmenttype_id: {
            employmenttype_id: payrollProfileData?.[0]?.employmenttype_id,
            etype_name: payrollProfileData?.[0]?.employmenttype,
          },
          paygrade_id: {
            paygrade_id: payrollProfileData?.[0]?.paygrade_id,
            name: payrollProfileData?.[0]?.paygrade,
          },
          costcenter_id: {
            costcenter_id: payrollProfileData?.[0]?.costcenter_id,
            name: payrollProfileData?.[0]?.costcenter,
          },
          hrmanager_id: {
            employee_id: payrollProfileData?.[0]?.hrmanager_id,
            name: payrollProfileData?.[0]?.hrmanager,
          },
          bu_id: payrollProfileData?.[0]?.bu_id
            ? {
              bu_id: payrollProfileData?.[0]?.bu_id,
              name: payrollProfileData?.[0]?.bu_name
            }
            : "",
          ats_work_location_id: payrollProfileData?.[0]?.ats_work_location_id
            ? {
              ats_work_location_id: payrollProfileData?.[0]?.ats_work_location_id,
              work_location_name: payrollProfileData?.[0]?.work_location_name,
            }
            : "",
        });

      } else {
        setWorkDetails({ ...initialValues });
      }
    }
  }, [payrollProfileData])


  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("pf_no" in fieldValues) {
      temp.pf_no =
        fieldValues.pf_no === "" || fieldValues.pf_no === null
          ? ""
          : fieldValues.pf_no.toString() !== "" &&
            !fieldValues.pf_no.toString().match(/^[A-Z]{2}\/[A-Z]{3}\/\d{7}\/\d{3}\/\d{7}$/)
            ? "Invalid EPF Number."
            : "";
    }
    if ("esi_no" in fieldValues) {
      temp.esi_no =
        fieldValues.esi_no === "" || fieldValues.esi_no === null
          ? ""
          : fieldValues.esi_no.toString() !== "" &&
            !fieldValues.esi_no.toString().match(/^\d{17}$/)
            ? "Invalid ESI Number."
            : "";
    }
    if ("uan_no" in fieldValues) {
      temp.uan_no =
        fieldValues.uan_no === "" || fieldValues.uan_no === null
          ? ""
          : fieldValues?.uan_no.toString() !== "" &&
            !fieldValues?.uan_no?.toString()?.match(/^\(?[0-9]{12}$/)
            ? "Invalid UAN Number."
            : "";
    }
    if ("pan" in fieldValues) {
      temp.pan =
        fieldValues.pan === "" || fieldValues.pan === null
          ? ""
          : fieldValues?.pan.toString() !== "" &&
            !fieldValues?.pan?.toString()?.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
            ? "Invalid PAN Number."
            : "";
    }
    setErrors({
      ...temp,
    });
    const temp1 = { ...temp };
    return Object.values(temp1).every((x) => x === "");
  };
  const handleInputChange = (event, value) => {
    if (["paygrade_id"].includes(event)) {
      if (value) {
        setWorkDetails({ ...workDetails, [event]: value });
      } else {
        setWorkDetails({ ...workDetails, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event]: value ? value : "" });
    }
    else if (["international_worker"].includes(event?.target?.name)) {
      setWorkDetails({
        ...workDetails,
        [event.target.name]: event.target.checked,
      });
    }
    else if (event === "bu_id") {
      if (value) {
        setWorkDetails({ ...workDetails, [event]: value });
      } else {
        setWorkDetails({ ...workDetails, [event]: "" });
      }
    }
    else {
      setWorkDetails({
        ...workDetails,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = (event, next) => {
    if (validate(workDetails)) {
      if (currentMode === "editemployee" && OnboardingEmpData) {
        dispatch(UpdateEmployeePayrollProfileDetailAction({
          ...workDetails,
          employee_id: OnboardingEmpData?.employee_id,
        }))
      } else {
        dispatch(UpdateEmployeePayrollProfileDetailAction({
          ...workDetails,
          employee_id: data?.employee_id,
        }))
      }
      if (success) {
        setdisableWorkEdit(true)
      }
    }
  };
  const handleWorkEdit = () => {
    setdisableWorkEdit(false);
  };

  const addMode = (resetForm) => {
    resetForm();
    setEditedRecord(null);
    setOpenPopup(false);
  };
  const BackStepper = () => {
    if (OnboardEmployee) {
      setActiveStep(3);
    } else {
      setActiveStep(3);
    }
  };
  const handleComplete = () => {
    if (!empBankData?.length) {
      toast.error(
        "Please add atleast one Bank Detail",
        {
          duration: 2000,
        }
      );
      return;
    }
    EmployeeValidateHelper({}).then((res) => {
      if (res?.data?.statuscode === 200) {
        if (res?.data?.data?.[0]?.fn_get_employee_form_validation) {
          UpdateEmployeeOnboardStatusHelper({}).then((res) => {
            if (res?.data?.statuscode === 200) {
              setSuccessPopup(true);
            }
          });
        } else {
          toast.error(
            "Please fill all mandatory fields",
            {
              duration: 2000,
            }
          );
        }
      }
    });
  };
  const HandleClose = () => {
    setSuccessPopup(false);
    sessionStorage.setItem("selectedMenu", "Home");
    dispatch(AddMenuHeadingAction({ heading: "Home" }));
    history.push("/home");
  };
  useEffect(() => {
    businessunitData?.length === 0 && dispatch(GetBusinessUnitAction());
    if (currentMode === "editemployee" && OnboardingEmpData) {
      dispatch(
        GetEmployeeBankAction({ employee_id: OnboardingEmpData?.employee_id })
      );
      dispatch(GetEmployeePayrollProfileDetailAction({
        domain: data?.domain,
        employee_id: OnboardingEmpData?.employee_id,
      }))
    } else if (currentMode === "addemployee") {
      dispatch(GetEmployeeBankAction({ employee_id: newdata?.employee_id }));
    } else {
      dispatch(GetEmployeeBankAction({ employee_id: data?.employee_id }));
      dispatch(GetEmployeePayrollProfileDetailAction({
        domain: data?.domain,
        employee_id: data?.employee_id,
      }))
    }
  }, []);

  const addButton = empBankData?.length < 2 && (
    <Button
      onClick={handleAdd}
      variant="contained"
      className="mpl-primary-btn"
      startIcon={<AddIcon />}
    >
      Add
    </Button>
  );

  return (
    <Box
      sx={{
        padding: OnboardEmployee ? "0px 0px 20px 25px" : "0px 0px 0px 0px",
      }}
    >
      {!disableEdit && (
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent:
              currentMode !== undefined ? "space-between" : "flex-end",
            marginRight: "10px",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          <Button
            className="mpl-primary-btn"
            sx={{ marginRight: "10px" }}
            type="submit"
            variant="contained"
            size="medium"
            onClick={BackStepper}
          >
            Back
          </Button>
          {!OnboardEmployee && currentMode !== undefined ? (
            <Button
              className="mpl-primary-btn"
              sx={{ marginRight: "10px" }}
              type="submit"
              variant="contained"
              size="medium"
              onClick={() => history.push("/myemployee")}
            >
              Back to List
            </Button>
          ) : null}
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              textAlign: currentMode !== undefined ? "center" : "center",
              paddingRight: currentMode !== undefined ? "190px" : "80px",

            }}
          >
            Bank Account
          </Typography>
          {OnboardEmployee ? (
            <Button
              className="mpl-primary-btn"
              sx={{ marginRight: "10px" }}
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleComplete}
            >
              Complete Form
            </Button>
          ) : null}
        </Grid>
      )}
      <Grid item xs={12} md={12}
        sx={{
          marginTop: "1.5rem",
        }}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            {OnboardEmployee ? "Bank Details *" : "Bank Details"}
          </Typography>
          {addButton}
          <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
            {empBankData?.map((bankDetail, index) => (
              <Grid item xs={12} key={index}>
                <Paper sx={{ padding: "1rem", marginBottom: "1rem" }}>
                  {/* First Row */}
                  <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-start" sx={{ height: "40px" }}>
                    <Grid item>
                      <IconButton
                        onClick={() => handleConfirmDelete(bankDetail)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleEdit(bankDetail)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} >

                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Account Number:
                        </Typography>
                        <Typography variant="body2">{bankDetail?.account_number}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Account Holder:
                        </Typography>
                        <Typography variant="body2">{bankDetail?.account_holder}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Bank:
                        </Typography>
                        <Typography variant="body2">{bankDetail?.bank_name}</Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Second Row */}
                  <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Branch:
                        </Typography>
                        <Typography variant="body2">{bankDetail?.bank_branch}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          IFSC Code:
                        </Typography>
                        <Typography variant="body2">{bankDetail?.ifsc_code}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Account Type:
                        </Typography>
                        <Typography variant="body2">{bankDetail?.account_type}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </ Paper>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "70px" }}>
        <Grid item>
          <Typography variant="h6" align="center">
            Payroll Profile
          </Typography>
        </Grid>
      </Grid>

      <Grid item marginBottom={3} >
        <Paper className={classes.paper} sx={{ position: 'relative' }} >
          {(currentMode === "add") ? (<Button
            variant="contained"
            size="medium"
            className="mpl-primary-btn"
            sx={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>) : (
            <Button
              variant="contained"
              size="medium"
              className="mpl-primary-btn"
              sx={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
              }}
              disabled={isLoading}
              onClick={disableWorkEdit ? handleWorkEdit : handleSubmit}
            >
              {disableWorkEdit ? "Edit" : "Save"}
            </Button>
          )}

          <Grid item xs={12} md={12}
            sx={{
              marginTop: "1.5rem",
            }}>
            <Typography variant="h6" gutterBottom>
              Payroll Details
            </Typography>
            <Grid pt={2.5} container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <SelectableSearch
                    disabled={disableWorkEdit}
                    label="Business Unit"
                    value={workDetails?.bu_id}
                    loading={() =>
                      setBusinessUnitData({
                        load: false,
                        values: businessunitData,
                      })}
                    fieldLabel="name"
                    variant={"outlined"}
                    name="bu_id"
                    size="small"
                    onChangeEvent={handleInputChange}
                    data={businessUnitData}
                    {...(errors.bu_id && {
                      error: true,
                      helperText: errors.bu_id,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  disabled={disableWorkEdit}
                  label="PAN Number"
                  fullWidth
                  name="pan"
                  onChange={handleInputChange}
                  value={workDetails?.pan || ""}
                  size="small"
                  placeholder="AAAAA9999A"
                  sx={{
                    "& input::placeholder": {
                      color: "#B0B0B0",
                    },
                  }}
                  {...(errors.pan && {
                    error: true,
                    helperText: errors.pan,
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  disabled={disableWorkEdit}
                  label="UAN Number"
                  fullWidth
                  name="uan_no"
                  onChange={handleInputChange}
                  value={workDetails?.uan_no || ""}
                  size="small"
                  placeholder="123456789012"
                  sx={{
                    "& input::placeholder": {
                      color: "#B0B0B0",
                    },
                  }}
                  {...(errors.uan_no && {
                    error: true,
                    helperText: errors.uan_no,
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disableWorkEdit}
                    label="EPF Number"
                    name="pf_no"
                    onChange={handleInputChange}
                    value={workDetails?.pf_no || ""}
                    size="small"
                    placeholder="AB/XYZ/1234567/001/1234567"
                    sx={{
                      "& input::placeholder": {
                        color: "#B0B0B0",
                      },
                    }}
                    {...(errors.pf_no && {
                      error: true,
                      helperText: errors.pf_no,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disableWorkEdit}
                    label="ESI Number"
                    name="esi_no"
                    onChange={handleInputChange}
                    value={workDetails?.esi_no || ""}
                    size="small"
                    placeholder="12345678901234567"
                    sx={{
                      "& input::placeholder": {
                        color: "#B0B0B0",
                      },
                    }}
                    {...(errors.esi_no && {
                      error: true,
                      helperText: errors.esi_no,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableWorkEdit}
                      name="international_worker"
                      checked={workDetails?.international_worker || false}
                      onChange={handleInputChange}
                    />
                  }
                  label="International Worker?"
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Popup
        title={editedRecord ? "Edit Bank Detail" : "Add Bank Detail"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <BankModal
          data={data}
          newdata={newdata}
          currentMode={currentMode}
          OnboardingEmpData={OnboardingEmpData}
          empBankData={empBankData}
          editedRecord={editedRecord}
          addMode={addMode}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        openPopup={successPopup}
        setOpenPopup={setSuccessPopup}
        fullWidth={false}
        maxWidth="md"
        close={false}
        hideDividers={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
            <img src={Success} alt="Success" width={"15%"} />
          </Box>
          <Typography variant="h6">
            {" "}
            Employee onboarded successfully.
          </Typography>
          <Typography variant="h6">
            {" "}
            For more updates please visit It's Me section.{" "}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Button
              onClick={HandleClose}
              variant="contained"
              type="submit"
              className="mpl-primary-btn profile-btn"
              size="small"
              sx={{
                margin: "10px",
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Popup>
      <ConfirmationDialog
        title="Do you wish to proceed?"
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Country Management"
        OkButtonText="Delete"
        onSubmit={handleDelete}
      />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openMoreVertIcon}
        onClose={handleMoreVertIconClose}
      >
        <MenuItem key="edit">
          <BookOnlineIcon sx={{ fontSize: "15px" }} /> &nbsp;{" "}
          <Typography sx={{ fontSize: "15px" }}>Raise Ticket</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default BankDetails;
