import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetCityListHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getCityList`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            country_code: data?.workspace_country
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const GetCityHelper = async (state_id) => {
    return await axiosInstance(`/ancillary/getCities`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            state_id: +state_id
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })

}

// To get Employee City (based on work location) for Holiday Calendar (NOT IN USE. Have it for future)
export const GetHolidayCalendarCityHelper = async () => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getHolidayCalendarCities`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: +data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })

}