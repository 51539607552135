import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetSalaryStructureHelper = async () => {
    const data = getUserData();
    toast.info("Loading...")
    return await axiosInstance(`/finance/ancillary/getSalaryStructure`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
        }
    })
        .then((res) => {
            return { message: "Salary structure retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived salary structure", hasError: false, data: err?.response?.data }
        })
}

export const AddSalaryStructureHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    return await axiosInstance(`/finance/ancillary/addSalaryStructure`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            name: params?.name,
            code: params?.code,
            is_public: "F"
        }
    })
        .then((res) => {
            return { message: "Salary Structure Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to add Salary Structure", hasError: false, data: err?.response?.data }
        })
}

export const UpdateSalaryStructureHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/updateSalaryStructure`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            sal_structure_id: params?.sal_structure_id,
            name: params?.name,
            code: params?.code,
            is_public: "F"
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Salary Structure Updated Successfully")
            return { message: "Salary Structure Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to update Salary Structure", hasError: false, data: err?.response?.data }
        })
}

export const DeleteSalaryStructureHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Deleting...")
    return await axiosInstance(`/finance/ancillary/deleteSalaryStructure`, {
        method: "DELETE",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            sal_structure_id: params?.id
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Salary Structure Deleted Successfully")
            return { message: "Salary Structure Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to Delete Salary Structure", hasError: false, data: err?.response?.data }
        })
}


export const GetSalaryComponentHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/ancillary/getSalaryComponent`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            sal_structure_id: params?.sal_structure_id
        }
    })
        .then((res) => {
            return { message: "Salary structure retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived salary structure", hasError: false, data: err?.response?.data }
        })
}

export const AddSalaryComponentHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/addSalaryComponent`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            sal_structure_id: params?.sal_structure_id,
            name: params?.name,
            code: params?.code,
            sal_comp_type_id: params?.sal_comp_type?.sal_comp_type_id,
            ed_flag: params?.ed_flag?.id,
            cal_type: params?.cal_type?.id,
            cal_value: params?.cal_value,
            is_public: "F",
            comp_alias: params?.comp_alias || null,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Salary Component Added Successfully")
            return { message: "Salary Component Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to add Salary Component", hasError: false, data: err?.response?.data }
        })
}

export const UpdateSalaryComponentHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/updateSalaryComponent`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_empid: data?.employee_id,
            sal_structure_id: params?.sal_structure_id,
            salcomponent_id: params?.salary_component_id,
            name: params?.name,
            code: params?.code,
            sal_comp_type_id: typeof (params?.sal_comp_type) === "object" ? params?.sal_comp_type?.sal_comp_type_id : params?.sal_comp_type_id,
            ed_flag: typeof (params?.ed_flag) === "object" ? params?.ed_flag?.id : params?.ed_flag,
            cal_type: typeof (params?.cal_type) === "object" ? params?.cal_type?.id : params?.cal_type,
            cal_value: params?.cal_value,
            is_public: params?.is_public,
            comp_alias: params?.comp_alias,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Salary Component Updated Successfully")
            return { message: "Salary Component Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to update Salary Component", hasError: false, data: err?.response?.data }
        })
}

export const DeleteSalaryComponentHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Deleting...")
    return await axiosInstance(`/finance/ancillary/deleteSalaryComponent`, {
        method: "DELETE",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            sal_structure_id: params?.sal_structure_id,
            salcomponent_id: params?.salary_component_id,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Salary Component Deleted Successfully")
            return { message: "Salary Component Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to delete Salary Component", hasError: false, data: err?.response?.data }
        })
}


export const GetSalaryComponentTypeHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/ancillary/getSalaryComponentTypes`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
        }
    })
        .then((res) => {
            return { message: "Salary Component Types retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived Salary Component Types", hasError: false, data: err?.response?.data }
        })
}

export const GetPayDateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/ancillary/getPayDate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
        }
    })
        .then((res) => {
            return { message: "success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived", hasError: false, data: err?.response?.data }
        })
}

export const UpdatePayDateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/updatePayDate`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            pay_day: +params?.pay_day,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("PayDate Updated Successfully")
            return { message: "PayDate Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to update PayDate", hasError: false, data: err?.response?.data }
        })
}



export const GetOverTimeHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/ancillary/getOverTime`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
        }
    })
        .then((res) => {
            return { message: "successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived", hasError: false, data: err?.response?.data }
        })
}

export const AddOverTimeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/addOverTime`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            overtime_type: params?.overtime_type,
            overtime_policy: params?.overtime_policy,
            daily_min_eligible_hours: null,
            weekly_min_eligible_hours: null,
            flat_amount: +params?.flat_amount || null,
            variable_earning_components: params?.variable_earning_components || null,
            variable_overtime_rate: +params?.variable_overtime_rate || null,
            user_id: +data?.employee_id
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("OveTime Added Successfully")
            return { message: "OvetTime Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to add OverTime", hasError: false, data: err?.response?.data }
        })
}

export const UpdateOverTimeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/updateOverTime`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            overtime_configuration_id: params?.overtime_configuration_id,
            overtime_type: params?.overtime_type,
            overtime_policy: params?.overtime_policy,
            daily_min_eligible_hours:null,
            weekly_min_eligible_hours:null,
            flat_amount: +params?.flat_amount || null,
            variable_earning_components: params?.variable_earning_components || null,
            variable_overtime_rate: +params?.variable_overtime_rate || null,
            user_id: +data?.employee_id
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("OverTime Updated Successfully")
            return { message: "OverTime Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to update OverTime", hasError: false, data: err?.response?.data }
        })
}

export const DeleteOverTimeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Deleting...")
    return await axiosInstance(`/finance/ancillary/deleteOverTime`, {
        method: "DELETE",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            overtime_configuration_id: params?.overtime_configuration_id,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("OverTime Deleted Successfully")
            return { message: "OverTime Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to delete OverTime", hasError: false, data: err?.response?.data }
        })
}
