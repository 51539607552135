import { GetObjectCommand } from "@aws-sdk/client-s3";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData, s3Client } from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export const UpdateCompanyLogoHelper = async (params) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/ancillary/updateLogo`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,
            user_empid: data.emp_users_pk,
            image: params?.image || "",
            image_name: params?.image_name || "",
            old_image: params?.old_image || "",
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Company Logo Updated Successfully")
            const companyLogo = res?.data?.data?.[0]?.companylogo;
            if (companyLogo) {
                return getImageSignedUrl(companyLogo)
                    .then(signedUrl => {
                        // sessionStorage.setItem("CompanyLogo", signedUrl);
                        return { message: "Company Logo Get Success", hasError: false, data: { ...res.data, signedUrl } };
                    });
            }
            else {
                // sessionStorage.setItem("CompanyLogo", "");
            }
            return { message: "Company Logo Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetCompanyLogoHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/getLogo`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain || data.domain,
        }
    })
        .then(res => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetCompanySignedLogoHelper = async (params) => {
    try {
        const res = await GetCompanyLogoHelper(params);
        const companyLogo = res?.data?.data?.[0]?.companylogo;
        if (companyLogo) {
            const signedUrl = await getImageSignedUrl(companyLogo);
            // sessionStorage.setItem("CompanyLogo", signedUrl)
            return { message: "Company Logo Get Success", hasError: false, data: { ...res.data, signedUrl } };
        } else {
            // sessionStorage.setItem("CompanyLogo", "")
            return { message: "Company Logo not found", hasError: true, data: null };
        }
    } catch (error) {
        return { message: "Error fetching company logo", hasError: true, data: null };
    }
};
const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
        Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
        Key: filename,
    };
    try {
        const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams)
        );
        return url;
    } catch (err) {
        return { message: "Error", data: err?.response?.data }
    }
};


export const UpdateCompanyAddressHelper = async (addressDetails) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/ancillary/updateCompanyAddress`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,
            com_street1: addressDetails?.com_street1,
            com_street2: addressDetails?.com_street2,
            com_city: +addressDetails?.com_city?.city_id,
            com_state: +addressDetails?.com_state?.state_id,
            com_country: +addressDetails?.com_country?.country_id,
            com_zipcode: addressDetails?.com_zipcode,
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Company Address Updated Successfully")
            return { message: "Company Logo Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const GetCompanyAddressHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/getCompanyAddress`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,

        }
    })
        .then(res => {
            return { message: "Successfully", hasError: false, data: res?.data }
        }).catch(err => {

            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddEmpIDHelper = async (params) => {
    var data = getUserData();
    toast.info("Validating...")
    return await axiosInstance(`/ancillary/addEmpID`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            prefix: params.prefix,
            suffix: params.suffix,
            emp_code: params.emp_code,
            yoj: params.yoj
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("The Employee ID configuration is completed successfully")
            return { message: "Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
export const UpdateEmpIDHelper = async (params) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/ancillary/updateEmpID`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            configure_employee_id: params?.configure_employee_pk,
            prefix: params.prefix,
            suffix: params.suffix,
            emp_code: params.emp_code,
            yoj: params.yoj
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Employee ID Updated Successfully")
            return { message: "Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
export const GetEmpIDHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/getEmpID`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id
        }
    })
        .then(res => {
            return { message: "Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}