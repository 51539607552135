import { useState, useEffect } from "react";
import { Box, Typography, Tooltip, Button, Paper, Grid, FormControl, TextField } from "@mui/material";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { createStyles, makeStyles } from "@mui/styles";
import {
  convertBase64,
  getUserData,
  s3Client,
} from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";
import {
  GetCompanyAddressHelper,
  GetCompanyLogoHelper,
  UpdateCompanyAddressHelper,
  UpdateCompanyLogoHelper,
} from "../helper/CompanyDetailsHelper";
import { v4 as uuidv4 } from "uuid";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetCountryHelper } from "../../Country/helper/CountryHelper";
import { GetStateHelper } from "../../State/helper/StateHelper";
import { GetCityHelper } from "../../City/helper/CityHelper";

const CompanyDetail = () => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        width: "500px",
        borderRadius: "10px",
        backgroundColor: "#e6d9fb7a",
      },
      comingSoon: {
        fontWeight: 600,
        fontSize: "18px",
        marginTop: "30px",
        color: "white",
      },
    })
  );
  const classes = useStyles();
  const userData = getUserData();
  const initialValue = {
    image: "",
  };
  const [comCountryData, setcomCountryData] = useState({
    load: true,
    values: [],
  });
  const [comStateData, setcomStateData] = useState({ load: true, values: [] });
  const [comCityData, setcomCityData] = useState({ load: true, values: [] });
  const [errors, setErrors] = useState({});
  const [addressDetails, setAddressDetails] = useState({
    com_city: "",
    com_state: "",
    com_country: "",
    com_street1: "",
    com_street2: "",
    com_zipcode: "",
  });
  const [inputData, setInputData] = useState(initialValue);
  const [imgfiles, setimgFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    GetCompanyLogoHelper()
      .then((res) => {
        res?.data?.data?.[0]?.companylogo &&
          getImageSignedUrl(res?.data?.data?.[0]?.companylogo);
        setInputData({ image: res?.data?.data?.[0]?.companylogo });
      })
      .catch((err) => {
        console.log(err);
      });
    GetCompanyAddressHelper()
      .then((res) => {
        setAddressDetails({
          ...res?.data?.data?.[0],
          com_street1: res?.data?.data?.[0]?.street1,
          com_street2: res?.data?.data?.[0]?.street2,
          com_zipcode: res?.data?.data?.[0]?.zipcode,
          com_city: res?.data?.data?.[0]?.city_id
            ? {
              city_id: res?.data?.data?.[0]?.city_id,
              city_name: res?.data?.data?.[0]?.city_name,
            }
            : "",
          com_country: res?.data?.data?.[0]?.country_id
            ? {
              country_id: res?.data?.data?.[0]?.country_id,
              country_name: res?.data?.data?.[0]?.country_name,
            }
            : "",
          com_state: res?.data?.data?.[0]?.state_id
            ? {
              state_id: res?.data?.data?.[0]?.state_id,
              state_name: res?.data?.data?.[0]?.state_name,
            }
            : "",

        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlecomCountry = () => {
    GetCountryHelper().then((res) => {
      setcomCountryData({ load: false, values: res?.data?.data });
    });
  };

  const handlecomState = () => {
    GetStateHelper(addressDetails?.com_country?.country_id).then((res) => {
      setcomStateData({ load: false, values: res?.data?.data });
    });
  };

  const handlecomCity = () => {
    GetCityHelper(addressDetails?.com_state?.state_id).then((res) => {
      setcomCityData({ load: false, values: res?.data?.data });
    });
  };
  const handleChange = (event, value) => {
    if ("com_city" === event) {
      if (value) {
        setAddressDetails({ ...addressDetails, [event]: value });
      } else {
        setAddressDetails({ ...addressDetails, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...addressDetails, [event]: value ? value : "" });
    } else if ("com_country" === event) {
        setcomStateData({ load: true, values: [] })
        setcomCityData({ load: true, values: [] })
        setAddressDetails({ 
            ...addressDetails, 
            [event]: value ? value : "",
            com_city: "",
            com_state: "",
          });
    } else if( "com_state" === event){
        setcomCityData({ load: true, values: [] })
        setAddressDetails({ 
          ...addressDetails, 
          [event]: value ? value : "",
          com_city: "",
        });
    }
    else {
      setAddressDetails({
        ...addressDetails,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...addressDetails,
          [event.target.name]: event.target.value,
        });
    }
  }
  const validate = (fieldValues = addressDetails) => {
    let temp = { ...errors };
    if ("com_city" in fieldValues) {
      temp.com_city = fieldValues.com_city === "" ? "City is required" : "";
    }
    if ("com_state" in fieldValues) {
      temp.com_state = fieldValues.com_state === "" ? "State is required" : "";
    }
    if ("com_country" in fieldValues) {
      temp.com_country =
        fieldValues.com_country === "" ? "Country is required" : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleSubmitAddress = async () => {
    if (validate()) {
      UpdateCompanyAddressHelper({
        ...addressDetails
      })
        .then((res) => { })
        .catch((err) => {
          console.log(err);
        });
    }

  }
  const handleSubmit = async () => {
    let img_filename = "";
    let img_b64string = "";
    let old_image = "";
    if (imgfiles?.[0]) {
      img_b64string = await convertBase64(imgfiles?.[0]);
      let ext = imgfiles?.[0]?.name?.split(".").at(-1);
      img_filename =
        `${userData?.domain}/Settings/CompanyLogo/` + uuidv4() + "." + ext;
      if (inputData?.image) {
        old_image = inputData?.image;
      }
    } else {
      img_filename = inputData?.image;
    }
    if (inputData?.image && !imageUrl) {
      old_image = inputData?.image;
      img_filename = img_filename ? img_filename : "";
    }
    UpdateCompanyLogoHelper({
      image: img_filename,
      image_name: img_b64string,
      old_image: old_image,
    })
      .then((res) => { })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (e) => {
    e.preventDefault();
    setimgFiles([]);
    setImageUrl("");
    setInputData((prevData) => ({
      ...prevData,
      image: "", // Setting image to an empty string
    }));
  };

  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 2000000,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setimgFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
    onDropRejected: () => {
      toast.error(
        "You can only image upload image with maximum size of 2 MB.",
        {
          duration: 2000,
        }
      );
    },
  });
  const img = imgfiles.map((file) => (
    <img
      style={{ borderRadius: "50%", padding: "2px" }}
      width="100%"
      height="100%"
      key={file.name}
      alt={file.name}
      className="single-file-image"
      src={URL.createObjectURL(file)}
    />
  ));

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap:"10px" }}>
      <Paper
        className={classes.Paper}
        sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Box
              sx={{
                marginBottom: "5px",
                border: "none !important",
                cursor: "default",
              }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} />
              <div className="presentation">
                <div className="placeholder">
                  {imgfiles.length ? (
                    img
                  ) : inputData?.image ? (
                    <img
                      style={{ borderRadius: "50%", padding: "2px" }}
                      alt="Nothing"
                      width="100%"
                      height="100%"
                      src={imageUrl}
                    />
                  ) : (
                    <>
                      <AddPhotoAlternateIcon />
                      <Typography variant="caption" display="block" gutterBottom>
                        Upload Photo
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Delete Image">
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  onClick={handleDelete}
                  color="error"
                />
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ margin: "5px auto 0px" }}
              variant="caption"
              className="content-sec"
            >
              File formats allowed *.jpeg, *.jpg, *.png
              <br /> max size of 2 MB
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
              className="mpl-primary-btn"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Paper>
      <Paper
        className={classes.Paper}
        sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: 600 }}> Company Address</Typography>
          <Button
            type="submit"
            variant="contained"
            size="medium"
            onClick={handleSubmitAddress}
            className="mpl-primary-btn"
          >
            Save
          </Button>
        </Box>
        <Grid container >
          <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
            <FormControl fullWidth >
              <TextField
                size="small"
                value={addressDetails?.com_street1 || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                id="name"
                label="Street 1"
                name="com_street1"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
            <FormControl fullWidth >
              <TextField
                size="small"
                value={addressDetails?.com_street2 || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                id="name"
                label="Street 2"
                name="com_street2"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
            <SelectableSearch
              label="Country"
              value={addressDetails?.com_country}
              loading={() => handlecomCountry()}
              fieldLabel="country_name"
              variant={"contained"}
              name="com_country"
              size="small"
              data={comCountryData}
              onChangeEvent={handleChange}
              required
              {...(errors.com_country && {
                error: true,
                helperText: errors.com_country,
              })}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
            <SelectableSearch
              label="State"
              value={addressDetails?.com_state}
              loading={() => handlecomState()}
              fieldLabel="state_name"
              required
              variant={"contained"}
              name="com_state"
              size="small"
              data={comStateData}
              onChangeEvent={handleChange}
              {...(errors.com_state && {
                error: true,
                helperText: errors.com_state,
              })}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
            <SelectableSearch
              label="City"
              value={addressDetails?.com_city}
              loading={() => handlecomCity()}
              fieldLabel="city_name"
              required
              variant={"contained"}
              name="com_city"
              size="small"
              data={comCityData}
              onChangeEvent={handleChange}
              {...(errors.com_city && {
                error: true,
                helperText: errors.com_city,
              })}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}>
            <FormControl fullWidth >
              <TextField
                size="small"
                value={addressDetails?.com_zipcode || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                id="name"
                label="Zip Code"
                name="com_zipcode"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
export default CompanyDetail;
