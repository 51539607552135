import {
    IconButton,
    FormControl,
    Grid
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import { GetFaceDataAction } from "../../../../../store/Leave/MyTimeSheet/Action";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetEmployeesFaceAction } from "../../../../../store/Employee/Employee/Action";
import { ConvertDate, ConvertTime, getUserData } from "../../../Base/helper/baseFunctions";
import MaterialTable from "material-table";
const TeamFaceGrid = () => {
    const userData = getUserData();
    const currentDate = new Date(); // Current date
    const formattedToday = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({
        employee_id: "",
        from_date: formattedToday,
        to_date: formattedToday,
    });
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [employeesData, setEmployeesData] = useState({
        load: true,
        values: [],
    });
    const { face_employees_options } = useSelector(
        (state) => state.EmployeeReducer.EmployeesReducer
    );

    const { face_data } = useSelector(
        (state) => state?.LeaveReducer?.MyTimeSheetReducer
    );
    const handleEmployees = () => {
        face_employees_options &&
            setEmployeesData({ load: false, values: face_employees_options });
    };
    useEffect(() => {
        dispatch(GetEmployeesFaceAction());
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const lastThreeYearsMonths = [];

        for (let year = currentYear - 2; year <= currentYear; year++) {
            for (let month = 0; month < 12; month++) {
                const date = new Date(year, month);
                const monthName = date.toLocaleString("en-US", { month: "short" });
                const yearMonth = `${monthName} ${year}`;
                lastThreeYearsMonths.push({
                    ats_users_id: `${year}_${month}`, // replace with a unique identifier
                    first_name: yearMonth,
                });
            }
        }
        dispatch(
            GetFaceDataAction({
                from_date: filters?.from_date,
                to_date: filters?.to_date,
                emp_id: filters?.employee_id?.employee_id || null,
                reporting_manager_emp_id: userData?.employee_id
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);
    const handleFilter = (event, value) => {
        if (["employee_id"].includes(event)) {
            setFilters({ ...filters, [event]: value });
        }
        if (["from_date", "to_date"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value });
        }
    };

    const handlePrevMonth = () => {
        const prevMonth = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth() - 1,
            1
        );
        const firstDayOfPrevMonth = `${prevMonth.getFullYear()}-${(
            "0" +
            (prevMonth.getMonth() + 1)
        ).slice(-2)}-01`;
        const lastDayOfPrevMonth = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth(),
            0
        );
        const lastDayFormatted = `${lastDayOfPrevMonth.getFullYear()}-${(
            "0" +
            (lastDayOfPrevMonth.getMonth() + 1)
        ).slice(-2)}-${("0" + lastDayOfPrevMonth.getDate()).slice(-2)}`;
        setFilters({
            ...filters,
            from_date: firstDayOfPrevMonth,
            to_date: lastDayFormatted,
        });
        setCurrentMonth(prevMonth);
    };
    const handleNextMonth = () => {
        const nextMonth = new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth() + 1,
            1
        );
        const firstDayOfNextMonth = `${nextMonth.getFullYear()}-${(
            "0" +
            (nextMonth.getMonth() + 1)
        ).slice(-2)}-01`;
        const lastDayOfNextMonth = new Date(
            nextMonth.getFullYear(),
            nextMonth.getMonth() + 1,
            0
        );
        const lastDayFormatted = `${lastDayOfNextMonth.getFullYear()}-${(
            "0" +
            (lastDayOfNextMonth.getMonth() + 1)
        ).slice(-2)}-${("0" + lastDayOfNextMonth.getDate()).slice(-2)}`;
        setFilters({
            ...filters,
            from_date: firstDayOfNextMonth,
            to_date: lastDayFormatted,
        });
        setCurrentMonth(nextMonth);
    };
    return (
        <div>
            <Grid pt={3} pb={2} container>
                <Grid pr={1} item xs={12} sm={3.5}>
                    <FormControl fullWidth>
                        <SelectableSearch
                            label="Employee"
                            value={filters?.employee_id}
                            loading={() => handleEmployees()}
                            fieldLabel="employee_name"
                            variant={"outlined"}
                            name="employee_id"
                            size="small"
                            onChangeEvent={handleFilter}
                            data={employeesData}

                        />
                    </FormControl>
                </Grid>
                <Grid pr={1} item xs={12} sm={3.5}>
                    <IconButton onClick={handlePrevMonth}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                    <FormControl>
                        <CommonDatePicker
                            name="from_date"
                            label="From Date"
                            size="small"
                            pickerType="date"
                            onKeyPress={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            DefaultDTvalue={filters.from_date}
                            maxDate={currentDate}
                            handleChange={(value) =>
                                handleFilter({
                                    target: {
                                        name: "from_date",
                                        value: value
                                            ? `${value?.getFullYear() +
                                            "-" +
                                            ("0" + (value?.getMonth() + 1)).slice(-2) +
                                            "-" +
                                            ("0" + value?.getDate()).slice(-2)
                                            }`
                                            : "",
                                    },
                                })
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid pr={1} item xs={12} sm={3.5}>
                    <FormControl>
                        <CommonDatePicker
                            name="to_date"
                            label="To Date"
                            pickerType="date"
                            size="small"
                            onKeyPress={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            DefaultDTvalue={filters.to_date}
                            minDate={filters.from_date}
                            maxDate={currentDate}
                            handleChange={(value) =>
                                handleFilter({
                                    target: {
                                        name: "to_date",
                                        value: value
                                            ? `${value?.getFullYear() +
                                            "-" +
                                            ("0" + (value?.getMonth() + 1)).slice(-2) +
                                            "-" +
                                            ("0" + value?.getDate()).slice(-2)
                                            }`
                                            : "",
                                    },
                                })
                            }
                        />
                    </FormControl>
                    <IconButton onClick={handleNextMonth}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Paper sx={{ width: "100%" }}>
            <MaterialTable
                    columns={[
                        { title: "Name", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "employee_name", editable: "never" },
                        { title: "Date", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "record_date", editable: "never",render: rowData => { return rowData?.record_date ? ConvertDate(rowData?.record_date) : "    " } },
                        { title: "In Time", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "in_time", editable: "never",render: rowData => { return rowData?.in_time ? ConvertTime(rowData?.in_time) : "    " } },
                        { title: "Out Time", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "out_time", editable: "never",render: rowData => { return rowData?.out_time ? ConvertTime(rowData?.out_time) : "    " } },
                        
                    ]}
                    data={face_data || []}
                    options={{
                        maxBodyHeight: "50vh",
                        minBodyHeight: "50vh",
                        rowStyle: { fontSize: "13px" },
                        sorting: false,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        showTextRowsSelected: false,
                        showSelectAllCheckbox: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                        columnsButton: false,
                        draggable:false,
                    }}
                  
                    title="Team Face Attendance"
                />
            </Paper>
        </div>
    );
};

export default TeamFaceGrid;
