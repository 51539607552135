// ** React Imports
import { Fragment } from 'react'
// ** MUI Imports
import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// ** Icon Imports
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify';
import { useState } from 'react'
import Popup from '../../../Base/views/Popup'
import { DownloadAttachment } from '../../../Base/helper/baseFunctions'


const FileUploader = ({ files, setFiles, fileUrl, task, setFileUrl, filename, filepath, candidateData, disabled, name }) => {
    let ext = filename?.split(".").at(-1)
    // ** State
    // const [files, setFiles] = useState([])
    // ** Hooks
    const [show, setShow] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        maxSize: 2000000,
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                if (task) {
                    if (file.type === 'image/png' ||
                        file.type === 'image/jpeg' ||
                        file.type === 'application/pdf' ||
                        file.type === 'application/msword') {
                        // Do something with the accepted file
                        setFileUrl("");
                        setFiles(acceptedFiles.map(file => Object.assign(file)));
                    }
                    else {
                        toast.error('Invalid File type Accepted file types .png,.jpg,.doc,.docx', {
                            duration: 2000
                        })
                    }

                }
                else {
                    if (file.type === 'application/pdf' ||
                        file.type === 'application/msword' ||
                        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                        // Do something with the accepted file
                        setFileUrl("")
                        setFiles(acceptedFiles.map(file => Object.assign(file)))
                    }
                    else {
                        // Reject the file
                        toast.error('Invalid File type Accepted file types .pdf,.doc,.docx', {
                            duration: 2000
                        })
                    }
                }
            });
        },
        onDropRejected: () => {
            toast.error('You can only upload 1 file & maximum size of 2 MB.', {
                duration: 2000
            })
        }
    })

    const renderFilePreview = file => {
        if (file.type.startsWith('image')) {
            return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />
        } else {
            return <DescriptionIcon />
        }
    }

    const handleRemoveFile = file => {
        const uploadedFiles = files
        const filtered = uploadedFiles?.filter(i => i.name !== file.name)
        setFiles([...filtered])
    }

    const fileList = files?.map(file => (
        <ListItem key={file.name} sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "6px",
            padding: "0.625rem 0.6rem 0.625rem 1.5rem",
            border: "1px solid rgba(93, 89, 98, 0.14)"
        }}>
            <div className='file-details' style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "10px" }} className='file-preview'>{renderFilePreview(file)}</div>
                <div>
                    <Typography className='file-name'>{file.name}</Typography>
                    <Typography className='file-size' variant='body2'>
                        {Math.round(file.size / 100) / 10 > 1000
                            ? (Math.round(file.size / 100) / 10000).toFixed(1) + "MB"
                            : (Math.round(file.size / 100) / 10).toFixed(1) + "KB"}
                    </Typography>
                </div>
            </div>
            <IconButton onClick={() => handleRemoveFile(file)}>
                <CloseIcon fontSize={"small"} />
            </IconButton>
        </ListItem>
    ))

    const handlePreview = () => {
        setShow(true)
    }

    return (
        <Fragment>
            <Popup
                openPopup={show}
                setOpenPopup={setShow}
            >
                <img src={fileUrl} alt='resume' />
            </Popup>
            <Grid spacing={1} container direction="row" justifyContent="center">
                {files?.length === 0 && fileUrl === "" &&
                    <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            cursor: "pointer",
                            position: "relative",
                            alignItems: "center",
                            width: "150px",
                            justifyContent: "center",
                            border: "none"
                        }} {...getRootProps({ className: 'dropzone', accept: '.doc, .docx, .pdf' })}>
                            <input {...getInputProps()} />
                            <Box sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], alignItems: 'center' }}>
                                <input name="evidence" accept=".doc, .docx, .pdf" id="evidence" type="file" style={{ display: "none", width: "20px" }} />
                                <div className="upload_doc">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true"
                                        role="img"
                                        className="MuiBox-root css-1ngw63v iconify iconify--eva"
                                        width="1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <g id="iconifyReact108">
                                            <g id="iconifyReact109">
                                                <g id="iconifyReact110" fill="currentColor">
                                                    <path d="M21.9 12c0-.11-.06-.22-.09-.33a4.17 4.17 0 0 0-.18-.57c-.05-.12-.12-.24-.18-.37s-.15-.3-.24-.44S21 10.08 21 10s-.2-.25-.31-.37s-.21-.2-.32-.3L20 9l-.36-.24a3.68 3.68 0 0 0-.44-.23l-.39-.18a4.13 4.13 0 0 0-.5-.15a3 3 0 0 0-.41-.09L17.67 8A6 6 0 0 0 6.33 8l-.18.05a3 3 0 0 0-.41.09a4.13 4.13 0 0 0-.5.15l-.39.18a3.68 3.68 0 0 0-.44.23l-.36.3l-.37.31c-.11.1-.22.19-.32.3s-.21.25-.31.37s-.18.23-.26.36s-.16.29-.24.44s-.13.25-.18.37a4.17 4.17 0 0 0-.18.57c0 .11-.07.22-.09.33A5.23 5.23 0 0 0 2 13a5.5 5.5 0 0 0 .09.91c0 .1.05.19.07.29a5.58 5.58 0 0 0 .18.58l.12.29a5 5 0 0 0 .3.56l.14.22a.56.56 0 0 0 .05.08L3 16a5 5 0 0 0 4 2h3v-1.37a2 2 0 0 1-1 .27a2.05 2.05 0 0 1-1.44-.61a2 2 0 0 1 .05-2.83l3-2.9A2 2 0 0 1 12 10a2 2 0 0 1 1.41.59l3 3a2 2 0 0 1 0 2.82A2 2 0 0 1 15 17a1.92 1.92 0 0 1-1-.27V18h3a5 5 0 0 0 4-2l.05-.05a.56.56 0 0 0 .05-.08l.14-.22a5 5 0 0 0 .3-.56l.12-.29a5.58 5.58 0 0 0 .18-.58c0-.1.05-.19.07-.29A5.5 5.5 0 0 0 22 13a5.23 5.23 0 0 0-.1-1Z" />
                                                    <path d="M12.71 11.29a1 1 0 0 0-1.4 0l-3 2.9a1 1 0 1 0 1.38 1.44L11 14.36V20a1 1 0 0 0 2 0v-5.59l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <p className="MuiTypography-root MuiTypography-body2 css-1o38g2n">
                                        {name ? name : "Upload file"}
                                    </p>
                                </div>
                            </Box>
                        </div>

                    </Grid>}
                <Grid item xs={12} md={12}>
                    {fileUrl !== "" ?
                        <>
                            <ListItem sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "space-between",
                                borderRadius: "6px",
                                padding: "0.625rem 0.6rem 0.625rem 1.5rem",
                                border: "1px solid rgba(93, 89, 98, 0.14)",
                                marginTop: 2.5,
                                mr: 9
                            }}>
                                <div className='file-details' style={{ display: "flex", cursor: "pointer" }}>
                                    <div style={{ marginRight: "10px" }} className='file-preview' onClick={() => { ['jpg', 'png', 'jpeg'].includes(ext) && handlePreview() }}>
                                        {
                                            ['jpg', 'png', 'jpeg'].includes(ext) ? <img width={38} height={38} alt={"Resume"} src={fileUrl} /> : <DescriptionIcon />
                                        }
                                    </div>
                                    <div>
                                        <Typography className='file-name' onClick={() => DownloadAttachment(candidateData?.resume || filepath)}>{filename}.{filepath.split(".").at(-1)}</Typography>
                                    </div>
                                </div>
                                <IconButton onClick={() => setFileUrl("")}>
                                    {!disabled && <CloseIcon fontSize={"small"} />}
                                </IconButton>
                            </ListItem>
                        </> :
                        files?.length ? (
                            <Fragment>
                                <List sx={{ padding: "0px" }}>{fileList}</List>
                                {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1.5625rem" }}>
                                    <Button sx={{ marginRight: "0.875rem" }} color='error' variant='outlined' onClick={handleRemoveAllFiles}>
                                        Remove All
                                    </Button>
                                    <Button variant='contained'>Upload Files</Button>
                                </div> */}
                            </Fragment>
                        ) : null}
                </Grid>
            </Grid >
        </Fragment >
    )
}

export default FileUploader
