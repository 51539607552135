import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import footerlogoSrc from "../../images/Meepl_new_logo with text png.png";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        paddingTop: 10,
        paddingBottom: 30,
        paddingHorizontal: 10,
    },
    container: {
        border: "1px solid #bfbfbf",
    },
    addressContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 5,
        borderBottom: "0.5px solid #bfbfbf",
    },
    logoContainer: {
        flex: 1,
        alignItems: 'flex-start',
        marginLeft: 5,
        marginTop: 3
    },
    logo: {
        objectFit: "contain",
        height: 60,
    },
    payslipContainer: {
        flex: 2,
        paddingHorizontal: 10,
        textAlign: "right"
    },
    companyNameLabel: {
        fontSize: 16,
        marginBottom: 2,
        fontWeight: 900,
        color: "#000",
    },
    addressLabel: {
        fontSize: 10,
        color: "#000",
    },
    monthContainer: {
        textAlign: "center",
        paddingBottom: 3,
        paddingTop: 2,
        borderBottom: "0.5px solid #bfbfbf",
    },
    monthLabel: {
        fontSize: 10,
        fontWeight: 900,
        color: "#000",
        textTransform: "uppercase"
    },
    employeeSummaryContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    column: {
        flex: 1,
        paddingHorizontal: 5,
    },
    row: {
        flexDirection: "row",
        marginTop: 10,
        width: "100%"
    },
    label: {
        fontSize: "8.6px",
        fontWeight: "bold",
        color: "#000",
        width: "50%"
    },
    value: {
        fontSize: "8.6px",
        fontWeight: "bold",
        color: "#000",
        marginLeft: 2,
        width: "50%"
    },
    table: {
        display: 'table',
        width: '100%',
        borderRight: "0.5px solid #bfbfbf"
    },
    table1: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',

    },
    tableRow1: {
        flexDirection: 'row',
        borderBottom: "0.5px solid #bfbfbf",
        borderTop: "0.5px solid #bfbfbf",
    },
    tableRow11: {
        width: "100%",
        textAlign: "center"
    },
    tableHeader: {
        flexDirection: 'row',
        width: '100%',
    },
    tablesubheader: {
        padding: 6,
        fontSize: "8.6px",
        fontWeight: "extrabold"
    },
    tableCell: {
        padding: 6,
        fontSize: "8.6px",
        color: "#000",
    },
    tableFooter: {
        borderTop: '1px solid #bfbfbf', // Border for Total section
        paddingTop: 5,
        fontSize: 10,
        textAlign: 'right',
    },
    totalLabel: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    totalValue: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    earningsAndDeductionsContainer: {
        flexDirection: 'row', // Aligns Earnings and Deductions side by side
        justifyContent: 'space-between',
        paddingTop: 15,
        width: '100%',
    },
    section: {
        width: '50%', // Ensure both sections take up almost half of the page width
    },
    sectionTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        borderBottom: "0.5px solid #bfbfbf",
        borderTop: "0.5px solid #bfbfbf",
        borderRight: "0.5px solid #bfbfbf",
        padding: 5
    },
    sectionTitle1: {
        fontSize: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        borderBottom: "0.5px solid #bfbfbf",
        borderTop: "0.5px solid #bfbfbf",
        padding: 5,
    },
    footer: {
        position: 'absolute', // Fix the position at the bottom
        bottom: 10,          // Position 10 units above the bottom of the page
        left: 30,            // Align to the left margin
        right: 30,           // Align to the right margin
        textAlign: 'center',
        fontSize: 10,
        color: '#000',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerLogo: {
        height: 25,
        objectFit: "contain",
        marginLeft: 5, // Space between text and logo
    },
});
const filterValidEntries = (obj) => {
    return Object.entries(obj || {})
        .filter(([_, value]) => value !== null) // Ignore null values
        .map(([key, value]) => ({ name: key.replace(/_/g, ' '), value })); // Format key names
};

const calculateTotal = (entries) => {
    const total = entries.reduce((total, entry) => total + parseFloat(entry.value || 0), 0);
    return parseFloat(total.toFixed(2));
};
const NewPaySlip = ({ data, imageUrl }) => {
    const actualEarnings = filterValidEntries(data.actual_earnings);
    const earnedEarnings = filterValidEntries(data.earned_earnings);
    const deductions = filterValidEntries(data.deductions);
    const totalEarnedEarnings = calculateTotal(earnedEarnings);
    const totalDeductions = calculateTotal(deductions);
    const netPay = parseFloat((totalEarnedEarnings - totalDeductions).toFixed(2));
    const capitalizeWords = (text) => {
        return text
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const toWordsDynamic = (num) => {
        if (num === 0) return "Zero";

        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        const convert = (n) => {
            if (n < 20) return ones[n];

            if (n < 100) {
                return tens[Math.floor(n / 10)] + (n % 10 !== 0 ? " " + ones[n % 10] : "");
            }

            if (n < 1000) {
                return ones[Math.floor(n / 100)] + " Hundred" + (n % 100 !== 0 ? " " + convert(n % 100) : "");
            }

            if (n < 100000) {
                return convert(Math.floor(n / 1000)) + " Thousand" + (n % 1000 !== 0 ? " " + convert(n % 1000) : "");
            }

            if (n < 10000000) {
                return convert(Math.floor(n / 100000)) + " Lakh" + (n % 100000 !== 0 ? " " + convert(n % 100000) : "");
            }

            return convert(Math.floor(n / 10000000)) + " Crore" + (n % 10000000 !== 0 ? " " + convert(n % 10000000) : "");
        };

        return convert(num);
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = now.toLocaleDateString('en-US', options);
        const hours = now.getHours();
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const amPm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes} ${amPm}`;
        return `* ${formattedDate} and ${formattedTime}`;
    };

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.container}>
                    {/* Header Section */}
                    <View style={styles.addressContainer}>
                        <View style={styles.logoContainer}>
                            {imageUrl && (
                                <Image style={[styles.logo, { width: "auto" }]} src={imageUrl} />
                            )}
                        </View>
                        <View style={styles.payslipContainer}>
                            <Text style={styles.companyNameLabel}>{data?.company_name}</Text>
                            <Text style={styles.addressLabel}>{data?.addressline1 ? `${data.addressline1} ` : " "}</Text>
                            <Text style={styles.addressLabel}>
                                {data?.addressline2 ? data.addressline2 : " "}</Text>
                        </View>
                    </View>

                    {/* Month Section */}
                    <View style={styles.monthContainer}>
                        <Text style={styles.monthLabel}>PAY SLIP FOR THE MONTH OF - {data?.month_year}</Text>
                    </View>

                    {/* Employee Summary Section */}
                    <View style={styles.employeeSummaryContainer}>
                        {/* Column 1 */}
                        <View style={styles.column}>
                            <View style={styles.row}>
                                <Text style={styles.label}>EMPLOYEE ID</Text>
                                <Text style={styles.value}>: {data?.employee_no}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>NAME</Text>
                                <Text style={styles.value}>: {data?.employee_name}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>GENDER</Text>
                                <Text style={styles.value}>: {data?.gender ? data.gender : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>DESIGNATION</Text>
                                <Text style={styles.value}>: {data?.designation ? data.designation : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>DATE OF JOINING</Text>
                                <Text style={styles.value}>: {data?.doj ? data.doj : "NA"}</Text>
                            </View>

                        </View>

                        {/* Column 2 */}
                        <View style={styles.column}>
                            <View style={styles.row}>
                                <Text style={styles.label}>BU</Text>
                                <Text style={styles.value}>: {data?.bu ? data.bu : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>UAN</Text>
                                <Text style={styles.value}>: {data?.uan ? data.uan : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>PAN</Text>
                                <Text style={styles.value}>: {data?.pan ? data.pan : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>BANK NAME</Text>
                                <Text style={styles.value}>: {data?.bank_acc_name ? data.bank_acc_name : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>BANK A/C NO.</Text>
                                <Text style={styles.value}>: {data?.bank_acc_no ? data.bank_acc_no : "NA"}</Text>
                            </View>
                        </View>

                        {/* Column 3 */}
                        <View style={styles.column}>
                            <View style={styles.row}>
                                <Text style={styles.label}>STANDARD DAYS</Text>
                                <Text style={styles.value}>: {data?.standard_days ? data.standard_days : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>DAYS ACCOUNTED</Text>
                                <Text style={styles.value}>: {data?.days_accounted ? data.days_accounted : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>LEAVE TAKEN</Text>
                                <Text style={styles.value}>: {data?.leaves_taken ? data.leaves_taken : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>LOP</Text>
                                <Text style={styles.value}>: {data?.lop ? data.lop : "NA"}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>PAY PERIOD</Text>
                                <Text style={styles.value}>: {data?.pay_period ? data.pay_period : "NA"}</Text>
                            </View>
                        </View>
                    </View>

                    {/* Earnings and Deductions Section */}
                    <View style={styles.earningsAndDeductionsContainer}>
                        {/* Earnings Section */}
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>EARNINGS</Text>
                            <View style={{ flexGrow: 1 }}> {/* Make this area take the remaining space */}
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tablesubheader, { width: '50%' }]}>Earnings</Text>
                                        <Text style={[styles.tablesubheader, { width: '25%', textAlign: "right" }]}>Actual</Text>
                                        <Text style={[styles.tablesubheader, { width: '25%', textAlign: "right" }]}>Earned</Text>
                                    </View>
                                    {earnedEarnings.map((entry, index) => (
                                        <View style={styles.tableRow} key={index}>
                                            <Text style={[styles.tableCell, { width: '50%' }]}>{capitalizeWords(entry.name)}</Text>
                                            <Text style={[styles.tableCell, { width: '25%', textAlign: "right" }]}>
                                                {actualEarnings[index]?.value || 0}
                                            </Text>
                                            <Text style={[styles.tableCell, { width: '25%', textAlign: "right" }]}>
                                                {entry.value}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                            {/* Total Earnings */}
                            <View style={[styles.tableRow1, { borderRight: "0.5px solid #bfbfbf" }]}>
                                <Text style={[styles.tablesubheader, { width: '50%' }]}>Total Earnings</Text>
                                <Text style={[styles.tablesubheader, { width: '50%', textAlign: "right" }]}>
                                    {totalEarnedEarnings}
                                </Text>
                            </View>
                        </View>

                        {/* Deductions Section */}
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle1}>DEDUCTIONS</Text>
                            <View style={{ flexGrow: 1 }}> {/* Make this area take the remaining space */}
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tablesubheader, { width: '70%' }]}>Deductions</Text>
                                        <Text style={[styles.tablesubheader, { width: '30%', textAlign: "right" }]}>Current Month</Text>
                                    </View>
                                    {deductions.map((entry, index) => (
                                        <View style={styles.tableRow} key={index}>
                                            <Text style={[styles.tableCell, { width: '70%' }]}>{capitalizeWords(entry.name)}</Text>
                                            <Text style={[styles.tableCell, { width: '30%', textAlign: "right" }]}>
                                                {entry.value}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                            {/* Total Deductions */}
                            <View style={styles.tableRow1}>
                                <Text style={[styles.tablesubheader, { width: '50%' }]}>Total Deductions</Text>
                                <Text style={[styles.tablesubheader, { width: '50%', textAlign: "right" }]}>
                                    {totalDeductions}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.tableRow1}>
                        <Text style={[styles.tablesubheader, { width: '10%' }]}>Net Pay</Text>
                        <Text style={[styles.tablesubheader, { width: '20%', textAlign: "left" }]}>
                            : {netPay}
                        </Text>
                        <Text style={[styles.tablesubheader, { width: '70%', fontStyle: "italic" }]}>
                            {`Indian Rupee ${capitalizeWords(toWordsDynamic(Math.floor(netPay)))} Only`}
                        </Text>
                    </View>
                    <View style={styles.tableRow1}>
                        <Text style={[styles.tablesubheader, { width: '30%', textAlign: "left" }]}>{getCurrentDateTime()}</Text>
                        <Text style={[styles.tablesubheader, { width: '60%', fontStyle: "oblique" }]}>Computer generated salary slip. Signature not required</Text>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <Text>Powered by</Text>
                    <Image style={[styles.footerLogo, { width: "auto" }]} src={footerlogoSrc} />
                </View>
            </Page>
        </Document>
    );
};

export default NewPaySlip;
