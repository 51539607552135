import * as ActionTypes from '../ActionTypes';

export const GetPayDateAction = (params) => {
    return {
        type: ActionTypes.PAY_DATE_GET_REQUEST,
        params
    }
}
export const UpdatePayDateAction = (params) => {
    return {
        type: ActionTypes.PAY_DATE_UPDATE_REQUEST,
        params
    }
}