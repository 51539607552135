import * as ActionTypes from '../ActionTypes';

export const GetWeekOffConfigureAction = () => {
    return {
        type: ActionTypes.WEEK_OFF_CONFIGURE_GET_REQUEST,
    }
}


export const AddWeekOffConfigureAction = (params) => {
    return {
        type: ActionTypes.WEEK_OFF_CONFIGURE_ADD_REQUEST,
        params
    }
}

export const UpdateWeekOffConfigureAction = (params) => {
    return {
        type: ActionTypes.WEEK_OFF_CONFIGURE_UPDATE_REQUEST,
        params
    }
}

export const GetWorkHoursAction = () => {
    return {
        type: ActionTypes.WORK_HOURS_GET_REQUEST,
    }
}

export const AddWorkHoursAction = (params) => {
    return {
        type: ActionTypes.WORK_HOURS_ADD_REQUEST,
        params
    }
}

export const UpdateWorkHoursAction = (params) => {
    return {
        type: ActionTypes.WORK_HOURS_UPDATE_REQUEST,
        params
    }
}
export const DeleteWorkHoursAction = (params) => {
    return {
        type: ActionTypes.WORK_HOURS_DELETE_REQUEST,
        params
    }
}
