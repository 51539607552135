import React from 'react';
import { BASE_URL } from '../../../../Constants/baseConstant';

const OfferApproveEmail = ({
    url,
    userName,
    guid,
    message,
    approvebutton,
    rejectbutton,
}) => {

    return (
        <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
            style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", boxShadow: "0 6px 18px 0 rgba(0,0,0,.06)" }}>
            <tbody>
                <tr style={{ textAlign: "left" }}>
                    <td>
                        <span style={{ color: "#455056", fontSize: "18px", fontWeight: 'bold', lineHeight: "24px", margin: 0 }}>
                            Dear {userName}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{ padding: "0 35px" }}>
                        <span
                            style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                        <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                            {message}
                        </span>
                        <br />
                        <br />
                        <br />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ display: "inline-block", margin: "10px" }}>
                                <a
                                    href={`${BASE_URL}${url}${guid}?approved=true`}
                                    style={{
                                        background: "#20e277",
                                        textDecoration: "none",
                                        fontWeight: 500,
                                        color: "#fff",
                                        textTransform: "uppercase",
                                        fontSize: "14px",
                                        padding: "10px 24px",
                                        borderRadius: "50px",
                                    }}
                                >
                                    {approvebutton}
                                </a>
                            </div>
                            <div style={{ display: "inline-block", margin: "10px" }}>
                                <a
                                    href={`${BASE_URL}${url}${guid}?approved=false`}
                                    style={{
                                        background: "#ff4d4f",
                                        textDecoration: "none",
                                        fontWeight: 500,
                                        color: "#fff",
                                        textTransform: "uppercase",
                                        fontSize: "14px",
                                        padding: "10px 24px",
                                        borderRadius: "50px",
                                    }}
                                >
                                    {rejectbutton}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{ padding: "0 35px", textAlign: "right" }}>
                        <span style={{ color: "#455056", fontSize: "12px", lineHeight: "24px" }}>
                            Powered by <strong>Meepl</strong>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default OfferApproveEmail;
