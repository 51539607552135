import * as React from "react";
import "../css/allnotification.css";

import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Box, IconButton, Typography } from "@mui/material";
import Done from "@mui/icons-material/Done";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    GetAllNotificationAction,
  GetNotificationAction,
  UpdateNotificationAction,
} from "../../../../store/Ancillary/Notification/Action";
import { AddMenuHeadingAction } from "../../../../store/Menu/Action";
import history from "../../../../history";

const AllNotification = React.memo(() => {
  const dispatch = useDispatch();
  const { notificationData, allNotificationData } = useSelector(
    (state) => state?.AncillaryReducer?.NotificationReducer
  );
  const { quicklinksData } = useSelector(
    (state) => state?.EmployeeDashboardReducer
  );
  const { atsModuleData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleReducer
  );

  const showTeamNotificaton = quicklinksData?.[0]?.lms?.[0]?.["Team"];
  const ATSAccess = atsModuleData?.find((item) => item.module_name === "ATS");
  const approvalTimeSheet = allNotificationData?.[0]?.notifications?.[0]?.approval_timesheet;
  const approvalTimeOff = allNotificationData?.[0]?.notifications?.[0]?.approval_timeoff;

  useEffect(() => {
    if (ATSAccess) {
      dispatch(GetNotificationAction());
    }
    if (showTeamNotificaton) {
        dispatch(GetAllNotificationAction());
    }
    // eslint-disable-next-line
  }, [dispatch, showTeamNotificaton, ATSAccess]);


  const handleClickOpen = () => {
    sessionStorage.setItem("selectedMenu", "My Task");
    dispatch(AddMenuHeadingAction({ heading: "My Task" }));
    history.push("/ats/tasks/");
  };

  const handleUpdateNotification = (event, id) => {
    event.stopPropagation();
    dispatch(
      UpdateNotificationAction({ is_read: "Y", notification_data: `{${id}}` })
    );
  };

  const handleLeaveRedirect = (tabIndex) => {
    sessionStorage.setItem("selectedMenu", "Team");
    dispatch(AddMenuHeadingAction({ heading: "Team" }));
    history.push("/lms/manage-team");
    sessionStorage.setItem("tabIndex", tabIndex ) 
  };
  
  return (
    <Box
      className= "notification-container"
    >
      {notificationData?.[0]?.notifiedusers?.filter(
        (item) => item?.is_read !== true
      )?.length > 0 ||
      approvalTimeOff > 0 ||
      approvalTimeSheet > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: 1,
              mt: -0.5,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{ color: "dark", fontWeight: "bold", fontSize: 20 }}
              >
                Notifications
              </Typography>
            </Box>
          </Box>
          <Divider />
          {showTeamNotificaton && (
            <>
              {approvalTimeOff > 0 && (
                <MenuItem onClick={() => handleLeaveRedirect("2")} sx={{ height: "40px" }}>
                  <Typography overflow="hidden" textOverflow="ellipsis">
                    <span>
                      You have <strong>{approvalTimeOff}</strong> leave{" "}
                      {approvalTimeOff > 1 ? "requests" : "request"} awaiting
                      your approval.
                    </span>
                  </Typography>
                </MenuItem>
              )}

              {approvalTimeSheet > 0 && (
                <MenuItem onClick={() =>handleLeaveRedirect("1")} sx={{ height: "40px" }}>
                  <Typography overflow="hidden" textOverflow="ellipsis">
                    <span>
                      You have <strong>{approvalTimeSheet}</strong> timesheet{" "}
                      {approvalTimeSheet > 1 ? "requests" : "request"} awaiting your
                      approval.
                    </span>
                  </Typography>
                </MenuItem>
              )}
            </>
          )}

          {/* Notification Related to ATS>TASKS-Notifications */}
          {notificationData?.[0]?.notifiedusers?.map((res, index) => {
            return (
              <React.Fragment key={index}>
                <MenuItem
                  onClick={handleClickOpen}
                  className="menu-item"
                >
                  <div className="scrolling-text-wrapper">
                    <Typography className="scrolling-text">
                      {res?.comment}
                    </Typography>
                  </div>

                  <Tooltip
                    title="Mark as read"
                    placement="bottom"
                    sx={{
                      "&:hover": { color: "#ba54f5", backgroundColor: "#ffff" },
                      backgroundColor: "#ffff",
                    }}
                  >
                    <IconButton
                      onClick={(e) =>
                        handleUpdateNotification(e, res?.notification_id)
                      }
                    >
                      <Done />
                    </IconButton>
                  </Tooltip>
                </MenuItem>
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <Typography
          sx={{ color: "dark", fontWeight: "bold", textAlign: "center" }}
        >
          Well done. All notifications are addressed.
        </Typography>
      )}
    </Box>
  );
});

export default AllNotification;
