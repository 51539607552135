import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import "../../css/styles.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TeamBalanceGrid from "./TeamBalanceGrid";
import TeamBalanceBulkUpload from "./TeamBalanceBulkUpload";

const TeamBalanceLandingPage = ({ defaultValue }) => {
    const [value, setValue] = useState(defaultValue ? defaultValue : '1');
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleChange = (event, newValue) => {
        setValue(newValue);

    };
    return (
      
            <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList sx={{
                                minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                '& .MuiTabs-indicator': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: "center !important",
                                },
                            }} onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Team Balance" value="1" />
                                <Tab label="Bulk Upload" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                            <TeamBalanceGrid />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            <TeamBalanceBulkUpload />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
      

    )
}

export default TeamBalanceLandingPage;