import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_EMPLOYEE_API_URL, HRMS_RESUME_PARSER_API, HRMS_UTILITY_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import NProgress from "nprogress";
import { validateEmailHelper } from "../../SignIn/helper/SigninHelper";
import { EmpPicPythonApiAction } from "../../../../store/ATS/PythonApi/Action";

// Employees 
export const GetEmployeesHelper = async (params) => {
    NProgress.start()
    var data = getUserData();
    return await axiosInstance(`/employee/getEmployees`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            is_active: params?.is_active
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Employees Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Employees", hasError: false, data: err?.response?.data }
        })
}

export const GetEmployeesFaceHelper = async (params) => {
    NProgress.start()
    var data = getUserData();
    return await axiosInstance(`/employee/getFaceEmployees`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            employee_id: data?.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Employees Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Employees", hasError: false, data: err?.response?.data }
        })
}



export const UpdateEmployeeStatusHelper = async (params) => {
    !params?.hide && toast.dismiss();
    !params?.hide && toast.info("Updating...")
    var data = getUserData();
    return await axiosInstance(`/employee/updateEmployeeStatus`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: params?.employee_id,
            is_active: params?.is_active
        }
    })
        .then((res) => {
            !params?.hide && toast.dismiss();
            !params?.hide && (params?.successMessage ? toast.success(params?.successMessage) : toast.success("Status Updated Successfully"))
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: false, data: err?.response?.data }
        })
}

// ATS Employees 
export const GetAtsEmployeesHelper = async (params) => {
    NProgress.start()
    var data = getUserData();
    return await axiosInstance(`/employee/getAtsEmployees`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            date: params?.date || null,
            from_date: params?.from_date || null,
            to_date: params?.to_date || null,
            is_active: params?.is_active,
            is_grouporg: data?.grouporg_flow || false
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Employees Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Employees", hasError: false, data: err?.response?.data }
        })
}

export const GetEmployeeBasicHelper = async (params) => {
    var data = getUserData();
    NProgress.start()
    return await axiosInstance(`/employee/getById/`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            employee_id: params?.employee_id ? params?.employee_id : data.employee_id
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })

}

export const AddEmployeeBasicHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/employee/basic/addBasic`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            empid: data.employee_id,
            first_name: params?.first_name,
            middle_name: params?.middle_name,
            last_name: params?.last_name,
            office_mobile: null,
            office_email: params?.office_email,
            designation_id: params?._designation_id?.designation_id || null,
            personal_mobile: params?.personal_mobile || null,
            personal_email: params?.personal_email || "",
            isactive: params?.isactive,
            image: params?.image || "",
            image_name: params?.image_name || "",
            joining_date: params?.joining_date,
            title: params?.title
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Basic Details Created Successfully")
            return { message: "Basic Details Created Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateEmployeeBasicHelper = async (params) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/basic/updateBasic`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            user_empid: data.employee_id,
            empid: params.employee_id,
            first_name: params?.first_name,
            middle_name: params?.middle_name,
            last_name: params?.last_name,
            office_mobile: null,
            office_email: params?.office_email,
            designation_id: params?._designation_id?.designation_id || null,
            personal_mobile: params?.personal_mobile || null,
            personal_email: params?.personal_email || "",
            isactive: params?.isactive,
            image: params?.image || "",
            image_name: params?.image_name || "",
            old_image: params?.old_image || "",
            joining_date: params?.joining_date,
            descriptor: params?.descriptor,
            title: params?.title
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Basic Details Updated Successfully")
            return { message: "Basic Details Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetEmployeeBankHelper = async (bankDetails) => {
    var data = getUserData();
    NProgress.start()
    return await axiosInstance(`/employee/bank/getAccounts`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: bankDetails?.employee_id,
        }
    })
        .then(res => {
            NProgress.done()
            if (res?.data?.data?.length === 0) {
                toast.dismiss();
                toast.warning("No Records Found")
            }

            return { message: "Data Retrived Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddEmployeeBankHelper = async (bankDetails) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/employee/bank/addAccount`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            emp_id: bankDetails.employee_id,
            account_number: bankDetails?.account_number,
            account_type: bankDetails?.account_type?.name,
            account_holder: bankDetails?.account_holder,
            bank_branch: bankDetails?.bank_branch,
            ifsc_code: bankDetails?.ifsc_code,
            bank_name: bankDetails?.bank_name
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Bank Details Added Successfully");
            return { message: "Bank Details Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateEmployeeBankHelper = async (bankDetails) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/bank/updateAccount`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            emp_id: bankDetails.employee_id,
            emp_bankdet_id: bankDetails?.emp_bankdet_id,
            account_number: bankDetails?.account_number,
            account_type: bankDetails?.account_type?.name,
            account_holder: bankDetails?.account_holder,
            bank_branch: bankDetails?.bank_branch,
            ifsc_code: bankDetails?.ifsc_code,
            bank_name: bankDetails?.bank_name
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Data Updated Successfully");
            return { message: "Data Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteEmployeeBankHelper = async (bankDetails) => {
    var data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/employee/bank/deleteAccount`, {
        method: "DELETE",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_empid: data.employee_id,
            emp_id: bankDetails.employee_id,
            emp_bankdet_id: bankDetails?.emp_bankdet_id,
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Bank Details Deleted Successfully");
            return { message: "Bank Details Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetEmployeeFamilyHelper = async (params) => {
    NProgress.start()
    return await axiosInstance(`/employee/family/getFamilies`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            employee_id: params.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            if (res?.data?.data?.length === 0) {
                toast.dismiss();
                toast.warning("No Records Found")
            }
            return { message: "Nominee Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Nominee", hasError: false, data: err?.response?.data }
        })

}

export const AddEmployeeFamilyHelper = async (family) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/employee/family/addFamily`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: family.domain,
            user_emp_id: data.employee_id,
            name: family.name,
            gender: family.gender || null,
            dob: family.dob,
            phone: family.phone,
            relationship_id: family.relationship_id.relationship_id,
            employee_id: family.employee_id,
            marital_status: family.marital_status?.name || "",
            marriage_date: family.marriage_date || null,
            other_details: family?.other_details || ""
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Nominee Added Successfully");
            return { message: "Nominee Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to add nominee", hasError: false, data: err?.response?.data }
        })

}
export const UpdateEmployeeFamilyHelper = async (family) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/family/updateFamily`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: family.domain,
            user_emp_id: data.employee_id,
            name: family.name,
            gender: family.gender || null,
            dob: family.dob,
            phone: family.phone,
            relationship_id: family.relationship_id.relationship_id,
            employee_id: family.employee_id,
            emp_family_id: family.emp_family_id,
            marital_status: family.relationship_id.reship_name === "Spouse" ? family.marital_status?.name : "",
            marriage_date: family.relationship_id.reship_name === "Spouse" ? family.marriage_date : null,
            other_details: family.relationship_id.reship_name === "Other" ? family.other_details : "",
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Nominee Updated Successfully");
            return { message: "Nominee Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })

}

export const DeleteEmployeeFamilyHelper = async (family) => {
    toast.info("Deleting...")

    return await axiosInstance(`/employee/family/deleteFamily`, {
        method: "DELETE",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: family.domain,
            employee_id: family.employee_id,
            emp_family_id: family.id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Nominee Deleted Successfully");
            return { message: "Nominee Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })

}

// education
export const GetEmployeeEducationHelper = async (params) => {
    NProgress.start()
    return await axiosInstance(`/employee/education/getEducation`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            employee_id: params.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            if (res?.data?.data?.length === 0) {
                toast.dismiss();
                toast.warning("No Records Found")
            }
            return { message: "Education Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Education", hasError: false, data: err?.response?.data }
        })

}

export const AddEmployeeEducationHelper = async (education) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/employee/education/addEducation`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: education.domain,
            user_emp_id: data.employee_id,
            employee_id: education.employee_id,
            studylevel_id: education.studylevel_id.studylevel_id,
            studyprogram_id: education.studyprogram_id.studyprogram_id,
            passingyear: education.passingyear?.year,
            institution: education.institution,
            board_unv: education.board_unv,
            mode: education.mode,
            result: education.result,
            any_arrears: education.any_arrears || false,
            proof: education?.proof || "",
            proof_name: education?.proof_name || null,
            content_type: education?.content_type
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Education Added Successfully");
            return { message: "Education Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to add Education", hasError: false, data: err?.response?.data }
        })

}
export const UpdateEmployeeEducationHelper = async (education) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/education/updateEducation`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: education.domain,
            user_emp_id: data.employee_id,
            employee_id: education.employee_id,
            emp_edu_id: education.emp_edu_id,
            studylevel_id: education.studylevel_id.studylevel_id,
            studyprogram_id: education.studyprogram_id.studyprogram_id,
            passingyear: education.passingyear?.year,
            institution: education.institution,
            board_unv: education.board_unv,
            mode: education.mode,
            result: education.result,
            any_arrears: education.any_arrears || false,
            proof: education?.proof || "",
            proof_name: education?.proof_name,
            old_proof: education?.old_proof,
            content_type: education?.content_type
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Education Updated Successfully");
            return { message: "Education Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })

}

export const DeleteEmployeeEducationHelper = async (education) => {
    var data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/employee/education/deleteEducation`, {
        method: "DELETE",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_empid: data.employee_id,
            employee_id: education.employee_id ? education.employee_id : data.employee_id,
            emp_edu_id: education?.id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Education Deleted Successfully");
            return { message: "Education Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })

}

// Work Experience
export const GetEmployeeWorkExperienceHelper = async (workExperience) => {
    NProgress.start()
    return await axiosInstance(`/employee/workexperience/getExperience`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domainname: workExperience.domain,
            empid: workExperience.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            if (res?.data?.data?.length === 0) {
                toast.dismiss();
                toast.warning("No Records Found")
            }
            return { message: "Work Experience Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Work Experience", hasError: false, data: err?.response?.data }
        })

}

export const AddEmployeeWorkExperienceHelper = async (workExperience) => {
    const data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/employee/workexperience/addExperience`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domainname: workExperience.domain,
            user_empid: data.employee_id,
            empid: workExperience.employee_id,
            previous_employer: workExperience.previous_employer,
            dojoining: workExperience.dojoining,
            doleaving: workExperience.doleaving,
            role_played: workExperience.role_played,
            proof: workExperience.proof || "",
            proof_name: workExperience.proof_name || null,
            content_type: workExperience.content_type
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Work Experience Added Successfully");
            return { message: "Work Experience Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to add Work Experience", hasError: false, data: err?.response?.data }
        })

}
export const UpdateEmployeeWorkExperienceHelper = async (workExperience) => {
    const data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/workexperience/updateExperience`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domainname: workExperience.domain,
            user_empid: data.employee_id,
            empid: workExperience.employee_id,
            emp_workexp_id: workExperience.emp_workexp_id,
            previous_employer: workExperience.previous_employer,
            dojoining: workExperience.dojoining,
            doleaving: workExperience.doleaving,
            role_played: workExperience.role_played,
            proof: workExperience?.proof || "",
            proof_name: workExperience?.proof_name,
            old_proof: workExperience?.old_proof,
            content_type: workExperience.content_type
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Work Experience Updated Successfully");
            return { message: "Work Experience Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })

}

export const DeleteEmployeeWorkExperienceHelper = async (workExperience) => {
    const data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/employee/workexperience/deleteExperience`, {
        method: "DELETE",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domainname: data.domain,
            user_empid: data.employee_id,
            empid: workExperience.employee_id ? workExperience.employee_id : data.employee_id,
            emp_workexp_id: workExperience.id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Work Experience Deleted Successfully");
            return { message: "Work Experience Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })

}


// Work Details
export const GetEmployeeWorkDetailHelper = async (params) => {
    NProgress.start()
    return await axiosInstance(`/employee/workDetail/getWorkDetail`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            employee_id: params.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Work Details Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Work Details", hasError: false, data: err?.response?.data }
        })

}

export const AddEmployeeWorkDetailHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/employee/workDetail/addWorkDetail`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            empid: params.employee_id,
            joining_date: params.joining_date,
            employmenttype_id: params.employmenttype_id.employmenttype_id || null,
            paygrade_id: params.paygrade_id.paygrade_id || null,
            dorejoining: params.dorejoining || null,
            phy_disabled: params.phy_disabled,
            phy_disabilities: params.phy_disabilities,
            contractor: params.contractor,
            contract_from: params.contract_from || null,
            contract_to: params.contract_to || null,
            costcenter_id: params.costcenter_id.costcenter_id || null,
            hrmanager_id: params.hrmanager_id.employee_id || null,
            ats_work_location_id: params?.ats_work_location_id?.ats_work_location_id || null,
            tfn_no: params?.tfn_no || null,
            work_hour_configuration_id: params?.work_hour_configuration_id?.work_hour_configuration_id || params?.work_hour_configuration_id?.work_hour_configuration_pk
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Work Details Add Successfully");
            return { message: "Work Details Add Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })

}

export const UpdateEmployeeWorkDetailHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/employee/workDetail/updateWorkDetail`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            employee_id: params.employee_id,
            joining_date: params.joining_date,
            employmenttype_id: params.employmenttype_id.employmenttype_id || null,
            paygrade_id: params.paygrade_id.paygrade_id || null,
            dorejoining: params.dorejoining || null,
            phy_disabled: params.phy_disabled,
            phy_disabilities: params.phy_disabilities,
            contractor: params.contractor,
            contract_from: params.contract_from || null,
            contract_to: params.contract_to || null,
            costcenter_id: params.costcenter_id.costcenter_id || null,
            hrmanager_id: params.hrmanager_id.employee_id || null,
            ats_work_location_id: params?.ats_work_location_id?.ats_work_location_id || null,
            tfn_no: params?.tfn_no || null,
            work_hour_configuration_id: params?.work_hour_configuration_id?.work_hour_configuration_id || params?.work_hour_configuration_id?.work_hour_configuration_pk
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Work Details Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })

}

// Payroll Profile Details
export const GetEmployeePayrollProfileDetailHeper = async (params) => {
    NProgress.start()
    return await axiosInstance(`/employee/payrollDetail/getPayrollProfile`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            employee_id: params.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Payroll Profile Details Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Unable to fetch Payroll Profile Details", hasError: false, data: err?.response?.data }
        })

}

export const UpdateEmployeePayrollProfileDetailHelper = async (params) => {
    const data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/payrollDetail/updatePayrollProfile`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            employee_id: params.employee_id,
            bu_id: params?.bu_id?.businessunit_id || null,
            pan: params?.pan || null,
            uan_no: params?.uan_no || null,
            pf_no: params.pf_no || null,
            esi_no: params.esi_no || null,
            international_worker: params.international_worker || false,

        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Payroll Profile Updated Successfully");
            return { message: "Payroll Profile Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })

}


// Personal Details
export const GetEmployeePersonaleHelper = async (params) => {
    NProgress.start()
    return await axiosInstance(`/employee/personal/getPersonal`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            emp_id: params.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            if (res?.data?.data?.length === 0) {
                toast.dismiss();
                toast.warning("No Records Found")
            }

            return { message: "Personal Details Loaded Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}

export const AddEmployeePersonaleHelper = async (personalData) => {
    const data = getUserData();
    const isPublicData = personalData?.proof_type?.is_public_data
        ? JSON.stringify(personalData.proof_type.is_public_data)
        : null;
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/employee/personal/addPersonal`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: personalData.domain,
            user_empid: data.employee_id,
            emp_id: personalData.employee_id,
            identity_id: personalData?.proof_type?.identity_id ? personalData?.proof_type?.identity_id : null,
            name: personalData?.name,
            number: personalData?.number,
            surname: personalData?.surname,
            givenname: personalData?.givenname,
            issued_place: personalData?.issued_place,
            expiry_date: personalData?.expiry_date,
            proof: personalData?.proof,
            proof_name: personalData?.proof_name,
            content_type: personalData?.content_type,
            is_public_data: isPublicData,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Personal Details Saved Successfully");
            return { message: "Personal Details Saved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to Update", hasError: false, data: err?.response?.data }
        })
}

export const UpdateEmployeePersonaleHelper = async (personalData) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/employee/personal/updatePersonal`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: personalData.domain,
            user_empid: data.employee_id,
            emp_id: personalData.employee_id,
            emp_idproof_id: personalData?.emp_idproof_id,
            identity_id: personalData?.proof_type?.identity_id ? personalData?.proof_type?.identity_id : null,
            name: personalData?.name,
            number: personalData?.number,
            surname: personalData?.surname,
            givenname: personalData?.givenname,
            issued_place: personalData?.issued_place,
            expiry_date: personalData?.expiry_date,
            proof: personalData?.proof,
            proof_name: personalData?.proof_name,
            old_proof: personalData?.old_proof,
            content_type: personalData?.content_type,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Personal Details Updated Successfully");
            return { message: "Personal Details Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to Update", hasError: false, data: err?.response?.data }
        })
}

export const DeleteEmployeePersonaleHelper = async (personalData) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/employee/personal/deletePersonal`, {
        method: "DELETE",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            user_empid: data.employee_id,
            emp_id: personalData?.employee_id,
            emp_idproof_id: personalData?.emp_idproof_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Personal Details Deleted Successfully");
            return { message: "Personal Details Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to Update", hasError: false, data: err?.response?.data }
        })
}

// Other Personal Details
export const GetEmployeeOtherPersonaleHelper = async (params) => {
    return await axiosInstance(`/employee/otherpersonal/getOtherPersonal`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            emp_id: params.employee_id,
        }
    })
        .then((res) => {
            return { message: "Personal Details Loaded Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}

export const UpdateEmployeeOtherPersonaleHelper = async (personalDetails) => {
    const data = getUserData();
    return await axiosInstance(`/employee/otherpersonal/updateOtherPersonal`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: personalDetails.domain,
            user_empid: data.employee_id,
            emp_id: personalDetails.employee_id,
            birthdate: personalDetails?.birthdate,
            gender: personalDetails?.gender,
            emg_contact_no: personalDetails?.emg_contact_no || null,
            emg_contact_person: personalDetails?.emg_contact_person || "",
            reportingmanager_id: +personalDetails.reportingmanager_id.employee_id || null,
        }
    })
        .then((res) => {
            return { message: "Personal Details Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed to Update", hasError: false, data: err?.response?.data }
        })
}


export const GetEmployeeAddressHelper = async (params) => {
    return await axiosInstance(`/employee/employeeaddress/getEmployeeAddress`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: params.domain,
            emp_id: params.employee_id,
        }
    })
        .then((res) => {
            return { message: "Address Details Loaded Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}

export const UpdateEmployeeAddressHelper = async (addressDetails) => {
    const data = getUserData();
    return await axiosInstance(`/employee/employeeaddress/updateEmployeeAddress`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: addressDetails.domain,
            user_empid: data.employee_id,
            emp_id: addressDetails.employee_id,
            cur_street1: addressDetails?.cur_street1,
            cur_street2: addressDetails?.cur_street2,
            cur_city: +addressDetails?.cur_city?.city_id,
            cur_state: +addressDetails?.cur_state?.state_id,
            cur_country: +addressDetails?.cur_country?.country_id,
            cur_zipcode: addressDetails?.cur_zipcode,
            per_street1: addressDetails?.per_street1,
            per_street2: addressDetails?.per_street2,
            per_city: +addressDetails?.per_city?.city_id,
            per_state: +addressDetails?.per_state?.state_id,
            per_country: +addressDetails?.per_country?.country_id,
            per_zipcode: addressDetails?.per_zipcode,
            is_permanent: addressDetails?.is_permanent,

        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Address Details Updated Successfully");
            return { message: "Address Details Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to Update", hasError: false, data: err?.response?.data }
        })
}


// Employee Payroll
export const GetEmployeePayrollDetailseHelper = async (params) => {
    var data = getUserData();
    NProgress.start()
    return await axiosInstance(`/employee/payroll/getPayroll`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: params.employee_id,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Payroll Details Loaded Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}

export const UpdateEmployeePayrollDetailsHelper = async (params) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/payroll/updatePayroll`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: params.employee_id,
            paygrade_id: +params.paygrade_id?.paygrade_id,
            ctc: params.ctc,
            sal_structure_id: +params.salary_structure_id?.sal_structure_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Employee pay roll Updated Successfully");
            return { message: "Employee pay roll Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })

}

export const AddRoleAccessHelper = async (params) => {
    var user = getUserData();
    let enableAtsValue = params?.enable_ats;
    // Check if user_type is "Admin" and set values accordingly
    if (params?.core_hr_user_type === "Admin") {
        enableAtsValue = true;
    }
    toast.info("Saving...")
    return await axiosInstance(`/employee/roleaccess/addRoleAccess`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: user?.domain,
            employee_id: params?.employee_id,
            user_emp_id: user?.employee_id,
            email_id: params?.office_email,
            user_type: params?.core_hr_user_type,
            enable_ats: enableAtsValue,
            enable_corehr: true,
            enable_lms: true,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("User Created successfully")
            validateEmailHelper({ domain: user?.domain, email: params?.office_email })
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateRoleAccessHelper = async (params) => {
    var user = getUserData();
    let enableAtsValue = params?.enable_ats;

    // Check if user_type is "Admin" and set values accordingly
    if (params?.core_hr_user_type === "Admin") {
        enableAtsValue = true;
    }
    toast.info("Saving...")
    return await axiosInstance(`/employee/roleaccess/updateRoleAccess`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: user?.domain,
            employee_id: params?.employee_id,
            user_emp_id: user?.employee_id,
            email_id: params?.office_email,
            user_type: params?.core_hr_user_type,
            enable_ats: enableAtsValue,
            enable_corehr: true,
            enable_lms: true,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("User update successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddEmployeeHelper = async (params) => {
    const data = getUserData();
    let enableAtsValue = params?.enable_ats;
    if (params?.core_hr_user_type === "Admin") {
        enableAtsValue = true;
    }
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/employee/addEmployee`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: data?.emp_users_pk,
            first_name: params?.first_name,
            last_name: params?.last_name,
            office_email: params?.office_email,
            ats_work_location_id: params?.ats_work_location_id?.ats_work_location_id || null,
            designation_id: params?._designation_id?.designation_id || null,
            isactive: params?.isactive,
            dob: params?.dob,
            user_type: params?.core_hr_user_type,
            enable_ats: enableAtsValue,
            enable_corehr: true,
            enable_lms: true,
            reportingmanager_id: params?.reportingmanager_id.employee_id || null,
            joining_date: params?.joining_date,
            image: params?.image,
            image_name: params?.image_name,
            descriptor: params?.descriptor,
            businessUnit_id: params?.businessUnit?.businessunit_id || null,
            uan_no: params?.uan_no || null,
            pan_no: params?.pan || null,
            previous_employee_id: params?.previous_employee_id || null,
            title: params?.title,
            work_hour_configuration_id: params?.work_hour_configuration_id?.work_hour_configuration_id || params?.work_hour_configuration_id?.work_hour_configuration_pk
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Employee Created Successfully");
            return { message: "Employee Created Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to Update", hasError: false, data: err?.response?.data }
        })
}

export const UpdateEmployeePopupHelper = async (params) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/employee/updateEmployeePopup`, {
        method: "PUT",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data.domain,
            empid: params.employee_id,
            first_name: params?.first_name,
            last_name: params?.last_name,
            designation_id: params?._designation_id?.designation_id || null,
            reportingmanager_id: params?.reportingmanager_id.employee_id || null,
            uan_no: params.uan_no || null,
            pan: params?.pan || null,
            previous_employee_id: params?.previous_employee_id || null,
            dob: params?.dob,
            office_email: params?.office_email,
            ats_work_location_id: params?.ats_work_location_id?.ats_work_location_id || null,
            joining_date: params?.joining_date,
            image: params?.image || "",
            image_name: params?.image_name || "",
            old_image: params?.old_image || "",
            businessUnit_id: params?.businessUnit?.businessunit_id || params?.businessUnit?.businessUnit || null,
            descriptor: params?.descriptor,
            title: params?.title,
            work_hour_configuration_id: params?.work_hour_configuration_id?.work_hour_configuration_id || params?.work_hour_configuration_id?.work_hour_configuration_pk
        }
    })
        .then(res => {
            toast.dismiss();
            toast.success("Employee Details Updated Successfully")
            return { message: "Employee Details Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const EmployeeValidateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/employee/EmployeeValidate`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: data?.employee_id,
        }
    })
        .then((res) => {
            return { message: "sucess", hasError: false, data: res?.data }
        }).catch(err => {
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: false, data: err?.response?.data }
        })
}

export const PicUploadHelper = async (params, dispatch) => {
    return await axiosInstance(`/employee/uploadPic`, {
        method: "POST",
        baseURL: HRMS_EMPLOYEE_API_URL,
        data: {
            b64Files: params?.b64Files,
            fileNames: params?.fileNames,
            contentTypes: params?.contentTypes,
        }
    })
        .then((res) => {
            if (res?.data?.statuscode === 200) {
                dispatch(EmpPicPythonApiAction(params?.data))
                return { message: "Success", hasError: false, data: res?.data }
            }
            else {
                toast.error("failed to upload")
            }
        }).catch(err => {
            toast.error("failed")
            return { message: "Error" }
        })
}
export const PicParserHelper = async (data) => {
    return await axiosInstance(`/imageimport`, {
        method: "POST",
        baseURL: HRMS_RESUME_PARSER_API,
        data: JSON.stringify(data),
    })
        .then((res) => {
            toast.success("Employee data import completed successfully")
            return { message: "Success", hasError: false, data: res?.data };
        })
        .catch((err) => {
            toast.error("Failed")
            return { message: "Error" };
        });
}