import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { BASE_URL, HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import { AtsEmailHelper } from "./ATSEmailHeper";
import { renderEmail } from "react-html-email";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";
import ProposeCandidateEmail from "../views/ATSJobPost/ProposeCandidateEmail";
import { v4 as uuidv4 } from "uuid";
import { AddEmailTokenHelper } from "../../Ancillary/EmailToken/helper/EmailTokenHelper";
import PreScreenCandidateEmail from "../views/ATSJobPost/PreScreenCandidateEmail";

export const GetAtsJobKanbanHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance(`/ats/getKanbanUsers`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user.domain,
      user_emp_id: user?.ats_user_id,
      ats_jobpost_id: params?.ats_jobpost_id,
      assigned_to_id: params?.assigned_to_id || null,
      proposed_by_name: params?.proposed_by_name || null,
      stage_by: params?.ats_job_stages_id || null,
      sort_order:params?.sort_order || null,
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
export const AddAtsJobKanbanHelper = async (params) => {
  var user = getUserData();
  toast.info("Saving...");
  return await axiosInstance(`/ats/addKanbanUsers`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_empid: user?.ats_user_id,
      kanban_data: params?.kanban_data,
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Added successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
export const UpdateAtsJobKanbanHelper = async (params) => {
  var user = getUserData();
  toast.info("Saving...");

  return await axiosInstance(`/ats/updateKanbanUsers`, {
    method: "PUT",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_empid: user?.ats_user_id,
      kanban_data: params?.kanban_data,
    },
  })
    .then((res) => {
      toast.dismiss();
      if (params.reject === true) {
        toast.success("Candidate removed from the Board ");
        return { message: "Success", hasError: false, data: res?.data };
      } else {
        if(params?.comment === "YES"){
          toast.success("Thanks for the feedback!");
        }
        else{
          toast.success("Thanks for the update!");
        }
        return { message: "Success", hasError: false, data: res?.data };
      }
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
export const DeleteAtsJobKanbanHelper = async (params) => {
  var user = getUserData();
  toast.info("Deleting...");
  return await axiosInstance(`/ats/deleteJobStage`, {
    method: "DELETE",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Job Stage Deleted successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const ProposeCandidateEmailHelper = async ({
  candidateData,
  jobData,
  kanban_data
}) => {
  var user = getUserData();
  let token = uuidv4() + `=${user?.domain}`;
  let url = "/ats/add-candidate/";
  let link = `${BASE_URL}${url}${token}`;

  let pre_screen_token = uuidv4() + `=${user?.domain}`;
  let pre_screen_token_url = "/ats/prescreen-response/";
  let pre_screen_token_link = `${BASE_URL}${pre_screen_token_url}${pre_screen_token}`;

  AddEmailTokenHelper({
    token: token,
    email_id: candidateData?.email_id,
    json_data: JSON.stringify([candidateData]),
  });
  AddEmailTokenHelper({
    token: pre_screen_token,
    email_id: candidateData?.email_id,
    json_data: JSON.stringify({  candidateData, jobData: jobData, kanban_data: kanban_data }),
  });
  AtsEmailHelper({
    to: candidateData?.email_id,
    subject: "Form Response",
    content: renderEmail(
      <BaseEmailTemplate>
        <ProposeCandidateEmail proposedJobData={jobData} formLink={link} PreScreenLink={pre_screen_token_link}/>
      </BaseEmailTemplate>
    ),
  });
};

export const PreScreenCandidateEmailHelper = async ({
  jobData,
  kanban_data
}) => {
  var user = getUserData();
  let pre_screen_token = uuidv4() + `=${user?.domain}`;
  let pre_screen_token_url = "/ats/prescreen-response/";
  let pre_screen_token_link = `${BASE_URL}${pre_screen_token_url}${pre_screen_token}`;

  AddEmailTokenHelper({
    token: pre_screen_token,
    email_id: kanban_data?.email_id,
    json_data: JSON.stringify({jobData: jobData, kanban_data: kanban_data }),
  });
  AtsEmailHelper({
    to: kanban_data?.email_id,
    subject: "Pre Screen Question",
    content: renderEmail(
      <BaseEmailTemplate>
        <PreScreenCandidateEmail proposedJobData={jobData} PreScreenLink={pre_screen_token_link}/>
      </BaseEmailTemplate>
    ),
  });
};

export const GetProposedByHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance(`/ats/getProposeBy`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user.domain,
      ats_jobpost_id: params?.ats_jobpost_id,
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};

export const GetAtsJobKanbanOfferHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance(`/ats/getKanbanOffer`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user.domain,
      user_emp_id: user?.ats_user_id,
      ats_jobpost_id: params?.ats_jobpost_id,
      assigned_to_id: params?.assigned_to_id || null,
      proposed_by_name: params?.proposed_by_name || null,
      stage_by: params?.ats_job_stages_id || null,
      sort_order:params?.sort_order || null
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};


export const AddAtsJobKanbanOfferHelper = async (params) => {
  var user = getUserData();
  toast.info("Saving...");
  return await axiosInstance(`/ats/addKanbanOffer`, {
    method: "POST",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_empid: user?.ats_user_id,
      kanban_data: params?.kanban_data,
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Added successfully");
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};
export const UpdateAtsJobKanbanOfferHelper = async (params) => {
  var user = getUserData();
  toast.info("Saving...");

  return await axiosInstance(`/ats/updateKanbanOffer`, {
    method: "PUT",
    baseURL: HRMS_ATS_API_URL,
    data: {
      workspace: user?.domain,
      user_empid: user?.ats_user_id,
      kanban_data: params?.kanban_data,
    },
  })
    .then((res) => {
      toast.dismiss();
      if (params.reject === true) {
        toast.success("Candidate removed from the Board ");
        return { message: "Success", hasError: false, data: res?.data };
      } else {
        toast.success("Thanks for the update!");
        return { message: "Success", hasError: false, data: res?.data };
      }
    })
    .catch((err) => {
      toast.dismiss();
      toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: "Error", hasError: true, data: err?.response?.data };
    });
};