import { Box } from '@mui/material'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Base from '../../Base/views/Base'
import MyEmployeeGrid from './MyEmployeeGrid';
import Employee from '../../Employee/views/Employee';
import { useLocation } from "react-router-dom";
import { GetWorkHoursAction } from '../../../../store/Leave/WeekOff/Action';

const MyEmployee = () => {
    const { access } = useSelector(state => state.UserAccessReducer)
    const { candidateData } = useSelector(state => state?.OnboardingReducer)
    const location = useLocation();
    const defaultValue = location.state || "";
    const [currentMode, setCurrentMode] = useState(defaultValue? defaultValue : candidateData ? candidateData?.mode : "grid")
    const [data, setData] = useState();
    const dispatch = useDispatch();
    const [modules, setModules] = useState();
    useEffect(() => {
        dispatch(GetWorkHoursAction());
        dispatch({ type: "ONBOARDING_ENABLE_ADD_REQUEST" })
        setModules(access?.find(item => item.module === sessionStorage.getItem("selectedMenu"))?.submodules?.[0]?.permission)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (defaultValue === "grid") {
          setCurrentMode("grid")
        }
      }, [defaultValue]);

    return (
        <> 
        {
            <Box sx={{ width: '100%', padding: " 0px 10px 25px 10px" }}>
                    {
                            currentMode === "grid" ? <Base><MyEmployeeGrid access={modules} setCurrentMode={setCurrentMode} setData={setData} /></Base>
                                : <Employee setCurrentMode={setCurrentMode} currentMode={currentMode} OnboardingEmpData={data}/>
                    }
                    </Box>
        }
        </>

    )
}

export default MyEmployee