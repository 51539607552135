import * as ActionTypes from '../ActionTypes';

const componentInitialState = {
    isLoading: false,
    success: false,
    error: false,
    employee_template_data: [],
    calculate_salary_employee_template_data:[],
    payroll_worklocation_data:[]
}

export const EmployeeTemplateReducer = function (state = componentInitialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                payroll_template_data:[]
            };
        case ActionTypes.EMPLOYEE_TEMPLATE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                employee_template_data: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_TEMPLATE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.EMPLOYEE_TEMPLATE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_TEMPLATE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                employee_template_data: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_TEMPLATE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                employee_template_data: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
            case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                calculate_salary_employee_template_data:[]
            };
        case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                calculate_salary_employee_template_data: action.payload?.data?.data
            };
        case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
            case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                calculate_salary_employee_template_data:[]
            };
        case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                calculate_salary_employee_template_data: action.payload?.data?.data
            };
        case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_ADD_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
            case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
                calculate_salary_employee_template_data:[]
            };
        case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                calculate_salary_employee_template_data: action.payload?.data?.data
            };
        case ActionTypes.CALCULATE_EMPLOYEE_TEMPLATE_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
            case ActionTypes.PAYROLL_WORK_LOCATION_GET_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                    success: false,
                    error: false,
                    payroll_worklocation_data:[]
                };
            case ActionTypes.PAYROLL_WORK_LOCATION_GET_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    successMessage: action.payload?.message,
                    payroll_worklocation_data: action.payload?.data?.data
                };
            case ActionTypes.PAYROLL_WORK_LOCATION_GET_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    error: true,
                    success: false,
                    errorMessage: action.payload?.data?.error?.[0]?.message
                }
        default:
            return state;
    }
}