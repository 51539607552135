// Tax Declaration
export const TAX_DECLARATION_GET_REQUEST = "TAX_DECLARATION_GET_REQUEST";
export const TAX_DECLARATION_GET_SUCCESS = "TAX_DECLARATION_GET_SUCCESS";
export const TAX_DECLARATION_GET_ERROR = "TAX_DECLARATION_GET_ERROR";

export const TAX_DECLARATION_ADD_REQUEST = "TAX_DECLARATION_ADD_REQUEST";
export const TAX_DECLARATION_ADD_SUCCESS = "TAX_DECLARATION_ADD_SUCCESS";
export const TAX_DECLARATION_ADD_ERROR = "TAX_DECLARATION_ADD_ERROR";

export const TAX_DECLARATION_UPDATE_REQUEST = "TAX_DECLARATION_UPDATE_REQUEST";
export const TAX_DECLARATION_UPDATE_SUCCESS = "TAX_DECLARATION_UPDATE_SUCCESS";
export const TAX_DECLARATION_UPDATE_ERROR = "TAX_DECLARATION_UPDATE_ERROR";

// Employee Profile
export const EMPLOYEE_PROFILE_PAYROLL_GET_REQUEST = "EMPLOYEE_PROFILE_PAYROLL_GET_REQUEST";
export const EMPLOYEE_PROFILE_PAYROLL_GET_SUCCESS = "EMPLOYEE_PROFILE_PAYROLL_GET_SUCCESS";
export const EMPLOYEE_PROFILE_PAYROLL_GET_ERROR = "EMPLOYEE_PROFILE_PAYROLL_GET_ERROR";

// Salary Structure
export const SALARY_STRUCTURE_GET_REQUEST = "SALARY_STRUCTURE_GET_REQUEST";
export const SALARY_STRUCTURE_GET_SUCCESS = "SALARY_STRUCTURE_GET_SUCCESS";
export const SALARY_STRUCTURE_GET_ERROR = "SALARY_STRUCTURE_GET_ERROR";

export const SALARY_STRUCTURE_ADD_REQUEST = "SALARY_STRUCTURE_ADD_REQUEST";
export const SALARY_STRUCTURE_ADD_SUCCESS = "SALARY_STRUCTURE_ADD_SUCCESS";
export const SALARY_STRUCTURE_ADD_ERROR = "SALARY_STRUCTURE_ADD_ERROR";

export const SALARY_STRUCTURE_UPDATE_REQUEST = "SALARY_STRUCTURE_UPDATE_REQUEST";
export const SALARY_STRUCTURE_UPDATE_SUCCESS = "SALARY_STRUCTURE_UPDATE_SUCCESS";
export const SALARY_STRUCTURE_UPDATE_ERROR = "SALARY_STRUCTURE_UPDATE_ERROR";

export const SALARY_STRUCTURE_DELETE_REQUEST = "SALARY_STRUCTURE_DELETE_REQUEST";
export const SALARY_STRUCTURE_DELETE_SUCCESS = "SALARY_STRUCTURE_DELETE_SUCCESS";
export const SALARY_STRUCTURE_DELETE_ERROR = "SALARY_STRUCTURE_DELETE_ERROR";

//Salary Components
export const SALARY_COMPONENT_GET_REQUEST = "SALARY_COMPONENT_GET_REQUEST";
export const SALARY_COMPONENT_GET_SUCCESS = "SALARY_COMPONENT_GET_SUCCESS";
export const SALARY_COMPONENT_GET_ERROR = "SALARY_COMPONENT_GET_ERROR";

export const SALARY_COMPONENT_ADD_REQUEST = "SALARY_COMPONENT_ADD_REQUEST";
export const SALARY_COMPONENT_ADD_SUCCESS = "SALARY_COMPONENT_ADD_SUCCESS";
export const SALARY_COMPONENT_ADD_ERROR = "SALARY_COMPONENT_ADD_ERROR";

export const SALARY_COMPONENT_UPDATE_REQUEST = "SALARY_COMPONENT_UPDATE_REQUEST";
export const SALARY_COMPONENT_UPDATE_SUCCESS = "SALARY_COMPONENT_UPDATE_SUCCESS";
export const SALARY_COMPONENT_UPDATE_ERROR = "SALARY_COMPONENT_UPDATE_ERROR";

export const SALARY_COMPONENT_DELETE_REQUEST = "SALARY_COMPONENT_DELETE_REQUEST";
export const SALARY_COMPONENT_DELETE_SUCCESS = "SALARY_COMPONENT_DELETE_SUCCESS";
export const SALARY_COMPONENT_DELETE_ERROR = "SALARY_COMPONENT_DELETE_ERROR";

// Salary Component Types
export const SALARY_COMPONENT_TYPE_GET_REQUEST = "SALARY_COMPONENT_TYPE_GET_REQUEST";
export const SALARY_COMPONENT_TYPE_GET_SUCCESS = "SALARY_COMPONENT_TYPE_GET_SUCCESS";
export const SALARY_COMPONENT_TYPE_GET_ERROR = "SALARY_COMPONENT_TYPE_GET_ERROR";


//Components

export const PAYROLL_EARNING_GET_REQUEST = "PAYROLL_EARNING_GET_REQUEST";
export const PAYROLL_EARNING_GET_SUCCESS = "PAYROLL_EARNING_GET_SUCCESS";
export const PAYROLL_EARNING_GET_ERROR = "PAYROLL_EARNING_GET_ERROR";

export const PAYROLL_DEDUCTION_GET_REQUEST = "PAYROLL_DEDUCTION_GET_REQUEST";
export const PAYROLL_DEDUCTION_GET_SUCCESS = "PAYROLL_DEDUCTION_GET_SUCCESS";
export const PAYROLL_DEDUCTION_GET_ERROR = "PAYROLL_DEDUCTION_GET_ERROR";

// Payroll Template

export const PAYROLL_TEMPLATE_GET_REQUEST = "PAYROLL_TEMPLATE_GET_REQUEST";
export const PAYROLL_TEMPLATE_GET_SUCCESS = "PAYROLL_TEMPLATE_GET_SUCCESS";
export const PAYROLL_TEMPLATE_GET_ERROR = "PAYROLL_TEMPLATE_GET_ERROR";

export const PAYROLL_TEMPLATE_ADD_REQUEST = "PAYROLL_TEMPLATE_ADD_REQUEST";
export const PAYROLL_TEMPLATE_ADD_SUCCESS = "PAYROLL_TEMPLATE_ADD_SUCCESS";
export const PAYROLL_TEMPLATE_ADD_ERROR = "PAYROLL_TEMPLATE_ADD_ERROR";

export const PAYROLL_TEMPLATE_UPDATE_REQUEST = "PAYROLL_TEMPLATE_UPDATE_REQUEST";
export const PAYROLL_TEMPLATE_UPDATE_SUCCESS = "PAYROLL_TEMPLATE_UPDATE_SUCCESS";
export const PAYROLL_TEMPLATE_UPDATE_ERROR = "PAYROLL_TEMPLATE_UPDATE_ERROR";

export const PAYROLL_TEMPLATE_DELETE_REQUEST = "PAYROLL_TEMPLATE_DELETE_REQUEST";
export const PAYROLL_TEMPLATE_DELETE_SUCCESS = "PAYROLL_TEMPLATE_DELETE_SUCCESS";
export const PAYROLL_TEMPLATE_DELETE_ERROR = "PAYROLL_TEMPLATE_DELETE_ERROR";

// Employee Template

export const EMPLOYEE_TEMPLATE_GET_REQUEST = "EMPLOYEE_TEMPLATE_GET_REQUEST";
export const EMPLOYEE_TEMPLATE_GET_SUCCESS = "EMPLOYEE_TEMPLATE_GET_SUCCESS";
export const EMPLOYEE_TEMPLATE_GET_ERROR = "EMPLOYEE_TEMPLATE_GET_ERROR";

export const EMPLOYEE_TEMPLATE_ADD_REQUEST = "EMPLOYEE_TEMPLATE_ADD_REQUEST";
export const EMPLOYEE_TEMPLATE_ADD_SUCCESS = "EMPLOYEE_TEMPLATE_ADD_SUCCESS";
export const EMPLOYEE_TEMPLATE_ADD_ERROR = "EMPLOYEE_TEMPLATE_ADD_ERROR";

export const EMPLOYEE_TEMPLATE_UPDATE_REQUEST = "EMPLOYEE_TEMPLATE_UPDATE_REQUEST";
export const EMPLOYEE_TEMPLATE_UPDATE_SUCCESS = "EMPLOYEE_TEMPLATE_UPDATE_SUCCESS";
export const EMPLOYEE_TEMPLATE_UPDATE_ERROR = "EMPLOYEE_TEMPLATE_UPDATE_ERROR";

export const WAGE_REPORT_GET_REQUEST = "WAGE_REPORT_GET_REQUEST";
export const WAGE_REPORT_GET_SUCCESS = "WAGE_REPORT_GET_SUCCESS";
export const WAGE_REPORT_GET_ERROR = "WAGE_REPORT_GET_ERROR";

export const EMPLOYEE_TIME_GET_REQUEST = "EMPLOYEE_TIME_GET_REQUEST";
export const EMPLOYEE_TIME_GET_SUCCESS = "EMPLOYEE_TIME_GET_SUCCESS";
export const EMPLOYEE_TIME_GET_ERROR = "EMPLOYEE_TIME_GET_ERROR";

export const EMPLOYEE_FACE_TIME_GET_REQUEST = "EMPLOYEE_FACE_TIME_GET_REQUEST";
export const EMPLOYEE_FACE_TIME_GET_SUCCESS = "EMPLOYEE_FACE_TIME_GET_SUCCESS";
export const EMPLOYEE_FACE_TIME_GET_ERROR = "EMPLOYEE_FACE_TIME_GET_ERROR";

export const PAYROLL_SETTINGS_COMPONENT_GET_REQUEST = "PAYROLL_SETTINGS_COMPONENT_GET_REQUEST";
export const PAYROLL_SETTINGS_COMPONENT_GET_SUCCESS = "PAYROLL_SETTINGS_COMPONENT_GET_SUCCESS";
export const PAYROLL_SETTINGS_COMPONENT_GET_ERROR = "PAYROLL_SETTINGS_COMPONENT_GET_ERROR";

export const LWF_ADD_REQUEST = "LWF_ADD_REQUEST";
export const LWF_ADD_SUCCESS = "LWF_ADD_SUCCESS";
export const LWF_ADD_ERROR = "LWF_ADD_ERROR";

export const LWF_UPDATE_REQUEST = "LWF_UPDATE_REQUEST";
export const LWF_UPDATE_SUCCESS = "LWF_UPDATE_SUCCESS";
export const LWF_UPDATE_ERROR = "LWF_UPDATE_ERROR";

export const LWF_DELETE_REQUEST = "LWF_DELETE_REQUEST";
export const LWF_DELETE_SUCCESS = "LWF_DELETE_SUCCESS";
export const LWF_DELETE_ERROR = "LWF_DELETE_ERROR";

export const OT_GET_REQUEST = "OT_GET_REQUEST";
export const OT_GET_SUCCESS = "OT_GET_SUCCESS";
export const OT_GET_ERROR = "OT_GET_ERROR";

export const OT_ADD_REQUEST = "OT_ADD_REQUEST";
export const OT_ADD_SUCCESS = "OT_ADD_SUCCESS";
export const OT_ADD_ERROR = "OT_ADD_ERROR";

export const OT_UPDATE_REQUEST = "OT_UPDATE_REQUEST";
export const OT_UPDATE_SUCCESS = "OT_UPDATE_SUCCESS";
export const OT_UPDATE_ERROR = "OT_UPDATE_ERROR";

export const OT_DELETE_REQUEST = "OT_DELETE_REQUEST";
export const OT_DELETE_SUCCESS = "OT_DELETE_SUCCESS";
export const OT_DELETE_ERROR = "OT_DELETE_ERROR";

export const CALCULATE_EMPLOYEE_TEMPLATE_REQUEST = "CALCULATE_EMPLOYEE_TEMPLATE_REQUEST";
export const CALCULATE_EMPLOYEE_TEMPLATE_SUCCESS = "CALCULATE_EMPLOYEE_TEMPLATE_SUCCESS";
export const CALCULATE_EMPLOYEE_TEMPLATE_ERROR = "CALCULATE_EMPLOYEE_TEMPLATE_ERROR";

export const CALCULATE_EMPLOYEE_TEMPLATE_ADD_REQUEST = "CALCULATE_EMPLOYEE_TEMPLATE_ADD_REQUEST";
export const CALCULATE_EMPLOYEE_TEMPLATE_ADD_SUCCESS = "CALCULATE_EMPLOYEE_TEMPLATE_ADD_SUCCESS";
export const CALCULATE_EMPLOYEE_TEMPLATE_ADD_ERROR = "CALCULATE_EMPLOYEE_TEMPLATE_ADD_ERROR";

export const CALCULATE_EMPLOYEE_TEMPLATE_GET_REQUEST = "CALCULATE_EMPLOYEE_TEMPLATE_GET_REQUEST";
export const CALCULATE_EMPLOYEE_TEMPLATE_GET_SUCCESS = "CALCULATE_EMPLOYEE_TEMPLATE_GET_SUCCESS";
export const CALCULATE_EMPLOYEE_TEMPLATE_GET_ERROR = "CALCULATE_EMPLOYEE_TEMPLATE_GET_ERROR";

export const PAYROLL_WORK_LOCATION_GET_REQUEST = "PAYROLL_WORK_LOCATION_GET_REQUEST";
export const PAYROLL_WORK_LOCATION_GET_SUCCESS = "PAYROLL_WORK_LOCATION_GET_SUCCESS";
export const PAYROLL_WORK_LOCATION_GET_ERROR = "PAYROLL_WORK_LOCATION_GET_ERROR";

export const PAY_DATE_GET_REQUEST = "PAY_DATE_GET_REQUEST";
export const PAY_DATE_GET_SUCCESS = "PAY_DATE_GET_SUCCESS";
export const PAY_DATE_GET_ERROR = "PAY_DATE_GET_ERROR";

export const PAY_DATE_UPDATE_REQUEST = "PAY_DATE_UPDATE_REQUEST";
export const PAY_DATE_UPDATE_SUCCESS = "PAY_DATE_UPDATE_SUCCESS";
export const PAY_DATE_UPDATE_ERROR = "PAY_DATE_UPDATE_ERROR";