import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Grid,
    FormControl,
    Button,
    Checkbox,
    Popover,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    CircularProgress
} from "@mui/material";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector, useDispatch } from "react-redux";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import ReportEmailPopup from "./ReportEmailPopup";
import Popup from "../../../Base/views/Popup";
import { GetWageReportAction } from "../../../../../store/Finance/Reports/Action";
import { GenerateWageReportHelper } from "../../helper/Reports/GetWageReportHelper";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { formatPayPeriod } from "../../../Base/helper/baseFunctions";
import { GetPayDateAction } from "../../../../../store/Finance/PayDate/Action";

const MyEmployeePayroleReports = () => {
    const dispatch = useDispatch();
    const { wage_report_data, isLoading } = useSelector(
        (state) => state?.FinanceReducer.WageReportsReducer
    );
    const date = new Date();
    const [filters, setFilters] = useState({
        month_year: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    });
    const { pay_date_data } = useSelector(state => state.FinanceReducer?.PayDateReducer);
    const [openPopup, setOpenPopup] = useState(false);
    const [name, setName] = useState(date.toLocaleString('default', { month: 'short', year: 'numeric' }));
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE, setAnchorE] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [columns, setColumns] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isLoadingbtn, setIsLoadingbtn] = useState(false);

    const flattenData = (data) => {
        const flatObject = {};
        Object.keys(data).forEach((key) => {
            if (typeof data[key] === 'object' && data[key] !== null) {
                if (key === 'earned_salary_json' || key === 'actual_earnings_json') {
                    const prefix = key === 'earned_salary_json' ? 'earned_' : 'actual_';
                    Object.keys(data[key]).forEach((nestedKey) => {
                        flatObject[`${prefix}${nestedKey}`] = data[key][nestedKey];
                    });
                } else {
                    Object.keys(data[key]).forEach((nestedKey) => {
                        flatObject[nestedKey] = data[key][nestedKey];
                    });
                }
            } else {
                flatObject[key] = data[key];
            }
        });
        return flatObject;
    };
    const hasNonZeroValues = (field, data) => {
        return data.some(record => {
            const value = Number(record[field]);
            return !isNaN(value) && value !== 0;
        });
    };
    const getRelevantFields = (data) => {
        if (!data || data.length === 0) return [];
        const defaultFields = {
            employee_no: "Employee No",
            employee_name: "Employee Name",
            actual_working_days: "Actual Working Days",
            gross_earned_salary_month: "Total Earned Salary",
            total_deductions: "Total Deductions",
            net_salary: "Net Salary",
        };
        const flattenedData = data.map(item => flattenData(item));
        const allFields = new Set();
        flattenedData.forEach(record => {
            Object.keys(record).forEach(key => {
                if (!["pay_summary_pk", "employee_fk", "createdby", "createdon", "modifiedby", "modifiedon"].includes(key)) {
                    allFields.add(key);
                }
            });
        });
        const formatFieldName = (field) => {
            const prefixMatch = field.match(/^(actual_|earned_)/);
            const prefix = prefixMatch ? prefixMatch[0].replace('_', '').charAt(0).toUpperCase() + prefixMatch[0].slice(1).toLowerCase() : '';
            const formattedName = field.replace(/^(actual_|earned_)/, "").replace(/(json_)/gi, "");
            return `${prefix} ${formattedName}`
                .split(/[_\s]+/)
                .filter(word => word.trim() !== "")
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')
                .trim();
        };
        return Array.from(allFields)
            .filter(field =>
                defaultFields[field] || hasNonZeroValues(field, flattenedData)
            )
            .map(field => ({
                title: defaultFields[field] || formatFieldName(field),
                field: field,
                show: !!defaultFields[field],
                hidden: !defaultFields[field],
                export: true
            }));
    };
    useEffect(() => {
        if (wage_report_data?.length > 0) {
            const dynamicColumns = getRelevantFields(wage_report_data);
            setColumns(dynamicColumns);
            setTableData(wage_report_data.map(item => flattenData(item)));
        }
    }, [wage_report_data]);

    useEffect(() => {
        dispatch(GetWageReportAction());
        dispatch(GetPayDateAction())
    }, [dispatch]);

    useEffect(() => {
        const selectedDate = new Date(filters.month_year);
        setName(selectedDate.toLocaleString('default', { month: 'short', year: 'numeric' }));
    }, [filters]);

    const handleFilter = (event) => {
        if (["month_year"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value });
        }
    };
    const handleSelect = (rows) => {
        setSelectedRows(rows);
    };
    const handleSendEmail = () => {
        setOpenPopup(true);
    };
    const handleGenerateClicked = () =>{
        setAnchorE(null);
        setOpenDialog(true);
    }
    const handleGenerate = () => {
        setIsLoadingbtn(true);
        const [f_year, month] = filters.month_year.split("-");
        const f_month = month.padStart(2, "0");
        const id = selectedRows.map(item => item.employee_fk);
        GenerateWageReportHelper({
            employee_id: id?.length ? `{${id}}` : null,
            month: f_month,
            year: f_year
        }).then(() => {
            setIsLoadingbtn(false);
            dispatch(GetWageReportAction());
            setSelectedRows([]);
        });
        setOpenDialog(false);
    };
    const handleColumnToggle = (field) => {
        setColumns(prevColumns =>
            prevColumns.map(col =>
                col.field === field ? { ...col, show: !col.show, hidden: !col.hidden } : col
            )
        );
    };
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const handleExportAll = () => {
        const headers = columns?.map((col) => ({
            label: col.title,
            key: col.field,
        }));

        const csvData = [headers.map((header) => header.label)];
        const rowsToExport = selectedRows?.length > 0 ? selectedRows : wage_report_data;
        rowsToExport?.forEach((row) => {
            const flattenedRow = flattenData(row);
            const rowData = headers.map((header) => flattenedRow[header.key] || "");
            csvData.push(rowData);
        });
        const csv = csvData.map((row) => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `${name}.csv`;
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };

    return (
        <Box sx={{ maxHeight: "89vh", overflowY: "auto"}}>
            <Box sx={{ m: 1 }}>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }} container columnSpacing={1} mb={1}>
                    <FormControl>
                        <CommonDatePicker
                            name="month_year"
                            openTo="month"
                            views={['year', 'month']}
                            label="Year and Month"
                            pickerType="date"
                            size="small"
                            onKeyDown={(e) => e.preventDefault()}
                            onKeyPress={(e) => e.preventDefault()}
                            pickerFormat="MM/yyyy"
                            DefaultDTvalue={filters.month_year}
                            maxDate={new Date()}
                            handleChange={(value) =>
                                handleFilter({
                                    target: {
                                        name: "month_year",
                                        value: value
                                            ? `${value?.getFullYear()}-${("0" + (value?.getMonth() + 1)).slice(-2)}-${("0" + value?.getDate()).slice(-2)}`
                                            : "",
                                    },
                                })
                            }
                        />
                    </FormControl>

                    <div>
                        {isLoadingbtn ? (
                            <CircularProgress disableShrink />
                        ) : (
                            <Button
                                onClick={handleGenerateClicked}
                                className="mpl-primary-btn"
                            >
                                Generate Payroll
                            </Button>
                        )}
                    </div>
                </Grid>
            </Box>

            <Box>
                <MaterialTable
                    isLoading={isLoading}
                    columns={columns.filter(col => col.show)}
                    data={tableData}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        maxWidth: "80vh",
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                        sorting: true,
                        search: true,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        selection: true,
                        actionsColumnIndex: -1,
                        showSelectAllCheckbox: true,
                        showTextRowsSelected: true,
                        grouping: false,
                        columnsButton: false,
                        showTitle: true
                    }}
                    onSelectionChange={handleSelect}
                    actions={[
                        {
                            icon: () => <CloudDownloadIcon />,
                            tooltip: 'Export as CSV',
                            onClick: handleExportAll,
                            hidden: wage_report_data.length === 0,
                            isFreeAction: selectedRows?.length ? false : true,
                        },
                        {
                            icon: () => <ForwardToInboxIcon />,
                            tooltip: 'Send Email',
                            onClick: handleSendEmail,
                            hidden: wage_report_data.length === 0,
                            isFreeAction: selectedRows?.length ? false : true,
                        },
                        {
                            icon: () => <ViewColumnIcon />,
                            onClick: handlePopoverOpen,
                            hidden: wage_report_data.length === 0,
                            isFreeAction: selectedRows?.length ? false : true,
                        },
                    ]}
                    title="Pay Summary Report"
                />
            </Box>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    {columns?.slice()?.sort((a, b) => a.title.localeCompare(b.title))?.map((col) => (
                        <ListItem
                            key={col.field}
                            onClick={() => handleColumnToggle(col.field)}
                        >
                            <ListItemIcon>
                                <Checkbox checked={col.show} />
                            </ListItemIcon>
                            <ListItemText primary={col.title} />
                        </ListItem>
                    ))}
                </List>
            </Popover>

            <Popup
                fullWidth={true}
                maxWidth="md"
                title="Pay Summary Report"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <ReportEmailPopup
                    setOpenPopup={setOpenPopup}
                    wage_report_data={selectedRows.length > 0 ? selectedRows : wage_report_data} employees_options={columns}
                    name={name}
                />
            </Popup>
            <ConfirmationDialog
                title="Generate Payroll"
                content= {`Payroll will be generated from ${formatPayPeriod(pay_date_data?.[0]?.pay_day)}. Are you sure you want to continue?`}
                anchorE={anchorE}
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                OkButtonText="Yes"
                onSubmit={handleGenerate}
            />
        </Box>
    );
};

export default MyEmployeePayroleReports;