
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import MaterialTable from "material-table"
import {GetPayrollSettingsAction } from "../../../../../store/Finance/PayRollTemplate/Action";
import { useEffect } from "react";

const LWFLandingPage = () => {
    const dispatch = useDispatch()
    const { payroll_setting_component_data, isLoading} = useSelector(
        (state) => state?.FinanceReducer.PayrollTemplateReducer
    );
    useEffect(() => {
        dispatch(GetPayrollSettingsAction({component_type:"LWF" }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box sx={{ mt:2}}>
            <MaterialTable
             isLoading={isLoading}
                 columns={[
                    { title: "State Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "state_name" },
                    { title: "Frequency", cellStyle: { padding: "10px 10px 10px 13px" }, field: "frequency" },
                    { title: "Employee", cellStyle: { padding: "10px 10px 10px 13px" }, field: "employee_contribution" },
                    { title: "Employer", cellStyle: { padding: "10px 10px 10px 13px" }, field: "employer_contribution" },
                    
                ]}
                data={payroll_setting_component_data?.[0]?.fn_get_payroll_settings_components || []}
                options={{
                    maxBodyHeight: "65vh",
                    minBodyHeight: "65vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                    sorting: false,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: false,
                    exportFileName: "Employees",
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,
                }}
                title="Labour Welfare Fund"
            />
        </Box>
    );
}
export default LWFLandingPage;