import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { setActiveComponent } from "../../../../../store/Slide/Action";
import { useDispatch } from 'react-redux';
import "./HeaderStyle.css";
import $ from "jquery";
import myImage from "../../../../../Image/Meepl_new_logo.svg";
import { getSessionData } from "../../../Base/helper/baseHelper";
function NavBar() {
  const region = getSessionData({ key: "region" });
  const [showMenu, setShowMenu] = useState(false);
  const DownTime = false;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  }, []);
  useEffect(() => {
    // Scroll to the section specified by the URL hash
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [window.location.hash]);
  const toggleNav = () => {
    setShowMenu(!showMenu);
    $("nav").slideToggle();
    $(window).resize(function () {
      if ($(window).width() > 780) {
        $("nav").removeAttr("style");
      }
    });
  };
  const nav = (hash) => {
    if (hash) {
      history.push(hash);
    } else {
      history.push("/");
    }
  };

  const handleLoginClick = () => {
    const newValue = false;
    dispatch(setActiveComponent(newValue))
  };
  // const handleSignUpClick = () => {
  //   const newValue = true;
  //   dispatch(setActiveComponent(newValue))
  // };

  return (
    <>
      <div className="fixed-navbar dropdowns">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="navbar-container">
            <Link
              to="/"
              aria-current="page"
              className="brand w-nav-brand w--current"
              aria-label="home"
            >
              <img src={myImage} loading="lazy" alt="" className="brand-logo" />
            </Link>

            <div>
              <button
                className={`navbar-burger ${showMenu ? "is-active" : ""}`}
                aria-label="menu"
                aria-expanded="false"
                onClick={() => toggleNav()}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </button>
            </div>
            {/* className="nav-menu w-nav-menu" */}
            {/* `navbar-menu ${showMenu ? 'is-active' : ''}` */}
            {/* className={ `nav-menu w-nav-menu drop_sec ${showMenu ? 'is-active' : ''}`} */}
            <div className="drop_sec">
              <nav role="navigation" className="nav-menu w-nav-menu">
                <div className="nav-links-holder ">
                  <div className="dropdown">
                    <a
                      onClick={nav}
                      href="#Product"
                      className=" nav-link w-nav-link "
                    >
                      Product
                    </a>
                    {/* <div className="dropdown-menu">
                      <a className="link" href="/hr">
                        CoreHR
                      </a>
                      <a className="link" href="/hr">
                        Web Applications
                      </a>
                      <a className="link" href="/hr">
                        SEO Advice
                      </a>
                    </div> */}
                  </div>
                  <div className="dropdown">
                    <a
                      onClick={nav}
                      href="#Solution"
                      className="nav-link w-nav-link"
                    >
                      Why Us
                    </a>
                    {/* <div className="dropdown-menu">
                      <a className="link" href="/hr">
                        UX Design
                      </a>
                      <a className="link" href="/hr">
                        Web ications
                      </a>
                      <a className="link" href="/hr">
                        SEO ice
                      </a>
                    </div> */}
                  </div>

                  <div className="dropdown">
                    <a
                      onClick={nav}
                      href="#Feature"
                      className="nav-link w-nav-link"
                    >
                      Features
                    </a>
                    {/* <div className="dropdown-menu">
                      <a className="link" href="/hr">
                        UX Design
                      </a>
                      <a className="link" href="/hr">
                        Web gggications
                      </a>
                      <a className="link" href="/hr">
                        SEO ice
                      </a>
                    </div> */}
                  </div>
                  {/* <a href="/MeetOurTeam" className="nav-link w-nav-link">
                    Meet Our Team
                  </a> */}
                  {/* {region === "in" && (
                    <a
                      onClick={nav}
                      href="#Pricing"
                      className="nav-link w-nav-link"
                    >
                      Pricing
                    </a>
                  )} */}
                </div>

                <div className="nav-button-holder">
                  <Link
                    to={DownTime ? "/DownTime" : `/${region}/signup`}
                    onClick={handleLoginClick} className="button transparent w-button">
                    Sign In
                  </Link>
                  {/* <Link onClick={handleSignUpClick} to="/signup" className="button purple w-button">
                    Join Us
                  </Link> */}
                  <Link
                    to={DownTime ? "/DownTime" : `/${region}/requestDemo`}
                    className="button purple w-button">
                    Signup For Demo
                  </Link>
                </div>
              </nav>
              <div
                className="menu-button w-nav-button"
                aria-label="menu"
                role="button"
                tabIndex="0"
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              ></div>
            </div>
          </div>
          <div
            className="w-nav-overlay"
            data-wf-ignore=""
            id="w-nav-overlay-0"
          ></div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
