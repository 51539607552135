import { useState, useEffect } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
  MenuItem,
  Box,
  Button,
  FormControl,
  FormLabel,
  CircularProgress
} from "@mui/material";
import JSZip from "jszip";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { toast } from 'react-toastify';
import ArrowBackButton from "../../../../../Base/views/ArrowBackButton";
import { AtsJobPostModeAction } from "../../../../../../../store/ATS/JobPost/Action";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MaterialTable from "material-table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Popup from "../../../../../Base/views/Popup";
import AtsCandidatePreview from "../../AtsCandidatePreview";

import {
  GetAtsJobKanbanHelper,
  GetAtsJobKanbanOfferHelper,
  UpdateAtsJobKanbanHelper,
} from "../../../../helper/AtsJobKanbanHelper";
import { GetAtsJobStageHelper } from "../../../../helper/AtsJobStageHelper";
import { useDispatch, useSelector } from "react-redux";
import { GetAtsCandidateAction } from "../../../../../../../store/ATS/Candidate/Action";
import AtsCandidateValidate from "../../../ATSCandidate/AtsCandidateValidate";
import FlagCandidatePopup from "../FlagCandidatePopup";
import RejectPopup from "../RejectPopup";
import AcceptPopup from "../AcceptPopup";
import { ConvertDateTime, s3Client } from "../../../../../Base/helper/baseFunctions";
import ConfirmationDialog from "../../../../../Base/views/ConfirmationDialog";
import { UpdateStatusHelper } from "../../../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper";
import {
  API_KEY,
  ATS_CANDIDATE,
  ATS_CANDIDATE_JOB,
  CLIENT_ID,
  SCOPES,
} from "../../../../../../Constants/baseConstant";
import ContactEmailForm from "../../../ATSCandidate/ContactEmailForm";
import { PowerUserDisabledAction } from "../../../../../../../store/RoleAccess/Permission/Action";
import InterviewScheduleForm from "../../../Dashboard/InterviewScheduleForm";
import { gapi } from "gapi-script";
import LoginForm from "../../../Dashboard/LoginForm";
import AccessWarning from "../../../Dashboard/AccessWarning";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import SendIcon from "@mui/icons-material/Send";
import ResumeSharingForm from "../../../ATSCandidate/ResumeSharingForm";
import HowToRegIcon from "@mui/icons-material/HowToReg";

function TableBoard({
  setArchived,
  currentMode,
  setCurrentMode,
  currentJobData,
  candidatevalueData,
  setCandidateUpdate,
  candidateUpdate,
  setCandidatePreview,
  toggleDrawer,
  setAnchor,
  anchor,
  setCurrentData,
  currentData,
  filters,
  sortValue,
  tabValue
}) {

  const today = new Date();
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(today);
  const dispatch = useDispatch();
  const [elements, setElements] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [atsJobStageData, setAtsJobStageData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openemailPopup, setOpenEmailPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const [reject, setReject] = useState(false);
  const [accept, setAccept] = useState(false);
  const [stagesData, setStagesData] = useState({ load: true, values: [] });
  const [isverified, setIsVerified] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorE, setAnchorE] = useState(null);
  const [clickedData, setClickedData] = useState(null);
  const [values, setValues] = useState({});
  const [sharedState, setSharedState] = useState();
  const [openLogin, setOpenLogin] = useState(false);
  const [accessgranted, setAccessGranted] = useState(false);
  const [orgname, setOrg] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const accessTokenforGoogle = localStorage.getItem("accessTokenforGoogle");
  const [gapiInitialized, setGapiInitialized] = useState(false);
  const [openResumePopup, setOpenResumePopup] = useState(false);
  useEffect(() => {
    setSharedState(sessionStorage.getItem('tabTypeIndex'));
  }, []);
  const [FlagCandidate, setFlagCandidate] = useState(false);
  const open = Boolean(anchorEl);
  const { featuresData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer
  );
  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: SCOPES,
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          if (authInstance.isSignedIn.get()) {
            const user = authInstance.currentUser.get();
            setOrg(user?.wt?.Ad);
            gapi.client.calendar.events.list({
              'calendarId': 'primary',
              'maxResults': 1,
            }).then(response => {
              setHasAccess(true);
            }).catch(error => {
              setHasAccess(false);
            });
          } else {
            setHasAccess(false);
          }
          setGapiInitialized(true);
        });
    }
    gapi.load("client:auth2", start);
  }, [accessgranted]);
  useEffect(() => {
    GetAtsJobStageHelper({
      ats_jobpost_id: currentJobData?.ats_jobpost_id,
    }).then((res) => {
      setAtsJobStageData(res?.data?.data);
    });
    getKanbanData();
    dispatch(GetAtsCandidateAction());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (candidateUpdate === true) {
      GetAtsJobStageHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        setAtsJobStageData(res?.data?.data);
      });
      getKanbanData();
      dispatch(GetAtsCandidateAction());
      setCurrentData(candidatevalueData);
      setCandidateUpdate(false);
    }
    // eslint-disable-next-line
  }, [candidateUpdate]);
  useEffect(() => {
    if (
      filters?.assignee_filter?.length > 0 ||
      filters?.proposed_filter?.length > 0 ||
      (filters?.stage_filter && filters.stage_filter.ats_job_stages_id) ||
      sortValue?.name === "priority"
    ) {
      let precruiter = [];
      let propose = [];
      filters?.assignee_filter?.forEach((res) =>
        precruiter.push(res?.recruiter_id)
      );
      filters?.proposed_filter?.forEach((res) =>
        propose.push(res?.recruiter_id)
      );
      const assigned_to_id =
        filters?.assignee_filter?.length > 0
          ? construcData(
            filters?.assignee_filter,
            "assigned_to_id",
            "recruiter_id"
          )
          : null;
      const proposed_by_name =
        filters?.proposed_filter?.length > 0
          ? construcData(
            filters?.proposed_filter,
            "proposed_by_name",
            "proposed_by_name"
          )
          : null;
      const stage_id = 
        filters?.stage_filter && filters.stage_filter.ats_job_stages_id
          ? JSON.stringify(
            [{ stage_id: filters.stage_filter.ats_job_stages_id }])
          : null;

      if (currentJobData?.offer_letter) {
        GetAtsJobKanbanOfferHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
          assigned_to_id: assigned_to_id,
          proposed_by_name: proposed_by_name,
          ats_job_stages_id: stage_id,
          sort_order: sortValue?.order,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
        });
      } else {
        GetAtsJobKanbanHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
          assigned_to_id: assigned_to_id,
          proposed_by_name: proposed_by_name,
          ats_job_stages_id: stage_id,
          sort_order: sortValue?.order,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
        });
      }
    } else {
      getKanbanData();
    }
    // eslint-disable-next-line
  }, [filters, sortValue]);
  const handleDisabled = (permission) => {
    let value = featuresData?.find((item) => item?.permission === permission);
    return value ? true : false;
  };
  const handlePowerDisabled = (e) => {
    e.stopPropagation();
    dispatch(PowerUserDisabledAction());
  };
  const construcData = (value, label, key) => {
    var temp = [];
    value?.forEach((res) => {
      temp.push({ [label]: res[key] });
    });
    return JSON.stringify(temp);
  };
  const getKanbanData = () => {
    if (currentJobData?.offer_letter) {
      GetAtsJobKanbanOfferHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
      });
    } else {
      GetAtsJobKanbanHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
      });
    }
  };

  const openEmailPopup = (event, data) => {
    event.stopPropagation();
    setOpenEmailPopup(true);
    setCurrentData(data);
    handleClose();
  };
  const handleValues = (event, value) => {
    if (["stage_id"].includes(event)) {
      setValues({ ...values, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...value, [event]: value });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...values, [event.target.name]: event.target.value });
    }
  };
  const validate = (inputData) => {
    let temp = { ...errors };
    if ("joined_date" in inputData) {
      temp.joined_date =
        inputData.joined_date !== "dd/mm/yyyy" ? "" : "Join date is required";
    }
    if ("tentative_joining_date" in inputData) {
      temp.tentative_joining_date =
        inputData.tentative_joining_date !== "dd/mm/yyyy"
          ? ""
          : "Tentative joning date is required";
    }
    if ("reason" in inputData) {
      temp.reason = inputData?.reason === "" ? "Reason is required" : "";
    }
    if ("flag_reason" in inputData) {
      temp.flag_reason =
        inputData?.flag_reason === "" ? "Reason is required" : "";
    }
    if ("revert_reason" in inputData) {
      temp.revert_reason =
        inputData?.revert_reason === "" ? "Revert Reason is required" : "";
    }
    if ("stage_id" in inputData) {
      temp.stage_id = ["", null].includes(inputData?.stage_id)
        ? "Moving Stage is required"
        : "";
    }
    if ("interview_date" in inputData) {
      temp.interview_date =
        inputData.interview_date === "" ? "Interview Date is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleVerify = () => {
    setIsVerified(false);
    setShowValidate(true);
  };

  const handleStageDataLoading = () => {
    setStagesData({ load: false, values: atsJobStageData });
  };
  const handleClick = (event, data) => {
    setClickedData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleCardClick = (event, data) => {
    if (data?.is_verified === true) {
      setCurrentData(data);
      setAnchor(true);
    } else {
      setCurrentData(data);
      setIsVerified(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleScheduleInterview = (event, data) => {
    if (data?.is_verified === true) {
      event.stopPropagation();
      setCurrentData(data);
      if (accessTokenforGoogle) {
        if (hasAccess) {
          setOpenPopup(true)
        }
        else {
          setOpenAccess(true)
        }
      } else {
        setOpenLogin(true);
      }
    } else {
      setCurrentData(data);
      setIsVerified(true);
    }
  };
  const handleArchiveClick = (event, data) => {
    event.stopPropagation();
    setAnchorE(null);
    setOpenDialog(true);
  };
  const FlagCandidateClick = (event, data) => {
    event.stopPropagation();
    if (data?.flag_reason) {
      setValues({ flag_reason: data?.flag_reason });
    } else {
      setValues({ flag_reason: "" });
    }
    setFlagCandidate(true);
  };
  const handleConfirmArchive = () => {
    UpdateStatusHelper({
      update_id: clickedData?.candidate_job_id,
      status: "Inactive",
      column_name: ATS_CANDIDATE_JOB,
      successMessage: "Candidate Archived Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        getKanbanData();
        clickedData?.is_external &&
          UpdateStatusHelper({
            update_id: clickedData?.candidate_id,
            status: "Inactive",
            column_name: ATS_CANDIDATE,
            successMessage: "Candidate Archived Successfully",
            hide: true,
          });
      }
    });
    setOpenDialog(false);
  };
  const handleReject = (e, data) => {
    e.stopPropagation();
    if (data?.job_stage_name === "Rejected") {
      setAccept(true);
      setValues({ revert_reason: "", stage_id: "" });
    } else {
      setReject(true);
      let rejectData = atsJobStageData?.find((res) => res?.name === "Rejected");
      setValues({ reason: "", stage_id: rejectData?.ats_job_stages_id });
    }
  };
  const handleRejectClick = () => {
    if (validate(values)) {
      UpdateAtsJobKanbanHelper({
        reject,
        kanban_data: JSON.stringify([
          {
            proposed_by: +clickedData?.proposed_by,
            candidate_job_id: +clickedData?.candidate_job_id,
            ats_jobpost_id: clickedData?.ats_jobpost_id,
            interview_feedback: clickedData?.interview_feedback,
            ats_job_stages_id:
              typeof values?.stage_id === "object"
                ? values?.stage_id?.ats_job_stages_id
                : values?.stage_id,
            priority: clickedData?.priority || 5,
            ats_status_id: null,
            selected: clickedData?.selected || null,
            candidate_id: clickedData?.candidate_id,
            accepted_ctc: clickedData?.accepted_ctc
              ? clickedData?.accepted_ctc
              : 0,
            tentative_joining_date: clickedData?.tentative_joining_date,
            joined_ctc: clickedData?.joined_ctc,
            joined_date: clickedData?.joined_date,
            offer_released_date: clickedData?.offer_released_date,
            rejected_reason: values?.reason || null,
            reverted_reason: values?.revert_reason || null,
          },
        ]),
      }).then((res) => {
        getKanbanData();
      });
      setErrors([]);
      setAccept(false);
      setReject(false);
    }
  };

  const handleFlagCandidateClick = () => {
    if (validate(values)) {
      UpdateAtsJobKanbanHelper({
        kanban_data: JSON.stringify([
          {
            proposed_by: +clickedData?.proposed_by,
            candidate_job_id: +clickedData?.candidate_job_id,
            ats_jobpost_id: clickedData?.ats_jobpost_id,
            interview_feedback: clickedData?.interview_feedback,
            ats_job_stages_id: clickedData?.ats_job_stages_id,
            priority: clickedData?.priority || 5,
            ats_status_id: null,
            selected: clickedData?.selected || null,
            candidate_id: clickedData?.candidate_id,
            accepted_ctc: clickedData?.accepted_ctc
              ? clickedData?.accepted_ctc
              : 0,
            tentative_joining_date: clickedData?.tentative_joining_date,
            joined_ctc: clickedData?.joined_ctc,
            joined_date: clickedData?.joined_date,
            offer_released_date: clickedData?.offer_released_date,
            rejected_reason: values?.reason || null,
            reverted_reason: values?.revert_reason || null,
            flag_reason: values?.flag_reason || null,
          },
        ]),
      }).then((res) => {
        getKanbanData();
      });
      setErrors([]);
      setFlagCandidate(false);
    }
  };
  const isTab1Disabled = sharedState == 2
  const generateCSVContent = (data) => {
    const csvRows = [];
    const headers = ["Name", "Email", "Stage", "Assignee", "Proposed By", "Candidate Score", "Modified Date"];
    csvRows.push(headers.join(","));
    data.forEach(row => {
      csvRows.push([
        row.full_name || "",
        row.email_id || "",
        row.job_stage_name || "",
        row.assignee_name || "",
        row.comp_cons_name || "",
        row.priority || "",
        row.modifiedon || ""
      ].join(","));
    });

    return csvRows.join("\n");
  };
  const handleZipDownload = async (data) => {
    setIsLoading(true);
    const zip = new JSZip();
    const csvContent = generateCSVContent(data);
    zip.file(`Candidates Details ${currentJobData?.title}.csv`, csvContent);
    for (const row of data) {
      if (row.resume) {
        const bucketParams = {
          Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
          Key: row.resume,
        };
        try {
          const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams),
            { expiresIn: 3 * 60 }
          );
          const response = await fetch(url);
          const blob = await response.blob();
          const fileExtension = row.resume.split(".").at(-1);
          const fileName = `${row.first_name || ""}.${fileExtension}`;
          zip.file(fileName, blob);
        } catch (err) {
          toast.error(`Error fetching resume for ${row.full_name}: ${err.message}`);
        }
      }
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      const zipBlob = new Blob([content], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download =`Candidates Details ${currentJobData?.title}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };
  const openResumeSharePopup = () => {
    setOpenResumePopup(true);
    handleClose();
  };
  return (
    <>
      {showValidate && (
        <AtsCandidateValidate
          setOpenValidatePopup={setShowValidate}
          openValidatePopup={showValidate}
          currentData={currentData}
          getKanbanData={getKanbanData}
          kanban={true}
        />
      )}
      <MaterialTable
        onRowClick={(e, rowData) => {
          if (!isTab1Disabled) {
            handleCardClick(e, rowData)
          }
        }}
        columns={[
          {
            title: "Name",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "full_name",
            grouping: false,
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ margin: "0px 0px 0px 0px" }}>
                    {rowData?.full_name}
                  </span>
                  {rowData?.flag_reason && (
                    <Tooltip title={rowData?.flag_reason}>
                      <FlagOutlinedIcon fontSize="small" color="error" />
                    </Tooltip>
                  )}
                </div>
              );
            },
          },
          {
            title: "Email",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "email_id",
            grouping: false,
          },
          {
            title: "Stage",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "job_stage_name",
            grouping: false,
          },
          {
            title: "Assignee",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "assignee_name",
            grouping: false,
          },
          {
            title: "Proposed By",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "comp_cons_name",
            grouping: false,
          },
          {
            title: "Candidate Score",
            cellStyle: { padding: "10px 10px 10px 30px" },
            field: "priority",
            grouping: false,
          },
          {
            title: "Modified Date",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "modifiedon",
            grouping: false,
            render: (rowData) => {
              return ConvertDateTime(rowData?.modifiedon);
            },
          },
          {
            title: "Status",
            cellStyle: { padding: "10px 10px 10px 20px" },
            field: "is_verified",
            grouping: false,
            render: (rowData) => {
              return rowData?.is_verified ? (
                <VerifiedIcon style={{ color: "green" }} /> 
              ) : (
                <VerifiedOutlinedIcon style={{ color: "red" }} /> 
              );
            }
          },
        ]}
        data={elements || []}
        options={{
          maxBodyHeight: "65vh",
          minBodyHeight: "65vh",
          rowStyle: { fontSize: "13px" },
          sorting: true,
          search: true,
          searchText: "",
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: false,
          paging: true,
          pageSizeOptions: [25, 50],
          pageSize: 25,
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          paginationPosition: "bottom",
          exportButton: false,
          exportAllData: true,
          exportFileName: `Candidates Details ${currentJobData?.title}`,
          addRowPosition: "first",
          selection: true,
          actionsColumnIndex: -1,
          showSelectAllCheckbox: true,
          showTextRowsSelected: true,
          grouping: false,
          columnsButton: false,
        }}
        actions={[
           {
            icon: () => isLoading ? <CircularProgress size={24} /> : <CloudDownloadOutlinedIcon />,
            tooltip: "Download Candidates Details",
            onClick: () => handleZipDownload(selectedRows),
            hidden: selectedRows.length === 0
          },
          {
            icon: () => <MoreVertIcon fontSize="small" style={{
              color: isTab1Disabled ? 'gray' : 'inherit',
              pointerEvents: isTab1Disabled ? 'none' : 'auto', opacity: isTab1Disabled ? 0.5 : 1
            }} />,
            position: "row",
            tooltip: "Settings",
            onClick: (event, rowData) => {
              if (!isTab1Disabled) {
                handleClick(event, rowData);
              }
            }
          },
        ]}
        onSelectionChange={(rows) => setSelectedRows(rows)}
        title=""
      /><br />
      <ArrowBackButton
        style={{
          top: '10px',
          left: '15px',
          position: 'relative',
        }}
        onClick={() => {
          setCurrentMode("grid");
          dispatch(AtsJobPostModeAction({ job_mode: "grid" }));
          currentMode === "archive"
            ? setArchived(true)
            : setArchived(false);
        }}
      />

      <Menu
        id="more-options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-options-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleDisabled("job_post_kanban_update_candidate")
              ? handlePowerDisabled(e)
              : handleArchiveClick(e, clickedData);
            handleClose();
          }}
        >
          <ListItemIcon>
            <PersonRemoveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
        { clickedData?.is_verified && (
          <>
            <MenuItem
              onClick={(e) => {
                openEmailPopup(e, clickedData);
              }}
            >
              <ListItemIcon>
                <ForwardToInboxIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Contact Email</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleDisabled("job_post_kanban_update_candidate")
                  ? handlePowerDisabled(e)
                  : handleScheduleInterview(e, clickedData);
                handleClose();
              }}
            >
              <ListItemIcon>
                <CalendarMonthIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Schedule Interview</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleDisabled("job_post_kanban_update_candidate")
                  ? handlePowerDisabled(e)
                  : handleReject(e, clickedData);
                handleClose();
              }}
            >
              <ListItemIcon>
                {clickedData?.job_stage_name === "Rejected" ? (
                  <CheckOutlinedIcon color="success" fontSize="small" />
                ) : (
                  <PersonOffOutlinedIcon color="error" fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>
                {clickedData?.job_stage_name === "Rejected" ? "Select" : "Reject"}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                handleDisabled("job_post_kanban_update_candidate")
                  ? handlePowerDisabled(e)
                  : FlagCandidateClick(e, clickedData);
                handleClose();
              }}
            >
              <ListItemIcon>
                <FlagOutlinedIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Flag Candidate</ListItemText>
            </MenuItem>
            </>)}
            { !clickedData?.is_verified && <MenuItem
              onClick={() => {
                handleVerify();
                handleClose();
                setCurrentData(clickedData);
              }}
              disabled={handleDisabled("candidate_verify_candidate")}
            >
              <ListItemIcon>
                <HowToRegIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Verify</ListItemText>
            </MenuItem>}
              {clickedData?.resume ? (
                  <MenuItem
                        onClick={openResumeSharePopup}
                      >
                        <ListItemIcon>
                          <SendIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Share Resume</ListItemText>
                      </MenuItem>
                    ) : (
                    <MenuItem>
                      <ListItemText>No Resume Found</ListItemText>
                    </MenuItem>
                )}
      </Menu>
      <ConfirmationDialog
        title="Archive"
        content="The candidate will be removed from this job, and you can still propose the candidate to other Jobs. Are you sure want to archive?"
        anchorE={anchorE}
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Job"
        OkButtonText="Archive"
        onSubmit={handleConfirmArchive}
      />
      <Popup
          fullWidth={true}
          maxWidth="md"
          title="Share Resume"
          openPopup={openResumePopup}
          setOpenPopup={setOpenResumePopup}
        >
          <ResumeSharingForm
            setOpenPopup={setOpenResumePopup}
            currentData={clickedData}
          />
        </Popup>
      <Popup
        fullWidth={true}
        maxWidth="md"
        close={false}
        openPopup={anchor}
        hideDividers={true}
      >
        <AtsCandidatePreview
          setCandidateUpdate={setCandidateUpdate}
          setCandidatePreview={setCandidatePreview}
          setElements={setElements}
          toggleDrawer={toggleDrawer}
          stageData={atsJobStageData}
          data={currentData}
          currentJobData={currentJobData}
          tabValue={tabValue}
        />
      </Popup>

      <Popup
        maxWidth="lg"
        title="Candidate is not Verified"
        isMasterProduct={false}
        setOpenPopup={setIsVerified}
        openPopup={isverified}
      >
        <Box minWidth="400px">
          <FormControl>
            <FormLabel id="gender-label">
              The Selected Candidate is not Verified
            </FormLabel>
          </FormControl>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              className="mpl-secondary-btn"
              onClick={() => setIsVerified(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              className="mpl-primary-btn"
              onClick={() => handleVerify()}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Popup>

      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Schedule Interview"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <InterviewScheduleForm
          setOpenPopup={setOpenPopup}
          gapiInitialized={gapiInitialized}
          date={formattedDate}
          jobdata={currentData}
          orgname={orgname}
        />
      </Popup>
      <Popup
        maxWidth="xs"
        title="SignIn Using"
        openPopup={openLogin}
        setOpenPopup={setOpenLogin}
      >
        <LoginForm setOpenPopup={setOpenLogin} setAccessGranted={setAccessGranted} />
      </Popup>
      <Popup
        maxWidth="sm"
        title="Google Access"
        openPopup={openAccess}
        setOpenPopup={setOpenAccess}
      >
        <AccessWarning setOpenPopup={setOpenAccess} setAccessGranted={setAccessGranted} />
      </Popup>
      <RejectPopup
        openPopup={reject}
        setOpenPopup={setReject}
        handleRejectClick={handleRejectClick}
        errors={errors}
        setErrors={setErrors}
        setValues={setValues}
        values={values}
      />
      <AcceptPopup
        accept={accept}
        setAccept={setAccept}
        handleStageDataLoading={handleStageDataLoading}
        handleValues={handleValues}
        errors={errors}
        values={values}
        setErrors={setErrors}
        setValues={setValues}
        handleRejectClick={handleRejectClick}
        stagesData={stagesData}
      />
      <FlagCandidatePopup
        openPopup={FlagCandidate}
        setOpenPopup={setFlagCandidate}
        handleFlagCandidateClick={handleFlagCandidateClick}
        errors={errors}
        setValues={setValues}
        setErrors={setErrors}
        values={values}
      />
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Contact Email"
        openPopup={openemailPopup}
        setOpenPopup={setOpenEmailPopup}
      >
        <ContactEmailForm
          setOpenPopup={setOpenEmailPopup}
          currentData={currentData}
        />
      </Popup>
    </>
  );
}
export default TableBoard;
