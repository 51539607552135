import React, { useEffect, useState } from "react";
import {
    Button,
    Typography,
    Box,
    Grid,
    Tooltip,
    Card,
    CardContent,
    IconButton,
    CardActions,
    Divider,
    Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { Add } from "@mui/icons-material";
import { NoData } from "../../../Base/views/NotAuthorized";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { DeletePayrollTemplateAction, GetPayrollTemplateAction } from "../../../../../store/Finance/PayRollTemplate/Action";


function Templates({setCurrentMode, setData}) {
    const dispatch = useDispatch()
    const [formId, setFormId] = useState("");
    const { payroll_template_data, isLoading } = useSelector(
        (state) => state?.FinanceReducer.PayrollTemplateReducer
    );
    const [deleteConfirm, setDeleteConform] = useState(false);
    useEffect(() => {
        dispatch(GetPayrollTemplateAction())
        //eslint-disable-next-line
    }, []);

    const handleEdit = (data) => {
        setCurrentMode("Edit")
        setData(data)
    }

    const handleDeleteConfirm = (id) => {
        setFormId(id);
        setDeleteConform(!deleteConfirm);
    }

    const handleDelete = () => {
        dispatch(DeletePayrollTemplateAction({ payroll_generation_template_id: formId }));
        setFormId("");
        setDeleteConform(!deleteConfirm);
    }
    const handleAdd = () => {
        setCurrentMode("Add")
        setData("")
    }

    return (
        <Box sx={{ maxHeight: "85vh", overflowY: "auto", pb: 2 }}>
           
                <Box sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#ffffff", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={() => handleAdd()}
                        variant="contained"
                        className="mpl-primary-btn"
                        startIcon={<Add />}
                        sx={{ marginBottom: "10px", marginTop:"10px" }}
                    >
                        Add Template
                    </Button>
                </Box>
                <>
                    {isLoading ? <Grid container direction="row" alignItems="center" spacing={2} mt={1}>
                        {Array(8)
                            .fill()
                            .map((data, index) => (
                                <Grid key={index} item lg={3} md={3} sm={3} xs={12}>
                                    <Card
                                        key={index}
                                        sx={{
                                            ":hover": { cursor: "pointer" },
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                            minHeight: "125px",
                                            maxHeight: "125px"
                                        }}
                                    >
                                        <CardContent>
                                            <Skeleton
                                                sx={{ height: 40 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                        </CardContent>
                                        <Divider variant="middle" />
                                        <CardActions sx={{ padding: "0px" }}>
                                            <Box
                                                sx={{
                                                    padding: "0px 20px 8px 20px",
                                                    display: "flex",
                                                    WebkitBoxPack: "justify",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Skeleton
                                                    animation="wave"
                                                    sx={{ borderRadius: 4 }}
                                                    height={20}
                                                    width="30%"
                                                />
                                                <Skeleton
                                                    animation="wave"
                                                    sx={{ borderRadius: 4 }}
                                                    height={20}
                                                    width="30%"
                                                />
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                        :
                        payroll_template_data?.length > 0 ? <Grid container direction="row" alignItems="center" spacing={2} mt={1}>
                            {payroll_template_data?.map((template, index) => (
                                <Grid key={index} item lg={3} md={3} sm={3} xs={12} >
                                    <Card key={index} sx={{ ":hover": { cursor: "pointer" }, minHeight: "125px", maxHeight: "125px" }}>
                                        <CardContent>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <Tooltip title={template?.template_name}>
                                                    <Typography variant="h5" className="truncate-heading">{template?.template_name}</Typography>
                                                </Tooltip>
                                            </Box>
                                        </CardContent>
                                        <Divider variant="middle" />
                                        <CardActions sx={{ padding: "0px" }}>
                                            <Box
                                                sx={{
                                                    padding: "0px 20px 8px 20px",
                                                    display: "flex",
                                                    WebkitBoxPack: "justify",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={() => handleEdit(template)}>
                                                        <EditIcon color="primary" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                {/* { */}
                                                    {/* // template?.response_id === null ? */}
                                                        <Tooltip title={"Delete"}>
                                                            <IconButton onClick={() => handleDeleteConfirm(template?.template_id)}
                                                                        disabled={template?.is_default}
                                                                >
                                                                <DeleteIcon color={template?.is_default ? "disabled" : "error"} fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {/* : */}
                                                        {/* <Tooltip title={"Responses are submitted for this form template. Please create new template."}>
                                                            <IconButton sx={{ color:'grey.500'}}>
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                {/* } */}

                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>))}
                        </Grid>
                            : <NoData title="You haven’t created any templates yet" />
                    }
                </>
                
            <ConfirmationDialog
                title="Delete Template?"
                content={"Are you sure want to Delete this Template?"}
                openDialog={deleteConfirm}
                closeDialog={setDeleteConform}
                OkButtonText="OK"
                onSubmit={handleDelete}
            />
        </Box>
    );
}

export default Templates;