import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import history from './history';
import rootStore from './store/rootStore';
import { CookiesProvider } from 'react-cookie';
import "nprogress/nprogress.css";
import './css/appCss.css';
import { GenerateHrmsBearerToken } from './app/Components/Base/helper/authHelper';
import { addSessionData } from './app/Components/Base/helper/baseHelper';

const store = rootStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  const [loading, setLoading] = useState(true);
  const [region, setRegion] = useState();

  useEffect(() => {
    async function setupRegion() {
      try {
        const response = await fetch(`https://ipapi.co/json/`);
        const data = await response.json();
        const region = data?.country_code?.toLowerCase();
        setRegion(region)
        addSessionData({ key: "region", value: region });
      } catch (error) {
        console.error("Error fetching country data:", error);
      } finally {
        setLoading(false);
      }
    }

    setupRegion();
    GenerateHrmsBearerToken();
  }, []);
  if (loading) return <div></div>;
  return (
    <CookiesProvider>
      <Router history={history}>
        <Provider store={store}>
          <Routes region={region} />
        </Provider>
      </Router>
    </CookiesProvider>
  );
}

root.render(<App />);
