import { useDispatch, useSelector } from "react-redux";
import { Box, TextField,Checkbox } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddProofTypeAction, UpdateProofTypeAction, DeleteProofTypeAction } from "../../../../../store/Ancillary/ProofType/Action";

const ProofType = () => {
    const dispatch = useDispatch()
    const { proof_type_data } = useSelector(state => state?.AncillaryReducer?.ProofTypeReducer);
    
    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Name", cellStyle: { padding: "0px 10px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                        {
                            title: "Proof Length", cellStyle: { padding: "0px 10px" }, field: "identity_length", validate: rowData => rowData?.identity_length === '' || rowData?.identity_length === undefined ? 'Length cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    type="number"
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                        {
                            title: "Document Required",
                            cellStyle: { padding: "0px 10px" },
                            field: "document_required",
                            editComponent: props => (
                                <Checkbox
                                    checked={props?.value || false}
                                    onChange={e => props.onChange(e.target.checked)}
                                />
                            )
                        }
                        
                    ]}
                    data={proof_type_data || []}
                    icons={{
                        Add: () => <AddIcon />,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteIcon />
                    }}
                    editable={{
                        isEditable: rowData => rowData?.is_public_data !== 1,
                        isDeletable: rowData => rowData?.is_public_data !== 1,
                        onRowAdd: (newRow) =>
                            new Promise((resolve, reject) => {
                                if (newRow.document_required === undefined) {
                                    newRow.document_required = false;
                                }
                                dispatch(AddProofTypeAction(newRow));
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(UpdateProofTypeAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(DeleteProofTypeAction({ identity_id: `{${selectedRow?.identity_id}}` }))
                                setTimeout(() => resolve(), 1000);
                            }),
                    }}
                    options={{
                        maxBodyHeight: 220,
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: false,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title=""
                />
            </Box>
        </>
    );
}

export default ProofType;
