/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import {
  Box,
  Button,
  Tooltip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Checkbox,
  Slider,
  Modal,
  IconButton,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import "../../css/style.css";
import {
  convertBase64,
  s3Client,
  getUserData,
} from "../../../Base/helper/baseFunctions";
import * as faceapi from "face-api.js";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetEmployeeBasicAction } from "../../../../../store/Employee/Basic/Action";
import { GetDesignationAction } from "../../../../../store/Ancillary/Designation/Action";
import { createStyles, makeStyles } from "@mui/styles";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {
  AddEmployeeBasicHelper,
  UpdateEmployeeBasicHelper,
} from "../../helper/EmployeeHelper";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import {
  GetEmployeeOtherPersonalAction,
  UpdateEmployeeOtherPersonalAction,
} from "../../../../../store/Employee/OtherPersonal/Action";
import history from "../../../../../history";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import { AddDesignationHelper } from "../../../Ancillary/Designation/helper/DesignationHelper";
import CreatableSearch from "../../../Base/views/CreatableSearch";
import Popup from "../../../Base/views/Popup";
import { AddWorkLocationHelper } from "../../../Ancillary/WorkLocation/helper/WorkLocation";
import { GetWorkLocationAction } from "../../../../../store/Ancillary/WorkLocation/Action";
import { GetEmploymentTypeAction } from "../../../../../store/Ancillary/EmploymentType/Action";
import { GetCostCenterAction } from "../../../../../store/Ancillary/CostCenter/Action";
import { AddEmployeeWorkDetailAction, GetEmployeeWorkDetailAction, UpdateEmployeeWorkDetailAction } from "../../../../../store/Employee/WorkDetail/Action";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Webcam from "react-webcam";

const BasicDetails = ({
  OnboardEmployee,
  setCurrentMode,
  OnboardingEmpData,
  currentMode,
  setStepper,
  setNewData,
  setActiveStep,
  newdata

}) => {
  const { atsModuleData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleReducer
  );
  const roleAccess = atsModuleData?.find(
    (item) => item.module_name === "My Employee"
  );

  const [disableEdit, setdisableEdit] = useState(
    currentMode === "addemployee" ? false : true
  );
  const [disableEmployee] = useState(
    OnboardEmployee === true ? true : false
  );
  const EditButton = roleAccess ? true : false;
  const [active] = useState(false);

  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "10px",
        borderRadius: "10px",
      },
      comingSoon: {
        fontWeight: 600,
        fontSize: "18px",
        marginTop: "30px",
        color: "white",
      },
      ActiveStatusDesign: {
        height: "24px",
        minWidth: "22px",
        borderRadius: "6px",
        alignItems: "center",
        display: "inline-flex",
        justifyContent: "center",
        padding: "0px 8px",
        color: active ? "rgb(27, 128, 106)" : "rgb(183, 33, 54)",
        fontSize: "0.75rem",
        backgroundColor: active
          ? "rgba(54, 179, 126, 0.16)"
          : "rgba(255, 72, 66, 0.16)",
        fontWeight: 700,
        textTransform: "uppercase",
      },
    })
  );
  const classes = useStyles();
  const userData = getUserData();
  const initialValue = {
    first_name: "",
    middle_name: "",
    last_name: "",
    employee_no: "",
    office_email: "",
    personal_email: "",
    personal_mobile: "",
    _designation_id: "",
    reportingmanager_id: "",
    image: "",
    isactive: true,
    birthdate: null,
    gender: "",
    emg_contact_no: "",
    emg_contact_person: "",
    joining_date: null,
    title: ""
  };
  const initialValues = {
    employmenttype_id: "",
    paygrade_id: "",
    dorejoining: null,
    phy_disabled: false,
    phy_disabilities: "",
    contractor: "",
    contract_from: null,
    contract_to: null,
    costcenter_id: "",
    hrmanager_id: "",
    ats_work_location_id: "",
    tfn_no: "",
    work_hour_configuration_id: ""
  };
  const [openCancel, setOpenCancel] = useState(false);
  const [backList, setBackList] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningpop, setWarningpop] = useState(false);
  const [inputData, setInputData] = useState(initialValue);
  const [candidateEditedData, setCandidateEditedData] = useState();
  const [imgfiles, setimgFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [called, setCalled] = useState(true);
  const [workDetails, setWorkDetails] = useState(initialValues);
  const [candidateEditedData1, setCandidateEditedData1] = useState();
  const [designationData, setDesignationData] = useState({
    load: false,
    values: [],
  });
  const [workhrData, setWorkhrData] = useState({
    load: false,
    values: [],
  });
  const handleEdit = () => {
    setdisableEdit(false);
  };
  const { otherPersonalData } = useSelector(
    (state) => state.EmployeeReducer.EmployeeOtherPersonalReducer
  );
  const { work_hours_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
  //Personal Details End
  const dispatch = useDispatch();
  const { isLoading, empData } = useSelector(
    (state) => state?.EmployeeReducer?.EmployeeBasicReducer
  );
  const { designation_options } = useSelector(
    (state) => state?.AncillaryReducer?.DesignationReducer
  );
  const { employees_options } = useSelector(
    (state) => state.EmployeeReducer.EmployeesReducer
  );
  const { cost_center_options } = useSelector(
    (state) => state.AncillaryReducer.CostCenterReducer
  );
  const { workLocation_options } = useSelector(
    (state) => state?.AncillaryReducer?.WorkLocationReducer
  );
  const { employment_type_options } = useSelector(
    (state) => state.AncillaryReducer.EmploymentTypeReducer
  );
  const { workData } = useSelector(
    (state) => state?.EmployeeReducer?.EmployeeWorkDetailReducer
  );
  const [employeesData, setEmployeesData] = useState({
    load: true,
    values: [],
  });
  const [costCenterData, setCostCentereData] = useState({
    load: true,
    values: [],
  });
  const [employeesData1, setEmployeesData1] = useState({
    load: true,
    values: [],
  });
  const [worklocationData, setWorklocationData] = useState({
    load: false,
    values: [],
  });
  const [employmentTypeData, setEmploymentTypeData] = useState({
    load: true,
    values: [],
  });

  const [image, setImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const cropperRef = useRef(null);
  const [webImage, setWebImage] = useState(null)
  const [useWebcam, setUseWebcam] = useState(false);
  const webcamRef = React.useRef(null);
  const fileInputRef = React.useRef(null);
  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
      await faceapi.loadFaceLandmarkModel(MODEL_URL);
      await faceapi.loadFaceRecognitionModel(MODEL_URL);
    };
    loadModels();
  }, []);
  useEffect(() => {
    if (currentMode === "add") {
      setdisableEdit(false);
    } else {
      setdisableEdit(true);
    }
    dispatch(GetEmployeesAction({ is_active: true }));
    isLoading && setInputData(initialValue);
    designation_options?.length === 0 && dispatch(GetDesignationAction());

    if (currentMode !== "addemployee") {
      if (OnboardingEmpData && called) {
        setCalled(false);
        dispatch(
          GetEmployeeBasicAction({
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
        dispatch(
          GetEmployeeOtherPersonalAction({
            domain: userData?.domain,
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
      } else if (called) {
        setCalled(false);
        dispatch(GetEmployeeBasicAction());
        dispatch(
          GetEmployeeOtherPersonalAction({
            domain: userData?.domain,
            employee_id: userData?.employee_id,
          })
        );
      }

      const mergedData = {
        ...empData?.[0],
        _designation_id: empData?.[0]?.designation_id
          ? {
            designation_id: empData?.[0]?.designation_id,
            name: empData?.[0]?.designation,
          }
          : "",
        role_id: empData?.[0]?.role_id
          ? { role_id: empData?.[0]?.role_id, name: empData?.[0]?.role }
          : "",

        domain: userData?.domain,
        ...otherPersonalData?.[0],
        reportingmanager_id: {
          employee_id: otherPersonalData?.[0]?.projectmanager_id,
          name: otherPersonalData?.[0]?.projectmanager_name,
        },

      };
      empData?.[0]?.image && getImageSignedUrl(empData?.[0]);
      setInputData(mergedData);
      setCandidateEditedData(mergedData);
    }
    // eslint-disable-next-line
  }, [!empData, !otherPersonalData]);
  useEffect(() => {

    isLoading && setWorkDetails(initialValues);
    dispatch(GetEmployeesAction({ is_active: true }));
    dispatch(GetWorkLocationAction());
    dispatch(GetEmploymentTypeAction());
    dispatch(GetCostCenterAction());
    // dispatch(GetPaygradeAction());
    if (currentMode !== "addemployee") {
      if (OnboardingEmpData) {
        dispatch(
          GetEmployeeWorkDetailAction({
            domain: userData?.domain,
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
      } else {
        dispatch(
          GetEmployeeWorkDetailAction({
            domain: userData?.domain,
            employee_id: userData?.employee_id,
          })
        );
      }
    }
  }, []);
  useEffect(() => {
    if (currentMode !== "addemployee") {
      const defaultWorkHour = work_hours_data?.find(wo => wo.is_default);
      // setEmployeeModalData({
      //   ...employeeModalData,
      //   work_hour_configuration_id: defaultWorkHour?.work_hour_configuration_pk
      //     ? {
      //       work_hour_configuration_id: defaultWorkHour?.work_hour_configuration_pk,
      //       work_hour_code: defaultWorkHour?.work_hour_code,
      //     }
      //     : "",
      // });
      if (workData?.[0]) {
        setWorkDetails({
          ...workData?.[0],
          domain: userData?.domain,
          employee_id: workData?.[0]?.employee_id,
          employmenttype_id: {
            employmenttype_id: workData?.[0]?.employmenttype_id,
            etype_name: workData?.[0]?.employmenttype,
          },
          paygrade_id: {
            paygrade_id: workData?.[0]?.paygrade_id,
            name: workData?.[0]?.paygrade,
          },
          costcenter_id: {
            costcenter_id: workData?.[0]?.costcenter_id,
            name: workData?.[0]?.costcenter,
          },
          hrmanager_id: {
            employee_id: workData?.[0]?.hrmanager_id,
            name: workData?.[0]?.hrmanager,
          },
          ats_work_location_id: workData?.[0]?.ats_work_location_id
            ? {
              ats_work_location_id: workData?.[0]?.ats_work_location_id,
              work_location_name: workData?.[0]?.work_location_name,
            }
            : "",
          work_hour_configuration_id: workData?.[0]?.work_hour_configuration_id
            ? {
              work_hour_configuration_id: workData?.[0]?.work_hour_configuration_id,
              work_hour_code: workData?.[0]?.work_hour_code,
            }
            : {
              work_hour_configuration_id: defaultWorkHour?.work_hour_configuration_pk,
              work_hour_code: defaultWorkHour?.work_hour_code,
            },
        });
        setCandidateEditedData1({
          ...workData?.[0],
          domain: userData?.domain,
          employee_id: workData?.[0]?.employee_id,
          employmenttype_id: {
            employmenttype_id: workData?.[0]?.employmenttype_id,
            etype_name: workData?.[0]?.employmenttype,
          },
          paygrade_id: {
            paygrade_id: workData?.[0]?.paygrade_id,
            name: workData?.[0]?.paygrade,
          },
          costcenter_id: {
            costcenter_id: workData?.[0]?.costcenter_id,
            name: workData?.[0]?.costcenter,
          },
          hrmanager_id: {
            employee_id: workData?.[0]?.hrmanager_id,
            name: workData?.[0]?.hrmanager,
          },
          ats_work_location_id: workData?.[0]?.ats_work_location_id
            ? {
              ats_work_location_id: workData?.[0]?.ats_work_location_id,
              work_location_name: workData?.[0]?.work_location_name,
            }
            : "",
          work_hour_configuration_id: workData?.[0]?.work_hour_configuration_id
            ? {
              work_hour_configuration_id: workData?.[0]?.work_hour_configuration_id,
              work_hour_code: workData?.[0]?.work_hour_code,
            }
            : {
              work_hour_configuration_id: defaultWorkHour?.work_hour_configuration_pk,
              work_hour_code: defaultWorkHour?.work_hour_code,
            },
        });
      } else {
        setWorkDetails({ ...initialValues });
      }
    }
  }, [workData])

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name === "" || null ? "First Name is required" : "";
    }
    if ("gender" in fieldValues) {
      temp.gender =
        fieldValues.gender === "" || fieldValues.gender === null
          ? "Gender is required"
          : "";
    }
    if ("last_name" in fieldValues) {
      temp.last_name =
        fieldValues.last_name === "" || null ? "Last Name is required" : "";
    }
    if ("office_email" in fieldValues) {
      temp.office_email =
        fieldValues.office_email === "" || null
          ? "Office Email is required"
          : /$^|.+@.+..+/.test(fieldValues.office_email)
            ? ""
            : "Invalid Office Email.";
    }
    //Personal Details Start
    if ("birthdate" in fieldValues) {
      temp.birthdate =
        fieldValues.birthdate === null ? "Birthdate is required" : "";
    }
    if ("_designation_id" in fieldValues) {
      temp._designation_id =
        fieldValues._designation_id === "" || fieldValues._designation_id === null
          ? "Designation is required"
          : "";
    }
    if ("reportingmanager_id" in fieldValues) {
      temp.reportingmanager_id =
        fieldValues.reportingmanager_id === "" || null
          ? "Reporting Manager is required"
          : "";
    }

    if ("joining_date" in fieldValues) {
      temp.joining_date =
        fieldValues.joining_date === null || fieldValues.joining_date === "" ? "Joining Date is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const validate2 = (fieldValues) => {
    let temp = { ...errors };
    if ("ats_work_location_id" in fieldValues) {
      temp.ats_work_location_id =
        fieldValues.ats_work_location_id === "" || fieldValues.ats_work_location_id === null
          ? "Work Location is required"
          : "";
    }
    if ("work_hour_configuration_id" in fieldValues) {
      temp.work_hour_configuration_id =
        fieldValues.work_hour_configuration_id === "" || null
          ? "Work Hour"
          : "";
    }
    if ("tfn_no" in fieldValues) {
      temp.tfn_no =
        fieldValues.tfn_no === "" || fieldValues.tfn_no === null
          ? ""
          : fieldValues.tfn_no.toString() !== "" &&
            !fieldValues.tfn_no.toString().match(/^\d{3}\s?\d{3}\s?\d{3}$/)
            ? "Invalid TFN Number."
            : "";
    }
    setErrors({
      ...temp,
    });
    const temp1 = { ...temp };
    return Object.values(temp1).every((x) => x === "");
  };

  const handleCostCenter = () => {
    cost_center_options &&
      setCostCentereData({ load: false, values: cost_center_options });
  };

  const handleEmployees1 = () => {
    const filteredData = employees_options.filter((employee) => {
      return (
        employee.employee_id !== userData?.employee_id ||
        userData?.core_hr_user_type === "Owner"
      );
    });
    employees_options &&
      setEmployeesData1({ load: false, values: filteredData });
  };

  const handleEmploymentType = () => {
    employment_type_options &&
      setEmploymentTypeData({ load: false, values: employment_type_options });
  };


  const handleEmployees = () => {
    const filteredData = employees_options.filter((employee) => {
      return (
        (employee.employee_id !== userData?.employee_id &&
          employee.core_hr_user_type !== "Employee") ||
        employee.core_hr_user_type === "Owner"
      );
    });
    employees_options &&
      setEmployeesData({ load: false, values: filteredData });
  };

  const handleInput = (event, value) => {
    if (["reportingmanager_id"].includes(event)) {
      if (value) {
        setInputData({ ...inputData, [event]: value });
      } else {
        setInputData({ ...inputData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value ? value : "" });
    } else if (
      ["_designation_id"].includes(
        event
      )
    ) {
      if (value?.id === null) {
        AddDesignationHelper({ name: value?.name }).then((res) => {
          setDesignationData({ load: false, values: res?.data?.data });
          setInputData({
            ...inputData,
            [event]: res?.data?.data?.filter(
              (resp) => resp?.name === value?.name
            )?.[0],
          });
        });
      } else setInputData({ ...inputData, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value ? value : "" });
    }

    else if (["isactive"].includes(event?.target?.name)) {
      setInputData({ ...inputData, [event.target.name]: event.target.checked });
    }
    else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event.target.name]: event.target.value });
    }
  };

  const handleInputChange = (event, value) => {
    if (["costcenter_id", "hrmanager_id"].includes(event)) {
      if (value) {
        setWorkDetails({ ...workDetails, [event]: value });
      } else {
        setWorkDetails({ ...workDetails, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event]: value ? value : "" });
    }
    else if (["work_hour_configuration_id"].includes(event)) {
      if (value) {
        setWorkDetails({ ...workDetails, [event]: value });
      } else {
        setWorkDetails({ ...workDetails, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate2({ ...workDetails, [event]: value ? value : "" });
    }

    else if (
      ["ats_work_location_id"].includes(
        event
      )
    ) {
      if (value?.id === null) {
        AddWorkLocationHelper({ name: value?.work_location_name }).then((res) => {
          setWorklocationData({ load: false, values: res?.data?.data });
          setWorkDetails({
            ...workDetails,
            [event]: res?.data?.data?.filter(
              (resp) => resp?.work_location_name === value?.work_location_name
            )?.[0],
          });
        });
      } else setWorkDetails({ ...workDetails, [event]: value });

      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate2({ ...workDetails, [event]: value ? value : "" });
    }
    else if (event === "employmenttype_id") {
      if (value) {
        setWorkDetails({
          ...workDetails,
          [event]: value,
          contractor: "",
          contract_from: "",
          contract_to: "",
        });
      } else {
        setWorkDetails({
          ...workDetails,
          [event]: "",
          contractor: "",
          contract_from: "",
          contract_to: "",
        });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event]: value ? value : "" });
    } else if (event?.target?.name === "phy_disabled") {
      setWorkDetails({
        ...workDetails,
        [event.target.name]: event.target.checked,
        phy_disabilities: "",
      });
    }
    else {
      setWorkDetails({
        ...workDetails,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...workDetails, [event.target.name]: event.target.value });
    }
  };



  const getImageSignedUrl = async (currentData) => {
    let filename = currentData?.image;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    disabled: disableEdit ? true : false,
    multiple: false,
    maxSize: 2000000,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setWarning(true)
      setimgFiles(acceptedFiles.map((file) => Object.assign(file)));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setOpenModal(true);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    onDropRejected: () => {
      toast.error(
        "You can only upload image with maximum size of 2 MB.",
        {
          duration: 2000,
        }
      );
    },
  });

  const handleFileChange = (event) => {
    setWebImage(null)
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
  
      if (file.size > 2000000) {
        toast.error("You can only upload an image with a maximum size of 2 MB.", {
          duration: 2000,
        });
        return;
      }
  
      if (!["image/png", "image/jpeg"].includes(file.type)) {
        toast.error("Only .jpeg, .jpg, and .png formats are allowed.", {
          duration: 2000,
        });
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setOpenModal(true); 
      };
      reader.readAsDataURL(file);
      setWarning(true);
      setimgFiles([file]);
    }
  };

  const handleRotationChange = (event, newValue) => {
    setRotation(newValue);
    if (cropperRef.current?.cropper) {
      cropperRef.current.cropper.rotateTo(newValue);
    }
  };
  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      const fileName = imgfiles?.[0]?.name;
      const fileExtension = fileName?.split(".").pop();

      croppedCanvas.toBlob((blob) => {
        const file = new File([blob], fileName, { type: `image/${fileExtension}` });
        setimgFiles([file]); 
        setOpenModal(false); 
        setUseWebcam(false);

      }, `image/${fileExtension}`);
    }
  };
  const openWebcamModal = () => {
    setUseWebcam(true);
    setOpenModal(true);
  };

  
  const captureImageFromWebcam = () => {
    const capturedImage = webcamRef.current.getScreenshot();
    if (capturedImage) {
      setImage(capturedImage);
      setWebImage(capturedImage);
      setWarning(true)
      setimgFiles([])
      setUseWebcam(false); 
    } else {
      toast.error("Failed to capture an image from the webcam.");
    }
  };


  const img = imgfiles.map((file) => (
    <img
      style={{ borderRadius: "50%", padding: "2px" }}
      width="100%"
      height="100%"
      key={file.name}
      alt={file.name}
      className="single-file-image"
      src={URL.createObjectURL(file)}
    />
  ));

  const NextStepper = (e, next) => {
    if (
      !disableEdit &&
      inputData?.employee_id &&
      (JSON.stringify(inputData) !== JSON.stringify(candidateEditedData)
        || JSON.stringify(workDetails) !== JSON.stringify(candidateEditedData1))
    ) {
      handleSubmit(e, next);
    } else {
      if (validate(inputData) && validate2(workDetails)) {
        setActiveStep(1);
      }

    }
  };
  const handleCancel = () => {
    if (backList) {
      history.push("/myemployee");
    } else {
      setActiveStep(1);
    }
  };
  const handleBackList = () => {
    setBackList(true);
    if (
      !disableEdit &&
      inputData?.employee_id &&
      (JSON.stringify(inputData) !== JSON.stringify(candidateEditedData)
        || JSON.stringify(workDetails) !== JSON.stringify(candidateEditedData1))
    ) {
      setOpenCancel(true);
    } else history.push("/myemployee");
  };

  const handleSubmit = async (e, next) => {
    if (warning && userData?.facerecognition_flow) {
      setWarningpop(true);
      return;
    }
    if (validate(inputData) && validate2(workDetails)) { 
      let img_filename = "";
      let img_b64string = "";
      let old_image = "";
      let detections;
      let descriptor;
    
      const generateImageData = async () => {
        if (webImage) {
          img_b64string = webImage;
          let ext = img_b64string?.split(";")[0]?.split(":")[1]?.split("/")[1];
          img_filename = `${userData?.domain}/CoreHr/Employee/Profile/${uuidv4()}.${ext}`;
  
          const imgElement = new Image();
          imgElement.src = webImage;
          await new Promise((resolve, reject) => {
            imgElement.onload = resolve;
            imgElement.onerror = reject;
          });
  
          detections = await faceapi
            .detectSingleFace(imgElement, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceDescriptor();
          if (detections) {
            const descriptorArray = detections.descriptor
              ? Array.from(detections.descriptor)
              : "";
            descriptor = descriptorArray ? JSON.stringify(descriptorArray) : "";
  
            if (inputData?.image) {
              old_image = inputData?.image;
            }
  
          } else {
            toast.error("Face detection failed. Please try again.");
            throw new Error("Face detection failed");
          }
        } else if (imgfiles?.[0]) {
          img_b64string = await convertBase64(imgfiles?.[0]);
          let ext = imgfiles?.[0]?.name?.split(".").at(-1);
          img_filename = `${userData?.domain}/CoreHr/Employee/Profile/` + uuidv4() + "." + ext;
          const img = await faceapi.bufferToImage(imgfiles?.[0]);
          detections = await faceapi
            .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks()
            .withFaceDescriptor();  
          const descriptorArray = detections?.descriptor
            ? Array.from(detections.descriptor)
            : "";
  
          descriptor = detections?.descriptor ? JSON.stringify(descriptorArray) : "";
          if (inputData?.image) {
            old_image = inputData?.image;
          }
        } else {
          img_filename = inputData?.image;
          descriptor = inputData?.descriptor;
        }
      };
  
      try {
        await generateImageData();
        if (currentMode === "addemployee") {
          AddEmployeeBasicHelper({
            ...inputData,
            image: img_filename,
            image_name: img_b64string,
          }).then((res) => {
            if (res?.data?.statuscode === 200) {
              setNewData(res?.data?.data?.[0]);
              dispatch(
                UpdateEmployeeOtherPersonalAction({
                  ...inputData,
                  domain: userData?.domain,
                  employee_id: res?.data?.data?.[0]?.employee_id,
                })
              );
              dispatch(
                AddEmployeeWorkDetailAction({
                  ...workDetails,
                  employee_id: res?.data?.data?.[0]?.employee_id,
                })
              );
            }
          });
        } else {
          const employeeData =
            currentMode === "editemployee" && OnboardingEmpData
              ? OnboardingEmpData
              : userData;
  
          dispatch(
            UpdateEmployeeWorkDetailAction({
              ...workDetails,
              employee_id: employeeData?.employee_id,
            })
          );
  
          UpdateEmployeeBasicHelper({
            ...inputData,
            image: img_filename,
            image_name: img_b64string,
            old_image: old_image,
            descriptor: descriptor,
          })
            .then((res) => {
              if (res?.data?.statuscode === 200) {
                dispatch(
                  UpdateEmployeeOtherPersonalAction({
                    ...inputData,
                    domain: userData?.domain,
                    employee_id: res?.data?.data?.[0]?.employee_id,
                  })
                );
                if (!OnboardEmployee) {
                  setdisableEdit(true);
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
        if (next === "Next") {
          setActiveStep(1);
        }
      } catch (err) {
        console.error("Error processing image data:", err);
      }
    }
  };
  
  const handleDelete = (e) => {
    e.preventDefault();
    setimgFiles([]);
    setImageUrl("");
    setWebImage("")
    setInputData((prevData) => ({
      ...prevData,
      image: "",
      descriptor: ""
    }));
  };
  return (
    <Grid
      sx={{
        padding: OnboardEmployee ? "0px 0px 20px 25px" : "0px 30px 20px 30px",
      }}
      container
    >
      {!OnboardEmployee && (
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "10px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Box>
            {currentMode !== undefined ? (
              <Button
                sx={{ marginRight: "10px" }}
                type="submit"
                variant="outlined"
                size="medium"
                onClick={handleBackList}
              >
                Back to List
              </Button>
            ) : null}
          </Box>

          <Typography variant="h6">Basic Details</Typography>

          <Box>
            {currentMode === "addemployee" && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => handleSubmit(e)}
                className="mpl-primary-btn"
              >
                Create Employee
              </Button>
            )}
            {disableEdit && EditButton && currentMode !== "addemployee" && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => handleEdit(e)}
                className="mpl-primary-btn"
              >
                Edit
              </Button>
            )}
            {!disableEdit && currentMode !== "addemployee" && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => handleSubmit(e)}
                className="mpl-primary-btn"
              >
                Save
              </Button>
            )}
            {currentMode !== "addemployee" && (
              <Button
                sx={{ marginLeft: "10px" }}
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => NextStepper(e, "Next")}
                className="mpl-primary-btn"
              >
                Next
              </Button>
            )}
          </Box>
        </Grid>
      )}

      {OnboardEmployee && (
        <Grid
          container
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            marginRight: "10px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Basic Details
          </Typography>

          <Box sx={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              onClick={(e) => handleSubmit(e)}
              className="mpl-primary-btn"
            >
              Save
            </Button>

            <Button
              sx={{ marginLeft: "10px" }}
              type="submit"
              variant="contained"
              size="medium"
              onClick={(e) => NextStepper(e, "Next")}
              className="mpl-primary-btn"
            >
              Next
            </Button>
          </Box>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={3}
        sx={{
          paddingRight: "1.5rem",
          marginTop: "1.5rem",
          paddingBottom: "1.5rem",
        }}
      >
        <Box>
          <Paper
            className={classes.Paper}
            sx={{ padding: "80px 24px 40px", borderRadius: "16px", minHeight: "505px" }}
          >
            <Box
              sx={{
                marginBottom: "5px",
                border: "none !important",
                cursor: "default",
              }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} onChange={handleFileChange} ref={fileInputRef} />
              <div className="presentation">
                <div className="placeholder">
                  {imgfiles?.length ? (
                    img
                  ) : inputData?.image ? (
                    <img
                      style={{ borderRadius: "50%", padding: "2px" }}
                      alt="Nothing"
                      width="100%"
                      height="100%"
                      src={imageUrl}
                    />
                  ) : (
                    <>
                      <AddPhotoAlternateIcon />
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Upload Photo
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", alignItems:"center", gap: "10px", marginTop: "10px" }}>

      <Tooltip
          title="Select File"
          onClick={() => fileInputRef.current.click()}
          sx={{ mr: 1 }}
        >
          <IconButton sx={{ "&:hover": { color: "#ba54f5 " } }}
            disabled={disableEdit}
          >
            <InsertPhotoIcon sx={{ transform: "true" }} />
          </IconButton>
      </Tooltip>           
      <Tooltip
          title="Take Selfie"
          onClick={() => openWebcamModal()}
          sx={{ mr: 1 }}
        >
          <IconButton sx={{ "&:hover": { color: "#ba54f5 " } }}
            disabled={disableEdit}
          >
            <CameraAltIcon sx={{ transform: "true" }} />
          </IconButton>
      </Tooltip>
                  <Box>
              {(imgfiles?.length > 0 || inputData?.image) &&
                (disableEdit ? (
                  <DeleteIcon
                  sx={{ color: "#919eab", cursor: "not-allowed" }}
                  />
                ) : (
                  <Tooltip title="Delete Image">
                    <DeleteIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleDelete}
                      color="error"
                      />
                  </Tooltip>
                ))}
                </Box>
    </Box>

            {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
              {(imgfiles?.length > 0 || inputData?.image) &&
                (disableEdit ? (
                  <DeleteIcon
                    sx={{ color: "#919eab", cursor: "not-allowed" }}
                  />
                ) : (
                  <Tooltip title="Delete Image">
                    <DeleteIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleDelete}
                      color="error"
                    />
                  </Tooltip>
                ))}
            </Box> */}

            <Typography
              sx={{ margin: "5px auto 0px" }}
              variant="caption"
              className="content-sec"
            >
              File formats allowed *.jpeg, *.jpg, *.png
              <br /> max size of 2 MB
            </Typography>
            <Box>
              <Grid
                item
                xs={12}
                md={7}
                sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
              >
                <FormControl disabled={disableEdit} required>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  {errors.gender && (
                    <Typography sx={{ color: "#FF4842", fontSize: "0.66rem" }}>
                      {errors.gender}
                    </Typography>
                  )}
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="gender"
                    onChange={handleInput}
                    value={inputData?.gender}
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      sx={{ width: "150px" }}
                      value="Non-Binary"
                      control={<Radio />}
                      label="Non-Binary"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={9}
        sx={{ marginTop: "1.5rem", paddingBottom: "1.5rem" }}
      >
        <Box>
          <Paper
            className={classes.Paper}
            sx={{ padding: "0px", borderRadius: "16px", minHeight: "500px" }}
          >
            <Box display="flex" justifyContent="flex-end">
              <Typography
                variant="h6"
                title="Employee Number/ID"
                fontWeight={700}
              >
                {inputData?.employee_no || "---"}
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem", display: "flex", justifyContent: "space-between" }}>
                <FormControl size="small" sx={{ width: "30%", mr: 1 }}>
                  <InputLabel id="recruiter-type-select-label">Title</InputLabel>
                  <Select
                    disabled={disableEdit || disableEmployee}
                    label="Title"
                    name='title'
                    id="title"
                    value={inputData?.title}
                    onChange={(e) => setInputData({ ...inputData, title: e.target.value })}
                  >
                    <MenuItem value={"Mr"}>Mr</MenuItem>
                    <MenuItem value={"Mrs"}>Mrs</MenuItem>
                    <MenuItem value={"Ms"}>Ms</MenuItem>
                    <MenuItem value={"Miss"}>Miss</MenuItem>
                    <MenuItem value={"Dr"}>Dr</MenuItem>
                    <MenuItem value={"Mx"}>Mx</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  disabled={disableEdit || disableEmployee}
                  label="First Name"
                  fullWidth
                  required
                  name="first_name"
                  onChange={handleInput}
                  autoFocus={true}
                  value={inputData?.first_name || ""}
                  size="small"
                  {...(errors.first_name && {
                    error: true,
                    helperText: errors.first_name,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit || disableEmployee}
                  label="Last Name"
                  fullWidth
                  name="last_name"
                  required
                  onChange={handleInput}
                  value={inputData?.last_name || ""}
                  size="small"
                  {...(errors.last_name && {
                    error: true,
                    helperText: errors.last_name,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="Middle Name"
                  fullWidth
                  name="middle_name"
                  onChange={handleInput}
                  value={inputData?.middle_name || ""}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="Personal Mobile"
                  fullWidth
                  name="personal_mobile"
                  onChange={handleInput}
                  value={inputData?.personal_mobile || ""}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={true}
                  label="Official Email"
                  fullWidth
                  name="office_email"
                  required
                  onChange={handleInput}
                  value={inputData?.office_email || ""}
                  size="small"
                  {...(errors.office_email && {
                    error: true,
                    helperText: errors.office_email,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <CommonDatePicker
                    required
                    disabled={disableEdit || disableEmployee}
                    size="small"
                    autoFocus={true}
                    name="birthdate"
                    label="Birth Date"
                    pickerType="date"
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyPress={(e) => e.preventDefault()}
                    maxDate={new Date().setFullYear(
                      new Date().getFullYear() - 18
                    )}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={inputData?.birthdate}
                    handleChange={(value) =>
                      handleInput({
                        target: {
                          name: "birthdate",
                          value: value
                            ? `${value?.getFullYear() +
                            "-" +
                            ("0" + (value.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value.getDate()).slice(-2)
                            }`
                            : "",
                        },
                      })
                    }
                    {...(errors.birthdate && {
                      error: true,
                      helperText: errors.birthdate,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <CreatableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option._designation_id === +value._designation_id
                  }
                  disabled={disableEdit || disableEmployee}
                  label="Designation"
                  value={inputData?._designation_id}
                  loading={() =>
                    setDesignationData({
                      load: false,
                      values: designation_options,
                    })
                  }
                  fieldLabel="name"
                  required={true}
                  variant={"contained"}
                  name="_designation_id"
                  size="small"
                  onChangeEvent={handleInput}
                  data={designationData}
                  showAddOption={true}
                  OptionName="Type to add new Designation"
                  {...(errors._designation_id && {
                    error: true,
                    helperText: errors._designation_id,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <SelectableSearch
                    disabled={disableEdit || disableEmployee}
                    label="Reporting Manager"
                    value={inputData?.reportingmanager_id}
                    loading={() => handleEmployees()}
                    fieldLabel="name"
                    required={true}
                    variant={"outlined"}
                    name="reportingmanager_id"
                    size="small"
                    onChangeEvent={handleInput}
                    data={employeesData}
                    {...(errors.reportingmanager_id && {
                      error: true,
                      helperText: errors.reportingmanager_id,
                    })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <CommonDatePicker
                    required
                    disabled={disableEdit || disableEmployee}
                    autoFocus={true}
                    size="small"
                    name="joining_date"
                    label="Date of Joining"
                    pickerType="date"
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyPress={(e) => e.preventDefault()}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={inputData?.joining_date}
                    handleChange={(value) =>
                      handleInput({
                        target: {
                          name: "joining_date",
                          value: value
                            ? `${value?.getFullYear() +
                            "-" +
                            ("0" + (value?.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value?.getDate()).slice(-2)
                            }`
                            : "",
                        },
                      })
                    }
                    {...(errors.joining_date && {
                      error: true,
                      helperText: errors.joining_date,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disableEdit}
                    size="small"
                    value={inputData?.emg_contact_person || ""}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Emergency Contact Person"
                    name="emg_contact_person"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disableEdit}
                    label="Emergency Contact Number"
                    fullWidth
                    name="emg_contact_no"
                    onChange={handleInput}
                    value={inputData?.emg_contact_no || ""}
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="Personal Email"
                  fullWidth
                  name="personal_email"
                  onChange={handleInput}
                  value={inputData?.personal_email || ""}
                  size="small"
                />
              </Grid>


              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableEdit}
                      name="phy_disabled"
                      checked={workDetails?.phy_disabled || false}
                      onChange={handleInputChange}
                    />
                  }
                  label="Differently Abled"
                />
              </Grid>
              {workDetails?.phy_disabled && (
                <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={disableEdit}
                      label="Disabilities"
                      name="phy_disabilities"
                      onChange={handleInputChange}
                      value={workDetails?.phy_disabilities || ""}
                      size="small"
                      {...(errors?.phy_disabilities && {
                        error: true,
                        helperText: errors.phy_disabilities,
                      })}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Box>
      </Grid>
      {!OnboardEmployee && <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
        <Grid item sx={{ paddingRight: currentMode !== undefined ? "40px" : "140px" }}>
          <Typography variant="h6" align="center">
            Work Details
          </Typography>
        </Grid>
      </Grid>}
      {!OnboardEmployee && <Grid item xs={12} md={12} sx={{ padding: "24px 0px 24px 0px" }}>
        <Paper className={classes.paper} sx={{ padding: "24px" }} >
          <Grid pt={2.5} container spacing={3}>
            <Grid item xs={12} sm={6} md={4} >
              <FormControl fullWidth>
                <CreatableSearch
                  disabled={disableEdit}
                  required
                  label="Work Location"
                  value={workDetails?.ats_work_location_id}
                  loading={() =>
                    setWorklocationData({
                      load: false,
                      values: workLocation_options,
                    })
                  }
                  fieldLabel="work_location_name"
                  variant={"contained"}
                  name="ats_work_location_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={worklocationData}
                  showAddOption={true}
                  OptionName="Type to add new Work Location"
                  {...(errors.ats_work_location_id && {
                    error: true,
                    helperText: errors.ats_work_location_id,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <FormControl fullWidth>
                <SelectableSearch
                 disabled={disableEdit}
                  label="Working Hour Code"
                  value={workDetails?.work_hour_configuration_id}
                  loading={() =>
                    setWorkhrData({
                      load: false,
                      values: work_hours_data,
                    })
                  }
                  fieldLabel="work_hour_code"
                  required={true}
                  variant={"outlined"}
                  name="work_hour_configuration_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={workhrData}
                  {...(errors.work_hour_configuration_id && {
                    error: true,
                    helperText: errors.work_hour_configuration_id,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <SelectableSearch
                  disabled={disableEdit}
                  label="Cost Center"
                  value={workDetails?.costcenter_id}
                  loading={() => handleCostCenter()}
                  fieldLabel="name"
                  required={false}
                  variant={"outlined"}
                  name="costcenter_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={costCenterData}
                  {...(errors.costcenter_id && {
                    error: true,
                    helperText: errors.costcenter_id,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <SelectableSearch
                  disabled={disableEdit}
                  label="HR Manager"
                  value={workDetails?.hrmanager_id}
                  loading={() => handleEmployees1()}
                  fieldLabel="name"
                  variant={"outlined"}
                  name="hrmanager_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={employeesData1}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <FormControl fullWidth>
                <SelectableSearch
                  disabled={disableEdit}
                  label="Type of Employment"
                  value={workDetails?.employmenttype_id}
                  loading={() => handleEmploymentType()}
                  fieldLabel="etype_name"
                  required={false}
                  variant={"outlined"}
                  name="employmenttype_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={employmentTypeData}
                  {...(errors.employmenttype_id && {
                    error: true,
                    helperText: errors.employmenttype_id,
                  })}
                />
              </FormControl>
            </Grid>
            {userData?.f_country_code === "ANZ" && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disableEdit}
                    label="TFN Number"
                    name="tfn_no"
                    onChange={handleInputChange}
                    value={workDetails?.tfn_no}
                    size="small"
                    {...(errors.tfn_no && {
                      error: true,
                      helperText: errors.tfn_no,
                    })}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          {workDetails?.employmenttype_id?.etype_name === "Contractual" && (
            <Grid item xs={12} md={12} padding={1.5}>
              <Typography variant="h6" gutterBottom>
                Type of Employement - Contract
              </Typography>
              <Grid pt={2.5} container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={disableEdit}
                      label="Contractor"
                      name="contractor"
                      required
                      onChange={handleInputChange}
                      value={workDetails?.contractor}
                      size="small"
                      {...(errors?.contractor && {
                        error: true,
                        helperText: errors.contractor,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <CommonDatePicker
                      disabled={disableEdit}
                      maxDate={new Date()}
                      size="small"
                      name="contract_from"
                      label="Contract Date From"
                      pickerType="date"
                      onKeyPress={(e) => e.preventDefault()}
                      onKeyDown={(e) => e.preventDefault()}
                      pickerFormat="dd/MM/yyyy"
                      DefaultDTvalue={workDetails?.contract_from}
                      handleChange={(value) =>
                        handleInputChange({
                          target: {
                            name: "contract_from",
                            value: `${value?.getFullYear() +
                              "-" +
                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + value?.getDate()).slice(-2)
                              }`,
                          },
                        })
                      }
                      {...(errors.contract_from && {
                        error: true,
                        helperText: errors.contract_from,
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth>
                    <CommonDatePicker
                      disabled={disableEdit}
                      minDate={
                        workDetails?.contract_from
                          ? new Date(workDetails.contract_from)
                          : ""
                      }
                      size="small"
                      name="contract_to"
                      label="Contract Date To"
                      pickerType="date"
                      onKeyPress={(e) => e.preventDefault()}
                      onKeyDown={(e) => e.preventDefault()}
                      pickerFormat="dd/MM/yyyy"
                      DefaultDTvalue={workDetails?.contract_to}
                      handleChange={(value) =>
                        handleInputChange({
                          target: {
                            name: "contract_to",
                            value: `${value?.getFullYear() +
                              "-" +
                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                              "-" +
                              ("0" + value?.getDate()).slice(-2)
                              }`,
                          },
                        })
                      }
                      {...(errors.contract_to && {
                        error: true,
                        helperText: errors.contract_to,
                      })}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>}

      <ConfirmationDialog
        title="Unsaved Changes"
        content="You have some unsaved changes. Please click on Save before proceeding"
        openDialog={openCancel}
        closeDialog={setOpenCancel}
        popupTitle="Consultancy"
        OkButtonText="Proceed without Save"
        onSubmit={handleCancel}
        CancelButtonText="OK"
      />
      <Popup
        maxWidth="xs"
        title="⚠️ Alert"
        close={false}
        isMasterProduct={true}
        setOpenPopup={setWarningpop}
        openPopup={warningpop}
      >
        <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
          Note: This image will be used for face recognition in the attendance tracker.
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className="mpl-primary-btn"
            onClick={() => { setWarningpop(false); setWarning(false); }}
          >
            Ok
          </Button>
        </Box>
      </Popup>

      {/* Modal for Cropping Image */}
    <Modal open={openModal} onClose={() => {setOpenModal(false);setUseWebcam(false) }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 4,
            boxShadow: 24,
            maxWidth: 500,
            width: "100%",
            textAlign: "center",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onClick={() => {setOpenModal(false); setUseWebcam(false)}}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
          {useWebcam ? "Capture Profile Image" : "Crop Profile Image"}
        </Typography>
          {useWebcam ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              facingMode: "user",
            }}
          />
        ) : (image && (
            <Box sx={{ marginTop: 2 }}>
              <Cropper
                ref={cropperRef}
                src={image}
                style={{
                  height: 300,
                  width: "100%",
                }}
                initialAspectRatio={1}
                aspectRatio={1}
                guides={false}
                movable={true}
                zoomable={true}
                scalable={true}
                cropBoxResizable={false}
                cropBoxMovable={true}
                minCropBoxWidth={200}
                minCropBoxHeight={200}
                background={true}
              />

              <Box sx={{ marginTop: 2 }}>
                <Typography variant="body1">Rotate Image</Typography>
                <Slider
                  value={rotation}
                  min={-180}
                  max={180}
                  step={1}
                  onChange={handleRotationChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}°`}
                />
              </Box>
            </Box>
          )
          )
          
          }
          {useWebcam && (
          <Button
            onClick={captureImageFromWebcam}
            className="mpl-primary-btn"
            variant="contained"
            size="medium"
            sx={{ marginTop: "10px" }}
          >
            Capture Image
          </Button>
        )}
        {!useWebcam && image && (
          <Button
            onClick={handleCrop}
            className="mpl-primary-btn"
            variant="contained"
            size="medium"
            sx={{ marginTop: "10px" }}
          >
            Crop Image
          </Button>
        )}
        </Box>
      </Modal>
    </Grid>
  );
};

export default BasicDetails;
