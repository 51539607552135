import * as ActionTypes from '../ActionTypes';
const initialState = {
    isUploading: false,
    error: false,
    success: false,
    successMessage: "",
};
export const AtsUploadReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ATS_RESUME_UPLOAD_REQUEST:
            return {
                ...state,
                isUploading: true,
                error: false,
                success: false
            };
        case ActionTypes.ATS_RESUME_UPLOAD_SUCCESS:
            return {
                ...state,
                isUploading: false,
                success: true,
                successMessage: action.payload?.status,
            };
        case ActionTypes.ATS_RESUME_UPLOAD_ERROR:
            return {
                ...state,
                isUploading: false,
            };
        case ActionTypes.EMP_PROFILE_PIC_UPLOAD_REQUEST:
            return {
                ...state,
                isUploading: true,
                error: false,
                success: false
            };
        case ActionTypes.EMP_PROFILE_PIC_UPLOAD_SUCCESS:
            return {
                ...state,
                isUploading: false,
                success: true,
                successMessage: action.payload?.status,
            };
        case ActionTypes.EMP_PROFILE_PIC_UPLOAD_ERROR:
            return {
                ...state,
                isUploading: false,
            };
        default:
            return state;
    }
}