import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Divider,
    Typography,
    Box,
    Tooltip,
    IconButton,
    FormGroup,
    Switch,
    InputAdornment,
    Skeleton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetDeductionAction, GetEarningAction } from "../../../../../store/Finance/Component/Action";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../Base/views/Popup";
import { AddPayrollTemplateAction, GetPayrollTemplateAction } from "../../../../../store/Finance/PayRollTemplate/Action";
import { GetCalculateEmployeeSalaryTemplateAction, GetEmployeeTemplateAction, GetPayrollWorkLocationAction } from "../../../../../store/Finance/Employee/Action";
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import { AddCalculateEmployeeSalaryTemplateHelper, AddEmployeeTemplateHelper, CalculateEmployeeSalaryTemplateHelper, UpdateEmployeeTemplateHelper } from "../../helper/Employee/EmployeeTemplateHelper";

const EmployeePayroleTemplate = ({ emp, isonboard, createEmp, setOpenTemplate, openTemplate, currentData, setSuccessPopup, setOpenPopup, setCurrentMode, setInternal }) => {
    const useStyles = makeStyles({
        row: {
            display: "flex",
            flexDirection: "row",
        },
        label: {
            fontSize: "1rem",
            fontWeight: 400,
            width: "170px",
            flexShrink: 0,
            color: "rgb(99, 115, 129)",
            height: "30px",
            lineHeight: "30px",
            margin: "4px 0px 4px 0px",
        },
        netlabel: {
            fontSize: "1rem",
            fontWeight: "bold",
            width: "170px",
            flexShrink: 0,
            color: "#212B36",
            height: "30px",
            lineHeight: "30px",
            margin: "4px 0px 4px 0px",
        },

        value: {
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            color: "rgb(99, 115, 129)",
        },
    });
    const classes = useStyles();
    const initialValue = {
        template_name: "",
        save_as_template: false,
    };
    const templateEarningsValues = {
        earning_key: "",
        earning_value: "0",
    };
    const templateDeductionValues = {
        deduction_key: "",
        deduction_value: "0",
    };
    const initialValues = {
        payroll_worklocation_id: "",
        ctc: "",
        basic: 20,
        hra: 50,
        conveyance_allowance: 1250,
        medical_allowance: 1600,
        educational_allowance: 200,
        lta: "",
        conveyance_allowance_flag: false,
        medical_allowance_flag: false,
        educational_allowance_flag: false,
        lta_flag: false,
        esic: false,
        pt: false
    };

    const [isSending, setIsSending] = useState(false);
    const [step, setStep] = useState(1);
    const [resData, setResData] = useState("");
    const earnings = [
        { label: "Basic", value: resData?.basic },
        { label: "EPF Earning", value: resData?.epf_earning },
        { label: "ESI Earning", value: resData?.esi_earning },
        { label: "HRA", value: resData?.hra },
        { label: "Conveyance Allowance", value: resData?.conveyance_allowance_calculated },
        { label: "Medical Allowance", value: resData?.medical_allowance_calculated },
        { label: "LTA", value: resData?.lta },
        { label: "Educational Allowance", value: resData?.education_allowance_calculated },
        { label: "Special Allowance", value: resData?.special_allowance },
    ];

    const deductions = [
        { label: "EPF Deduction", value: resData?.epf_deduction },
        { label: "PT Deduction", value: resData?.pt_deduction },
        { label: "ESI Deduction", value: resData?.esi_deduction },
    ];
    const calculateTotal = (items) => items.reduce((total, item) => total + parseFloat(item.value || 0), 0);
    const grossEarningsTempTotal = calculateTotal(earnings);
    const grossDeductionsTempTotal = calculateTotal(deductions);
    const netTempTotal = grossEarningsTempTotal - grossDeductionsTempTotal;
    const [templateEarningData, setTemplateEarningData] = useState([]);
    const [templateDeductionData, setTemplateDeductionData] = useState([]);
    const [opentempname, setOpenTempname] = useState(false);
    const [openvalid, setOpenValid] = useState(false);
    const [venilatemplate, setVenilaTemplate] = useState(false);
    const [defaulttemplate, setDefaultTemplate] = useState(false);
    const [templatename, setTemplatename] = useState(initialValue);
    const [template, setTemplate] = useState(initialValues);
    const [overtime, setOverTime] = useState({ overtime_applicable_flag: false });
    const [temperrors, setTempErrors] = useState({});
    const [inputData, setInputData] = useState({ template_id: "" });
    const [errors, setErrors] = useState({});
    const [templateEarningErrors, setTemplateEarningErrors] = useState([]);
    const [templateDeductionErrors, setTemplateDeductionErrors] = useState([]);
    const [disabledEarningTemplate, setDisabledEarningTemplate] = useState(false);
    const [disabledDeductionTemplate, setDisabledDeductionTemplate] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [grossEarningsTotal, setGrossEarningsTotal] = useState(0);
    const [grossDeductionsTotal, setGrossDeductionsTotal] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [earningData, setEarningData] = useState({
        load: true,
        values: [],
    });
    const [deductionData, setDeductionData] = useState({
        load: true,
        values: [],
    });
    const [templateData, setTemplateData] = useState({ load: true, values: [] });
    const [worklocationData, setWorklocationData] = useState({ load: true, values: [] });
    const handleToggle = (field) => {
        const defaultValues = {
            conveyance_allowance: 1250,
            medical_allowance: 1600,
            educational_allowance: 200,
        };
        setTemplate((prevState) => {
            const newState = { ...prevState, [field]: !prevState[field] };
            const fieldName = field.replace('_flag', '');
            if (newState[field]) {
                newState[fieldName] = defaultValues[fieldName] || '';
            } else {
                newState[fieldName] = '';
            }
            return newState;
        });
    };
    const handleToggle1 = (field) => {
        setTemplate((prevState) => {
            const newState = { ...prevState, [field]: !prevState[field] };
            return newState;
        });
    };

    useEffect(() => {
        const allFieldsEmpty = templateEarningData?.every(
            (field) =>
                field?.earning_key !== "" &&
                field?.earning_value !== ""
        );
        setDisabledEarningTemplate(!allFieldsEmpty);
    }, [templateEarningData]);
    useEffect(() => {
        const allFieldsEmpty = templateDeductionData?.every(
            (field) =>
                field?.deduction_key !== "" &&
                field?.deduction_value !== ""
        );
        setDisabledDeductionTemplate(!allFieldsEmpty);
    }, [templateDeductionData]);
    const { earning_data, deduction_data } = useSelector(
        (state) => state?.FinanceReducer.ComponentReducer
    );
    const { employee_template_data, payroll_worklocation_data, calculate_salary_employee_template_data, isLoading } = useSelector(
        (state) => state?.FinanceReducer.EmployeeTemplateReducer
    );
    const { payroll_template_data } = useSelector(
        (state) => state?.FinanceReducer.PayrollTemplateReducer
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetPayrollWorkLocationAction());
        dispatch(GetEarningAction());
        dispatch(GetDeductionAction());
        if (currentData?.payroll_blueprint_applied) {
            dispatch(GetCalculateEmployeeSalaryTemplateAction({
                employee_id: currentData?.employee_id
            }));
        }
        else {
            dispatch(GetEmployeeTemplateAction({
                employee_id: currentData?.employee_id
            }));
        }
        dispatch(GetPayrollTemplateAction())
    }, []);
    useEffect(() => {
        let totalEarnings = 0;
        let totalDeductions = 0;
        if (employee_template_data?.length > 0) {
            if (!currentData?.payroll_blueprint_applied) {
                setVenilaTemplate(true);
                setDefaultTemplate(false);
                setOverTime({ overtime_applicable_flag: employee_template_data?.[0]?.overtime_applicable })
                const earnings = employee_template_data[0]?.earnings;
                const deductions = employee_template_data[0]?.deductions;
                if (earnings) {
                    const earningFields = Object.entries(earnings)
                        .filter(([key, value]) => value?.value != null)
                        .map(([key, value]) => {
                            return {
                                earning_key: {
                                    label: value?.label,
                                    name: value?.name,
                                },
                                earning_value: value?.value
                            };
                        });
                    setTemplateEarningData(earningFields);
                    setDisabled(true);
                    totalEarnings = earningFields?.reduce((sum, field) => sum + (field.earning_value || 0), 0);
                    setGrossEarningsTotal(totalEarnings);

                }
                if (deductions) {
                    const deductionFields = Object.entries(deductions)
                        .filter(([key, value]) => value?.value != null)
                        .map(([key, value]) => {
                            return {
                                deduction_key: {
                                    label: value?.label,
                                    name: value?.name,
                                },
                                deduction_value: value?.value
                            };
                        });
                    setTemplateDeductionData(deductionFields);
                    setDisabled(true);
                    totalDeductions = deductionFields.reduce((sum, field) => sum + (field.deduction_value || 0), 0);
                    setGrossDeductionsTotal(totalDeductions);
                }
                setNetTotal(totalEarnings - totalDeductions);

            }
            else {
                setDisabled(false);
                setTemplateEarningData([]);
                setTemplateDeductionData([]);
                setGrossEarningsTotal(0);
                setGrossDeductionsTotal(0);
                setNetTotal(0);
                setDefaultTemplate(true);
                setVenilaTemplate(false);
                setInputData({
                    ...inputData,
                    template_id: { template_id: "0", template_name: "Meepl Salary Template" },
                });
                setOverTime({ overtime_applicable_flag: false })
            }

        } else if (
            calculate_salary_employee_template_data?.length > 0
        ) {
            if (currentData?.payroll_blueprint_applied) {
                setDefaultTemplate(true);
                setVenilaTemplate(false);
                setOverTime({ overtime_applicable_flag: calculate_salary_employee_template_data?.[0]?.overtime_applicable })
                setTemplate({
                    ...calculate_salary_employee_template_data?.[0],
                    payroll_worklocation_id: calculate_salary_employee_template_data?.[0]?.payroll_worklocation_fk

                        ? {
                            payroll_worklocation_id: calculate_salary_employee_template_data?.[0]?.payroll_worklocation_fk
                            ,
                            state_name: calculate_salary_employee_template_data?.[0]?.payroll_worklocation_name,
                        }
                        : "",
                    basic: calculate_salary_employee_template_data?.[0]?.basic_percentage,
                    hra: calculate_salary_employee_template_data?.[0]?.hra_percentage,
                    educational_allowance: calculate_salary_employee_template_data?.[0]?.education_allowance,
                    esic: calculate_salary_employee_template_data?.[0]?.esic_flag,
                    pt: calculate_salary_employee_template_data?.[0]?.pt_flag,
                    lta: calculate_salary_employee_template_data?.[0]?.lta_percentage
                });
                setInputData({
                    ...inputData,
                    template_id: { template_id: "0", template_name: "Meepl Salary Template" },
                });
            }
            else {
                setDisabled(false);
                setTemplateEarningData([]);
                setTemplateDeductionData([]);
                setGrossEarningsTotal(0);
                setGrossDeductionsTotal(0);
                setNetTotal(0);
                setDefaultTemplate(true);
                setVenilaTemplate(false);
                setInputData({
                    ...inputData,
                    template_id: { template_id: "0", template_name: "Meepl Salary Template" },
                });
                setOverTime({ overtime_applicable_flag: false })
            }
        }
        else {
            setDisabled(false);
            setTemplateEarningData([]);
            setTemplateDeductionData([]);
            setGrossEarningsTotal(0);
            setGrossDeductionsTotal(0);
            setNetTotal(0);
            setDefaultTemplate(true);
            setVenilaTemplate(false);
            setInputData({
                ...inputData,
                template_id: { template_id: "0", template_name: "Meepl Salary Template" },
            });
            setOverTime({ overtime_applicable_flag: false })
        }
    }, [employee_template_data, calculate_salary_employee_template_data]);
    const handleInputChange = (event, value) => {
        if (["save_as_template"].includes(event?.target?.name)) {
            setTemplatename({
                ...templatename,
                [event.target.name]: event.target.checked,
            });
        } else {
            setTemplatename({
                ...templatename,
                [event.target.name]: event.target.value,
            });
        }
    };
    const handleInputChange1 = (event, value) => {
        if (["overtime_applicable_flag"].includes(event?.target?.name)) {
            setOverTime({
                ...overtime,
                [event.target.name]: event.target.checked,
            });
        } else {
            setOverTime({
                ...overtime,
                [event.target.name]: event.target.value,
            });
        }
    };
    const handleSubmit = () => {
        if (templatename?.save_as_template) {
            const isEarningsValid = Earningvalidate();
            const isDeductionsValid = Deductionvalidate();
            if (isEarningsValid && isDeductionsValid) {
                setOpenTempname(true);
                setIsSending(true);
            }
        }
        else {
            const isEarningsValid = Earningvalidate();
            const isDeductionsValid = Deductionvalidate();
            if (isEarningsValid && isDeductionsValid) {
                const EarningformattedData = templateEarningData?.reduce((acc, item) => {
                    if (item.earning_key?.name && item.earning_value) {
                        acc[item.earning_key.name] = item.earning_value;
                    }
                    return acc;
                }, {});
                const DeductionformattedData = templateDeductionData?.reduce((acc, item) => {
                    if (item.deduction_key?.name && item.deduction_value) {
                        acc[item.deduction_key.name] = item.deduction_value;
                    }
                    return acc;
                }, {});
                if (employee_template_data?.length > 0) {
                    UpdateEmployeeTemplateHelper({
                        earnings: JSON.stringify([EarningformattedData]),
                        deductions: JSON.stringify([DeductionformattedData]),
                        employee_id: currentData?.employee_id,
                        overtime_applicable_flag: overtime?.overtime_applicable_flag
                    }).then((res) => {
                        if (res?.data?.statuscode === 200) {
                            setIsSending(false);
                            if (emp) {
                                dispatch(GetEmployeesAction({ is_active: true }));
                            }
                        }
                    });
                }
                else {
                    AddEmployeeTemplateHelper({
                        earnings: JSON.stringify([EarningformattedData]),
                        deductions: JSON.stringify([DeductionformattedData]),
                        employee_id: currentData?.employee_id,
                        overtime_applicable_flag: overtime?.overtime_applicable_flag
                    }).then((res) => {
                        setIsSending(false);
                        if (res?.data?.statuscode === 200) {
                            if (emp) {
                                dispatch(GetEmployeesAction({ is_active: true }));
                            }
                        }
                    });
                }
                setOpenTemplate(false);
                if (emp) {
                    dispatch(GetEmployeesAction({ is_active: true }));
                }
                if (createEmp) {
                    setOpenPopup(false);
                    setSuccessPopup(false)
                }
                else if (isonboard) {
                    setCurrentMode("grid");
                    setInternal(true)
                }
            }
        }
    };
    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("template_name" in fieldValues) {
            temp.template_name = fieldValues.template_name === "" ? "Template Name is required" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };
    const handleCreateTemplate = () => {
        if (validate(templatename) && validateTemplateName(templatename)) {
            setIsSending(true);
            const EarningformattedData = templateEarningData?.reduce((acc, item) => {
                if (item.earning_key?.name && item.earning_value) {
                    acc[item.earning_key.name] = item.earning_value;
                }
                return acc;
            }, {});
            const DeductionformattedData = templateDeductionData?.reduce((acc, item) => {
                if (item.deduction_key?.name && item.deduction_value) {
                    acc[item.deduction_key.name] = item.deduction_value;
                }
                return acc;
            }, {});
            const earningKeys = templateEarningData?.map(item => item.earning_key).filter(Boolean);
            const deductionKeys = templateDeductionData?.map(item => item.deduction_key).filter(Boolean);
            const templateFields = JSON.stringify({
                earning_key: earningKeys,
                deduction_key: deductionKeys
            });

            dispatch(AddPayrollTemplateAction({
                template_name: templatename?.template_name,
                template_fields: templateFields
            }));
            if (employee_template_data?.length > 0) {
                UpdateEmployeeTemplateHelper({
                    earnings: JSON.stringify([EarningformattedData]),
                    deductions: JSON.stringify([DeductionformattedData]),
                    employee_id: currentData?.employee_id,
                    overtime_applicable_flag: overtime?.overtime_applicable_flag
                }).then((res) => {
                    if (res?.data?.statuscode === 200) {
                        setIsSending(false);
                        if (emp) {
                            dispatch(GetEmployeesAction({ is_active: true }));
                        }
                    }
                });
            }
            else {
                AddEmployeeTemplateHelper({
                    earnings: JSON.stringify([EarningformattedData]),
                    deductions: JSON.stringify([DeductionformattedData]),
                    employee_id: currentData?.employee_id,
                    overtime_applicable_flag: overtime?.overtime_applicable_flag
                }).then((res) => {
                    if (res?.data?.statuscode === 200) {
                        setIsSending(false);
                        if (emp) {
                            dispatch(GetEmployeesAction({ is_active: true }));
                        }
                    }
                });
            }
            setOpenTemplate(false);
            setOpenTempname(false);
            if (emp) {
                dispatch(GetEmployeesAction({ is_active: true }));
            }
            if (createEmp) {
                setOpenPopup(false);
                setSuccessPopup(false)
            }
            else if (isonboard) {
                setCurrentMode("grid");
                setInternal(true)
            }
        }
    };
    //Earning Template Name Section
    const handleAddEarningFields = () => {
        setTemplateEarningData((state) => [...state, templateEarningsValues]);
    };
    const handleEarningDelete = (e, id, deleteid) => {
        let Data = [...templateEarningData];
        Data.splice(id, 1);
        setTemplateEarningData(Data);
        const newGrossEarningsTotal = Data.reduce((total, item) => {
            return total + (parseFloat(item.earning_value) || 0); // Add the valid values
        }, 0);
        setGrossEarningsTotal(newGrossEarningsTotal);
        const newNetTotal = newGrossEarningsTotal - grossDeductionsTotal;
        setNetTotal(newNetTotal);
    };
    const Earningvalidate = () => {
        let isValid = true;
        const newErrors = [...templateEarningErrors];
        templateEarningData?.forEach((store, index) => {
            const error = {};
            if (!store.earning_key) {
                error.earning_key = "Name is required";
                isValid = false;
            }
            if (!store.earning_value) {
                error.earning_value = "Value is required";
                isValid = false;
            }
            while (newErrors.length <= index) {
                newErrors.push({});
            }
            newErrors[index] = error;
        });
        setTemplateEarningErrors(newErrors);
        return isValid;
    };
    const handleEarningChange = (e, value, id) => {
        let newArr = [...templateEarningData];
        const newErrors = [...templateEarningErrors];
        let item = newArr[id];
        item = { ...item, [e]: value };
        newArr[id] = item;
        newErrors[id] = { ...newErrors[id], [e]: "" };
        setTemplateEarningData(newArr);
        setTemplateEarningErrors(newErrors);
        const newGrossEarningTotal = newArr.reduce((total, item) => {
            return total + (parseFloat(item.earning_value) || 0);
        }, 0);
        setGrossEarningsTotal(newGrossEarningTotal);
        const newNetTotal = newGrossEarningTotal - grossDeductionsTotal;
        setNetTotal(newNetTotal);
    }
    const handleEarnings = () => {
        earning_data &&
            setEarningData({ load: false, values: earning_data?.[0]?.fn_get_earning_components });
    };
    const getFilteredEarnings = (id) => {
        const selectedEarnings = templateEarningData.map((item, index) => index !== id && item.earning_key?.name).filter(Boolean);
        return earningData.values.filter(
            (option) => !selectedEarnings.includes(option.name)
        );
    };

    //Deduction Template Name Section
    const handleDeduction = () => {
        deduction_data &&
            setDeductionData({ load: false, values: deduction_data?.[0]?.fn_get_deduction_components });
    };
    const handleAddDeductionFields = () => {
        setTemplateDeductionData((state) => [...state, templateDeductionValues]);
    };
    const handleDeductionDelete = (e, id, deleteid) => {
        let Data = [...templateDeductionData];
        Data.splice(id, 1);
        setTemplateDeductionData(Data);
        const newGrossDeductionTotal = Data.reduce((total, item) => {
            return total + (parseFloat(item.deduction_value) || 0); // Add the valid values
        }, 0);
        setGrossDeductionsTotal(newGrossDeductionTotal);
        const newNetTotal = grossEarningsTotal - newGrossDeductionTotal;
        setNetTotal(newNetTotal);
    };
    const Deductionvalidate = () => {
        let isValid = true;
        const newErrors = [...templateDeductionErrors];
        templateDeductionData?.forEach((store, index) => {
            const error = {};
            if (!store.deduction_key) {
                error.deduction_key = "Name is required";
                isValid = false;
            }
            if (!store.deduction_value) {
                error.deduction_value = "Value is required";
                isValid = false;
            }
            while (newErrors.length <= index) {
                newErrors.push({});
            }
            newErrors[index] = error;
        });
        setTemplateDeductionErrors(newErrors);
        return isValid;
    };
    const handleDeductionChange = (e, value, id) => {
        let newArr = [...templateDeductionData];
        const newErrors = [...templateDeductionErrors];
        let item = newArr[id];
        item = { ...item, [e]: value };
        newArr[id] = item;
        newErrors[id] = { ...newErrors[id], [e]: "" };
        setTemplateDeductionData(newArr);
        setTemplateDeductionErrors(newErrors);
        const newGrossDeductionTotal = newArr.reduce((total, item) => {
            return total + (parseFloat(item.deduction_value) || 0);
        }, 0);
        setGrossDeductionsTotal(newGrossDeductionTotal);
        setNetTotal(grossEarningsTotal - newGrossDeductionTotal);
    }
    const getFilteredDeduction = (id) => {
        const selectedDeduction = templateDeductionData.map((item, index) => index !== id && item.deduction_key?.name).filter(Boolean);
        return deductionData.values.filter(
            (option) => !selectedDeduction.includes(option.name)
        );
    };

    const handleTemplate = () => {
        payroll_template_data &&
            setTemplateData({ load: false, values: payroll_template_data });
    };
    const handleInput = (event, value) => {
        const selectedTemplate = payroll_template_data?.find(t => t.template_id === value?.template_id);
        if (["template_id"].includes(event)) {
            if (value) {
                setInputData({ ...inputData, [event]: value });
            } else {
                setInputData({ ...inputData, [event]: "" });
            }
        }
        if (selectedTemplate) {
            if (selectedTemplate.template_id === "0") {
                setDefaultTemplate(true);
                setVenilaTemplate(false);
            }
            else {
                setVenilaTemplate(true);
                setDefaultTemplate(false);
                setTemplateEarningData(selectedTemplate?.template_fields?.earning_key?.map(earning => ({
                    earning_key: {
                        name: earning?.name,
                        label: earning?.label
                    },
                    earning_value: "0"
                })));
                setTemplateDeductionData(selectedTemplate?.template_fields?.deduction_key?.map(deduction => ({
                    deduction_key: { name: deduction?.name, label: deduction?.label },
                    deduction_value: "0"
                })));
                setGrossEarningsTotal(0);
                setGrossDeductionsTotal(0);
                setNetTotal(0)
            }
        }

    }
    const validateTemplateName = (fieldValues) => {
        const templateExists = payroll_template_data?.some(template => template.template_name === fieldValues?.template_name);
        if (templateExists) {
            toast.error("Template name is already taken", { duration: 2000, });
            return false;
        }
        return true;
    }
    const handleEdit = () => {
        setDisabled(false)
    }
    // Default Template Section
    const handleInputTemplate = (event, value) => {
        if (["payroll_worklocation_id"].includes(event)) {
            if (value) {
                setTemplate({ ...template, [event]: value });
            } else {
                setTemplate({ ...template, [event]: "" });
            }
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
                tempvalidate({ ...template, [event]: value ? value : "" });
        } else {
            setTemplate({
                ...template,
                [event.target.name]: event.target.value,
            });
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
                tempvalidate({
                    ...template,
                    [event.target.name]: event.target.value,
                });
        }
    };
    const tempvalidate = (fieldValues) => {
        let temp = { ...temperrors };
        if ("ctc" in fieldValues) {
            temp.ctc = fieldValues.ctc === "" ? "CTC is required" : "";
        }
        if ("payroll_worklocation_id" in fieldValues) {
            temp.payroll_worklocation_id = fieldValues.payroll_worklocation_id === "" ? "Work Location is required" : "";
        }
        if ("basic" in fieldValues) {
            temp.basic = fieldValues.basic === "" ? "Basic is required" : "";
        }
        if ("hra" in fieldValues) {
            temp.hra = fieldValues.hra === "" ? "HRA is required" : "";
        }
        if ("conveyance_allowance" in fieldValues) {
            if (fieldValues?.conveyance_allowance_flag) {
                temp.conveyance_allowance = fieldValues.conveyance_allowance === "" ? "Conveyance Allowance is required" : "";
            }
            else {
                temp.conveyance_allowance = "";
            }
        }
        if ("medical_allowance" in fieldValues) {
            if (fieldValues?.medical_allowance_flag) {
                temp.medical_allowance = fieldValues.medical_allowance === "" ? "Medical Allowance is required" : "";
            }
            else {
                temp.medical_allowance = "";
            }
        }
        if ("educational_allowance" in fieldValues) {
            if (fieldValues?.educational_allowance_flag) {
                temp.educational_allowance = fieldValues.educational_allowance === "" ? "Educational Allowance is required" : "";
            }
            else {
                temp.educational_allowance = "";
            }
        }
        if ("lta" in fieldValues) {
            if (fieldValues?.lta_flag) {
                temp.lta = fieldValues.lta === "" ? "LTA is required" : "";
            }
            else {
                temp.lta = "";
            }
        }
        setTempErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };
    const handleNext = () => {
        if (tempvalidate(template)) {
            setIsSending(true);
            CalculateEmployeeSalaryTemplateHelper({ ...template }).then((res) => {
                setIsSending(false);
                setResData(res?.data?.data?.[0]);
                setStep(2);
            });
        }
    }
    const handleSubmitTemp = () => {
        AddCalculateEmployeeSalaryTemplateHelper({
            ...resData,
            ctc: (grossEarningsTempTotal * 12).toString(),
            conveyance_allowance_flag: template?.conveyance_allowance_flag,
            medical_allowance_flag: template?.medical_allowance_flag,
            educational_allowance_flag: template?.educational_allowance_flag,
            lta_flag: template?.lta_flag,
            employee_id: currentData?.employee_id,
            overtime_applicable_flag: overtime?.overtime_applicable_flag

        }).then((res) => {
            if (res?.data?.statuscode === 200) {
                setIsSending(false);
                if (emp) {
                    dispatch(GetEmployeesAction({ is_active: true }));
                }
            }
        });
        setOpenTemplate(false);
        if (emp) {
            dispatch(GetEmployeesAction({ is_active: true }));
        }
        if (createEmp) {
            setOpenPopup(false);
            setSuccessPopup(false)
        }
        else if (isonboard) {
            setCurrentMode("grid");
            setInternal(true)
        }
    }

    return (
        <>
            {isLoading ? (
                <Box sx={{ width: '100%', marginTop: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Skeleton variant="text" width="60%" height={30} sx={{ marginBottom: 1 }} />
                                <Skeleton variant="text" width="80%" height={20} sx={{ marginBottom: 2 }} />
                                <Skeleton variant="rectangular" width="100%" height={300} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Skeleton variant="text" width="60%" height={30} sx={{ marginBottom: 1 }} />
                                <Skeleton variant="text" width="80%" height={20} sx={{ marginBottom: 2 }} />
                                <Skeleton variant="rectangular" width="100%" height={300} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                marginTop: "1rem",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            {(payroll_template_data?.length > 0 && !disabled) && (
                                <FormControl fullWidth>
                                    <SelectableSearch
                                        isOptionEqualToValue={(option, value) =>
                                            +option.template_id === +value.template_id
                                        }
                                        label="Select from Saved Templates"
                                        fullWidth
                                        disabled={disabled}
                                        value={inputData?.template_id}
                                        loading={() => handleTemplate()}
                                        fieldLabel="template_name"
                                        required={false}
                                        variantType={"standard"}
                                        name="template_id"
                                        size="small"
                                        onChangeEvent={handleInput}
                                        data={templateData}
                                    />
                                </FormControl>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                marginTop: "1rem",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}
                        >
                            {employee_template_data?.length > 0 && (
                                <EditIcon
                                    onClick={(e) => handleEdit(e)}
                                    sx={{ margin: "15px 15px 0px 15px", cursor: "pointer" }}
                                    fontSize="small"
                                />
                            )}
                        </Grid>
                    </Grid>
                    {venilatemplate && (
                        <Box>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        marginTop: "1rem",
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <Box sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                        >
                                            Earnings
                                        </Typography>
                                        <Tooltip title="Add">
                                            <IconButton
                                                disabled={disabledEarningTemplate || disabled}
                                                className="mpl-primary-btn"
                                                onClick={handleAddEarningFields}
                                                style={{ padding: '4px', minWidth: '30px', height: '30px' }}
                                            >
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        marginTop: "1rem",
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                    >
                                        Monthly
                                    </Typography>
                                </Grid>
                            </Grid>
                            {templateEarningData?.map((res, id) => {
                                return (
                                    <>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                sx={{
                                                    marginTop: "1rem",
                                                    paddingRight: "15px",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <SelectableSearch
                                                        isOptionEqualToValue={(option, value) =>
                                                            +option.label === +value.label
                                                        }
                                                        label="Select an Earnings"
                                                        fullWidth
                                                        disabled={disabled}
                                                        value={res?.earning_key}
                                                        loading={() => handleEarnings()}
                                                        fieldLabel="label"
                                                        required={false}
                                                        variantType={"standard"}
                                                        name="earning_key"
                                                        size="small"
                                                        onChangeEvent={(e, newValue) =>
                                                            handleEarningChange("earning_key", newValue, id)  // Handle change
                                                        }
                                                        data={{ load: earningData.load, values: getFilteredEarnings(id) }}
                                                        {...(templateEarningErrors[id] &&
                                                            templateEarningErrors[id].earning_key && {
                                                            error: true,
                                                            helperText: templateEarningErrors[id].earning_key,
                                                        })}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={5}
                                                sx={{
                                                    marginTop: "32px",
                                                    paddingRight: "15px",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        required
                                                        value={res.earning_value}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value)) {
                                                                handleEarningChange(e.target.name, value, id);
                                                            }
                                                        }}
                                                        variant={"standard"}
                                                        fullWidth
                                                        disabled={disabled}
                                                        id="earning_value"
                                                        label=""
                                                        name="earning_value"
                                                        autoComplete="earning_value"
                                                        inputProps={{
                                                            inputMode: 'decimal',
                                                            pattern: "[0-9]*",
                                                            step: "0.01"
                                                        }}
                                                        {...(templateEarningErrors[id] &&
                                                            templateEarningErrors[id].earning_value && {
                                                            error: true,
                                                            helperText: templateEarningErrors[id].earning_value,
                                                        })}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={1}>
                                                <Box
                                                    sx={{
                                                        marginTop: "40px",
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "flex-end",
                                                        cursor: disabled ? "not-allowed" : "pointer",
                                                        opacity: disabled ? 0.5 : 1,
                                                    }}
                                                >
                                                    <DeleteIcon color="error" onClick={(e) => {
                                                        if (!disabled) {
                                                            handleEarningDelete(e, id, res?.certificate_id);
                                                        }
                                                    }} fontSize="small" />

                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            })}
                            <Divider sx={{ mt: 2 }} />
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        marginTop: "1rem",
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <Box sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                        >
                                            Deduction
                                        </Typography>
                                        <Tooltip title="Add">
                                            <IconButton
                                                disabled={disabledDeductionTemplate || disabled}
                                                className="mpl-primary-btn"
                                                style={{ padding: '4px', minWidth: '30px', height: '30px' }}
                                                onClick={handleAddDeductionFields}
                                            >
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        marginTop: "1rem",
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                    >
                                        Monthly
                                    </Typography>
                                </Grid>
                            </Grid>
                            {templateDeductionData?.map((res, id) => {
                                return (
                                    <>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                sx={{
                                                    marginTop: "1rem",
                                                    paddingRight: "15px",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <SelectableSearch
                                                        isOptionEqualToValue={(option, value) =>
                                                            +option.label === +value.label
                                                        }
                                                        label="Select an Deductions"
                                                        fullWidth
                                                        disabled={disabled}
                                                        value={res?.deduction_key}
                                                        loading={() => handleDeduction()}
                                                        fieldLabel="label"
                                                        required={false}
                                                        variantType={"standard"}
                                                        name="deduction_key"
                                                        size="small"
                                                        onChangeEvent={(e, newValue) =>
                                                            handleDeductionChange("deduction_key", newValue, id)  // Handle change
                                                        }
                                                        data={{ load: deductionData.load, values: getFilteredDeduction(id) }}
                                                        {...(templateDeductionErrors[id] &&
                                                            templateDeductionErrors[id].deduction_key && {
                                                            error: true,
                                                            helperText: templateDeductionErrors[id].deduction_key,
                                                        })}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={5}
                                                sx={{
                                                    marginTop: "32px",
                                                    paddingRight: "15px",
                                                    paddingLeft: "15px",
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        required
                                                        value={res.deduction_value}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^\d*\.?\d*$/.test(value)) {
                                                                handleDeductionChange(e.target.name, value, id);
                                                            }
                                                        }}
                                                        variant={"standard"}
                                                        fullWidth
                                                        id="deduction_value"
                                                        disabled={disabled}
                                                        label=""
                                                        name="deduction_value"
                                                        autoComplete="deduction_value"
                                                        inputProps={{
                                                            inputMode: 'decimal',
                                                            pattern: "[0-9]*",
                                                            step: "0.01"
                                                        }}
                                                        {...(templateDeductionErrors[id] &&
                                                            templateDeductionErrors[id].deduction_value && {
                                                            error: true,
                                                            helperText: templateDeductionErrors[id].deduction_value,
                                                        })}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={1}>
                                                <Box
                                                    sx={{
                                                        marginTop: "40px",
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "flex-end",
                                                        cursor: disabled ? "not-allowed" : "pointer",
                                                        opacity: disabled ? 0.5 : 1,
                                                    }}
                                                >
                                                    <DeleteIcon disabled={disabled} color="error" onClick={(e) => {
                                                        if (!disabled) {
                                                            handleDeductionDelete(e, id, res?.certificate_id);
                                                        }
                                                    }} fontSize="small" />

                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            })}
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        marginTop: "1rem",
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                    }}
                                />
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{
                                        marginTop: "1rem",
                                        paddingRight: "15px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    <div className={classes.row}>
                                        <span className={classes.label}> Gross Earning Total </span>
                                        <div className={classes.value}>: &nbsp; {grossEarningsTotal}</div>
                                    </div>
                                    <div className={classes.row}>
                                        <span className={classes.label}>  Gross Deduction Total &nbsp;</span>
                                        <div className={classes.value}>: &nbsp;  {grossDeductionsTotal}</div>
                                    </div>
                                    <Divider sx={{ mt: 2 }} />
                                    <div className={classes.row}>
                                        <span className={classes.netlabel}> Net Total </span>
                                        <div className={classes.value}>: &nbsp;  {netTotal}</div>
                                    </div>
                                </Grid>
                            </Grid>
                            <Box sx={{
                                width: "100%",
                                mt: 3,
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={netTotal === 0 || disabled}
                                            name="save_as_template"
                                            checked={templatename?.save_as_template || false}
                                            onChange={handleInputChange}
                                        />
                                    }
                                    label="Save as Template"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={disabled}
                                            name="overtime_applicable_flag"
                                            checked={overtime?.overtime_applicable_flag || false}
                                            onChange={handleInputChange1}
                                        />
                                    }
                                    label="Overtime Applicable?"
                                />
                                <Button disabled={netTotal === 0 || disabled || isSending} onClick={handleSubmit} size="small" variant="outlined">
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {defaulttemplate && (
                        <>
                            {step === 1 ? (
                                <>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            Enter CTC Amount here * (Annually)
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="small"
                                                    required
                                                    type="number"
                                                    value={template?.ctc}
                                                    onChange={handleInputTemplate}
                                                    variant={"standard"}
                                                    fullWidth
                                                    id="ctc"
                                                    label=""
                                                    name="ctc"
                                                    autoComplete="ctc"
                                                    {...(temperrors.ctc && {
                                                        error: true,
                                                        helperText: temperrors.ctc,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            Select Work Location here *
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            <FormControl fullWidth>
                                                <SelectableSearch
                                                    isOptionEqualToValue={(option, value) =>
                                                        +option.state_id ===
                                                        +value.state_id
                                                    }
                                                    value={template?.payroll_worklocation_id}
                                                    loading={() =>
                                                        setWorklocationData({
                                                            load: false,
                                                            values: payroll_worklocation_data,
                                                        })
                                                    }
                                                    fieldLabel="state_name"
                                                    required={false}
                                                    variantType={"standard"}
                                                    name="payroll_worklocation_id"
                                                    size="small"
                                                    data={worklocationData}
                                                    onChangeEvent={handleInputTemplate}
                                                    {...(temperrors.payroll_worklocation_id && {
                                                        error: true,
                                                        helperText: temperrors.payroll_worklocation_id,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            Basic *
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="small"
                                                    required
                                                    value={template?.basic}
                                                    type="number"
                                                    onChange={handleInputTemplate}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                        maxLength: 4,
                                                    }}
                                                    variant={"standard"}
                                                    fullWidth
                                                    id="basic"
                                                    label=""
                                                    name="basic"
                                                    autoComplete="basic"
                                                    {...(temperrors.basic && {
                                                        error: true,
                                                        helperText: temperrors.basic,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            HRA *
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            <FormControl fullWidth>
                                                <TextField
                                                    size="small"
                                                    required
                                                    type="number"
                                                    value={template?.hra}
                                                    onChange={handleInputTemplate}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                        maxLength: 4,
                                                    }}
                                                    variant={"standard"}
                                                    fullWidth
                                                    id="hra"
                                                    label=""
                                                    name="hra"
                                                    autoComplete="hra"
                                                    {...(temperrors.hra && {
                                                        error: true,
                                                        helperText: temperrors.hra,
                                                    })}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            Conveyance Allowance
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={() => handleToggle("conveyance_allowance_flag")}
                                                                checked={template?.conveyance_allowance_flag}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            {template?.conveyance_allowance_flag && (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        type="number"
                                                        required
                                                        value={template?.conveyance_allowance}
                                                        onChange={handleInputTemplate}
                                                        variant={"standard"}
                                                        fullWidth
                                                        id="conveyance_allowance"
                                                        label=""
                                                        name="conveyance_allowance"
                                                        autoComplete="conveyance_allowance"
                                                        {...(temperrors.conveyance_allowance && {
                                                            error: true,
                                                            helperText: temperrors.conveyance_allowance,
                                                        })}
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            Medical Allowance
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={() => handleToggle("medical_allowance_flag")}
                                                                checked={template?.medical_allowance_flag}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            {template?.medical_allowance_flag && (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        type="number"
                                                        required
                                                        value={template?.medical_allowance}
                                                        onChange={handleInputTemplate}
                                                        variant={"standard"}
                                                        fullWidth
                                                        id="medical_allowance"
                                                        label=""
                                                        name="medical_allowance"
                                                        autoComplete="medical_allowance"
                                                        {...(temperrors.medical_allowance && {
                                                            error: true,
                                                            helperText: temperrors.medical_allowance,
                                                        })}
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            Educational Allowance
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={() => handleToggle("educational_allowance_flag")}
                                                                checked={template?.educational_allowance_flag}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            {template?.educational_allowance_flag && (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        required
                                                        type="number"
                                                        value={template?.educational_allowance}
                                                        onChange={handleInputTemplate}
                                                        variant={"standard"}
                                                        fullWidth
                                                        id="educational_allowance"
                                                        label=""
                                                        name="educational_allowance"
                                                        autoComplete="educational_allowance"
                                                        {...(temperrors.educational_allowance && {
                                                            error: true,
                                                            helperText: temperrors.educational_allowance,
                                                        })}
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            LTA
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={() => handleToggle("lta_flag")}
                                                                checked={template?.lta_flag}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                        >
                                            {template?.lta_flag && (
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        type="number"
                                                        required
                                                        value={template?.lta}
                                                        onChange={handleInputTemplate}
                                                        variant={"standard"}
                                                        fullWidth
                                                        id="lta"
                                                        label=""
                                                        name="lta"
                                                        autoComplete="lta"
                                                        {...(temperrors.lta && {
                                                            error: true,
                                                            helperText: temperrors.lta,
                                                        })}
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            ESIC
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={() => handleToggle1("esic")}
                                                                checked={template?.esic}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",

                                            }}
                                        ></Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            PT
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                onChange={() => handleToggle1("pt")}
                                                                checked={template?.pt}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",

                                            }}
                                        ></Grid>

                                        <Box sx={{
                                            width: "100%",
                                            mt: 3,
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}>
                                            <Button onClick={() => { setOpenTemplate(false); }} size="small" variant="outlined">
                                                Cancel
                                            </Button>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={disabled}
                                                        name="overtime_applicable_flag"
                                                        checked={overtime?.overtime_applicable_flag || false}
                                                        onChange={handleInputChange1}
                                                    />
                                                }
                                                label="Overtime Applicable?"
                                            />
                                            <Button disabled={isSending} onClick={handleNext} size="small" variant="outlined">
                                                Next
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <div>
                                    <Grid container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography variant="h6" gutterBottom style={{ marginTop: "2rem" }}>
                                                Earnings
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography variant="h6" gutterBottom style={{ marginTop: "2rem" }}>
                                                Monthly
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        {earnings?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    sx={{
                                                        marginTop: "1rem",
                                                        paddingRight: "15px",
                                                        paddingLeft: "15px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.label}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    sx={{
                                                        marginTop: "1rem",
                                                        paddingRight: "15px",
                                                        paddingLeft: "15px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.value}
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                    <Divider sx={{ mt: 2 }} />
                                    <Grid container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography variant="h6" gutterBottom style={{ marginTop: "2rem" }}>
                                                Deductions
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography variant="h6" gutterBottom style={{ marginTop: "2rem" }}>
                                                Monthly
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ paddingLeft: "15px" }}>
                                        {deductions?.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    sx={{
                                                        marginTop: "1rem",
                                                        paddingRight: "15px",
                                                        paddingLeft: "15px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.label}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    sx={{
                                                        marginTop: "1rem",
                                                        paddingRight: "15px",
                                                        paddingLeft: "15px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {item.value}
                                                </Grid>
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                            }}
                                        />
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                marginTop: "1rem",
                                                paddingRight: "15px",
                                                paddingLeft: "15px",
                                            }}
                                        >
                                            <div className={classes.row}>
                                                <span className={classes.label}> Gross Earning Total </span>
                                                <div className={classes.value}>: &nbsp; {grossEarningsTempTotal}</div>
                                            </div>
                                            <div className={classes.row}>
                                                <span className={classes.label}>  Gross Deduction Total &nbsp;</span>
                                                <div className={classes.value}>: &nbsp;  {grossDeductionsTempTotal}</div>
                                            </div>
                                            <Divider sx={{ mt: 2 }} />
                                            <div className={classes.row}>
                                                <span className={classes.netlabel}> Net Total </span>
                                                <div className={classes.value}>: &nbsp;  {netTempTotal}</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{
                                        width: "100%",
                                        mt: 3,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                        <Button onClick={() => { setStep(1); }} size="small" variant="outlined">
                                            Back
                                        </Button>
                                        <Button disabled={isSending} onClick={handleSubmitTemp} size="small" variant="outlined">
                                            Submit
                                        </Button>
                                    </Box>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            <Popup
                maxWidth="sm"
                fullWidth={true}
                title="Enter Template Name"
                openPopup={opentempname}
                setOpenPopup={setOpenTempname}
            >
                <FormControl fullWidth>
                    <TextField
                        size="small"
                        required
                        value={templatename?.template_name}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="template_name"
                        label="Template Name"
                        name="template_name"
                        autoComplete="template_name"
                        {...(errors.template_name && {
                            error: true,
                            helperText: errors.template_name,
                        })}
                    />
                </FormControl>
                <Box sx={{
                    width: "100%",
                    mt: 3,
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Button onClick={() => { setOpenTempname(false); }} size="small" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateTemplate} size="small" variant="outlined">
                        Save
                    </Button>
                </Box>
            </Popup>
            <Popup
                maxWidth="sm"
                fullWidth={true}
                title="Warning"
                openPopup={openvalid}
                setOpenPopup={setOpenValid}
            >
                <Typography variant="h6" gutterBottom style={{ display: "flex", justifyContent: "center" }}>
                    Given CTC is not Matching Gross Earning
                </Typography>
                <Box sx={{
                    width: "100%",
                    mt: 3,
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Button onClick={() => { setOpenValid(false); }} size="small" variant="outlined">
                        Ok
                    </Button>

                </Box>
            </Popup>
        </>
    );
}

export default EmployeePayroleTemplate;
