import {
  Box,
  Card,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useEffect } from "react";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CelebrationIcon from "@mui/icons-material/Celebration";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import Base from "../../Base/views/Base";
import {
  GetLeaveOverallBalanceAction,
  GetPendingLeaveApprovalAction,
} from "../../../../store/Leave/LeaveDashboard/Action";
import { GetUpcomingHolidaysAction } from "../../../../store/Leave/HolidayCalendar/Action";
import { ConvertDate, getUserData } from "../../Base/helper/baseFunctions";
import { GetEmployeeDashboardAction } from "../../../../store/EmployeeDashboard/Action";
import history from "../../../../history";
import { AddMenuHeadingAction } from "../../../../store/Menu/Action";
import { GetCurrentTimeAction } from "../../../../store/Leave/MyTime/Action";
import { toast } from "react-toastify";
import ViewHolidayCalendar from "../../Leave/views/ManageLeave/ApplyLeave/HolidayCalendar";

const DashboardLandingPage = ({ setValue, handleChange }) => {
  const dispatch = useDispatch();
  const empData = getUserData();
  const { pendingLeaveApprovalData } = useSelector(
    (state) => state?.LeaveReducer.LeaveDashboardReducer
  );
  const { upcomingHolidaysData } = useSelector(
    (state) => state?.LeaveReducer.HolidayCalendarReducer
  );
  const { leaveOverallBalanceData } = useSelector(
    (state) => state?.LeaveReducer.LeaveDashboardReducer
  );
  const { dashboardData } = useSelector(
    (state) => state?.EmployeeDashboardReducer
  );
  const { atsModuleData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleReducer);
  const { current_time } = useSelector(
    (state) => state?.LeaveReducer?.CurrentTimeReducer
  );
  const myEmployeeAccess = atsModuleData?.find(
    (item) => item.module_name === "My Employee"
  );
  const [openPopup, setOpenPopup] = useState(false);
  // FOR slider (Upcoming Leaves)
  useEffect(() => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    dispatch(GetUpcomingHolidaysAction({ show: "no" }));
    dispatch(GetLeaveOverallBalanceAction({ show: "no" }));
    dispatch(GetPendingLeaveApprovalAction());
    dispatch(
      GetEmployeeDashboardAction({
        domain: empData?.domain,
        emp_id: empData?.employee_id,
      })
    );
    dispatch(
      GetCurrentTimeAction({
        current_date_day: day,
        current_date_month: month,
        current_date_year: year,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleApproveClick = (menu, value) => {
    if (myEmployeeAccess) {
      sessionStorage.setItem("selectedMenu", menu);
      dispatch(AddMenuHeadingAction({ heading: menu }));
      history.push({
        pathname: "/lms/manage-team",
        state: { defaultValue: value },
      });
    }
    else {
      toast.error("You Don't Have Permission")
    }

  };
  const handleTimeSheetClick = (menu, value) => {
    sessionStorage.setItem("selectedMenu", menu);
    dispatch(AddMenuHeadingAction({ heading: menu }));
    history.push({
      pathname: "/lms/manage-timesheet",
      state: { click_event: value }
    })
  };


  const getEventColor = (status) => {
    switch (status) {
      case "Approved":
        return "#20e277";
      case "Submitted":
        return "#20e277";
      case "Rejected":
        return "#e68d45";
      case "Reopened":
        return "#fbeae8";

      default:
        return "#94b2d1";
    }
  };
  // For Donut Chart (Leave Balance)

  return (
    <>
      <Base>
        <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
              "@media (max-width: 600px)": {
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              },
            }}
          >
            <Typography sx={{ padding: "10px" }} variant="p">
              Employee ID: {empData?.employee_no}
            </Typography>
            <Typography sx={{ padding: "10px" }} variant="p">
              Reporting Manager :{" "}
              {dashboardData?.[0]?.reporting_manager_info?.[0]?.Name}
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card
                sx={{
                  margin: "10px",
                  padding: "10px",
                  boxShadow: "0 0 10px 0 #b1b2b726",
                  border: "1px solid #e9e5e5",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBottom: "2px solid #8b44ff",
                    padding: "10px",
                  }}
                >
                  <PendingActionsIcon padding="10px" fontSize="small" />{" "}
                  <Typography pl={1} variant="h6">
                    My Timesheet
                  </Typography>
                </Box>
                <Grid sx={{ padding: "20px 0px 20px 5px" }} container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="p">Current Timesheet</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        fontSize: "11px",
                        color: "#8b44ff",
                        cursor: "pointer"
                      }}
                      variant="p"
                      onClick={() => handleTimeSheetClick("My Timesheet")}
                    >
                      All Timesheet &gt;
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ padding: "0px 0px 0px 5px" }} container>
                  <Grid item xs={7} sm={7} md={7} lg={7}>
                    <Typography sx={{ fontSize: "11px", fontWeight: "bold" }}>
                      {current_time?.[0]?.start_date} -{" "}
                      {current_time?.[0]?.end_date}
                    </Typography>
                    <Typography sx={{ fontSize: "10px" }}>
                      Due: {current_time?.[0]?.end_date} &nbsp;&nbsp;&nbsp;
                      Hours:{current_time?.[0]?.total_time || 0}
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                    item
                    xs={5}
                    sm={5}
                    md={5}
                    lg={5}
                  >
                    <Box
                      sx={{
                        background: getEventColor(current_time?.[0]?.flag),
                        fontWeight: 500,
                        color: "#000",
                        fontSize: "12px",
                        padding: "8px 10px",
                        borderRadius: "50px",
                      }}

                    >
                      {current_time?.[0]?.flag}
                    </Box>
                    {current_time?.[0]?.start_date && <ArrowForwardIosIcon sx={{ cursor: "pointer" }} onClick={() => handleTimeSheetClick("My Timesheet", "2")} fontSize="10px" />}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card
                sx={{
                  margin: "10px",
                  padding: "10px",
                  boxShadow: "0 0 10px 0 #b1b2b726",
                  border: "1px solid #e9e5e5",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderBottom: "2px solid #8b44ff",
                    padding: "10px",
                  }}
                >
                  <CelebrationIcon padding="10px" fontSize="small" />{" "}
                  <Typography pl={1} variant="h6">
                    My Time Off
                  </Typography>
                </Box>
                <Accordion sx={{ margin: "15px 0px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Balances
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{ maxHeight: "40vh", overflowY: "auto" }}
                  >
                    {leaveOverallBalanceData?.map((leavedata, i) => {
                      return (
                        <>
                          <Grid container>
                            <Grid pb={2} item xs={6} sm={6} md={6} lg={6}>
                              {leavedata?.gender ? (
                                <Typography
                                  sx={{ fontSize: "12px" }}
                                >{`[${leavedata?.leave_code}] - ${leavedata?.leavetype_name}(${leavedata?.gender})`}</Typography>
                              ) : (
                                <Typography
                                  sx={{ fontSize: "12px" }}
                                >{`[${leavedata?.leave_code}] - ${leavedata?.leavetype_name}`}</Typography>
                              )}
                              <Typography
                                sx={{ fontSize: "12px", color: "#000" }}
                              >
                                <strong>{leavedata?.leave_debited}</strong> Days
                                Taken
                              </Typography>
                            </Grid>
                            <Grid pb={2} item xs={6} sm={6} md={6} lg={6}>
                              {leavedata?.gender ? (
                                <Typography
                                  sx={{ fontSize: "12px" }}
                                >{`[${leavedata?.leave_code}] - ${leavedata?.leavetype_name}(${leavedata?.gender})`}</Typography>
                              ) : (
                                <Typography
                                  sx={{ fontSize: "12px" }}
                                >{`[${leavedata?.leave_code}] - ${leavedata?.leavetype_name}`}</Typography>
                              )}
                              <Typography
                                sx={{ fontSize: "12px", color: "#000" }}
                              >
                                <strong>{leavedata?.available_days}</strong>{" "}
                                Days
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {myEmployeeAccess && (
                <Card
                  sx={{
                    margin: "10px",
                    padding: "10px",
                    boxShadow: "0 0 10px 0 #b1b2b726",
                    border: "1px solid #e9e5e5",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderBottom: "2px solid #8b44ff",
                      padding: "10px",
                    }}
                  >
                    <HowToRegIcon padding="10px" fontSize="small" />{" "}
                    <Typography pl={1} variant="h6">
                      Approvals
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "25px 10px", // Adjust padding as needed
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px", // Adjust margin as needed
                      }}
                    >
                      <Box
                        sx={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundImage:
                            "linear-gradient(to left, #facf39, #fbdb6b)",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#000",
                          }}
                        >
                          {pendingLeaveApprovalData?.[0]?.time_sheet_pending_approval}
                        </Typography>
                      </Box>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "14px", color: "#000", pl: 1 }}
                      >
                        <strong>Time Sheet </strong>waiting for approval
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}></Box>{" "}
                    {/* This box takes up remaining space */}
                    <ArrowForwardIosIcon
                      onClick={() => handleApproveClick("Team")}
                      sx={{ cursor: "pointer" }}
                      fontSize="12px"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 10px", // Adjust padding as needed
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px", // Adjust margin as needed
                      }}
                    >
                      <Box
                        sx={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundImage:
                            "linear-gradient(to left, #facf39, #fbdb6b)",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            color: "#000",
                          }}
                        >
                          {pendingLeaveApprovalData?.[0]?.time_off_pending_approval}
                        </Typography>
                      </Box>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "14px", color: "#000", pl: 1 }}
                      >
                        <strong>Time Off </strong>waiting for approval
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}></Box>{" "}
                    {/* This box takes up remaining space */}
                    <ArrowForwardIosIcon
                      onClick={() => handleApproveClick("Team", "2")}
                      sx={{ cursor: "pointer" }}
                      fontSize="12px"
                    />
                  </Box>
                </Card>
              )}

              <Card
                sx={{
                  margin: "10px",
                  padding: "10px",
                  boxShadow: "0 0 10px 0 #b1b2b726",
                  border: "1px solid #e9e5e5",
                }}
              >
                <Grid sx={{ padding: "20px 0px 20px 5px" }} container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="p" sx={{ fontWeight: 600 }}>
                      Upcoming Time Off
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ padding: "0px 0px 0px 5px" }} container>
                  <Grid item xs={7} sm={7} md={7} lg={7}>
                    {upcomingHolidaysData?.length === 0 ? (
                      <Typography sx={{ fontSize: "10px" }}>
                        No Upcoming Holidays
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          sx={{ fontSize: "11px", fontWeight: "bold" }}
                        >
                          {ConvertDate(upcomingHolidaysData?.[0]?.date)}
                        </Typography>
                        <Typography sx={{ fontSize: "10px" }}>
                          {upcomingHolidaysData?.[0]?.name}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Card>
              <Card
                sx={{
                  margin: "10px",
                  padding: "10px",
                  boxShadow: "0 0 10px 0 #b1b2b726",
                  border: "1px solid #e9e5e5",
                }}
              >
                <Grid sx={{ padding: "10px 0px 10px 5px" }} container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="p" sx={{ fontWeight: 600 }}>
                      Holiday Calendar
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={6} sm={6} md={6} lg={6}>
                    <Typography onClick={() => setOpenPopup(true)} variant="p" sx={{
                      fontWeight: 600, color: "#8b44ff",
                      cursor: "pointer"
                    }}>
                      View Here &gt;
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <ViewHolidayCalendar
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          Holiday={true}
        />
      </Base>


    </>
  );
};

export default DashboardLandingPage;
