import MaterialTable from "material-table";
import { useEffect } from "react";
import {
    Box,
    Button,
    Select,
    Typography,
    MenuItem,
    Grid,
    CircularProgress,
    IconButton,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import { getUserData } from "../../../Base/helper/baseFunctions";
import Popup from "../../../Base/views/Popup";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../../Constants/baseConstant";
import { DeleteLeaveBalanceStageHelper, GetLeaveBalanceStageHelper, UpdateLeaveBalanceStageHelper } from "../../../Finance/helper/BulkUpload/BulkUploadHelper";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { GetLeaveTypeAction } from "../../../../../store/Leave/LeaveType/Action";
const TeamBalanceBulkUpload = () => {
    let userData = getUserData();
    const dispatch = useDispatch();
    const { leavetypes_options } = useSelector(state => state.LeaveReducer?.LeaveTypeReducer);
    const [, setOpenBulk] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [editedRecord, setEditedRecord] = useState(null);
    const [id, setId] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [errors, setErrors] = useState({});
    const [editerrors, setEditErrors] = useState({});
    const [empdata, setEmpData] = useState([]);
    const [file, setFile] = useState(null);
    const initialColMapping = [
        { excel: "", db: "previous_employee_id", label: "Prev.Emp ID", required: true },
        { excel: "", db: "", label: "Earned Leave", required: true },
    ];
    const [excelDbColMapping, setExcelDbColMapping] = useState(initialColMapping);
    const [rowHeaders, setRowHeaders] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    useEffect(() => {
        GetLeaveBalanceStageHelper().then((res) => {
            setEmpData(res?.data?.data);
            setId(res?.data?.data?.[0]?.stage_leave_balance_pk)
        });
        dispatch(GetLeaveTypeAction());
    }, [dispatch]);
    const handleSelectFileClick = () => {
        if (empdata?.length > 0) {
            setShowConfirmPopup(true); // Show confirmation popup if data exists
        } else {
            document.getElementById("contained-button-file").click(); // Directly trigger file selection if no data exists
        }
    };
    const fileSelected = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;
        processFile(selectedFile, e);
    };
    const processFile = (selectedFile, event) => {
        setFile(selectedFile);
        setExcelDbColMapping(initialColMapping);
        setUploadProgress(0);
        const fileReader = new FileReader();
        toast.info("Reading File. Please wait");
        fileReader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const firstRowData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                range: 0,
            });
            setRowHeaders(firstRowData[0]);
            toast.success("File imported");
        };

        fileReader.readAsArrayBuffer(selectedFile);
        if (event) event.target.value = ""; // Reset the input
    };
    function setColMapping(value, dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = value;
            });
            return [...prev];
        });
    }
    const validate = () => {
        const temp = {};
        if (!file) temp.file = "File is required";
        const unmappedRequiredFields = excelDbColMapping.filter(
            (col) => col.required && !col.excel
        );
        if (unmappedRequiredFields.length > 0) {
            toast.error("Please map all required fields.")
            temp.columnMapping = "Please map all required fields.";
        }
        setErrors(temp);
        return Object.keys(temp).length === 0; // Return true if no errors
    };
    async function addTeamBalanceStage(e) {
        if (!validate()) return;
        e.preventDefault();
        try {
            const updatedMapping = excelDbColMapping.map(col => {
                if (col.label === "Earned Leave") {
                    const cfwdLeaveType = leavetypes_options?.find(leave => leave.cfwd_leave === true);
                    if (cfwdLeaveType) {
                        return {
                            ...col,
                            db: cfwdLeaveType.name, // Assign the name of the leave type where cfwd_leave is true
                        };
                    }
                }
                return col;
            });
            setExcelDbColMapping(updatedMapping);
            const formData = new FormData();
            formData.append("file", file);
            formData.append("user_id", userData?.employee_id);
            formData.append("domain_name", userData?.domain);
            formData.append("column_mapping", JSON.stringify(updatedMapping));
            toast.info("Uploading file");
            try {
                setUploadProgress(0);
                await axiosInstance
                    .post("/lms/uploadLeaveBalanceFile", formData, {
                        baseURL: HRMS_LMS_API_URL,
                        headers: {
                            Accept: "multipart/form-data",
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progress) => {
                            const percentCompleted = Math.round(
                                (progress.loaded * 90) / progress.total
                            );
                            setUploadProgress(percentCompleted);
                            if (percentCompleted > 89) {
                                toast.info("Processing...");
                            }
                        },
                    })
                    .then((response) => {
                        const result = response?.data?.statuscode;
                        setEmpData(response?.data?.data)
                        setId(response?.data?.data?.[0]?.stage_leave_balance_pk)
                        if (result) {
                            toast.success("File processed successfully!");
                        }
                    });

                setUploadProgress(100);
                setFile(null);
                // refreshCatalog();
            } catch (error) {
                setUploadProgress(0);
                toast.warning(error.response?.data?.message || error.message);
            }
        } catch (error) {
            console.error("Unexpected error", error);
        }
    }
    async function handleSubmit(e) {
    }
    const handleCancel = () => {
        setShowConfirmPopup(false);
        DeleteLeaveBalanceStageHelper({ stage_leave_balance_id: id }).then((res) => {
            setEmpData([]); // Clear table data
            setFile(null); // Allow new file selection
            document.getElementById("contained-button-file").click();
        });
    };
    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };
    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleDelete = () => {
        const updatedEmpData = empdata?.map((item) => ({
            ...item,
            leave_balance_details: item.leave_balance_details.filter(
                (emp) => emp.previous_employee_id !== currentData?.previous_employee_id
            ),
        }));

        setEmpData(updatedEmpData); // Update state
        toast.success("Record deleted successfully!");
        setOpenDialog(false);

        UpdateLeaveBalanceStageHelper({
            stage_leave_balance_id: id,
            leave_balance_details: JSON.stringify(
                updatedEmpData?.[0]?.leave_balance_details || []
            ),
        }).then((res) => {
        });
    };
    const handleEditSubmit = (updatedRecord) => {
        const validationResult = validateDynamicFields(updatedRecord);
        if (!validationResult.isValid) {
            setEditErrors(validationResult.errors);
            return;
        }

        const updatedEmpData = empdata?.map((item) => ({
            ...item,
            leave_balance_details: item.leave_balance_details.map((emp) =>
                emp.previous_employee_id === updatedRecord.previous_employee_id
                    ? updatedRecord
                    : emp
            ),
        }));

        setEmpData(updatedEmpData); // Update state
        UpdateLeaveBalanceStageHelper({
            stage_leave_balance_id: id,
            leave_balance_details: JSON.stringify(
                updatedEmpData?.[0]?.leave_balance_details || []
            ),
        }).then((res) => {
            toast.success("Record updated successfully!");
        });

        setOpenPopup(false); // Close popup
    };
    const validateDynamicFields = (record) => {
        const errors = {};
        Object.entries(record).forEach(([key, value]) => {
            if (!value) {
                errors[key] = `${key.replace(/_/g, " ")} is required.`;
            }
        });
        return {
            isValid: Object.keys(errors).length === 0,
            errors,
        };
    };
    const dynamicColumnName = empdata?.[0]?.leave_balance_details?.[0]
        ? Object.keys(empdata[0].leave_balance_details[0]).find(
            (key) => key !== "previous_employee_id"
        )
        : null;
    const columns = [
        {
            title: "Prev.Emp ID",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "previous_employee_id",
            grouping: false,
        },
        ...(dynamicColumnName
            ? [
                {
                    title: "Earned Leave",
                    cellStyle: { padding: "10px 10px 10px 13px" },
                    field: dynamicColumnName,
                },
            ]
            : []),
    ];
    const tableData = empdata?.flatMap((item) =>
        item.leave_balance_details?.map((detail) => ({
            ...detail,
        }))
    );
    return (
        <>
            <Box>
                <form
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "16px", // Adjust gap between elements
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {file ? (
                            <p style={{ margin: 0 }}>{file.name.substring(0, 20)}...</p>
                        ) : errors.selectedFile ? (
                            <p style={{ color: "red", margin: 0 }}>No file chosen</p>
                        ) : (
                            <p style={{ margin: 0 }}>No file chosen</p>
                        )}
                    </div>
                    <label htmlFor="contained-button-file" style={{ display: "none" }}>
                        <input
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            id="contained-button-file"
                            type="file"
                            style={{ display: "none" }} // Hidden input
                            multiple={false}
                            onChange={fileSelected} // Handle file selection
                        />
                    </label>
                    <Button
                        variant="contained"
                        className="mpl-primary-btn"
                        component="span"
                        sx={{ marginBottom: "5px" }}
                        onClick={handleSelectFileClick} // Trigger confirmation or file input
                    >
                        Select File
                    </Button>
                    <Button
                        variant="contained"
                        className="mpl-primary-btn"
                        sx={{ marginBottom: "5px", marginRight: "5px" }}
                        disabled={empdata?.length === 0 || empdata === undefined}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </form>
            </Box>

            <MaterialTable
                columns={columns}
                data={tableData || []}
                options={{
                    maxBodyHeight: "65vh",
                    minBodyHeight: "65vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: false,
                    exportFileName: "Employees",
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,
                }}
                actions={[
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                        tooltip: "Edit",
                        onClick: (_event, rowData) => { handleEdit(rowData) },
                    },
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                        tooltip: "Delete",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                ]}
                title="Employee Bulk Upload"
            />
            <Popup
                maxWidth="sm"
                fullWidth={true}
                title="Pls map the column headers from your sheet & Meepl Fields"
                openPopup={file}
                close={false}
                setOpenPopup={setOpenBulk}
            >
                <Box>
                    <Box
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            alignItems: "center",
                            padding: 5,
                            borderBottom: "1px solid gray",
                        }}
                    >
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            Imported Headers
                        </Typography>
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            Meepl Fields
                        </Typography>
                    </Box>
                    {excelDbColMapping.map((col) => (
                        <Grid
                            key={col.db}
                            container
                            spacing={2}
                            style={{
                                padding: 5,
                                alignItems: "center",
                            }}
                        >
                            <Grid sx={{ mt: 2 }} item xs={6}>
                                <Select
                                    onChange={(e) => setColMapping(e.target.value, col.db)}
                                    value={col.excel}
                                    fullWidth
                                    required={col.required}
                                    variant="standard"
                                >
                                    {rowHeaders.map((header) => (
                                        <MenuItem key={header} value={header}>
                                            {header}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid sx={{ mt: 2 }} item xs={6}>
                                <Typography>
                                    {col.label || col.db}
                                    {col.required && " *"}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", m: 2 }}>
                    <Button
                        onClick={() => {
                            setFile(null);
                            setRowHeaders([]);
                            setOpenBulk(false);
                        }}
                        className="mpl-primary-btn"
                        disabled={uploadProgress > 0 && uploadProgress < 100}
                    >
                        Close
                    </Button>

                    <div>
                        {uploadProgress > 0 && uploadProgress < 100 ? (
                            <CircularProgress disableShrink />
                        ) : (
                            <Button
                                variant="contained"
                                type="submit"
                                className="mpl-primary-btn"
                                loading={uploadProgress > 0 && uploadProgress < 100}
                                onClick={addTeamBalanceStage}
                            >
                                Upload
                            </Button>
                        )}
                    </div>

                </Box>
            </Popup>
            <Popup
                maxWidth="xs"
                fullWidth={true}
                title="Attention Needed!"
                openPopup={showConfirmPopup}
                close={false}
                setOpenPopup={setShowConfirmPopup}
            >
                <Typography variant="h5"> You will lose the imported data. Do you want to submit it before proceeding? </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                    <Button
                        onClick={handleCancel}
                        className="mpl-primary-btn"
                    >
                        No
                    </Button>

                    <Button
                        onClick={() => {
                            setShowConfirmPopup(false); // Close popup and retain existing data
                        }}
                        className="mpl-primary-btn"
                    >
                        Yes
                    </Button>

                </Box>
            </Popup>
            <Popup
                title={"Edit"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                fullWidth={true}
                maxWidth="sm"
            >
                <form>
                    {Object.entries(editedRecord || {})
                        .filter(([key]) => key !== "tableData") // Exclude "tableData" from fields
                        .map(([key, value]) => (
                            <div key={key} style={{ marginBottom: "15px" }}>
                                <TextField
                                    label={key === "previous_employee_id" ? "Prev.Emp ID" : "Earned Leave"}  // Format label text
                                    variant="outlined"
                                    fullWidth
                                    type={key === "previous_employee_id" ? "text" : "number"} // Conditionally set type
                                    value={value || ""}
                                    onChange={(e) =>
                                        setEditedRecord((prev) => ({
                                            ...prev,
                                            [key]: key === "previous_employee_id"
                                                ? e.target.value // Keep as string
                                                : parseFloat(e.target.value) || "", // Convert to number
                                        }))
                                    }
                                    error={!!editerrors[key]} // Show error state
                                    helperText={editerrors[key] || ""} // Show error message
                                />
                            </div>
                        ))}
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                        <Button
                            className="mpl-primary-btn"
                            onClick={() => setOpenPopup(false)}
                            sx={{ mr: 2 }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => handleEditSubmit(editedRecord)} className="mpl-primary-btn">
                            Update
                        </Button>
                    </Box>
                </form>
            </Popup>
            <ConfirmationDialog
                title="Do you wish to proceed?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                OkButtonText="Delete"
                onSubmit={handleDelete}
            />
        </>
    );
};

export default TeamBalanceBulkUpload;