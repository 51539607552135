import * as ActionTypes from '../ActionTypes';
export const UploadResumeAction = (params,dispatch) => {
    return {
        type: ActionTypes.ATS_RESUME_UPLOAD_REQUEST,
        params, dispatch
    }
}
export const UploadProfilePicAction = (params,dispatch) => {
    return {
        type: ActionTypes.EMP_PROFILE_PIC_UPLOAD_REQUEST,
        params, dispatch
    }
}