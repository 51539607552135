import { Grid, Paper, Box } from '@mui/material';
import React from 'react';
import BadgeIcon from "@mui/icons-material/Badge";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import WorkIcon from "@mui/icons-material/Work";
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { AddMenuHeadingAction } from '../../../../store/Menu/Action';
import history from '../../../../history';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../Base/helper/baseFunctions';

const QuickLinks = ({ quicklinksData }) => {
  const userData = getUserData();
  const dispatch = useDispatch();
  const ShowCoreHr = quicklinksData?.[0]?.corehr?.[0];
  const ShowLms = quicklinksData?.[0]?.lms?.[0];
  const ShowAts = quicklinksData?.[0]?.ats?.[0];
  const areAllFalse = obj => obj && Object.values(obj)?.every(value => value === false);

  const coreHr = [
    { name: "It's Me", icon: <BadgeIcon />, color: '#1769ff', show: ShowCoreHr?.['Its Me'], route: '/employee', },
    { name: 'Employee', icon: <AssignmentIndIcon />, color: '#ea4c89', show: ShowCoreHr?.['My Employee'], route: '/myemployee' },
    { name: 'Employee Access Control', icon: <ManageAccountsIcon />, color: '#c32aa3', show: ShowCoreHr?.['My Employee'], route: '/myemployee-access' },
  ];

  const lms = [
    { name: 'My Timesheet', icon: <PendingActionsIcon />, color: '#1769ff', show: ShowLms?.['My-Timesheet'], route: '/lms/manage-timesheet' },
    { name: 'Team', icon: <Diversity3Icon />, color: '#ea4c89', show: ShowLms?.['Team'], route: '/lms/manage-team' },
  ];

  const ats = [

    { name: 'Job dashboard', icon: <WorkIcon />, color: '#ea4c89', show: ShowAts?.['ATS Job dashboard'], route: '/ats/manage-job', menu: ' Job dashboard' },
    { name: 'Add Job', icon: <HomeRepairServiceIcon />, color: '#c32aa3', show: ShowAts?.['ATS Add Job'], route: '/ats/add-job/', menu: 'Add Job' },
    { name: 'Schedule Interview', icon: <CalendarMonthIcon />, color: '#1769ff', show: ShowAts?.['ATS Schedule Interview'], route: '/ats/dashboard', menu: 'ATS Dashboard' },
    { name: 'Reports', icon: <AssessmentIcon />, color: '#c32aa3', show: ShowAts?.['ATS Reports'], route: '/ats/reports/', menu: 'Operational Reports' },
    { name: 'ATS Settings', icon: <ContentPasteSearchIcon />, color: '#c32aa3', show: ShowAts?.['ATS Settings'], route: '/ats/ats-settings/', menu: 'ATS' },
    { name: 'Form Builder', icon: <DynamicFeedIcon />, color: '#c32aa3', show: ShowAts?.['ATS Feedback'], route: '/ats/ats-settings/', menu: 'ATS' },
  ];
  const handleMenuClick = (menu, route, name) => {
    sessionStorage.setItem("selectedMenu", menu);
    dispatch(AddMenuHeadingAction({ heading: menu }));
    if (route === '/ats/add-job/') {
      history.push({ pathname: route, state: { from: 'dashboard' } });
    }
    else if (name === 'Form Builder') {
      history.push({ pathname: route, state: { defaultValue: '4' } });
    }
    else {
      history.push(route);
    }
  }
  return (
    <Grid container justifyContent="center" alignItems="center">
      {!areAllFalse(ShowCoreHr) && (
        <Grid item xs={12} sm={12} md={12} lg={12} pb={2}>
          <Paper elevation={3} className="folder">
            <div className="folder-title">CORE HR</div>
            <Grid container spacing={2}>
              {coreHr?.map((app, index) => (
                app.show && (
                  <Grid item xs={6} sm={4} md={4} key={index}>
                    <Box className="quick-app" onClick={() => handleMenuClick(app.name, app.route)}>
                      <div className="quick-app-icon">{app.icon}</div>
                      <div className="quick-app-name">{app.name}</div>
                    </Box>
                  </Grid>
                )
              ))}
            </Grid>
          </Paper>
        </Grid>
      )}
      {!areAllFalse(ShowAts) && (
        <Grid item xs={12} sm={12} md={12} lg={12} pb={2}>
          <Paper elevation={3} className="folder">
            <div className="folder-title">ATS</div>
            <Grid container spacing={2}>
              {ats?.map((app, index) => (
                app.show && (
                  <Grid item xs={6} sm={4} md={4} key={index}>
                    <Box className="quick-app" onClick={() => handleMenuClick(app.menu, app.route, app.name,)}>
                      <div className="quick-app-icon">{app.icon}</div>
                      <div className="quick-app-name">{app.name}</div>
                    </Box>
                  </Grid>
                )
              ))}
            </Grid>
          </Paper>
        </Grid>
      )}
      {userData?.f_country_code === "IND" && !areAllFalse(ShowLms) && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={3} className="folder">
            <div className="folder-title">MY TIME</div>
            <Grid container spacing={2}>
              {lms?.map((app, index) => (
                app.show && (
                  <Grid item xs={6} sm={4} md={4} key={index}>
                    <Box className="quick-app" onClick={() => handleMenuClick(app.name, app.route)}>
                      <div className="quick-app-icon">{app.icon}</div>
                      <div className="quick-app-name">{app.name}</div>
                    </Box>
                  </Grid>
                )
              ))}
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

export default QuickLinks;
