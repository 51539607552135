import { useState, useEffect } from "react";
import Popup from "../../../Base/views/Popup"
import FamilyModalForm from "./FamilyModalForm";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog"
import { useDispatch, useSelector } from "react-redux";
import { DeleteEmployeeFamilyAction, GetEmployeeFamilyAction } from "../../../../../store/Employee/Family/Action";
import { Box, Button, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MaterialTable from "material-table"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ConvertDate, getUserData } from "../../../Base/helper/baseFunctions";
import history from "../../../../../history";
import { UpdateEmployeeOnboardStatusHelper } from "../../../Ancillary/ConfigureStatus/helper/ConfigureStatusHelper";
import Success from "../../../MyEmployee/Image/check.png";
import { AddMenuHeadingAction } from "../../../../../store/Menu/Action";
import { toast } from "react-toastify";
import { EmployeeValidateHelper } from "../../helper/EmployeeHelper";

export default function FamilyPage({ setCurrentMode, OnboardingEmpData, disableEdit, newdata, currentMode,setActiveStep, OnboardEmployee }) {
    const userData = getUserData()
    const data = getUserData();

    const dispatch = useDispatch()
    const { familyData } = useSelector(state => state?.EmployeeReducer.EmployeeFamilyReducer);
    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);

    const openMoreVertIcon = Boolean(anchorEl);
    const handleMoreVertIconClose = () => {
        setAnchorEl(null);
    };
    const handleMoreVertIconClick = (event, row) => {
        setCurrentData(row)
        setAnchorEl(event.currentTarget);
    };

    const handleDelete = () => {
        setOpenDialog(false);
        var id = [];
        if (Array.isArray(currentData)) {
            currentData.forEach(item => {
                id.push(item.emp_family_id);
            })
            if(currentMode ==="addemployee"){
                dispatch(DeleteEmployeeFamilyAction({
                    id: `{${id}}`,
                    domain: data?.domain,
                    employee_id: newdata?.employee_id,
                }))
            }
            else{
                dispatch(DeleteEmployeeFamilyAction({
                    id: `{${id}}`,
                    domain: data?.domain,
                    employee_id: OnboardingEmpData ? OnboardingEmpData?.employee_id : data?.employee_id,
                }))
            }
            
        } 
        else if(currentMode ==="addemployee"){
            dispatch(DeleteEmployeeFamilyAction({
                id: `{${currentData?.emp_family_id}}`,
                domain: data?.domain,
                employee_id: newdata?.employee_id,
            }))
        }
        else {
            dispatch(DeleteEmployeeFamilyAction({
                id: `{${currentData?.emp_family_id}}`,
                domain: data?.domain,
                employee_id: OnboardingEmpData ? OnboardingEmpData?.employee_id : data?.employee_id,
            }))
        }
    };
    const handleBulkDelete = (selectedRow) => {
        setCurrentData(selectedRow);
    };
    const NextStepper = () => {     
        if(OnboardEmployee){
            setActiveStep(4)
        }
        else{
            setActiveStep(4)
        }  
    }

    const HandleClose = () => {
        setSuccessPopup(false);
        sessionStorage.setItem("selectedMenu", "Home");
         dispatch(AddMenuHeadingAction({ heading: "Home" }));
        history.push("/home");
      };

    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };

    const handleEdit = (rowData) => {
        setAnchorEl(false);
        if (currentMode ==="editemployee" && OnboardingEmpData) {
            setEditedRecord({ ...rowData, domain: userData?.domain, employee_id: OnboardingEmpData?.employee_id });
        } 
        else if(currentMode ==="addemployee"){
            setEditedRecord({ ...rowData, domain: userData?.domain, employee_id: newdata?.employee_id });
        }
        else {
            setEditedRecord({ ...rowData, domain: userData?.domain, employee_id: userData?.employee_id });
        }
        setOpenPopup(true);
    };

    const handleConfirmDelete = (rowData) => {
        setAnchorEl(false);
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleAdd = () => {
        setEditedRecord(null);
        setOpenPopup(true);
    };
    const BackStepper = () => {   
        if(OnboardEmployee){
            setActiveStep(2)
        }
        else{
            setActiveStep(2)
        }  
       
    }
     const handleComplete = () => {
        EmployeeValidateHelper({}).then((res) => {
          if (res?.data?.statuscode === 200) {
            if (res?.data?.data?.[0]?.fn_get_employee_form_validation) {
              UpdateEmployeeOnboardStatusHelper({}).then((res) => {
                if (res?.data?.statuscode === 200) {
                  setSuccessPopup(true);
                }
              });
            } else {
              toast.error(
                "Please fill all mandatory fields",
                {
                  duration: 2000,
                }
              );
            }
          }
        });
      };
    useEffect(() => {
        if (currentMode ==="editemployee" && OnboardingEmpData) {
            dispatch(GetEmployeeFamilyAction({ domain: userData?.domain, employee_id: OnboardingEmpData?.employee_id }))
        } 
        else if(currentMode ==="addemployee"){
            dispatch(GetEmployeeFamilyAction({ domain: userData?.domain, employee_id: newdata?.employee_id }))
        }
        else {
            dispatch(GetEmployeeFamilyAction({ domain: userData?.domain, employee_id: userData?.employee_id }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addButton = <Button onClick={handleAdd} variant="contained" startIcon={<AddIcon />} className="mpl-primary-btn" >
        Add
    </Button>

    return (
        <Box sx={{ padding: OnboardEmployee ? "0px 0px 20px 25px" : "0px 0px 0px 0px"  }}>
           {!disableEdit && (
  <Grid
    container
    sx={{
      display: "flex",
      justifyContent: currentMode !== undefined ? "space-between" : "flex-end",
      marginRight: "10px",
      marginTop: "10px",
      marginBottom: "20px"
    }}
  >
    <Button
         className="mpl-primary-btn"
        sx={{ marginRight: "10px" }}
        type="submit"
        variant="contained"
        size="medium"
        onClick={BackStepper}
      >
        Back
      </Button>
    {!OnboardEmployee && currentMode !== undefined ? (
      <Button
      className="mpl-primary-btn"
        sx={{ marginRight: "10px" }}
        type="submit"
        variant="contained"
        size="medium"
        onClick={() => history.push("/myemployee")}
      >
        Back to List
      </Button>
    ) : null}
    <Typography variant="h6" sx={{ flexGrow: 1, textAlign: currentMode !== undefined ? "center" : "center" }}>
      Nominee / Family
    </Typography>
    { userData?.f_country_code === "IND" && <Button  className="mpl-primary-btn" sx={{ marginRight: "10px" }} onClick={NextStepper} type="submit" variant="contained" size="medium">
      Next
    </Button>}
    {OnboardEmployee && userData?.f_country_code !== "IND"  ? (
                <Button
                  className="mpl-primary-btn"
                  sx={{ marginRight: "10px" }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={handleComplete}
                >
                  Complete Form
                </Button>
              ) : null}
    </Grid>
    )}

            <MaterialTable
                columns={[
                    { title: "Name", field: "name", cellStyle: { padding: "0px" }, },
                    { title: "Relationship", field: "relationship", cellStyle: { padding: "0px" }, },
                    {
                        title: "Date of Birth", field: "dob", cellStyle: { padding: "0px" },
                        render: rowData => {
                            return rowData?.dob ? ConvertDate(rowData?.dob) : ""
                        }
                    },
                    { title: "Phone", field: "phone", cellStyle: { padding: "0px" }, },
                ]}
                data={familyData}
                onSelectionChange={(selectedRow) => handleBulkDelete(selectedRow)}
                options={{
                    maxBodyHeight: OnboardingEmpData ? "50vh" : "59vh",
                    minBodyHeight: OnboardingEmpData ? "50vh" : "59vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "0px", fontWeight: "bold", paddingRight: "10px" },
                    sorting: true,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: "Family Details",
                    addRowPosition: "first",
                    selection: true,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: true,
                }}
                actions={[
                    {
                        icon: () => <MoreVertIcon />,
                        position: "row",
                        tooltip: "Setting",
                        onClick: (_event, rowData) => handleMoreVertIconClick(_event, rowData),

                    },
                    {
                        icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                        tooltip: "Delete",
                        onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                    },
                ]}
                title={addButton}
            />
            <Popup
                title={editedRecord ? "Edit Nominee" : "Add Nominee"}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <FamilyModalForm newdata={newdata} currentMode={currentMode} data={data} OnboardingEmpData={OnboardingEmpData} editedRecord={editedRecord} addMode={addMode} setOpenPopup={setOpenPopup} />
            </Popup>
            <ConfirmationDialog
                title="Do you wish to proceed?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Family Management"
                OkButtonText="OK"
                onSubmit={handleDelete}
            />
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={openMoreVertIcon}
                onClose={handleMoreVertIconClose}
            >
                <MenuItem key="edit" onClick={() => (handleEdit(currentData))}>
                    <EditIcon sx={{ fontSize: "15px" }} /> &nbsp; <Typography sx={{ fontSize: "15px" }}>Edit</Typography>
                </MenuItem>
                <MenuItem key="delete" onClick={() => (handleConfirmDelete(currentData))}>
                    <DeleteIcon sx={{ fontSize: "15px" }} /> &nbsp; <Typography sx={{ fontSize: "15px" }}>Delete</Typography>
                </MenuItem>
            </Menu>
            <Popup
                openPopup={successPopup}
                setOpenPopup={setSuccessPopup}
                fullWidth={false}
                maxWidth="md"
                close={false}
                hideDividers={true}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
                    <img src={Success} alt="Success" width={"15%"} />
                  </Box>
                  <Typography variant="h6">
                    {" "}
                    Employee onboarded successfully.
                  </Typography>
                  <Typography variant="h6">
                    {" "}
                    For more updates please visit It's Me section.{" "}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                    }}
                  >
                <Button
                  onClick={HandleClose}
                  variant="contained"
                  type="submit"
                  className="mpl-primary-btn profile-btn"
                  size="small"
                  sx={{
                    margin: "10px",
                  }}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </Popup>
        </Box>
    );
}
