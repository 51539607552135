import * as ActionTypes from '../ActionTypes';

const payrollProfileDetailInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: ""
}

export const EmployeePayrollProfileDetailReducer = function (state = payrollProfileDetailInitialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                payrollProfileData: ""
            };
        case ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                payrollProfileData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
        case ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
            };
        case ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                payrollProfileData: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEE_PAYROLL_PROFILE_DETAIL_UPDATE_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }

        default:
            return state;
    }
}