import { A} from "react-html-email";
import { BASE_URL } from "../../../Constants/baseConstant";

const BaseEmailTemplate = ({ children }) => {
    const companyLogo = "https://meepldevstorage.nyc3.digitaloceanspaces.com/fulllogo.PNG"
    return (
        <>
            <table
                cellSpacing="0"
                border="0"
                cellPadding="0"
                width="100% !important"
                style={{
                    margin: "0",
                    padding: "0",
                    backgroundColor: "#f2f3f8",
                    width: "100% !important",
                    height: "100vh",
                }}
            >
                <tr>
                    <td align="center" valign="top" style={{ padding: "20px 0" }}>
                        <table
                            style={{
                                backgroundColor: "#ffffff",
                                maxWidth: "600px",
                                width: "100%",
                                margin: "0 auto",
                                borderRadius: "8px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                            border="0"
                            cellPadding="20"
                            cellSpacing="0"
                        >
                            {companyLogo && (
                                <tr>
                                    <td style={{ textAlign: "center", padding: "0px !important" }}>
                                        <A href={BASE_URL} title="logo" target="_blank">
                                            <img
                                                style={{
                                                    objectFit: "contain",
                                                    width: "150px",
                                                    height: "70px",
                                                }}
                                                src={companyLogo}
                                                title="CompanyLogo"
                                                alt="CompanyLogo"
                                            />
                                        </A>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td style={{ padding: "0px 20px 20px 20px !important" }}>{children}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center", padding: "0px 0px 20px 0px !important" }}>
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "rgba(69, 80, 86, 0.7411764705882353)",
                                            lineHeight: "18px",
                                        }}
                                    >
                                        <strong>powered by Meepl</strong>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

        </>
    )

}

export default BaseEmailTemplate