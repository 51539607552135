import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
    Box,
    TextField,
    FormControl,
    Grid,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from "@mui/material";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { UpdateOTAction, AddOTAction } from "../../../../../store/Finance/PayRollTemplate/Action";
import { GetEarningAction } from "../../../../../store/Finance/Component/Action";

const OverTime = ({ editedRecord, setOpenPopup }) => {
    const dispatch = useDispatch();
    const initialValue = {
        overtime_type: "",
        overtime_policy: "",
        daily_min_eligible_hours: "",
        weekly_min_eligible_hours: "",
        flat_amount: "",
        variable_earning_components: [],
        variable_overtime_rate: ""
    };
    const [overtime, setOverTime] = useState(initialValue);
    const [errors, setErrors] = useState({});
    const [earningData, setEarningData] = useState({
        load: true,
        values: [],
    });
    const { earning_data } = useSelector(
        (state) => state?.FinanceReducer.ComponentReducer
    );
    useEffect(() => {
        if (editedRecord != null) {
            setOverTime({
                ...editedRecord,
            });
        }
        dispatch(GetEarningAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedRecord]);
    const handleEarnings = () => {
        earning_data &&
            setEarningData({ load: false, values: earning_data?.[0]?.fn_get_earning_components });
    };
    const validate = (fieldValues = overtime) => {
        let temp = { ...errors };

        if ("overtime_type" in fieldValues)
            temp.overtime_type = fieldValues.overtime_type ? "" : "Overtime type is required.";

        if ("flat_amount" in fieldValues && overtime.overtime_type === "Flat")
            temp.flat_amount = fieldValues.flat_amount ? "" : "Flat amount is required.";

        if ("overtime_policy" in fieldValues && (overtime?.overtime_type === "Flat" || overtime?.overtime_type === "Variable"))
            temp.overtime_policy = fieldValues.overtime_policy ? "" : "OT Policy is required.";

        if ("variable_overtime_rate" in fieldValues && overtime.overtime_type === "Variable")
            temp.variable_overtime_rate = fieldValues.variable_overtime_rate
                ? ""
                : "Overtime rate is required.";
        if ("variable_earning_components" in fieldValues && overtime.overtime_type === "Variable")
            temp.variable_earning_components =
                fieldValues.variable_earning_components?.length === 0
                    ? "Earning is required"
                    : fieldValues.variable_earning_components === null
                        ? "Earning is required"
                        : "";

        setErrors({ ...temp });

        return Object.values(temp).every((x) => x === "");
    };
    const handleInput = (event, value) => {

        if (["variable_earning_components"].includes(event)) {
            if (value) {
                setOverTime({ ...overtime, [event]: value });
            } else {
                setOverTime({ ...overtime, [event]: "" });
            }
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
                validate({ ...overtime, [event]: value ? value : "" });
        }
        else if (event.target.name === "overtime_type") {
            setOverTime({
                ...initialValue,
                [event.target.name]: event.target.value,
            });
        }
        else if (event.target.name === "overtime_policy") {
            setOverTime({
                ...overtime,
                [event.target.name]: event.target.value,
            });
        }
        else {
            setOverTime({
                ...overtime,
                [event.target.name]: event.target.value,
            });
            Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
                validate({
                    ...overtime,
                    [event.target.name]: event.target.value,
                })
        }

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            const transformedVariableEarningComponents = JSON.stringify(
                overtime?.variable_earning_components?.map((item) => ({
                    name: item.name,
                    label: item.label,
                }))
            );
            if (editedRecord?.overtime_configuration_pk) {
                dispatch(UpdateOTAction({
                    ...overtime,
                    variable_earning_components: overtime?.variable_earning_components?.length >= 1 ? transformedVariableEarningComponents || null : null,
                    overtime_configuration_id: editedRecord?.overtime_configuration_pk
                }));
            }
            else {
                dispatch(
                    AddOTAction({
                        ...overtime,
                        variable_earning_components: overtime?.variable_earning_components?.length >= 1 ? transformedVariableEarningComponents || null : null,
                    })
                );
            }
            setOpenPopup(false);
        }
    };

    return (
        <Box>
            <Box sx={{ m: 1 }}>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }} container columnSpacing={1} mb={1}>
                    <FormControl
                        sx={{ width: "50%" }}
                        error={errors?.overtime_type ? true : false}
                        size="small"
                    >
                        <InputLabel id="recruiter-type-select-label">
                            Over Time Type *
                        </InputLabel>
                        <Select
                            name="overtime_type"
                            labelId="recruiter-type-select-label"
                            id="recruiter-type-select"
                            value={overtime?.overtime_type}
                            label="Over Time Type"
                            onChange={handleInput}
                        >
                            <MenuItem value={"Flat"}>Flat</MenuItem>
                            <MenuItem value={"Variable"}>Variable</MenuItem>
                        </Select>
                        <FormHelperText>
                            {errors?.overtime_type}
                        </FormHelperText>
                    </FormControl>
                    <Box>
                        <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            onClick={handleSubmit}
                            className="mpl-primary-btn"
                        >
                            {editedRecord ? "Update" : "Save"}
                        </Button>
                    </Box>

                </Grid>
            </Box>
            {["Flat"].includes(overtime?.overtime_type) && (
                <Box>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }} container columnSpacing={1} mb={1}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                marginTop: "1rem",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    required
                                    type="number"
                                    value={overtime?.flat_amount}
                                    onChange={handleInput}
                                    variant={"outlined"}
                                    fullWidth
                                    id="flat_amount"
                                    label="Amount/hr"
                                    name="flat_amount"
                                    autoComplete="flat_amount"
                                    {...(errors.flat_amount && {
                                        error: true,
                                        helperText: errors.flat_amount,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                marginTop: "1rem",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            <FormControl
                                fullWidth
                                error={errors?.overtime_policy ? true : false}
                                size="small"
                            >
                                <InputLabel id="recruiter-type-select-label">
                                    Over Time Policy *
                                </InputLabel>
                                <Select
                                    name="overtime_policy"
                                    labelId="recruiter-type-select-label"
                                    id="recruiter-type-select"
                                    value={overtime?.overtime_policy}
                                    label="Over Time Type"
                                    onChange={handleInput}
                                >
                                    <MenuItem value={"Daily"}>Daily</MenuItem>
                                    <MenuItem value={"Weekly"}>Weekly</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {errors?.overtime_policy}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                       
                    </Grid>

                </Box>
            )}
            {/* Variable Section */}
            {["Variable"].includes(overtime?.overtime_type) && (
                <Box>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }} container columnSpacing={1} mb={1}>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{
                                marginTop: "1rem",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            <FormControl fullWidth>
                                <SelectableSearch
                                    isOptionEqualToValue={(option, value) =>
                                        option.label === value.label
                                    }
                                    multiple={true}
                                    label="Select an Earnings"
                                    fullWidth
                                    value={overtime?.variable_earning_components}
                                    loading={() => handleEarnings()}
                                    fieldLabel="label"
                                    required={true}
                                    variantType={"outlined"}
                                    name="variable_earning_components"
                                    size="small"
                                    onChangeEvent={handleInput}
                                    data={earningData}
                                    {...(errors.variable_earning_components && {
                                        error: true,
                                        helperText: errors.variable_earning_components,
                                    })}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{
                                marginTop: "1rem",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            <FormControl
                                fullWidth
                                error={errors?.overtime_policy ? true : false}
                                size="small"
                            >
                                <InputLabel id="recruiter-type-select-label">
                                    Over Time Policy *
                                </InputLabel>
                                <Select
                                    name="overtime_policy"
                                    labelId="recruiter-type-select-label"
                                    id="recruiter-type-select"
                                    value={overtime?.overtime_policy}
                                    label="Over Time Type"
                                    onChange={handleInput}
                                >
                                    <MenuItem value={"Daily"}>Daily</MenuItem>
                                    <MenuItem value={"Weekly"}>Weekly</MenuItem>
                                </Select>
                                <FormHelperText>
                                    {errors?.overtime_policy}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{
                                marginTop: "1rem",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    required
                                    type="number"
                                    value={overtime?.variable_overtime_rate}
                                    onChange={handleInput}
                                    variant={"outlined"}
                                    fullWidth
                                    id="variable_overtime_rate"
                                    label="Over Time Rate"
                                    name="variable_overtime_rate"
                                    autoComplete="variable_overtime_rate"
                                    {...(errors.variable_overtime_rate && {
                                        error: true,
                                        helperText: errors.variable_overtime_rate,
                                    })}
                                />
                            </FormControl>

                        </Grid>
                    </Grid>

                </Box>
            )}
        </Box>
    );
};
export default OverTime;
