import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import { sendLeaveCancelEmail, sendLeaveEmail } from "./EmailHelper";
import { v4 as uuidv4 } from 'uuid';

// Leave Request
export const GetLeaveRequestHelper = async (params) => {
    var data = getUserData();
    params?.show !== "no" && toast.dismiss();
    params?.show !== "no" && toast.info("Loading...")
    return await axiosInstance(`/lms/getLeaveRequests`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            employee_id: data.employee_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Leave Request Retrieved Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Unable to fetch Leave Request", hasError: false, data: err?.response?.data }
        })
}

export const AddLeaveRequestHelper = async (leaveRequestDetails) => {
    var data = getUserData();
    var guid = uuidv4();
    var url = "/leave-4";
    toast.info("Saving...")
    return await axiosInstance(`/lms/addLeaveRequest`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.emp_users_pk,
            employee_id: leaveRequestDetails?.employee_id,
            leavetype_id: leaveRequestDetails.leavetype_id.leavetype_fk,
            year: leaveRequestDetails.year,
            requested_days: leaveRequestDetails.reqDays,
            from_date: leaveRequestDetails.from_date,
            to_date: leaveRequestDetails.to_date,
            is_from_half: leaveRequestDetails.is_from_half,
            is_to_half: leaveRequestDetails.is_to_half,
            status: leaveRequestDetails.status,
            note: leaveRequestDetails.note || '',
            leave_proof: leaveRequestDetails.leave_proof || '',
            leave_proof_name: leaveRequestDetails.leave_proof_name || null,
            lta_availed: leaveRequestDetails.lta_availed || false,
            is_from_half_value: leaveRequestDetails.is_from_half_value,
            is_to_half_value: leaveRequestDetails.is_to_half_value,
            guid: guid,
            mail_url: url,
            leave_reason: leaveRequestDetails.leave_reason?.leave_reason_id || "",
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Request Added Successfully");
            sendLeaveEmail({ ...leaveRequestDetails, guid: guid, dashboardData: leaveRequestDetails.dashboard })
            return { message: "Leave Request Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to add Leave Request", hasError: false, data: err?.response?.data }
        })
}

export const UpdateLeaveRequesteHelper = async (leaveRequestDetail) => {
    var data = getUserData();
    toast.info("Updating...")
    return await axiosInstance(`/lms/updateLeaveRequest`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.employee_id,
            employee_id: leaveRequestDetail.employee_id.employee_id,
            leaverequest_id: leaveRequestDetail.leaverequest_id,
            leavetype_id: leaveRequestDetail.leavetype_id,
            year: leaveRequestDetail.year,
            requested_days: leaveRequestDetail.requested_days,
            from_date: leaveRequestDetail.from_date,
            to_date: leaveRequestDetail.to_date,
            from_half: leaveRequestDetail.from_half,
            to_half: leaveRequestDetail.to_half,
            status: leaveRequestDetail.status,
            note: leaveRequestDetail.note,
            leave_proof: leaveRequestDetail.leave_proof,
            lta_availed: leaveRequestDetail.lta_availed || false,
            leave_reason: leaveRequestDetail.leave_reason || "",
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Request Updated Successfully");
            return { message: "Leave Request Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to update", hasError: false, data: err?.response?.data }
        })
}

export const DeleteLeaveRequestHelper = async (leaveRequestDetail) => {
    var data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/lms/deleteLeaveRequest`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            user_emp_id: data.emp_users_pk,
            employee_id: leaveRequestDetail?.employee_fk,
            leaverequest_ids: leaveRequestDetail?.uuid,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Request Deleted Successfully");
            sendLeaveCancelEmail({...leaveRequestDetail, flag :"Leave Cancel"});
            return { message: "Leave Request Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed to delete", hasError: false, data: err?.response?.data }
        })
}
