import { renderEmail } from "react-html-email";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";
import { ApprovedContent, CancelledContent, CancelRequestContent, LeaveCancelContent, RejectedContent, RequestContent } from "../views/ManageLeave/ApplyLeave/EmailTemplate";
import { getSessionData } from "../../Base/helper/baseHelper";

const componenet = {
    "Submitted": RequestContent,
    "Cancel Requested": CancelRequestContent,
    "Approved": ApprovedContent,
    "Rejected": RejectedContent,
    "Cancelled": CancelledContent,
    "Leave Cancel": LeaveCancelContent
}

// Email
export const sendLeaveEmail = async (params) => {
    const data = getUserData();
    const region = getSessionData({ key: "region" });
    var Component = componenet[params?.status]
    var userName = ["Submitted", "Cancel Requested"].includes(params?.status) ? data?.name : params?.employee
    return await axiosInstance(`/ancillary/lms/sendEmail`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            subject: `Action Required! ${userName} has requested for Leave Approval.`,
            user_email: ["Submitted", "Cancel Requested"].includes(params?.status) ? params?.dashboardData?.Email : params?.office_email,
            email_content: renderEmail(<BaseEmailTemplate>
                <Component
                    userName={userName}
                    managerName={params?.dashboardData?.Name }
                    fromDate={params?.from_date}
                    toDate={params?.to_date}
                    leaveType={params?.leavetype_id?.leavetype_name}
                    guid={params?.guid}
                    reqDays={params?.reqDays}
                    region={region}
                />
            </BaseEmailTemplate>)
        }
    })
        .then((res) => {
            return { message: "Email Sent Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to Send Email", hasError: false, data: err?.response?.data }
        })

}

// Manager Approval Email trigger
export const sendLeaveApprovalEmail = async (params) => {
    const data = getUserData();
    var Component = componenet[params?.flag];
    var employee = params?.dashboardData?.name;
    const managerName = data?.name;
    return await axiosInstance(`/ancillary/lms/sendEmail`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            subject: params?.flag === "Rejected" ? `Your Leave Request Has Been Rejected`: params?.flag === "Approved" ? `Your Leave Request Has Been Approved` : "",
            user_email: params?.dashboardData?.employee_email,
            email_content: renderEmail(<BaseEmailTemplate>
                <Component
                    userName={employee}
                    managerName={managerName}
                    fromDate={params?.dashboardData?.start}
                    toDate={params?.dashboardData?.end}
                    leaveType={params?.dashboardData?.Leavetype}
                    reqDays={params?.dashboardData?.leave_duration}
                />
            </BaseEmailTemplate>)
        }
    })
        .then((res) => {
            return { message: "Email Sent Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to Send Email", hasError: false, data: err?.response?.data }
        })

}

// Employee Cancle Email trigger to manager and cc-employee
export const sendLeaveCancelEmail = async (params) => {
    const data = getUserData();
    var Component = componenet[params?.flag]
    var employee = params?.employee ;
    const managerName = params?.manager_name;
    return await axiosInstance(`/ancillary/lms/sendEmail`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            subject: "Leave Request Cancellation",
            user_email: params?.manager_email,
            cc_email: params?.employee_mail,
            email_content: renderEmail(<BaseEmailTemplate>
                <Component
                    userName={employee}
                    managerName={managerName}
                    fromDate={params?.end_date}
                    toDate={params?.start_date}
                    leaveType={params?.leavetype}
                    reqDays={params?.effective_days}
                />
            </BaseEmailTemplate>)
        }
    })
        .then((res) => {
            return { message: "Email Sent Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to Send Email", hasError: false, data: err?.response?.data }
        })

}
