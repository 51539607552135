import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_LMS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";


export const GetLeaveTypesHelper = async () => {
    var data = getUserData();
    return await axiosInstance(`/ancillary/lms/getLeaveTypes`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then((res) => {
            toast.dismiss();
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const AddLeaveTypesHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/addLeaveType`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            name: params?.name,
            code: params?.code,
            actual_days: params?.actual_days,
            req_second_approval: params?.req_second_approval || false,
            colour_code: params?.colour_code,
            cfwd_leave: params?.cfwd_leave || false,
            noof_cfwd_days: params?.noof_cfwd_days || 0,
            gender: params?.gender?.name || "",
            gender_based: params?.gender_based,
            is_unlimited: params?.is_unlimited || false
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Type Added Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateLeaveTypesHelper = async (params) => {
    var data = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/lms/updateLeaveType`, {
        method: "PUT",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data?.employee_id,
            leavetype_id: params?.leavetype_id,
            name: params?.name,
            code: params?.code,
            actual_days: params?.actual_days,
            req_second_approval: params?.req_second_approval || false,
            colour_code: params?.colour_code,
            cfwd_leave: params?.cfwd_leave || false,
            noof_cfwd_days: params?.noof_cfwd_days || 0,
            gender: params?.gender?.name || "",
            gender_based: params?.gender_based,
            is_unlimited: params?.is_unlimited || false
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Type Updated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteLeaveTypesHelper = async (params) => {
    var data = getUserData();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/lms/deleteLeaveType`, {
        method: "DELETE",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data?.domain,
            leavetype_id: params?.leavetype_id,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Leave Type Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}