import { Box, Button, IconButton, Typography, Grid } from '@mui/material';
import MaterialTable from 'material-table';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Popup from "../../../Base/views/Popup"
import { useState } from 'react';
import ConfirmationDialog from '../../../Base/views/ConfirmationDialog';
import { DeleteOTAction } from '../../../../../store/Finance/PayRollTemplate/Action';
import OverTime from './OverTime';
import { useTheme } from "@mui/material/styles";

const OverTimeGrid = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { ot_data } = useSelector(state => state.FinanceReducer?.PayrollTemplateReducer);
    const [editedRecord, setEditedRecord] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };

    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };

    const handleAdd = () => {
        setEditedRecord()
        setOpenPopup(true);
    };

    const handleDelete = () => {
        setOpenDialog(false);
        dispatch(DeleteOTAction({ overtime_configuration_id: currentData?.overtime_configuration_pk }))
    };

    const addMode = (resetForm) => {
        resetForm();
        setEditedRecord(null);
        setOpenPopup(false);
    };

    const addButton = ot_data?.length < 1 && (<Button onClick={handleAdd} variant="contained" className="mpl-primary-btn" startIcon={<AddIcon />} >
        Add
    </Button>);

    return (
        <Grid container spacing={2} style={{ padding: "10px 20px" }}>
            <Grid item xs={12} md={12}>
                <Box>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            paddingLeft: "15px",
                            color: theme.palette.primary.contrastText,
                        }}
                    >
                        {"Over Time"}
                    </Typography>
                    <MaterialTable
                        columns={[
                            { title: "Over Time Type", field: "overtime_type", cellStyle: { padding: "10px 10px 10px 10px" } },
                            { title: "Over Time Policy", field: "overtime_policy", cellStyle: { padding: "10px 10px 10px 10px" } },
                        ]}
                        data={ot_data}
                        options={{
                            maxBodyHeight: 220,
                            rowStyle: { fontSize: "13px" },
                            headerStyle: { padding: "5px 10px", fontWeight: "bold" },
                            sorting: true,
                            search: false,
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: false,
                            paging: false,
                            selection: false,
                            actionsColumnIndex: -1,
                            grouping: false,
                            addRowPosition: "first",
                        }}
                        onSelectionChange={(rows) => { return (rows.filter(row => row?.status === "Submitted")) }}
                        actions={[
                            {
                                icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                                tooltip: "Edit",
                                onClick: (_event, rowData) => { handleEdit(rowData) },
                            },
                            {
                                icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                                tooltip: "Delete",
                                onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                            },
                        ]}
                        title={addButton}
                        components={{
                            Toolbar: props => (
                                <div style={{
                                    minHeight: "10px",
                                    padding: "10px 25px"
                                }}>
                                    {props.title}
                                </div>
                            )
                        }}
                    />
                    <Popup
                        fullWidth={true}
                        maxWidth="md"
                        title={editedRecord ? "Edit" : "Add"}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}

                    >
                        <OverTime editedRecord={editedRecord} addMode={addMode} setOpenPopup={setOpenPopup} />
                    </Popup>
                    <ConfirmationDialog
                        title="Do you wish to proceed?"
                        openDialog={openDialog}
                        closeDialog={setOpenDialog}
                        popupTitle="Country Management"
                        OkButtonText="Delete"
                        onSubmit={handleDelete}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default OverTimeGrid;
