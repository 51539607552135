import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Grid,
    FormControl,
    CircularProgress
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { ConvertDateTime, s3Client } from "../../../Base/helper/baseFunctions";
import NewPaySlip from "../Employee/NewPaySlip";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import { GetEmployeePaySlipHelper } from "../../helper/PaySlipHelper";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import Popup from "../../../Base/views/Popup";
import PaySlipEmailPopup from "./PaySlipEmailPopup";
import { GetCompanyLogoHelper } from "../../../Ancillary/CompanyDetails/helper/CompanyDetailsHelper";

const PaySlipGrid = () => {
    const dispatch = useDispatch();
    const date = new Date();
    const [filters, setFilters] = useState({
        month_year: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    });
    const selectedDate = new Date(filters.month_year);
    const [name, setName] = useState(selectedDate.toLocaleString('default', { month: 'short', year: 'numeric' }));
    const [openPopup, setOpenPopup] = useState(false);
    const [currentData, setCurrentData] = useState("");
    const [loadingRowId, setLoadingRowId] = useState(null);
    const [loadingEmailRowId, setLoadingEmailRowId] = useState(null);
    const [payData, setPayData] = useState("");
    const { employees_options } = useSelector(
        (state) => state?.EmployeeReducer.EmployeesReducer
    );
    const [imageUrl, setImageUrl] = useState("");
    useEffect(() => {
        dispatch(GetEmployeesAction({ is_active: true }));
        GetCompanyLogoHelper().then((res) => {
            res?.data?.data?.[0]?.companylogo && getImageSignedUrl(res?.data?.data?.[0]?.companylogo);
        })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const handleFilter = (event) => {
        if (["month_year"].includes(event?.target?.name)) {
            const updatedFilters = { ...filters, [event?.target?.name]: event?.target?.value };
            setFilters(updatedFilters);
    
            // Extract and set the new name
            const selectedDate = new Date(updatedFilters.month_year);
            const formattedName = selectedDate.toLocaleString("default", {
                month: "short",
                year: "numeric",
            });
            setName(formattedName);
        }
    };
    
    const handleSendEmail = async (rowData) => {
        setLoadingEmailRowId(rowData.employee_id); // Show loader for this row
        const selectedDate = new Date(filters.month_year);
        setName(selectedDate.toLocaleString('default', { month: 'short', year: 'numeric' }));
        setCurrentData(rowData);
        const [f_year, month] = filters.month_year.split("-");
        const f_month = month.padStart(2, "0");
        try {
            const res = await GetEmployeePaySlipHelper({
                employee_id: rowData?.employee_id,
                month: f_month,
                year: f_year,
            });
            if (res?.data?.data?.[0]) {
                setPayData(res?.data?.data?.[0]);
                setOpenPopup(true);
            }
        } catch (error) {
            console.error("Error sending email:", error);
        } finally {
            setLoadingEmailRowId(null); // Hide loader
            
        }
    };
    const handleDownloadPDF = async (rowData) => {
        setLoadingRowId(rowData.employee_id);
        const selectedDate = new Date(filters.month_year);
        setName(selectedDate.toLocaleString('default', { month: 'short', year: 'numeric' }));
        const [f_year, month] = filters.month_year.split("-");
        const f_month = month.padStart(2, "0");
        try {
            const res = await GetEmployeePaySlipHelper({
                employee_id: rowData?.employee_id,
                month: f_month,
                year: f_year
            });

            if (res?.data?.data?.[0]) {
                const MyDocument = <NewPaySlip data={res?.data?.data?.[0]} imageUrl={imageUrl} />;
                const blob = await pdf(MyDocument).toBlob();
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${rowData?.employee_no} - ${name}.pdf`;
                a.click();
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error("Error generating the PDF:", error);
        } finally {
            setLoadingRowId(null);
        }
    };
    const getImageSignedUrl = async (currentData) => {
        let filename = currentData;
        const bucketParams = {
            Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
            Key: filename,
        };
        const expiresIn = 604800;
        try {
            const url = await getSignedUrl(
                s3Client,
                new GetObjectCommand(bucketParams),
                { expiresIn }
            ); // Adjustable expiration.
            setImageUrl(url); // This will update the imageUrl state
            return url;
        } catch (err) {
            console.error("Error fetching image URL", err);
        }
    };
    return (
        <>
            <Box sx={{ maxHeight: "89vh", overflowY: "auto" }}>
                <Box sx={{ m: 1 }}>
                    <Grid sx={{ display: "flex", justifyContent: "space-between" }} container columnSpacing={1} mb={1}>
                        <FormControl>
                            <CommonDatePicker
                                name="month_year"
                                openTo="month"
                                views={['year', 'month']}
                                label="Year and Month"
                                pickerType="date"
                                size="small"
                                onKeyDown={(e) => e.preventDefault()}
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="MM/yyyy"
                                DefaultDTvalue={filters.month_year}
                                maxDate={new Date()}
                                handleChange={(value) =>
                                    handleFilter({
                                        target: {
                                            name: "month_year",
                                            value: value
                                                ? `${value?.getFullYear()}-${("0" + (value?.getMonth() + 1)).slice(-2)}-${("0" + value?.getDate()).slice(-2)}`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                </Box>

                <MaterialTable
                    columns={[
                        {
                            title: "Name",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "name",
                            grouping: false,
                        },
                        {
                            title: "Email",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "office_email",
                            grouping: false,
                        },
                        {
                            title: "Joining Date",
                            cellStyle: { padding: "10px 10px 10px 13px" },
                            field: "doj",
                            render: (rowData) => {
                                return ConvertDateTime(rowData.doj);
                            },
                        },
                        {
                            title: "Payroll Setup", field: "payroll_setup_flag", headerStyle: { textAlign: "left" }, cellStyle: { padding: "10px 10px 10px 35px", textAlign: "left" },
                            render: rowData => {
                                return rowData.payroll_setup_flag ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                    : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                            }
                        },
                        {
                            title: "Download PaySlip", field: "payroll_setup_flag", headerStyle: { textAlign: "left" }, cellStyle: { padding: "10px 10px 10px 45px", textAlign: "left" },
                            render: (rowData) => {
                                return loadingRowId === rowData.employee_id ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <CloudDownloadOutlinedIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDownloadPDF(rowData);
                                        }}
                                    />
                                );
                            },
                        },
                        {
                            title: "Send Email",
                            field: "payroll_setup_flag",
                            headerStyle: { textAlign: "left" },
                            cellStyle: { padding: "10px 10px 10px 35px", textAlign: "left" },
                            render: (rowData) => {
                                return loadingEmailRowId === rowData.employee_id ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <ForwardToInboxIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSendEmail(rowData);
                                        }}
                                    />
                                );
                            },
                        }
                    ]}
                    data={employees_options?.filter((employee) => employee.payroll_setup_flag === true)}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                        sorting: true,
                        search: true,
                        searchText: "",
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        paginationType: "stepped",
                        showFirstLastPageButtons: false,
                        paginationPosition: "bottom",
                        exportButton: false,
                        exportAllData: false,
                        exportFileName: "Employees",
                        addRowPosition: "first",
                        selection: false,
                        actionsColumnIndex: -1,
                        showSelectAllCheckbox: true,
                        showTextRowsSelected: true,
                        grouping: false,
                        columnsButton: false,
                    }}

                    title="Employees"
                />
            </Box>
            <Popup
                fullWidth={true}
                maxWidth="md"
                title={`Pay Slip of ${name}`}
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <PaySlipEmailPopup
                    setOpenPopup={setOpenPopup}
                    name={name}
                    currentData={currentData}
                    payData={payData}
                    imageUrl={imageUrl}
                />
            </Popup>
        </>
    );
};

export default PaySlipGrid;