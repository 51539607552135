import * as ActionTypes from '../ActionTypes';

export const GetNotificationAction = () => {
    return {
        type: ActionTypes.NOTIFICATION_GET_REQUEST,
    }
}
export const AddNotificationAction = (params) => {
    return {
        type: ActionTypes.NOTIFICATION_ADD_REQUEST,
        params
    }
}
export const UpdateNotificationAction = (params) => {
    return {
        type: ActionTypes.NOTIFICATION_UPDATE_REQUEST,
        params
    }
}

export const GetAllNotificationAction = () => {
    return {
        type: ActionTypes.ALL_NOTIFICATION_GET_REQUEST,
    }
}