import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab } from '@mui/material';
import React from 'react';
import Base from "../../Base/views/Base";
import DashboardIcon from '@mui/icons-material/Dashboard';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SoapIcon from '@mui/icons-material/Soap';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useState } from 'react';
import { GetSalaryStructureAction } from '../../../../store/Finance/SalaryStructure/Action';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import TemplateLandingPage from './Template/TemplateLandingPage';
import Dashboard from './Dashboard';
import LWFLandingPage from './LabourWelfareFound/LWFLandingPage';
import PTLandingPage from './ProfessionalTax/PTLandingPage';
import Payperiod from './PayPeriod/Payperiod';
import OverTimeGrid from './OverTime/OverTimeGrid';
const FinanceSettingsLandingPage = () => {

    const [value, setValue] = useState('1');
    const [accordian, setAccordian] = useState('1');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetSalaryStructureAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Base>
            <Paper className='onboarding-settings admin-setting' sx={{ borderRadius: "10px", minHeight: "87vh", maxHeight: "87vh", overflowY: "auto" }}>
                <TabContext value={value}>
                    <Box>
                        <TabList sx={{
                            minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                            '& .MuiTabs-indicator': {
                                width: '100%',
                                display: 'flex',
                                justifyContent: "center !important",
                            },
                        }} onChange={handleChange} aria-label="lab API tabs example">
                            <Tab icon={<DashboardIcon fontSize='small' />} iconPosition="start" label="Dashboard" value="1" />
                            <Tab icon={<DynamicFeedIcon fontSize='small' />} iconPosition="start" label="Template" value="2" />
                            <Tab icon={<AccountBalanceWalletIcon fontSize='small' />} iconPosition="start" label="Professional Tax" value="3" />
                            <Tab icon={<SoapIcon fontSize='small' />} iconPosition="start" label="Labour Welfare Fund" value="4" />
                            <Tab icon={<CurrencyExchangeIcon fontSize='small' />} iconPosition="start" label="Pay Period" value="5" />
                            <Tab icon={<AccessTimeFilledIcon fontSize='small' />} iconPosition="start" label="Over Time" value="6" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                    <Dashboard setValue={setValue} setAccordian={setAccordian} />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "0px", minHeight: "45px" }}>
                        <TemplateLandingPage />
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: "0px", minHeight: "45px" }}>
                        <PTLandingPage />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: "0px", minHeight: "45px" }}>
                        <LWFLandingPage />
                    </TabPanel>
                    <TabPanel value="5" sx={{ padding: "0px", minHeight: "45px" }}>
                        <Payperiod />
                    </TabPanel>
                    <TabPanel value="6" sx={{ padding: "0px", minHeight: "45px" }}>
                    <OverTimeGrid />
                    </TabPanel>
                </TabContext>
            </Paper>
        </Base>
    );
}

export default FinanceSettingsLandingPage;
