import { useState, useEffect } from "react";
import {
    Box,
    TextField,
    FormControl,
    FormControlLabel,
    Grid,
    Button,
    Paper,
    Checkbox,
    Typography,
    FormLabel
} from "@mui/material";
import Note from "../../Images/alert.png";
import { createStyles, makeStyles } from "@mui/styles";
import { AddEmpIDHelper, GetEmpIDHelper, UpdateEmpIDHelper } from "../../../Ancillary/CompanyDetails/helper/CompanyDetailsHelper";
import Popup from "../../../Base/views/Popup";

const EmployeeID = ({validateOnly, setOpenConfirmation}) => {
    const useStyles = makeStyles((theme) =>
        createStyles({
            Paper: {
                boxShadow:
                    theme.palette.mode === "dark"
                        ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
                        : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "#e6d9fb7a",
            },
            comingSoon: {
                fontWeight: 600,
                fontSize: "18px",
                marginTop: "30px",
                color: "white",
            },
            previewBox: {
                backgroundColor: "#ffffff",
                padding: "15px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0"
            },
            previewLabel: {
                fontWeight: 600,
                color: "#666"
            },
            previewValue: {
                fontSize: "18px",
                fontFamily: "monospace",
                letterSpacing: "1px",
                color: "#333",
                wordBreak: "break-all"
            }
        })
    );
    const classes = useStyles();
    const initialValue = {
        prefix: "",
        suffix: "",
        emp_code: "4",
        yoj: true,
    };
    const [inputData, setInputData] = useState(initialValue);
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [updateshow, setUpdateShow] = useState(false);
    useEffect(() => {
        GetEmpIDHelper()
            .then((res) => {
                if(res?.data?.data?.[0]){
                    setInputData(res?.data?.data?.[0]);
                } 
            })
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("emp_code" in fieldValues) {
            temp.emp_code = (fieldValues.emp_code === null || fieldValues.emp_code === "") ? "Emp code is required" : "";
        }
        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };
    const generateSampleOutput = () => {
        const currentYear = new Date().getFullYear();
        const empCodeLength = parseInt(inputData?.emp_code) || "4"; // Default to 4 if empty
        const empNumber = '1'.padStart(empCodeLength, '0');
        let output = '';
        if (inputData?.prefix) {
            output += inputData?.prefix;
        }
        output += empNumber;
        if (inputData?.yoj) {
            output += currentYear;
        }
        if (inputData?.suffix) {
            output += inputData?.suffix;
        }
        return output;
    };
    const handleInput = (event, value) => {
        if (["yoj"].includes(event?.target?.name)) {
            setInputData({
                ...inputData,
                [event.target.name]: event.target.checked,

            });
        }
        else if (["emp_code"].includes(event?.target?.name)) {
            const value = event?.target?.value;
            if (value === "" || /^[3-6]$/.test(value)) {
                setInputData({ ...inputData, emp_code: value });
            }
        }
        else {
            setInputData({ ...inputData, [event.target.name]: event.target.value })
        }
    };
    const handleValidate = async () => {
        if (validate(inputData)) {
            if (inputData?.configure_employee_pk) {
                setUpdateShow(true);
            }
            else {
                setShow(true);
            }
        }
    };
    const handleAdd = async () => {
        setShow(false);
        AddEmpIDHelper({
            ...inputData
        })
            .then((res) => {
                setInputData(res?.data?.data?.[0]);
                if (validateOnly) {
                    setOpenConfirmation(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleUpdate = async () => {
        setUpdateShow(false);
        UpdateEmpIDHelper({
            ...inputData
        })
            .then((res) => {
                setInputData(res?.data?.data?.[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <Box>
            <Popup
                maxWidth="xs"
                openPopup={show}
                setOpenPopup={setShow}
                title="Note!"
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
                        <img src={Note} width={"25%"} alt="note_img" />
                    </Box>
                    <FormControl>
                        <FormLabel id="gender-label">
                            <Typography variant="h5">
                                All new onboarding employees will follow the same pattern.
                            </Typography>
                        </FormLabel>
                    </FormControl>

                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                    }}
                >
                    <Button
                        onClick={() => {
                            setShow(false);
                        }}
                        variant="contained"
                        type="submit"
                        className="mpl-primary-btn profile-btn"
                        size="small"
                        sx={{
                            margin: "10px",
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAdd}
                        variant="contained"
                        type="submit"
                        className="mpl-primary-btn profile-btn"
                        size="small"
                        sx={{
                            margin: "10px",
                        }}
                    >
                        Ok
                    </Button>
                </Box>
            </Popup>
            <Popup
                maxWidth="xs"
                openPopup={updateshow}
                setOpenPopup={setUpdateShow}
                title="Note!"
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
                        <img src={Note} width={"25%"}  alt="note_img"/>
                    </Box>
                    <FormControl>
                        <FormLabel id="gender-label">
                            <Typography variant="h5">
                                The NewEmployee IDs will be generated in the new format.
                            </Typography>
                        </FormLabel>
                    </FormControl>

                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                    }}
                >
                    <Button
                        onClick={() => {
                            setUpdateShow(false);
                        }}
                        variant="contained"
                        type="submit"
                        className="mpl-primary-btn profile-btn"
                        size="small"
                        sx={{
                            margin: "10px",
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpdate}
                        variant="contained"
                        type="submit"
                        className="mpl-primary-btn profile-btn"
                        size="small"
                        sx={{
                            margin: "10px",
                        }}
                    >
                        Ok
                    </Button>
                </Box>
            </Popup>
            <Paper
                className={classes.Paper}
                sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
            >

                <Grid container>
                    <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                label="Prefix"
                                fullWidth
                                name="prefix"
                                onChange={handleInput}
                                value={inputData?.prefix || ""}
                                size="small"

                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
                        <FormControl fullWidth>
                            <TextField
                                type="text"
                                label="Suffix"
                                fullWidth
                                name="suffix"
                                onChange={handleInput}
                                value={inputData?.suffix || ""}
                                size="small"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
                        <FormControl fullWidth>
                            <TextField
                                required
                                type="text"
                                label="Number of Digits in Emp code"
                                fullWidth
                                name="emp_code"
                                onChange={handleInput}
                                inputProps={{
                                    maxLength: 6
                                }}
                                value={inputData?.emp_code}
                                size="small"
                                {...(errors.emp_code && {
                                    error: true,
                                    helperText: errors.emp_code,
                                })}
                            />

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="yoj"
                                        checked={
                                            inputData?.yoj || false
                                        }
                                        onChange={handleInput}
                                    />
                                }
                                label="Year of Joining"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8} sx={{ padding: "0.75rem" }}>
                        <Box className={classes.previewBox} sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                variant="subtitle1"
                                className={classes.previewLabel}
                                sx={{ marginRight: "0.5rem", fontWeight: "bold" }}
                            >
                                Sample:
                            </Typography>
                            <Typography className={classes.previewValue}>
                                {generateSampleOutput()}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "10px",
                    }}
                >
                    <Box>
                        <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            onClick={handleValidate}
                            className="mpl-primary-btn"
                        >
                            {inputData?.configure_employee_pk ? "Update" : "Validate"}
                        </Button>
                    </Box>
                </Grid>
            </Paper>
        </Box>
    );
};
export default EmployeeID;
