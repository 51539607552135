import { useEffect } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Button,
    Select,
    Typography,
    MenuItem,
    Grid,
    CircularProgress,
    IconButton,
    FormControl,
    TextField
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Base from "../../../Base/views/Base";
import { getUserData } from "../../../Base/helper/baseFunctions";
import Popup from "../../../Base/views/Popup";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../../Constants/baseConstant";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetPayrollTemplateAction } from "../../../../../store/Finance/PayRollTemplate/Action";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { AddPayrollStagetoMainHelper, DeletePayrollStageHelper, GetPayrollStageHelper, UpdatePayrollStageHelper } from "../../helper/BulkUpload/BulkUploadHelper";
import CloseIcon from '@mui/icons-material/Close';

const PayrollBulkUpload = () => {
    const dispatch = useDispatch();
    let userData = getUserData();
    const [, setOpenBulk] = useState(true);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [editedRecord, setEditedRecord] = useState(null);
    const [id, setId] = useState();
    const [openPopup, setOpenPopup] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [editerrors, setEditErrors] = useState({});
    const [empdata, setEmpData] = useState([]);
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);

    const initialColMapping = [
        { excel: "", db: "previous_employee_id", label: "Prev.Emp ID", required: true },
        { excel: "", db: "first_name", label: "First Name", required: false },
        { excel: "", db: "last_name", label: "Last Name", required: false },
        { excel: "", db: "office_email", label: "Email", required: false },
        { excel: "", db: "ctc", label: "CTC", required: false },
        { excel: "", db: "basic", label: "Basic", required: false },
        { excel: "", db: "hra", label: "HRA", required: false },
        { excel: "", db: "conveyance_allowance", label: "Conveyance Allowance", required: false },
        { excel: "", db: "medical_allowance", label: "Medical Allowance", required: false },
        { excel: "", db: "educational_allowance", label: "Educational Allowance", required: false },
        { excel: "", db: "lta", label: "LTA", required: false },
    ];
    const [excelDbColMapping, setExcelDbColMapping] = useState(initialColMapping);
    const [rowHeaders, setRowHeaders] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [templateData, setTemplateData] = useState({ load: true, values: [] });
    const [inputData, setInputData] = useState({ template_id: "" });
    useEffect(() => {
        dispatch(GetPayrollTemplateAction())
        setInputData({
            ...inputData,
            template_id: { template_id: "0", template_name: "Meepl Salary Template" },
        });
        GetPayrollStageHelper().then((res) => {
            setEmpData(res?.data?.data?.[0]?.payroll_details);
            setId(res?.data?.data?.[0]?.stage_payroll_pk)
        });
    }, []);
    const { payroll_template_data } = useSelector(
        (state) => state?.FinanceReducer.PayrollTemplateReducer
    );
    const handleTemplate = () => {
        payroll_template_data &&
            setTemplateData({ load: false, values: payroll_template_data });
    };
    const handleInput = (event, value) => {
        const selectedTemplate = payroll_template_data?.find(
            (t) => t.template_id === value?.template_id
        );
        if (selectedTemplate) {
            if (selectedTemplate.template_id === "0") {
                setExcelDbColMapping(initialColMapping)
            }
            else {
                const newColMapping = [];
                const { earning_key = [], deduction_key = [] } = selectedTemplate.template_fields || {};
                newColMapping.push({
                    excel: rowHeaders.includes("previous_employee_id") ? "previous_employee_id" : "",
                    db: "previous_employee_id",
                    label: "Prev.Emp ID",
                    required: true,
                });
                [...earning_key, ...deduction_key].forEach((field) => {
                    newColMapping.push({
                        excel: "",
                        db: field.name,
                        label: field.label,
                        required: false,
                    });
                });
                setExcelDbColMapping(newColMapping);
            }

        } else {
            setExcelDbColMapping([]);
        }

        if (event === "template_id") {
            setInputData({ ...inputData, [event]: value });
        }
    };
    const handleSelectFileClick = () => {
        if (empdata?.length > 0) {
            setShowConfirmPopup(true); // Show confirmation popup if data exists
        } else {
            document.getElementById("contained-button-file").click(); // Directly trigger file selection if no data exists
        }
    };
    const fileSelected = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) return;
        processFile(selectedFile, e);
    };
    const processFile = (selectedFile, event) => {
        setFile(selectedFile);
        setExcelDbColMapping(initialColMapping);
        setUploadProgress(0);
        const fileReader = new FileReader();
        toast.info("Reading File. Please wait");
        fileReader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const firstRowData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                range: 0,
            });
            setRowHeaders(firstRowData[0]);
            toast.success("File imported");
        };

        fileReader.readAsArrayBuffer(selectedFile);
        if (event) event.target.value = ""; // Reset the input
    };
    function setColMapping(value, dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = value;
            });
            return [...prev];
        });
    }
    function clearColMapping(dbCol) {
        setExcelDbColMapping((prev) => {
            prev.forEach((col) => {
                if (col["db"] === dbCol) col.excel = "";
            });
            return [...prev];
        });
    }
    const validate = () => {
        const temp = {};
        if (!file) temp.file = "File is required";
        const unmappedRequiredFields = excelDbColMapping.filter(
            (col) => col.required && !col.excel
        );
        if (unmappedRequiredFields.length > 0) {
            toast.error("Please map all required fields.")
            temp.columnMapping = "Please map all required fields.";
        }
        setErrors(temp);
        return Object.keys(temp).length === 0; // Return true if no errors
    };
    async function addPayrollStage(e) {
        if (!validate()) return;
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("user_id", userData?.employee_id);
            formData.append("template_id", inputData?.template_id?.template_id === '0' ? '0' : '1');
            formData.append("domain_name", userData?.domain);
            formData.append("column_mapping", JSON.stringify(excelDbColMapping));
            toast.info("Uploading file");
            try {
                setUploadProgress(0);
                await axiosInstance
                    .post("/finance/uploadPayrollFile", formData, {
                        baseURL: HRMS_FINANCE_API_URL,
                        headers: {
                            Accept: "multipart/form-data",
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progress) => {
                            const percentCompleted = Math.round(
                                (progress.loaded * 90) / progress.total
                            );
                            setUploadProgress(percentCompleted);
                            if (percentCompleted > 89) {
                                toast.info("Processing...");
                            }
                        },
                    })
                    .then((response) => {
                        const result = response?.data?.statuscode;
                        setEmpData(response?.data?.data?.[0]?.payroll_details)
                        setId(response?.data?.data?.[0]?.stage_payroll_pk)
                        if (result) {
                            toast.success("File processed successfully!");
                        }
                    });

                setUploadProgress(100);
                setFile(null);
                // refreshCatalog();
            } catch (error) {
                setUploadProgress(0);
                toast.warning(error.response?.data?.message || error.message);
            }
        } catch (error) {
            console.error("Unexpected error", error);
        }
    }
    async function handleSubmit(e) {
        AddPayrollStagetoMainHelper().then((res) => {
            if (res.data.statuscode === 200) {
                toast.success("Payroll data import completed successfully");
                setEmpData([]);
            }
        });
    }
    const handleCancel = () => {
        setShowConfirmPopup(false);
        DeletePayrollStageHelper({ stage_payroll_id: id }).then((res) => {
            setEmpData([]); // Clear table data
            setFile(null); // Allow new file selection
            document.getElementById("contained-button-file").click();
        });
    };
    const handleEdit = (rowData) => {
        setEditedRecord(rowData);
        setOpenPopup(true);
    };
    const handleConfirmDelete = (rowData) => {
        setOpenDialog(true);
        setCurrentData(rowData)
    };
    const handleDelete = () => {
        const updatedData = empdata?.filter(emp => emp?.previous_employee_id !== currentData?.previous_employee_id); // Use unique field
        setEmpData(updatedData); // Update state
        toast.success("Record deleted successfully!");
        setOpenDialog(false);
        UpdatePayrollStageHelper({ stage_payroll_id: id, payroll_details: JSON.stringify(updatedData) }).then((res) => {
        });
    };
    const handleEditSubmit = (updatedRecord) => {
        if (!updatedRecord?.previous_employee_id) {
            setEditErrors({ previous_employee_id: "Previous Employee ID is required" });
            return;
        }
        setEditErrors({ previous_employee_id: "" });
        const updatedData = empdata?.map((emp) =>
            emp.previous_employee_id === updatedRecord.previous_employee_id ? updatedRecord : emp
        );
        setEmpData(updatedData);
        UpdatePayrollStageHelper({ stage_payroll_id: id, payroll_details: JSON.stringify(updatedData) }).then((res) => {
        });
        setOpenPopup(false); // Close popup
        toast.success("Record updated successfully!");
    };
    return (
        <>
            <Base>
                <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
                    <Box>
                        <form
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "16px", // Adjust gap between elements
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {file ? (
                                    <p style={{ margin: 0 }}>{file.name.substring(0, 20)}...</p>
                                ) : errors.selectedFile ? (
                                    <p style={{ color: "red", margin: 0 }}>No file chosen</p>
                                ) : (
                                    <p style={{ margin: 0 }}>No file chosen</p>
                                )}
                            </div>
                            <label htmlFor="contained-button-file" style={{ display: "none" }}>
                                <input
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    id="contained-button-file"
                                    type="file"
                                    style={{ display: "none" }} // Hidden input
                                    multiple={false}
                                    onChange={fileSelected} // Handle file selection
                                />
                            </label>
                            <Button
                                variant="contained"
                                className="mpl-primary-btn"
                                component="span"
                                sx={{ marginBottom: "5px" }}
                                onClick={handleSelectFileClick} // Trigger confirmation or file input
                            >
                                Select File
                            </Button>
                            <Button
                                variant="contained"
                                className="mpl-primary-btn"
                                sx={{ marginBottom: "5px", marginRight: "5px" }}
                                disabled={empdata?.length === 0 || empdata === undefined}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>

                    <MaterialTable
                        columns={[
                            {
                                title: "Prev.Emp ID",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "previous_employee_id",
                                grouping: false,
                            },
                            {
                                title: "First Name",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "first_name",
                                grouping: false,
                            },
                            {
                                title: "Last Name",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "last_name",
                                grouping: false,
                            },
                            {
                                title: "Email",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "office_email",
                                grouping: false,
                            },
                            {
                                title: "CTC",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "ctc",
                            },
                            {
                                title: "Basic",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "basic",
                            },
                        ]}
                        data={empdata}
                        options={{
                            maxBodyHeight: "65vh",
                            minBodyHeight: "65vh",
                            rowStyle: { fontSize: "13px" },
                            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                            sorting: true,
                            search: true,
                            searchText: "",
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: false,
                            paging: true,
                            pageSizeOptions: [25, 50],
                            pageSize: 25,
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            paginationPosition: "bottom",
                            exportButton: false,
                            exportAllData: false,
                            exportFileName: "Employees",
                            addRowPosition: "first",
                            selection: false,
                            actionsColumnIndex: -1,
                            showSelectAllCheckbox: true,
                            showTextRowsSelected: true,
                            grouping: false,
                            columnsButton: false,
                        }}
                        actions={[
                            {
                                icon: () => <IconButton sx={{ padding: "0px" }}><EditIcon /></IconButton>,
                                tooltip: "Edit",
                                onClick: (_event, rowData) => { handleEdit(rowData) },
                            },
                            {
                                icon: () => <IconButton sx={{ padding: "0px" }}><DeleteIcon /></IconButton>,
                                tooltip: "Delete",
                                onClick: (_event, rowData) => { handleConfirmDelete(rowData) },
                            },
                        ]}
                        title="Payroll Bulk Upload"
                    />
                    <Popup
                        maxWidth="sm"
                        fullWidth={true}
                        title="Pls map the column headers from your sheet & Meepl Fields"
                        openPopup={file}
                        close={false}
                        setOpenPopup={setOpenBulk}
                    >
                        <Box>
                            <Box sx={{ m: 1 }}>
                                <FormControl fullWidth>
                                    <SelectableSearch
                                        isOptionEqualToValue={(option, value) =>
                                            +option.template_id === +value.template_id
                                        }
                                        label="Select from Saved Templates"
                                        fullWidth
                                        value={inputData?.template_id}
                                        loading={() => handleTemplate()}
                                        fieldLabel="template_name"
                                        required={false}
                                        variantType={"standard"}
                                        name="template_id"
                                        size="small"
                                        onChangeEvent={handleInput}
                                        data={templateData}
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    alignItems: "center",
                                    padding: 5,
                                    borderBottom: "1px solid gray",
                                }}
                            >
                                <Typography variant="h6" style={{ textAlign: "center" }}>
                                    Imported Headers
                                </Typography>
                                <Typography variant="h6" style={{ textAlign: "center" }}>
                                    Meepl Fields
                                </Typography>
                            </Box>
                            {excelDbColMapping.map((col) => (
                                <Grid
                                    key={col.db}
                                    container
                                    spacing={2}
                                    style={{
                                        padding: 5,
                                        alignItems: "center",
                                    }}
                                >
                                    <Grid sx={{ mt: 2 }} item xs={6} container alignItems="center" spacing={1}>
                                        <Grid item xs>
                                            <Select
                                                onChange={(e) => setColMapping(e.target.value, col.db)}
                                                value={col.excel}
                                                fullWidth
                                                required={col.required}
                                                variant="standard"
                                            >
                                                {rowHeaders.map((header) => (
                                                    <MenuItem key={header} value={header}>
                                                        {header}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        {col.excel && (  
                                            <Grid item>
                                                <IconButton onClick={() => clearColMapping(col.db)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid sx={{ mt: 2 }} item xs={6}>
                                        <Typography>
                                            {col.label || col.db}
                                            {col.required && " *"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>

                        <Box sx={{ display: "flex", justifyContent: "space-between", m: 2 }}>
                            <Button
                                onClick={() => {
                                    setFile(null);
                                    setRowHeaders([]);
                                    setOpenBulk(false);
                                }}
                                className="mpl-primary-btn"
                                disabled={uploadProgress > 0 && uploadProgress < 100}
                            >
                                Close
                            </Button>
                            <div>
                                {uploadProgress > 0 && uploadProgress < 100 ? (
                                    <CircularProgress disableShrink />
                                ) : (
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="mpl-primary-btn"
                                        loading={uploadProgress > 0 && uploadProgress < 100}
                                        onClick={addPayrollStage}
                                    >
                                        Upload
                                    </Button>
                                )}
                            </div>

                        </Box>
                    </Popup>
                    <Popup
                        maxWidth="xs"
                        fullWidth={true}
                        title="Attention Needed!"
                        openPopup={showConfirmPopup}
                        close={false}
                        setOpenPopup={setShowConfirmPopup}
                    >
                        <Typography variant="h5"> You will lose the imported data. Do you want to submit it before proceeding? </Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                            <Button
                                onClick={handleCancel}
                                className="mpl-primary-btn"
                            >
                                No
                            </Button>

                            <Button
                                onClick={() => {
                                    setShowConfirmPopup(false); // Close popup and retain existing data
                                }}
                                className="mpl-primary-btn"
                            >
                                Yes
                            </Button>

                        </Box>
                    </Popup>
                    <Popup
                        title={"Edit Employee"}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                    >
                        <form>
                            <Grid container spacing={2}>
                                {excelDbColMapping.map((field) => {
                                    if (!editedRecord?.hasOwnProperty(field.db)) return null; // Skip unmapped fields

                                    return (
                                        <Grid item xs={12} key={field.db}>
                                            <FormControl fullWidth>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    {field.label}
                                                </Typography>
                                                <TextField
                                                    size="small"
                                                    type={["previous_employee_id", "first_name", "last_name", "office_email"].includes(field.db) ? "text" : "number"}
                                                    variant="standard"
                                                    value={editedRecord[field.db] || ""}
                                                    onChange={(e) =>
                                                        setEditedRecord({
                                                            ...editedRecord,
                                                            [field.db]: e.target.value,
                                                        })
                                                    }
                                                    placeholder={`Enter ${field.label}`}

                                                />
                                                {field.db === "previous_employee_id" && editerrors.previous_employee_id && (
                                                    <Typography variant="caption" color="error">
                                                        {editerrors.previous_employee_id}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => setOpenPopup(false)}
                                    className="mpl-secondary-btn"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => handleEditSubmit(editedRecord)}
                                    className="mpl-primary-btn"
                                >
                                    Save
                                </Button>

                            </Box>
                        </form>
                    </Popup>
                    <ConfirmationDialog
                        title="Do you wish to proceed?"
                        openDialog={openDialog}
                        closeDialog={setOpenDialog}
                        OkButtonText="Delete"
                        onSubmit={handleDelete}
                    />
                </Box>
            </Base>
        </>
    );
};

export default PayrollBulkUpload;