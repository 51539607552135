import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import history from "../../../../history";
import UnauthorizedBase from "../../Base/views/UnauthorizedBase";
import { FormControl, Grid } from "@mui/material";
import LockSvg from "../../../../svg/LockSvg";
import { useEffect, useState } from "react";
import { GetDomainHelper, validateEmailHelper } from "../helper/SigninHelper";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";
import EmailSvg from "../../../../svg/EmailSvg";
import SelectableSearch from "../../Base/views/SelectableSearch";
import { getSessionData } from "../../Base/helper/baseHelper";

export default function ForgotPassword() {
  const region = getSessionData({ key: "region" });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const defaultValue = location.state?.defaultValue || "";
  const defaultEmail = location.state?.email || "";
  const [errors, setErrors] = useState({});
  const [viewMode, setViewMode] = useState("forgotPassword");
  const [inputData, setInputData] = useState({
    email: defaultEmail ? defaultEmail : "",
    domain: defaultValue === "meepladmin" ? "meepladmin" : "",
  });
  const [workspace, setWorkSpace] = useState({ load: true, values: [] });

  useEffect(() => {
    if(defaultEmail){
    GetDomainHelper({ email: defaultEmail }).then((res) => {
      setWorkSpace(res?.data?.data);
    });
  }
    // eslint-disable-next-line
  }, [defaultEmail]);
  const handleInputChange = (e) => {
    e.preventDefault();
      setInputData({
        ...inputData,
        [e.target.name]: e.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [e.target.name]: e.target.value });
  };
  const storeWorkSpace = (event, value) => {
    if (["domain"].includes(event)) {
      if (value) setInputData({ ...inputData, [event]: value });
      else setInputData({ ...inputData, [event]: "" });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate(inputData)) {
      setLoading(true);
      validateEmailHelper({
        email: inputData?.email,
        domain: defaultEmail? inputData?.domain?.domainname :inputData?.domain,
      }).then((res) => {
        setLoading(false);
        res?.message?.statuscode === 200 && setViewMode("emailSentView");
      });
    }
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email === "" ? "Email is required" : "";
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password === "" ? "Password is required" : "";
    }

    if ("domain" in fieldValues) {
      temp.domain = fieldValues.domain === "" ? "Workspace is required" : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  return (
    <UnauthorizedBase>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
          marginTop="48px"
        >
          {viewMode === "forgotPassword" && (
            <Grid item>
              <Box
                sx={{
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <div style={{ marginBottom: "40px", height: "96px" }}>
                  <LockSvg />
                </div>
                <Typography
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                >
                  Forgot your password?
                </Typography>
                <Typography
                  color="text.secondary"
                  textAlign={"center"}
                  sx={{
                    fontWeight: 600,
                    fontSize: "0.775rem",
                  }}
                >
                  Please enter the email address associated with your account
                  and We will email you a link to reset your password.
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    disabled={defaultEmail}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={inputData?.email}
                    onChange={handleInputChange}
                    {...(errors.email && {
                      error: true,
                      helperText: errors.email,
                    })}
                  />

                  {defaultEmail ? (
                    <FormControl fullWidth>
                    <SelectableSearch
                     margin="normal"
                     fullWidth 
                     required
                      label="WorkSpace"
                      fieldLabel="domainname"
                      loading={() =>
                        setWorkSpace({ load: false, values: workspace })
                      }
                      name="domain"
                      data={workspace}
                      size="small"
                      onChangeEvent={storeWorkSpace}
                      value={inputData?.domain}
                      {...(errors.domain && {
                        error: true,
                        helperText: errors.domain,
                      })}
                    />
                    </FormControl>
                  ) : (
                    <TextField
                      size="small"
                      margin="normal"
                      required
                      fullWidth
                      id="domain"
                      label="Workspace"
                      name="domain"
                      autoComplete="domain"
                      value={inputData.domain}
                      disabled={defaultValue === "meepladmin"}
                      onChange={handleInputChange}
                      {...(errors.domain && {
                        error: true,
                        helperText: errors.domain,
                      })}
                    />
                  )}
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: grey[50],
                      ":hover": {
                        backgroundColor: "#f9c307",
                        color: "#000000",
                      },
                    }}
                  >
                    Send Request
                  </LoadingButton>
                  {defaultValue === "meepladmin" ? (
                    <Button
                      className="mpl-secondary-btn"
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2, ":hover": { color: grey[50] } }}
                      onClick={() => history.push("/admin-signin")}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className="mpl-secondary-btn"
                      fullWidth
                      variant="outlined"
                      sx={{ mb: 2, ":hover": { color: grey[50] } }}
                      onClick={() => history.push(`/${region}/signup`)}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          )}
          {viewMode === "emailSentView" && (
            <Grid item>
              <Box
                sx={{
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <div style={{ marginBottom: "40px", height: "96px" }}>
                  <EmailSvg />
                </div>
                <Typography
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                >
                  Please check your email!
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  color="text.secondary"
                >
                  We have sent a link to your email ID{" "}
                  <span style={{ fontWeight: 600 }}>{inputData?.email} </span> ,
                  please open your email and follow the procedure to set your
                  password.
                </Typography>
                {defaultValue === "meepladmin" ? (
                  <Button
                    sx={{
                      mt: 3,
                      color: grey[50],
                      ":hover": {
                        backgroundColor: "#f9c307",
                        color: "#000000",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      setViewMode("forgotPassword");
                      setInputData({ email: "", domain: defaultValue });
                    }}
                  >
                    Forgot Password
                  </Button>
                ) : (
                  <Button
                    sx={{
                      mt: 3,
                      color: grey[50],
                      ":hover": {
                        backgroundColor: "#f9c307",
                        color: "#000000",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      setViewMode("forgotPassword");
                      setInputData({ email: "", domain: "" });
                    }}
                  >
                    Forgot Password
                  </Button>
                )}
                {defaultValue === "meepladmin" ? (
                  <Button
                    sx={{ mt: 3 }}
                    onClick={() => history.push("/meepl-admin-signin")}
                  >
                    Back to login
                  </Button>
                ) : (
                  <Button
                    sx={{ mt: 3 }}
                    onClick={() => history.push(`/${region}/signup`)}
                  >
                    Back to login
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </UnauthorizedBase>
  );
}
