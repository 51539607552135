import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { Divider, Paper, Grid, Container } from "@mui/material";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepLabel from "@mui/material/StepLabel";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import BasicDetails from "../../Employee/views/Basic/BasicDetails";
import ProfessionalSummaryPage from "../../Employee/views/ProfessionalSummary/ProfessionalSummaryPage";
import PersonalPage from "../../Employee/views/Personal/PersonalPage";
import FamilyPage from "../../Employee/views/Family/FamilyPage";
import BankDetails from "../../Employee/views/BankDetails/BankDetails";
import { getUserData } from "../../Base/helper/baseFunctions";
export default function ConfigureEmployeeStepper() {
  const userData = getUserData();
  const icons = {
    1: <PermContactCalendarIcon />,
    2: <WorkHistoryIcon />,
    3: <PrivacyTipIcon />,
    4: <GroupAddIcon />,
    5: <AccountBalanceIcon />,
};
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.secondary : "#8f8f94",
    display: "flex",
    height: 22,
    fontSize: 40,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme?.palette?.primary?.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: theme?.palette?.primary?.main,
      zIndex: 1,
      fontSize: 40,
    },
    "& .QontoStepIcon-circle": {
      width: 30,
      height: 30,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      fontWeight: "800",
    },
  }));
  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#e6d9fb7a",
      },
    })
  );
  const classes = useStyles();

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : props?.icon ? (
          icons[props?.icon]
        ) : (
          <Check />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
  };
  const steps = [
    'Basic Details *',
    'Professional Summary',
    'Identity Proof *',
    'Nominee / Family',
    'Payroll Profile *',
    
];
const filteredSteps = userData?.f_country_code !== "IND" 
        ? steps.filter((step, index) => index !== 4) 
        : steps;

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <BasicDetails currentMode={"add"} OnboardEmployee={true} setActiveStep={setActiveStep}/>;
      case 1:
        return <ProfessionalSummaryPage currentMode={"add"} OnboardEmployee={true} setActiveStep={setActiveStep} />;
      case 2:
        return <PersonalPage currentMode={"add"} OnboardEmployee={true} setActiveStep={setActiveStep} />;
      case 3:
        return <FamilyPage currentMode={"add"} OnboardEmployee={true} setActiveStep={setActiveStep} />;
      case 4:
        return <BankDetails currentMode={"add"} OnboardEmployee={true} setActiveStep={setActiveStep} />;
        default:
        return "Unknown stepIndex";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
 
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  return (
    <>
        <Container maxWidth="xl">
          <Grid
            spacing={1}
            justifyContent="center"
            marginTop="48px"
            minHeight="75vh"
          >
            <Paper className={classes.paper}>
              <Grid sx={{ width: "100%" }} item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "100%",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Grid container>
                      <Grid xs={12} md={2.5}>
                        <Stepper
                          nonLinear
                          orientation="vertical"
                          activeStep={activeStep}
                          sx={{
                            paddingTop: "15px",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          {filteredSteps.map((label, index) => (
                            <Step key={label}>
                              <StepButton
                                color="inherit"
                                onClick={handleStep(index)}
                                sx={{ padding: "10px 16px" }}
                              >
                                <StepLabel
                                  sx={{
                                    ".Mui-active": {
                                      fontWeight: "700!important",
                                    },
                                    ".MuiStepLabel-label":{
                                      fontSize:"16px"
                                    }
                                   
                                  }}
                                  StepIconComponent={QontoStepIcon}
                                >
                                  {label}
                                </StepLabel>
                              </StepButton>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                      <Divider
                        variant="middle"
                        orientation="vertical"
                        flexItem
                        sx={{ paddingTop: "10px" }}
                      />
                      <Grid xs={12} md={9.4}>
                        <div className="employee">
                          {getStepContent(activeStep)}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Container>
    </>
  );
}
