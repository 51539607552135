import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import history from "../../../../history";
import UnauthorizedBase from "../../Base/views/UnauthorizedBase";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import LockSvg from "../../../../svg/LockSvg";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { GetEmailTokenHelper } from "../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { LoadingButton } from "@mui/lab";
import { resetPasswordHelper } from "../helper/SigninHelper";
import TokenNotFound from "../../Base/views/TokenNotFound";
import { getSessionData } from "../../Base/helper/baseHelper";

const ChangePassword = () => {
  const region = getSessionData({ key: "region" });
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [viewMode, setviewMode] = useState("setPasswordView");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputData, setInputData] = useState({
    email: "",
    new_password: "",
    confirm_password: "",
  });
  useEffect(() => {
    GetEmailTokenHelper({ domain: token?.split("=")?.[1], token: token }).then(
      (res) => {
        if (res?.data?.data?.[0]?.email_tokens_id) {
          setInputData({ ...inputData, email: res?.data?.data?.[0]?.email_id });
          setLoading(false);
          let expiry_time =
            Date.parse(res?.data?.data?.[0]?.createdon) + 86400000;
          if (expiry_time < Date.parse(new Date())) {
            setviewMode("tokenNotFoundView");
          }
        } else {
          setviewMode("tokenNotFoundView");
        }
        setLoading(false);
      }
    );
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate(inputData)) {
      setLoading(true);
      resetPasswordHelper({
        domain: token?.split("=")?.[1],
        token: token,
        email_id: inputData?.email,
        password: inputData?.new_password,
      })
        .then((res) => {
          setLoading(false);
          res?.data?.statuscode === 200 && setviewMode("passwordUpdatedView");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email === "" ? "Email is required" : "";
    }

    if ("new_password" in fieldValues) {
      temp.new_password =
        fieldValues.new_password === "" ? "Password is required" : "";
    }

    if ("confirm_password" in fieldValues) {
      temp.confirm_password =
        fieldValues.confirm_password === ""
          ? "Confirm Password is required"
          : fieldValues?.new_password !== fieldValues?.confirm_password
          ? "Confirm Password is not match with new_password"
          : "";
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  return (
    <UnauthorizedBase>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
          marginTop="48px"
        >
          {viewMode === "setPasswordView" && (
            <Grid item>
              <Box
                sx={{
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <div style={{ marginBottom: "40px", height: "96px" }}>
                  <LockSvg />
                </div>
                <Typography
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                >
                  Change your password here
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    value={inputData?.email}
                    size="small"
                    margin="normal"
                    disabled
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleInputChange}
                    {...(errors.email && {
                      error: true,
                      helperText: errors.email,
                    })}
                  />
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="new_password"
                    label="New Password"
                    name="new_password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new_password"
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle new_password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <VisibilityIcon fontSize="small" />
                            ) : (
                              <VisibilityOffIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...(errors.new_password && {
                      error: true,
                      helperText: errors.new_password,
                    })}
                  />
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="confirm_password"
                    label="Confirm Password"
                    name="confirm_password"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="confirm_password"
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle new_password visibility"
                            onClick={() => {
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                          >
                            {showConfirmPassword ? (
                              <VisibilityIcon fontSize="small" />
                            ) : (
                              <VisibilityOffIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...(errors.confirm_password && {
                      error: true,
                      helperText: errors.confirm_password,
                    })}
                  />
                  <LoadingButton
                    type="submit"
                    fullWidth
                    loading={loading}
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color:grey[50],
                      ":hover": { backgroundColor: "#f9c307",color:"#000000"},
                    }}
                  >
                    Reset Password
                  </LoadingButton>
                  <Button
                    className="mpl-secondary-btn"
                    fullWidth
                    variant="outlined"
                    sx={{ ":hover": { color: grey[50] }, mb: 2 }}
                    onClick={() => history.push(`/${region}/signup`)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
          {viewMode === "tokenNotFoundView" && (
            <TokenNotFound
              navigateButton={
                <Button
                sx={{
                  color:grey[50],
                  ":hover": { backgroundColor: "#f9c307",color:"#000000"},
                }}
                  variant="contained"
                  onClick={() => history.push("/forgotPassword")}
                >
                  Forgot Password
                </Button>
              }
            />
          )}
          {viewMode === "passwordUpdatedView" && (
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  maxWidth: "400px",
                }}
              >
                <Typography
                  textAlign={"center"}
                  component="h1"
                  variant="h3"
                  fontWeight={700}
                  lineHeight={1.5}
                >
                  Password changed successfully!
                </Typography>
                <Typography
                  textAlign={"center"}
                  variant="body1"
                  color="text.secondary"
                >
                  You can use your new password to log in to your account
                </Typography>
                <Button sx={{ mt: 3 }} onClick={() => history.push(`/${region}/signup`)}>
                  Back to login
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </UnauthorizedBase>
  );
};

export default ChangePassword;
