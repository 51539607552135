import { toast } from "react-toastify";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_UTILITY_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";

export const GetProofTypeHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/ancillary/getProofTypes`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: params?.domain ? params?.domain : data?.domain,
            phonecode: data?.phone_code
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const AddProofTypeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/addProofType`, {
        method: "POST",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data.employee_id,
            name: params?.name,
            identity_length: params?.identity_length,
            document_required:params?.document_required,
            content_type: params?.content_type
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Proof Type Added Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const UpdateProofTypeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ancillary/updateProofType`, {
        method: "PUT",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            user_emp_id: data.employee_id,
            identity_id: params?.identity_id,
            name: params?.name,
            identity_length: params?.identity_length,
            document_required:params?.document_required,
            content_type: params?.content_type
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Proof Type Updated Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}

export const DeleteProofTypeHelper = async (params) => {
    const data = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ancillary/deleteProofType`, {
        method: "DELETE",
        baseURL: HRMS_UTILITY_API_URL,
        data: {
            domain_name: data?.domain,
            identity_id: params?.identity_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Proof Type Deleted Successfully");
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message);
            return { message: "Failed", hasError: true, data: err?.data }
        })
}