import { Box, Grid, TextField, Typography } from "@mui/material";
import MaterialTable from "material-table";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { UpdatePayDateAction } from "../../../../../store/Finance/PayDate/Action";
import { formatPayPeriod } from "../../../Base/helper/baseFunctions";
import { useTheme } from "@mui/material/styles";

const Payperiod = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pay_date_data } = useSelector(
    (state) => state.FinanceReducer?.PayDateReducer
  );
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const showAddButton = !pay_date_data || pay_date_data.length === 0;
  return (
    <>
      <Grid container spacing={2} style={{ padding: "10px 20px" }}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              paddingLeft: "15px",
              color: theme.palette.primary.contrastText,
            }}
          >
            {"Pay Period"}
          </Typography>
          <Box>
            <MaterialTable
              columns={[
                {
                  title: "Pay Period",
                  cellStyle: { padding: "0px 10px" },
                  field: "pay_day",
                  validate: (rowData) =>
                    rowData?.pay_day === "" || rowData?.pay_day === undefined
                      ? "Pay Period cannot be empty"
                      : "",
                  render: (rowData) => formatPayPeriod(rowData?.pay_day),
                  editComponent: (props) => (
                    <TextField
                      required={true}
                      variant="standard"
                      type="number"
                      value={props?.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      error={props?.error}
                      helperText={props?.helperText}
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  ),
                },
              ]}
              data={pay_date_data || ""}
              icons={{
                Add: () => <AddIcon />,
                Edit: () => <EditIcon />,
              }}
              editable={
                showAddButton
                  ? {
                      onRowAdd: (newRow) =>
                        new Promise((resolve, reject) => {
                          dispatch(UpdatePayDateAction(newRow));
                          setTimeout(() => resolve(), 500);
                        }),
                      onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                          dispatch(UpdatePayDateAction(newRow));
                          setTimeout(() => resolve(), 500);
                        }),
                    }
                  : {
                      onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                          dispatch(UpdatePayDateAction(newRow));
                          setTimeout(() => resolve(), 500);
                        }),
                    }
              }
              options={{
                maxBodyHeight: 220,
                rowStyle: { fontSize: "13px" },
                headerStyle: { padding: "5px 10px", fontWeight: "bold" },
                sorting: true,
                search: false,
                searchFieldAlignment: "right",
                searchAutoFocus: true,
                searchFieldVariant: "standard",
                filtering: false,
                paging: false,
                selection: false,
                actionsColumnIndex: -1,
                grouping: false,
                addRowPosition: "first",
              }}
              title=""
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      minHeight: "10px",
                      padding: "10px 25px",
                    }}
                  >
                    {props.title}
                  </div>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Payperiod;
