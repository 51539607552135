import React, { useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css"

export const CommonDateTimeRangePicker = ({
  name,
  size,
  label,
  pickerFormat,
  DefaultDTvalue,
  handleChange,
  minDate,
  maxDate,
  disableDates,
  error,
  helperText,
  variant,
  disabled,
  required,
  onKeyPress,
  onKeyDown
}) => {
  const [dateRange, setDateRange] = useState(DefaultDTvalue || new Date());
  useEffect(() => {
    if (DefaultDTvalue) {
      setDateRange(DefaultDTvalue);
    }
  }, [DefaultDTvalue]);

  const onDateChange = (date) => {
    setDateRange(date);
    handleChange(date); 
  };

  return (
        <DatePicker
          selected={dateRange}
          onChange={onDateChange}
          showTimeSelect
          dateFormat={pickerFormat}
          popperPlacement="bottom-start"
          minDate={minDate}
          maxDate={maxDate}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          disabled={disabled}
          customInput={
            <TextField
              label={label}
              name={name}
              variant={variant}
              size={size}
              fullWidth
              value={dateRange ? dateRange.toLocaleString() : ""}
              error={error}
              helperText={helperText}
              required={required}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onKeyPress={onKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InsertInvitationIcon style={{ cursor: "pointer" }} />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
  );
};