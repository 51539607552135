// import React from "react";
import React from "react";
// import { getSessionData } from "../../../Base/helper/baseHelper";

// import "./WhyUs.css";
function WhyUs() {
  // const region = getSessionData({ key: "region" });
  return (
    <>
      <div id="Solution" className="section">
        <div
          data-w-id="610726fb-fa11-ffcb-387d-7b283cb7d045"
          className="orange-section"
        >
          <div className="container _4ems">
            <div
              data-w-id="e43504e0-a5a9-1072-6cb3-dc1608b7f6b9"
              className="orange-section-title-holder"
            >
              <div className="half-title-container">
                <h1 className="title">
                  What makes Meepl.{" "}
                  <span className="text-with-underline">Different</span>

                </h1>
              </div>
              <div className="left-arrow-holder">
                <img
                  src="https://assets.website-files.com/634e968b219cc43522715fb8/634fbbef71ccb5a9a7b43e0b_Arrow%20Vector.svg"
                  loading="lazy"
                  alt=""
                  className="arrow-left"
                />
              </div>
            </div>
            <div className="lnr-section-holder">
              <div className="text-section-holder">
                <div>
                  <div className="fade-in-move-on-scroll">
                    <div className="icon-holder">
                      <img
                        src="https://assets.website-files.com/634e968b219cc43522715fb8/634fbe00766bef5f06ece631_Group%20Icon.svg"
                        loading="lazy"
                        alt=""
                        className="icon"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="fade-in-move-on-scroll">
                    <div className="content">
                      <h2 className="title">
                        Experience the power of collaborative hiring
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="fade-in-move-on-scroll">
                  <div className="description-holder">
                    <p className="description">
                      Allows faster collaboration among all 3 parties. Recruiters, Hiring Company & Candidate.
                    </p>
                  </div>
                </div>
                {/* {region === "in" && (
                  <div className="fade-in-move-on-scroll">
                    <div className="content">
                      <a href="#Pricing" className="button black w-button">
                        Read More
                      </a>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="lnr-phone-holder">
                <img
                  src="https://assets.website-files.com/634e968b219cc43522715fb8/634fbef3766bef686ded0a01_Phone%20Rotated%20Image.svg"
                  loading="lazy"
                  alt=""
                  className="phone-image"
                />
              </div>

              <div className="red-circle-holder">
                <div className="red-circle"></div>
              </div>
            </div>
            <div className="lnr-section-holder _02">
              <div className="lnr-tablet-holder">
                <div className="tablet-image-holder">
                  <img
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/634fc38ddc605c849ea570c2_Tablet.svg"
                    loading="lazy"
                    alt=""
                    className="tablet-image"
                  />
                  <div className="tablet-screen-holder">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634fc38d53b051ddddd5b169_Tablet%20Image.webp"
                      loading="lazy"
                      alt=""
                      className="tablet-screen-image"
                    />
                  </div>
                </div>
              </div>
              <div className="text-section-holder _02">
                <div className="fade-in-move-on-scroll">
                  <div className="icon-holder">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634fbfeb45e80b53154ce446_Desktop%20Icon.svg"
                      loading="lazy"
                      alt=""
                      className="icon invert"
                    />
                  </div>
                </div>
                <div className="fade-in-move-on-scroll">
                  <div className="content">
                    <h2 className="title">
                      Track your hiring stage transparently
                    </h2>
                  </div>
                </div>
                <div className="fade-in-move-on-scroll">
                  <div className="description-holder">
                    <p className="description white-text">
                      Everyone involved in the hiring process is fully aware and in complete control
                    </p>
                  </div>
                </div>
                {/* {region === "in" && (
                  <div className="fade-in-move-on-scroll">
                    <div className="content">
                      <a href="#Pricing" className="button black w-button">
                        Read More
                      </a>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <div className="lnr-section-holder">
              <div className="text-section-holder _03">
                <div className="fade-in-move-on-scroll">
                  <div className="icon-holder">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634fe22653b051cee3d7d0dd_Hour%20Glass.svg"
                      loading="lazy"
                      alt=""
                      className="icon"
                    />
                  </div>
                </div>
                <div className="fade-in-move-on-scroll">
                  <div className="content">
                    <h2 className="title">Manage your Recruitment team in a jiffy</h2>
                  </div>
                </div>
                <div className="fade-in-move-on-scroll">
                  <div className="description-holder">
                    <p className="description">
                      Bring all your hiring consultants under one roof.
                    </p>
                  </div>
                </div>
                {/* {region === "in" && (
                  <div className="fade-in-move-on-scroll">
                    <div className="content">
                      <a href="#Pricing" className="button black w-button">
                        Read More
                      </a>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="lnr-last-phone-holder">
                <div className="lnr-iphone-holder">
                  <img
                    src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 87vw, (max-width: 991px) 416.265380859375px, 460.865234375px"
                    srcSet="https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight-p-500.png 500w, https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight-p-800.png 800w, https://assets.website-files.com/634e968b219cc43522715fb8/634eb3af77434fe0d7319118_iPhone%2013%20Pro%20-%20Starlight.png 1002w"
                    alt=""
                    className="iphone-image"
                  />
                  <div className="hero-iphone-screen-holder">
                    <img
                      src="https://assets.website-files.com/634e968b219cc43522715fb8/634eb3fd4146bc51a396b30b_Dashboard%20Mobile.svg"
                      loading="lazy"
                      alt=""
                      className="iphone-screen-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyUs;
