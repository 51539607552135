import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import "../../css/styles.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ApproveTimeOffGrid from "./TimeOff/ApproveTimeOffGrid"
import ApproveTimeSheetGrid from "./TimeSheet/ApproveTimeSheetGrid"

const TeamApproveLandingPage = ({ defaultValue }) => {
    const [value, setValue] = useState(defaultValue ? defaultValue : '1');
    let tabIndex = sessionStorage.getItem("tabIndex")
    useEffect(() => {
        if (tabIndex === "2" || tabIndex === "1"){
            setValue(tabIndex)
            sessionStorage.removeItem("tabIndex")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex])
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
            <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList sx={{
                                minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                '& .MuiTabs-indicator': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: "center !important",
                                },
                            }} onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Time Sheets" value="1" />
                                <Tab label="Time Off" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
                            <ApproveTimeSheetGrid />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                          <ApproveTimeOffGrid/>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
            </Box>
           
    )
}

export default TeamApproveLandingPage;