import { put, call } from "redux-saga/effects";
import {
  AddAtsCompanyHelper,
  DeleteAtsCompanyHelper,
  GetAtsCompanysHelper,
  UpdateAtsCompanyHelper,
} from "../../app/Components/ATS/helper/AtsCompanyHelper";
import {
  AddAtsGroupCompanyHelper,
  DeleteAtsGroupCompanyHelper,
  GetAtsGroupCompanysHelper,
  UpdateAtsGroupCompanyHelper,
} from "../../app/Components/ATS/helper/AtsGroupCompanyHelper";
import {
  GetAtsUserHelper,
  UpdateAtsUserHelper,
  AddAtsUserHelper,
  GetAtsUserInfoHelper,
} from "../../app/Components/ATS/helper/AtsUserHelper";
import {
  GetAtsConsultancyHelper,
  AddAtsConsultancyHelper,
  UpdateAtsConsultancyHelper,
  DeleteAtsConsultancyHelper,
} from "../../app/Components/ATS/helper/AtsConsultancyHelper";
import * as ActionTypes from "./ActionTypes";
import {
  AddAtsCandidateHelper,
  GetAtsCandidateByEmailHelper,
  GetAtsCandidateHelper,
  UpdateAtsCandidateHelper,
  GetQuickSearchHelper,
  DeleteAtsCandidateHelper,
} from "../../app/Components/ATS/helper/AtsCandidateHelper";
import {
  AddAtsJobPostHelper,
  GetAtsJobPostHelper,
  UpdateAtsJobPostHelper,
  GetAtsJobOfferHelper,
} from "../../app/Components/ATS/helper/JobPostHelper";
import { GetUserProfileScoreHelper } from "../../app/Components/ATS/helper/AtsProfileScoreHelper";
import {
  AddAtsInterviewDetailsHelper,
  GetAtsInterviewDetailsHelper,
  UpdateAtsInterviewDetailsHelper,
  DeleteAtsInterviewDetailsHelper,
} from "../../app/Components/ATS/helper/ATSInterviewDetailsHelper";
import {
  AddAtsInvoiceHelper,
  GetAtsInvoiceCandidatesHelper,
  GetAtsInvoiceGenerateCandidatesHelper,
  GetAtsInvoiceHelper,
  UpdateAtsInvoiceHelper,
} from "../../app/Components/ATS/helper/AtsInvoiceHelper";
import { FileUploadHelper } from "../../app/Components/ATS/helper/AtsFileUploadHelper";
import { ResumeParserHelper } from "../../app/Components/ATS/helper/AtsResumeParserHelper";
import { PicUploadHelper } from "../../app/Components/Employee/helper/EmployeeHelper";
import { PicParserHelper } from "../../app/Components/Employee/helper/EmployeeHelper";
import {
  GetAtsQualityofResumesReportHelper,
  GetAtsRevenueReportHelper,
  GetAtsTargetAchievementReportHelper,
  GetDailyProductivityReportHelper,
  GetMonthlyReportHelper,
  GetReportRecruiterHelper,
  GetAtsWorkLoadReportHelper,
  GetAtsCandidateVerifiedReportHelper,
  GetQualityReportHelper
} from "../../app/Components/ATS/helper/AtsReportHelper";
import { GetAtsCandidateExportHelper } from "../../app/Components/ATS/helper/AtsCandidateExportHelper";
import {
  AddJobTemplateHelper,
  DeleteJobTemplateHelper,
  GetJobTemplateHelper,
  GetMeeplAdminJobTemplateHelper,
  UpdateJobTemplateHelper,
} from "../../app/Components/ATS/helper/Ancillary/JobTemplateHelper";
import {
  AddSkillsHelper,
  DeleteSkillsHelper,
  GetMeeplAdminSkillsHelper,
  GetSkillsHelper,
  UpdateSkillsHelper,
} from "../../app/Components/ATS/helper/Ancillary/SkillsHelper";
import {
  AddIndustryTypeHelper,
  DeleteIndustryTypeHelper,
  GetIndustryTypeHelper,
  GetMeeplAdminIndustryTypeHelper,
  UpdateIndustryTypeHelper,
} from "../../app/Components/ATS/helper/Ancillary/IndustryTypeHelper";
import {
  AddAtsTaskHelper,
  DeleteAtsTaskHelper,
  GetAtsTaskHelper,
  UpdateAtsTaskHelper,
} from "../../app/Components/ATS/helper/AtsTasksHelper";
import {
  AddFormTemplateHelper,
  DeleteFormTemplateHelper,
  GetFormTemplateHelper,
  UpdateFormTemplateHelper,
  AddPreScreenTemplateHelper,
  DeletePreScreenTemplateHelper,
  GetPreScreenTemplateHelper,
  UpdatePreScreenTemplateHelper,
  GetOfferLetterTemplateHelper,
  AddOfferLetterTemplateHelper,
  UpdateOfferLetterTemplateHelper,
  DeleteOfferLetterTemplateHelper,
} from "../../app/Components/ATSSettings/helper/AtsFormTemplateHelper";
import {
  GetAtsDesignationHelper,
  AddAtsDesignationHelper,
  UpdateAtsDesignationHelper,
  DeleteAtsDesignationHelper,
} from "../../app/Components/ATS/helper/Ancillary/AtsDesignationHelper";
import {
  GetAtsJobFilterAdminHelper,
  GetAtsJobFilterCompanyHelper,
  GetAtsJobFilterConsultancyHelper,
  GetAtsJobFilterInternalHelper,
  GetAtsJobFilterGroupOrgHelper,
} from "../../app/Components/ATS/helper/JobFilterHelper";

export function* GetAtsUser(payload) {
  const response = yield call(GetAtsUserHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_USER_GET_SUCCESS, payload: response });
  }
}

export function* AddAtsUser(payload) {
  const response = yield call(AddAtsUserHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_USER_ADD_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.ATS_USER_ADD_ERROR, payload: response });
  }
}

export function* UpdateAtsUser(payload) {
  const response = yield call(UpdateAtsUserHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_USER_UPDATE_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.ATS_USER_UPDATE_ERROR, payload: response });
  }
}

// Company
export function* GetAtsCompany() {
  const response = yield call(GetAtsCompanysHelper);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_COMPANY_GET_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.ATS_COMPANY_GET_ERROR, payload: response });
  }
}

export function* AddAtsCompany(payload) {
  const response = yield call(AddAtsCompanyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_COMPANY_ADD_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.ATS_COMPANY_ADD_ERROR, payload: response });
  }
}

export function* UpdateAtsCompany(payload) {
  const response = yield call(UpdateAtsCompanyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_COMPANY_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_COMPANY_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteAtsCompany(payload) {
  const response = yield call(DeleteAtsCompanyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_COMPANY_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_COMPANY_DELETE_ERROR,
      payload: response,
    });
  }
}

// Group Company
export function* GetAtsGroupCompany() {
  const response = yield call(GetAtsGroupCompanysHelper);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_GET_ERROR,
      payload: response,
    });
  }
}

export function* AddAtsGroupCompany(payload) {
  const response = yield call(AddAtsGroupCompanyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_ADD_ERROR,
      payload: response,
    });
  }
}

export function* UpdateAtsGroupCompany(payload) {
  const response = yield call(UpdateAtsGroupCompanyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteAtsGroupCompany(payload) {
  const response = yield call(DeleteAtsGroupCompanyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_GROUP_COMPANY_DELETE_ERROR,
      payload: response,
    });
  }
}
// Consultancy

export function* GetAtsConsultancy() {
  const response = yield call(GetAtsConsultancyHelper);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_GET_ERROR,
      payload: response,
    });
  }
}

export function* AddAtsConsultancy(payload) {
  const response = yield call(AddAtsConsultancyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_ADD_ERROR,
      payload: response,
    });
  }
}

export function* UpdateAtsConsultancy(payload) {
  const response = yield call(UpdateAtsConsultancyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteAtsConsultancy(payload) {
  const response = yield call(DeleteAtsConsultancyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CONSULTANCY_DELETE_ERROR,
      payload: response,
    });
  }
}

// Candidate
export function* GetAtsCandidatesByEmail(payload) {
  const response = yield call(GetAtsCandidateByEmailHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_BY_EMAIL_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_BY_EMAIL_GET_ERROR,
      payload: response,
    });
  }
}

export function* GetAtsCandidates(payload) {
  const response = yield call(GetAtsCandidateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ATS_CANDIDATE_GET_ERROR, payload: response });
  }
}

export function* DeleteAtsCandidate(payload) {
  const response = yield call(DeleteAtsCandidateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ATS_CANDIDATE_GET_ERROR, payload: response });
  }
}

export function* GetAtsQuickSearch(payload) {
  const response = yield call(GetQuickSearchHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_QUICK_SEARCH_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_QUICK_SEARCH_GET_ERROR,
      payload: response,
    });
  }
}
export function* AddAtsCandidate(payload) {
  const response = yield call(AddAtsCandidateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ATS_CANDIDATE_ADD_ERROR, payload: response });
  }
}

export function* UpdateAtsCandidate(payload) {
  const response = yield call(UpdateAtsCandidateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_UPDATE_ERROR,
      payload: response,
    });
  }
}

// Job Post
export function* GetAtsJobPost(payload) {
  const response = yield call(GetAtsJobPostHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_POST_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ATS_JOB_POST_GET_ERROR, payload: response });
  }
}

export function* AddAtsJobPost(payload) {
  const response = yield call(AddAtsJobPostHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_POST_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ATS_JOB_POST_ADD_ERROR, payload: response });
  }
}

export function* UpdateAtsJobPost(payload) {
  const response = yield call(UpdateAtsJobPostHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_POST_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_JOB_POST_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* GetAtsJobOffer(payload) {
  const response = yield call(GetAtsJobOfferHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_OFFER_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ATS_JOB_OFFER_GET_ERROR, payload: response });
  }
}
// Job Filter

export function* GetAtsJobFilterAdmin(payload) {
  const response = yield call(GetAtsJobFilterAdminHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_ADMIN_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_ADMIN_GET_ERROR,
      payload: response,
    });
  }
}

export function* GetAtsJobFilterInternal(payload) {
  const response = yield call(GetAtsJobFilterInternalHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_INTERNAL_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_INTERNAL_GET_ERROR,
      payload: response,
    });
  }
}
export function* GetAtsJobFilterGroupOrg(payload) {
  const response = yield call(GetAtsJobFilterGroupOrgHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_GROUP_ORG_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_GROUP_ORG_GET_ERROR,
      payload: response,
    });
  }
}

export function* GetAtsJobFilterConsultancy(payload) {
  const response = yield call(GetAtsJobFilterConsultancyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_CONSULTANCY_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_CONSULTANCY_GET_ERROR,
      payload: response,
    });
  }
}

export function* GetAtsJobFilterCompany(payload) {
  const response = yield call(GetAtsJobFilterCompanyHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_COMPANY_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_JOB_FILTER_COMPANY_GET_ERROR,
      payload: response,
    });
  }
}
//Profile Score Details
export function* GetAtsProfileScore(payload) {
  const response = yield call(GetUserProfileScoreHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_PROFILE_SCORE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_PROFILE_SCORE_GET_ERROR,
      payload: response,
    });
  }
}

// Interview Details
export function* GetAtsInterviewDetails(payload) {
  const response = yield call(GetAtsInterviewDetailsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_GET_ERROR,
      payload: response,
    });
  }
}

export function* AddAtsInterviewDetails(payload) {
  const response = yield call(AddAtsInterviewDetailsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_ADD_ERROR,
      payload: response,
    });
  }
}

export function* UpdateAtsInterviewDetails(payload) {
  const response = yield call(UpdateAtsInterviewDetailsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_UPDATE_ERROR,
      payload: response,
    });
  }
}
export function* DeleteAtsInterviewDetails(payload) {
  const response = yield call(DeleteAtsInterviewDetailsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_INTERVIEW_DETAILS_DELETE_ERROR,
      payload: response,
    });
  }
}

// ATS User Info
export function* GetAtsUserInfo(payload) {
  const response = yield call(
    GetAtsUserInfoHelper,
    payload.params,
    payload.dispatch
  );
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_USER_INFO_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.ATS_USER_INFO_ERROR, payload: response });
  }
}

// ATS Invoice Details
export function* GetAtsInvoiceDetails(payload) {
  const response = yield call(GetAtsInvoiceHelper, payload?.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_INVOICE_GET_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.ATS_INVOICE_GET_ERROR, payload: response });
  }
}

export function* GetAtsInvoiceCandidateDetails(payload) {
  const response = yield call(GetAtsInvoiceCandidatesHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_INVOICE_CANDIDATES_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_INVOICE_CANDIDATES_GET_ERROR,
      payload: response,
    });
  }
}

export function* GetAtsGenerateInvoiceCandidateDetails(payload) {
  const response = yield call(
    GetAtsInvoiceGenerateCandidatesHelper,
    payload.params
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_INVOICE_GENERATE_CANDIDATES_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_INVOICE_GENERATE_CANDIDATES_GET_ERROR,
      payload: response,
    });
  }
}

export function* AddAtsInvoiceDetails(payload) {
  const response = yield call(AddAtsInvoiceHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.ATS_INVOICE_ADD_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.ATS_INVOICE_ADD_ERROR, payload: response });
  }
}

export function* UpdateAtsInvoiceDetails(payload) {
  const response = yield call(UpdateAtsInvoiceHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_INVOICE_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_INVOICE_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* AddAtsWorkSpaceSwitch(payload) {
  yield put({
    type: ActionTypes.ATS_WORKSPACE_ADD_SUCCESS,
    payload: payload.params,
  });
}

export function* AddAtsJobFilter(payload) {
  yield put({
    type: ActionTypes.ATS_JOB_FILTER_ADD_SUCCESS,
    payload: payload.params,
  });
}

export function* UploadResume(payload) {
  const response = yield call(
    FileUploadHelper,
    payload.params,
    payload.dispatch
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_RESUME_UPLOAD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ATS_RESUME_UPLOAD_ERROR, payload: response });
  }
}

export function* PythonApi(payload) {
  const response = yield call(ResumeParserHelper, payload.params);
  if (response) {
    yield put({
      type: ActionTypes.ATS_RESUME_PYTHON_API_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_RESUME_PYTHON_API_ERROR,
      payload: response,
    });
  }
}
export function* UploadEmpPic(payload) {
  const response = yield call(
    PicUploadHelper,
    payload.params,
    payload.dispatch
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.EMP_PROFILE_PIC_UPLOAD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.EMP_PROFILE_PIC_UPLOAD_ERROR, payload: response });
  }
}
export function* PythonEmpApi(payload) {
  const response = yield call(PicParserHelper, payload.params);
  if (response) {
    yield put({
      type: ActionTypes.EMP_PROFILE_PIC_PYTHON_API_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.EMP_PROFILE_PIC_PYTHON_API_ERROR,
      payload: response,
    });
  }
}

export function* AtsTargetAchievementReport(payload) {
  const response = yield call(
    GetAtsTargetAchievementReportHelper,
    payload.params
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_TARGET_ACHIEVEMENT_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_TARGET_ACHIEVEMENT_REPORT_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsWorkLoadReport(payload) {
  const response = yield call(
    GetAtsWorkLoadReportHelper,
    payload.params
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_WORK_LOAD_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_WORK_LOAD_REPORT_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsQualityofResumesReport(payload) {
  const response = yield call(
    GetAtsQualityofResumesReportHelper,
    payload.params
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_QUALITY_OF_RESUME_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_QUALITY_OF_RESUME_REPORT_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsDailyProductivityReport(payload) {
  const response = yield call(GetDailyProductivityReportHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_DAILY_PRODUCTIVITY_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_DAILY_PRODUCTIVITY_REPORT_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsMonthlyReport(payload) {
  const response = yield call(GetMonthlyReportHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_MONTHLY_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_MONTHLY_REPORT_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsRevenueReport(payload) {
  const response = yield call(GetAtsRevenueReportHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_REVENUE_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_REVENUE_REPORT_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsReportRecruiter(payload) {
  const response = yield call(GetReportRecruiterHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_REPORT_RECRUITER_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_REPORT_RECRUITER_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsCandidateVerifiedReport(payload) {
  const response = yield call(
    GetAtsCandidateVerifiedReportHelper,
    payload.params
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_VERIFIED_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_VERIFIED_REPORT_GET_ERROR,
      payload: response,
    });
  }
}

export function* AtsQualityReport(payload) {
  const response = yield call(
    GetQualityReportHelper,
    payload.params
  );
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_QUALITY_REPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_QUALITY_REPORT_GET_ERROR,
      payload: response,
    });
  }
}
//CandidateExport
export function* AtsCandidateExport(payload) {
  const response = yield call(GetAtsCandidateExportHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_EXPORT_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ATS_CANDIDATE_EXPORT_GET_ERROR,
      payload: response,
    });
  }
}
//Industry Type
export function* GetMeeplAdminIndustryType(payload) {
  const response = yield call(GetMeeplAdminIndustryTypeHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.INDUSTRY_TYPE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.INDUSTRY_TYPE_GET_ERROR, payload: response });
  }
}

export function* GetIndustryType(payload) {
  const response = yield call(GetIndustryTypeHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.INDUSTRY_TYPE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.INDUSTRY_TYPE_GET_ERROR, payload: response });
  }
}

export function* AddIndustryType(payload) {
  const response = yield call(AddIndustryTypeHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.INDUSTRY_TYPE_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.INDUSTRY_TYPE_ADD_ERROR, payload: response });
  }
}

export function* UpdateIndustryType(payload) {
  const response = yield call(UpdateIndustryTypeHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.INDUSTRY_TYPE_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.INDUSTRY_TYPE_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteIndustryType(payload) {
  const response = yield call(DeleteIndustryTypeHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.INDUSTRY_TYPE_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.INDUSTRY_TYPE_DELETE_ERROR,
      payload: response,
    });
  }
}

export function* GetMeeplAdminSkills(payload) {
  const response = yield call(GetMeeplAdminSkillsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.SKILLS_GET_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.SKILLS_GET_ERROR, payload: response });
  }
}

export function* GetSkills(payload) {
  const response = yield call(GetSkillsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.SKILLS_GET_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.SKILLS_GET_ERROR, payload: response });
  }
}

export function* AddSkills(payload) {
  const response = yield call(AddSkillsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.SKILLS_ADD_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.SKILLS_ADD_ERROR, payload: response });
  }
}

export function* UpdateSkills(payload) {
  const response = yield call(UpdateSkillsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.SKILLS_UPDATE_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.SKILLS_UPDATE_ERROR, payload: response });
  }
}

export function* DeleteSkills(payload) {
  const response = yield call(DeleteSkillsHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.SKILLS_DELETE_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.SKILLS_DELETE_ERROR, payload: response });
  }
}

//Job Template
export function* GetJobTemplate(payload) {
  const response = yield call(GetJobTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.JOB_TEMPLATE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.JOB_TEMPLATE_GET_ERROR, payload: response });
  }
}

export function* GetMeeplAdminJobTemplate(payload) {
  const response = yield call(GetMeeplAdminJobTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.JOB_TEMPLATE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.JOB_TEMPLATE_GET_ERROR, payload: response });
  }
}

export function* AddJobTemplate(payload) {
  const response = yield call(AddJobTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.JOB_TEMPLATE_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.JOB_TEMPLATE_ADD_ERROR, payload: response });
  }
}

export function* UpdateJobTemplate(payload) {
  const response = yield call(UpdateJobTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.JOB_TEMPLATE_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.JOB_TEMPLATE_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteJobTemplate(payload) {
  const response = yield call(DeleteJobTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.JOB_TEMPLATE_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.JOB_TEMPLATE_DELETE_ERROR,
      payload: response,
    });
  }
}

export function* GetMyTask(payload) {
  const response = yield call(GetAtsTaskHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.MY_TASK_GET_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.MY_TASK_GET_ERROR, payload: response });
  }
}

export function* UpdateMyTask(payload) {
  const response = yield call(UpdateAtsTaskHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({ type: ActionTypes.MY_TASK_UPDATE_SUCCESS, payload: response });
  } else {
    yield put({ type: ActionTypes.MY_TASK_UPDATE_ERROR, payload: response });
  }
}

export function* GetAssignedTask(payload) {
  const response = yield call(GetAtsTaskHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ASSIGNED_TASK_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ASSIGNED_TASK_GET_ERROR, payload: response });
  }
}

export function* AddAssignedTask(payload) {
  const response = yield call(AddAtsTaskHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ASSIGNED_TASK_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.ASSIGNED_TASK_ADD_ERROR, payload: response });
  }
}

export function* UpdateAssignedTask(payload) {
  const response = yield call(UpdateAtsTaskHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ASSIGNED_TASK_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ASSIGNED_TASK_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteAssignedTask(payload) {
  const response = yield call(DeleteAtsTaskHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ASSIGNED_TASK_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.ASSIGNED_TASK_DELETE_ERROR,
      payload: response,
    });
  }
}

export function* GetFormTemplate(payload) {
  const response = yield call(GetFormTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.FORM_TEMPLATE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.FORM_TEMPLATE_GET_ERROR, payload: response });
  }
}

export function* AddFormTemplate(payload) {
  const response = yield call(AddFormTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.FORM_TEMPLATE_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({ type: ActionTypes.FORM_TEMPLATE_ADD_ERROR, payload: response });
  }
}

export function* UpdateFormTemplate(payload) {
  const response = yield call(UpdateFormTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.FORM_TEMPLATE_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.FORM_TEMPLATE_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteFormTemplate(payload) {
  const response = yield call(DeleteFormTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.FORM_TEMPLATE_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.FORM_TEMPLATE_DELETE_ERROR,
      payload: response,
    });
  }
}

export function* GetPreScreenTemplate(payload) {
  const response = yield call(GetPreScreenTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_GET_ERROR,
      payload: response,
    });
  }
}

export function* AddPreScreenTemplate(payload) {
  const response = yield call(AddPreScreenTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_ADD_ERROR,
      payload: response,
    });
  }
}

export function* UpdatePreScreenTemplate(payload) {
  const response = yield call(UpdatePreScreenTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeletePreScreenTemplate(payload) {
  const response = yield call(DeletePreScreenTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.PRE_SCREEN_TEMPLATE_DELETE_ERROR,
      payload: response,
    });
  }
}

export function* GetOfferLetterTemplate(payload) {
  const response = yield call(GetOfferLetterTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_GET_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_GET_ERROR,
      payload: response,
    });
  }
}

export function* AddOfferLetterTemplate(payload) {
  const response = yield call(AddOfferLetterTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_ADD_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_ADD_ERROR,
      payload: response,
    });
  }
}

export function* UpdateOfferLetterTemplate(payload) {
  const response = yield call(UpdateOfferLetterTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_UPDATE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_UPDATE_ERROR,
      payload: response,
    });
  }
}

export function* DeleteOfferLetterTemplate(payload) {
  const response = yield call(DeleteOfferLetterTemplateHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_DELETE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: ActionTypes.OFFER_LETTER_TEMPLATE_DELETE_ERROR,
      payload: response,
    });
  }
}

export function* GetAtsDesignationSaga(payload) {
  const response = yield call(GetAtsDesignationHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_DESIGNATION_GET_SUCCESS,
      payload: response,
    });
  }
}

export function* AddAtsDesignationSaga(payload) {
  const response = yield call(AddAtsDesignationHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_DESIGNATION_ADD_SUCCESS,
      payload: response,
    });
  }
}

export function* UpdateAtsDesignationSaga(payload) {
  const response = yield call(UpdateAtsDesignationHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_DESIGNATION_UPDATE_SUCCESS,
      payload: response,
    });
  }
}

export function* DeleteAtsDesignationSaga(payload) {
  const response = yield call(DeleteAtsDesignationHelper, payload.params);
  if (response?.data?.statuscode === 200) {
    yield put({
      type: ActionTypes.ATS_DESIGNATION_DELETE_SUCCESS,
      payload: response,
    });
  }
}
