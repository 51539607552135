import {
  Box,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import FileUploader from "../ATSCandidate/FileUploader";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { useParams } from "react-router-dom";
import { convertBase64 } from "../../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import CarreerportalSidbar from "./AtsCareerPortalSidebar";
import { AddAtsExternalCandidateHelper } from "../../helper/AtsExternalCandidateHelper";
import { GetProofTypeHelper } from "../../../Ancillary/ProofType/helper/ProofTypeHelper";
import UnauthorizedBase from "../../../Base/views/UnauthorizedBase";
import history from "../../../../../history";
import Popup from "../../../Base/views/Popup";
import Done from "../../../../../Image/Done.png";
import PreScreenResponse from "../../../ATSSettings/views/PreScreen/PreScreenResponse";

const AtsCareerPortalCandidateForm = ({ jobdata, view , imageUrl }) => {
  const initialFValues = {
    first_name: "",
    last_name: "",
    contact_no: "",
    alternative_mobile: "",
    email_id: "",
    dob: "",
    designation: "",
    years_of_experience: "",
    current_ctc: "",
    expected_pay: "",
    current_employer: "",
    notice_period: "",
    resume: "",
    gender: "Male",
    primary_skill: "",
    secondary_skill: "",
    status: "Active",
    identity_id: "",
    proof_number: "",
    location: "",
    github_profile: "",
    linkedin_profile: "",
  };

  const certficationInitValue = {
    certificate_name: "",
    certificate_link: "",
  };

  const useStyles = makeStyles((theme) =>
    createStyles({
      paper: {
        boxShadow:
          theme?.palette?.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "15px",
        borderRadius: "10px",
      },
    })
  );

  const { domain } = useParams();
  const decodedToken = atob(domain);
  // Split the decoded token
  const parts = decodedToken.split('=');
  const res_id = parts[0] || "";
  const domainname = parts[1] || "";
  const userType = parts[2] || "";
  const isConsultancy = userType === "Consultancy";
  const classes = useStyles();
  const [candidateData, setCandidateData] = useState(initialFValues);
  const [files, setFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [proofData, setProofData] = useState({ load: true, values: [] });
  const [resumevalid, setResumeValid] = useState(false);
  const [pop, setPop] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const [proofErrors, setProofErrors] = useState([]);
  const [disabledCertificate, setDisabledCertificate] = useState(false);
  const [step, setStep] = useState(1);
  const [externalData, setExternalData] = useState("");

  useEffect(() => {
    const allFieldsEmpty = certificateData.every(
      (certificate) =>
        certificate.certificate_name !== "" &&
        certificate.certificate_link !== ""
    );
    setDisabledCertificate(!allFieldsEmpty);
  }, [certificateData]);




  
  const handleInputChange = (event, value) => {
    if (["identity_id"].includes(event)) {
      if (value)
        setCandidateData({
          ...candidateData,
          [event]: value,
          proof_number: "",
        });
      else
        setCandidateData({ ...candidateData, [event]: "", proof_number: "" });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...candidateData, [event]: value ? value : "" });
      setErrors({ ...errors, proof_number: "" });
    } 
    else {
      setCandidateData({
        ...candidateData,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...candidateData, [event.target.name]: event.target.value });
    }
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name?.trim() === "" ? "First Name is required" : "";
    }
    if ("contact_no" in fieldValues) {
      temp.contact_no =
        fieldValues.contact_no === "" || fieldValues.contact_no === null
          ? "Mobile No is required"
          : fieldValues?.contact_no.toString() !== "" &&
            !fieldValues?.contact_no
              ?.toString()
              ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
          ? "Invalid Mobile Number."
          : "";
    }
    if ("alternative_mobile" in fieldValues) {
      temp.alternative_mobile =
        fieldValues.alternative_mobile === "" ||
        fieldValues.alternative_mobile === null
          ? ""
          : fieldValues?.alternative_mobile.toString() !== "" &&
            !fieldValues?.alternative_mobile
              ?.toString()
              ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
          ? "Invalid Mobile Number."
          : "";
    }
    if ("email_id" in fieldValues) {
      temp.email_id =
        fieldValues.email_id === ""
          ? "Email is required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              fieldValues.email_id
            )
          ? ""
          : "Invalid Email.";
    }
    if ('proof_number' in fieldValues) {
      const proofType = candidateData?.identity_id;
      const proof_number = fieldValues.proof_number;
      temp.proof_number =
          fieldValues?.identity_id !== "" && fieldValues.proof_number === ""? "Proof Number is required" : "";

      if (proofType?.identity_length) {
          const length = proofType?.identity_length;
          const isAlphaNumeric = /^[a-zA-Z0-9]+$/.test(proof_number);
          temp.proof_number = !isAlphaNumeric ? "Proof Must be alphanumeric" : proof_number.length !== length ? `Proof Must be ${length} characters` : "";
      }
  }
    if ("years_of_experience" in fieldValues) {
      temp.years_of_experience =
        fieldValues.years_of_experience?.trim() === ""
          ? "Year of Experience is required"
          : "";
    }
    if ("expected_pay" in fieldValues) {
      temp.expected_pay =
        fieldValues.expected_pay?.trim() === ""
          ? "Expected Pay is required"
          : "";
    }
    if ("primary_skill" in fieldValues) {
      temp.primary_skill =
        fieldValues.primary_skill?.trim() === "" ? "Skills is required" : "";
    }
    if ("secondary_skill" in fieldValues) {
      temp.secondary_skill =
        fieldValues.secondary_skill?.trim() === ""
          ? "Software & Tools is required"
          : "";
    }
    if ("location" in fieldValues) {
      temp.location =
        fieldValues.location?.trim() === "" ? "Location is required" : "";
    }
    setErrors({
      ...temp,
      proof_number: temp.proof_number === false ? "" : temp.proof_number,
    });

    const temp1 = {
      ...temp,
      proof_number: temp.proof_number === false ? "" : temp.proof_number,
    };
    return Object.values(temp1).every((x) => x === "");
  };

  const handleProofType = () => {
    GetProofTypeHelper({ domain: domainname  }).then((res) => {
      if (res?.data?.statuscode === 200) {
        setProofData({ load: false, values: res?.data?.data });
      }
    });
  };
    
  async function handleSubmit() {
    if (proofvalidate() && validate(candidateData) && files?.[0]) {
      let b64string = "";
      let filename = "";
      let certfication_details = [];
      let content_type = "";
      for (let i = 0; i < certificateData?.length; i++) {
        certfication_details.push({
          certificate_name: certificateData[i]?.certificate_name,
          certificate_link: certificateData[i]?.certificate_link,
        });
      }
      if (files?.[0]) {
        b64string = await convertBase64(files?.[0]);
        let ext = files?.[0]?.name?.split(".").at(-1);
        content_type = ext === "pdf" ? "application/pdf" : "application/msword";
        filename = `${domainname }/ATS/Resumes/` + uuidv4() + "." + ext;
      } else {
        filename = candidateData?.resume;
      }
      AddAtsExternalCandidateHelper({
        ...candidateData,
        content_type: content_type,
        is_external: true,
        domain: domainname ,
        resume: b64string,
        resume_name: filename,
        ats_jobpost_id: jobdata?.[0]?.ats_jobpost_id,
        consultancy_fk: isConsultancy ? res_id : "0",
        ats_company_fk: isConsultancy ? "0" : res_id,

        certificate_details: `${JSON.stringify(certfication_details)}`,
      }).then((res) => {
        if (res?.data?.statuscode === 200) {
          setExternalData(res?.data?.data?.[0])
          if(res?.data?.data?.[0]?.fn_ats_ext_addcandidate){
            setStep(2)
          }
          else{
            setPop(true)
            setTimeout(() => history.push("/"), 3000);
          }
          
        }
      });
    }
    if (files?.[0]) {
      setResumeValid(false);
    } else {
      setResumeValid(true);
    }
  }
  const proofvalidate = () => {
    let ret_value = [];
    certificateData?.forEach((store, index) => {
      const error = {};
      if (!store.certificate_name)
        error.certificate_name = "Certificate Name is required";
      if (!store.certificate_link)
        error.certificate_link = "Certificate Link is required";
      const newErrors = [...proofErrors];
      while (newErrors.length <= index) {
        newErrors.push({});
      }
      newErrors[index] = error;
      setProofErrors(newErrors);
      ret_value.push(Object.values(error).every((e) => e === ""));
    });
    return ret_value;
  };

  const handleAddPoof = () => {
    setCertificateData((state) => [...state, certficationInitValue]);
    proofvalidate();
  };

  const handleProofDelete = (e, id) => {
    let proofData = [...certificateData];
    proofData.splice(id, 1);
    setCertificateData(proofData);
  };

  const handleDocumentChange = (e, value, id) => {
    let newArr = [...certificateData];
    const newErrors = [...proofErrors];
    let item = newArr[id];
    item = { ...item, [e]: value };
    newArr[id] = item;
    newErrors[id] = { ...newErrors[id], [e]: "" };
    setCertificateData(newArr);
    setProofErrors(newErrors);
  };

  const handleReset = () => {
    setFileUrl("");
    setFiles([]);
    setCandidateData(initialFValues);
  };

  const handleCancel = () => {
    history.push("/");
  };
  return (
  
    <UnauthorizedBase imageUrl={imageUrl}>
      <Box sx={{ pb: 5 }}>
        <Grid container display={"flex"}>
          <Grid
            container
            item
            xs={12}
            sm={4}
            md={3}
            lg={2}
            sx={{ maxHeight: "100vh", overflowY: "-moz-hidden-unscrollable" }}
          >
            {view === "Apply" && <CarreerportalSidbar jobdata={jobdata} />}
          </Grid>
          
          {step === 1 ? (
            <>
          <Grid
            container
            item
            xs={view === "Apply" ? 12 : 12}
            sm={view === "Apply" ? 8 : 12}
            md={view === "Apply" ? 9 : 12}
            lg={view === "Apply" ? 10 : 12}
            sx={{ maxHeight: "100vh", overflowY: "scroll" }}
          >
            <Grid item xs={12} md={12} padding={1.5}>
              <Paper className={classes?.paper}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ paddingLeft: "15px" }}
                >
                  Personal Info
                </Typography>
                <Grid container>
                  <Grid
                    key="first_name"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        autoFocus={true}
                        required
                        size="small"
                        value={candidateData.first_name}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        onKeyPress={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        autoComplete="first_name"
                        {...(errors.first_name && {
                          error: true,
                          helperText: errors.first_name,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="last_name"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.last_name}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="last_name"
                        label="Last Name"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 35 && e.preventDefault()
                        }
                        name="last_name"
                        autoComplete="last_name"
                        {...(errors.last_name && {
                          error: true,
                          helperText: errors.last_name,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="contact_no"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        required
                        value={candidateData.contact_no}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="contact_no"
                        label="Mobile No"
                        name="contact_no"
                        autoComplete="contact_no"
                        {...(errors.contact_no && {
                          error: true,
                          helperText: errors.contact_no,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="alternative_mobile"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.alternative_mobile}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="alternative_mobile"
                        label="Alternate Mobile No"
                        name="alternative_mobile"
                        autoComplete="alternative_mobile"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="email_id"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        required
                        value={candidateData.email_id}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="email_id"
                        label="Email"
                        name="email_id"
                        autoComplete="email_id"
                        {...(errors.email_id && {
                          error: true,
                          helperText: errors.email_id,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="dob"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <CommonDatePicker
                        size="small"
                        name="dob"
                        label="Date of Birth"
                        pickerType="date"
                        onKeyDown={(e) => e.preventDefault()}
                        onKeyPress={(e) => e.preventDefault()}
                        pickerFormat="dd/MM/yyyy"
                        DefaultDTvalue={candidateData?.dob}
                        maxDate={new Date()}
                        handleChange={(value) =>
                          handleInputChange({
                            target: {
                              name: "dob",
                              value: value
                                ? `${
                                    value?.getFullYear() +
                                    "-" +
                                    ("0" + (value?.getMonth() + 1)).slice(-2) +
                                    "-" +
                                    ("0" + value?.getDate()).slice(-2)
                                  }`
                                : "",
                            },
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="identity_id"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <SelectableSearch
                        isOptionEqualToValue={(option, value) =>
                          +option.identity_id === +value.identity_id
                        }
                        label="Proof Type"
                        fullWidth
                        value={candidateData?.identity_id}
                        loading={() => handleProofType()}
                        fieldLabel="name"
                        required={false}
                        variant={"outlined"}
                        name="identity_id"
                        size="small"
                        onChangeEvent={handleInputChange}
                        data={proofData}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="proof_number"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.proof_number}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="proof_number"
                        disabled={candidateData.identity_id === ""}
                        label="Proof Number"
                        name="proof_number"
                        autoComplete="proof_number"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="location"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="location"
                      required={true}
                      label="Location"
                      size="small"
                      value={candidateData?.location}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      variant="outlined"
                      {...(errors.location && {
                        error: true,
                        helperText: errors.location,
                      })}
                    />
                  </Grid>
                  <Grid
                    key="gender"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl>
                      <FormLabel id="gender-label">Gender</FormLabel>
                      <RadioGroup
                        onChange={handleInputChange}
                        row
                        aria-labelledby="gender-label"
                        name="gender"
                        value={candidateData.gender}
                      >
                        <FormControlLabel
                          value="Male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="Female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="Non-Binary"
                          control={<Radio />}
                          label="Non-Binary"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} padding={1.5}>
              <Paper className={classes?.paper}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ paddingLeft: "15px" }}
                >
                  Professional Info
                </Typography>
                <Grid container>
                  <Grid
                    key="designation"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                        name="designation"
                        label="Designation"
                        size="small"
                        value={candidateData?.designation}
                        onChange={handleInputChange}
                        onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                        }
                        onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                        }
                        fullWidth
                        variant="outlined"
                        />
                  </Grid>
                  <Grid
                    key="years_of_experience"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.years_of_experience}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                        fullWidth
                        type="number"
                        id="years_of_experience"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 2 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 2 && e.preventDefault()
                        }
                        label="Year of Experience"
                        name="years_of_experience"
                        autoComplete="years_of_experience"
                        {...(errors.years_of_experience && {
                          error: true,
                          helperText: errors.years_of_experience,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="current_ctc"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.current_ctc}
                        onChange={handleInputChange}
                        variant="outlined"
                        type={"number"}
                        fullWidth
                        id="current_ctc"
                        label="Current CTC"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        name="current_ctc"
                        autoComplete="current_ctc"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Annum
                            </InputAdornment>
                          ),
                          maxLength: 9,
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    key="expected_pay"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.expected_pay}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                        type={"number"}
                        fullWidth
                        id="expected_pay"
                        label="Expected CTC"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 10 && e.preventDefault()
                        }
                        name="expected_pay"
                        autoComplete="expected_pay"
                        {...(errors.expected_pay && {
                          error: true,
                          helperText: errors.expected_pay,
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Annum
                            </InputAdornment>
                          ),
                          maxLength: 9,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="current_employer"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData.current_employer}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="current_employer"
                        label="Current Employer"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 100 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 100 && e.preventDefault()
                        }
                        name="current_employer"
                        autoComplete="current_employer"
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    key="notice_period"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        type={"number"}
                        size="small"
                        value={candidateData?.notice_period}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="notice_period"
                        label="Notice Period"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 3 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 3 && e.preventDefault()
                        }
                        name="notice_period"
                        autoComplete="notice_period"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              Days
                            </InputAdornment>
                          ),
                          maxLength: 4,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="git_hub"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="github_profile"
                      label="Github Profile"
                      size="small"
                      value={candidateData?.github_profile}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    key="linkedin_profile"
                    item
                    xs={12}
                    md={4}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <TextField
                      name="linkedin_profile"
                      label="LinkedIn Profile"
                      size="small"
                      value={candidateData?.linkedin_profile}
                      onChange={handleInputChange}
                      onKeyPress={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      onPaste={(e) =>
                        e.target.value?.length > 100 && e.preventDefault()
                      }
                      fullWidth
                      variant="outlined"
                      placeholder="https://www.<linkedin_profile_url>"
                    />
                  </Grid>
                  <Grid
                    key="primary_skill"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData?.primary_skill}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        id="primary_skill"
                        label="Skills"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        name="primary_skill"
                        autoComplete="primary_skill"
                        {...(errors.primary_skill && {
                          error: true,
                          helperText: errors.primary_skill,
                        })}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    key="secondary_skill"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        value={candidateData?.secondary_skill}
                        onChange={handleInputChange}
                        required
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        id="secondary_skill"
                        label="Software & Tools"
                        onKeyPress={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        onPaste={(e) =>
                          e.target.value?.length >= 254 && e.preventDefault()
                        }
                        name="secondary_skill"
                        autoComplete="secondary_skill"
                        {...(errors.secondary_skill && {
                          error: true,
                          helperText: errors.secondary_skill,
                        })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} padding={1.5}>
              <Paper className={classes?.paper}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ paddingLeft: "15px" }}
                  >
                    Certifications
                  </Typography>
                  <Tooltip title="Add Certificate">
                    <IconButton
                      sx={{ pt: 0 }}
                      disabled={disabledCertificate}
                      onClick={handleAddPoof}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {certificateData?.map((res, id) => {
                  return (
                    <>
                      <Grid pt={2.5} container spacing={2} padding={1.5}>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              value={res?.certificate_name || ""}
                              onChange={(e) =>
                                handleDocumentChange(
                                  e.target.name,
                                  e.target.value,
                                  id
                                )
                              }
                              variant="outlined"
                              fullWidth
                              required
                              id="certificate_name"
                              label="Certificate Name"
                              name="certificate_name"
                              {...(proofErrors[id] &&
                                proofErrors[id].certificate_name && {
                                  error: true,
                                  helperText: proofErrors[id].certificate_name,
                                })}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              value={res?.certificate_link || ""}
                              onChange={(e) =>
                                handleDocumentChange(
                                  e.target.name,
                                  e.target.value,
                                  id
                                )
                              }
                              variant="outlined"
                              fullWidth
                              required
                              id="certificate_link"
                              label="Certificate link"
                              name="certificate_link"
                              {...(proofErrors[id] &&
                                proofErrors[id].certificate_link && {
                                  error: true,
                                  helperText: proofErrors[id].certificate_link,
                                })}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              p: 1,
                              pt: 0,
                            }}
                          >
                            <Fab
                              id={res.id}
                              onClick={(e) => {
                                handleProofDelete(e, id);
                              }}
                              size="small"
                              color="error"
                              aria-label="delete"
                            >
                              <DeleteIcon fontSize="small" />
                            </Fab>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} padding={1}>
              <Paper className={classes?.paper}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ paddingLeft: "15px" }}
                >
                  Upload Resume
                </Typography>
                <Grid container>
                  <Grid item xs={12} md={6} padding={1}>
                    <FileUploader
                      fileUrl={fileUrl}
                      setFileUrl={setFileUrl}
                      files={files}
                      setFiles={setFiles}
                      filename={`${candidateData?.first_names}_${APP_NAME}`}
                      filepath={candidateData?.resume}
                      candidateData={candidateData}
                    />
                    {resumevalid === true && (
                      <Typography sx={{ color: "red", paddingLeft: "20px" }}>
                        Resume is required
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} padding={1}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", pr: 3 }}
            >
              <Box>
                <Button
                  className="mpl-secondary-btn"
                  variant="outlined"
                  sx={{ mr: 2 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={handleReset}
                  className="mpl-secondary-btn"
                  variant="outlined"
                  sx={{ mr: 2 }}
                >
                  Reset
                </Button>
                <Button
                  className="mpl-primary-btn"
                  variant="contained"
                  onClick={handleSubmit}
                >
                 {jobdata ? "Next" : "Submit"}
                </Button>
              </Box>
            </Box>
          </Grid>
          </>
  ) : (
    <>
      <Grid
            container
            item
            xs={view === "Apply" ? 12 : 12}
            sm={view === "Apply" ? 8 : 12}
            md={view === "Apply" ? 9 : 12}
            lg={view === "Apply" ? 10 : 12}
            sx={{ maxHeight: "100vh", overflowY: "scroll" }}
          >
              <PreScreenResponse data={jobdata?.[0]} carrerPortal={true} domainname={domainname } externalData={externalData} setPop={setPop}/>
          </Grid>
    </>
    )}
        </Grid>
      </Box>
      <Popup
        maxWidth="lg"
        title="Saved"
        isMasterProduct={false}
        setOpenPopup={setPop}
        openPopup={pop}
      >
        <Box minWidth="400px">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ mt: -4, mb: -3 }}>
              <img src={Done} alt="Done" width={"25%"} />
            </Box>
            <FormControl>
              <FormLabel id="gender-label">
                <Typography sx={{ color: "#000000" }} variant="h5">
                  Form Submitted Successfully
                </Typography>
              </FormLabel>
              <FormLabel id="gender-label">
                <Typography sx={{ color: "#000000" }}>
                  Thank you for filling out your information! and we will get
                  back to you.
                </Typography>
              </FormLabel>
            </FormControl>
          </Box>
        </Box>
      </Popup>
    </UnauthorizedBase>
   
  );
};

export default AtsCareerPortalCandidateForm;
