import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GetEmployeesAction } from "../../../../store/Employee/Employee/Action";
import { useEffect } from "react";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { ConvertDateTime, getUserData } from "../../Base/helper/baseFunctions";
import ArchiveIcon from "@mui/icons-material/Archive";
import { GetATSModuleFeaturesAction } from "../../../../store/RoleAccess/ATSModuleFeatures/Action";
import history from "../../../../history";
import ConfirmationDialog from "../../Base/views/ConfirmationDialog";
import CustomToggle from "../../Base/views/CustomToggle";
import { UpdateEmployeeStatusHelper } from "../../Employee/helper/EmployeeHelper";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Popup from "../../Base/views/Popup";
import AddEmployeePopup from "./AddEmployeePopup";
import EmployeePayroleTemplate from "../../Finance/views/Employee/EmployeePayroleTemplate";

const MyEmployeeGrid = ({ setCurrentMode, setData }) => {
  const userData = getUserData();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inactive, setInactive] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const open = Boolean(anchorEl);
  const { employees_options } = useSelector(
    (state) => state?.EmployeeReducer.EmployeesReducer
  );

  useEffect(() => {
    dispatch(
      GetATSModuleFeaturesAction({ module_name: "ATS Employees Onboarding" })
    );
  }, [dispatch]);
  useEffect(() => {
    if (inactive === true) {
      dispatch(GetEmployeesAction({ is_active: false }));
    } else dispatch(GetEmployeesAction({ is_active: true }));
    
  }, [dispatch, inactive]);

  const handleAdd = () => {
    setCurrentData({})
    setOpenPopup(true)
  };
  const handleEdit = (event, row) => {
    setCurrentData(row)
    setOpenPopup(true);
  };
  const handleView = (event, row) => {
    history.push({
      pathname: "/Onboardemployee",
      state: { defaultValue: currentData, Mode: "editemployee" },
    });
  };

  const handlePayrollSetupPopup = (data) => {
    setOpenTemplate(true);
    setCurrentData(data);
  };
  const handleClick = (event, data) => {
    setCurrentData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleConfirmArchive = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const handleToggle = (e) => {
    setInactive(!inactive);
  };
  const handleUnArchive = () => {
    UpdateEmployeeStatusHelper({
      employee_id: currentData?.employee_id,
      is_active: true,
      successMessage: "Employee made Active Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        dispatch(GetEmployeesAction({ is_active: false }));
      }
    });
    setOpenDialog(false);
  };

  const handleArchive = () => {
    UpdateEmployeeStatusHelper({
      employee_id: currentData?.employee_id,
      is_active: false,
      successMessage: "Employee made Inactive Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        dispatch(GetEmployeesAction({ is_active: true }));
      }
    });
    setOpenDialog(false);
  };
  return (
    <>
      <Popup
        title={currentData?.employee_id ? "Edit Employee" : "Add Employee"}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        fullWidth={true}
        maxWidth="sm"
      >
        <AddEmployeePopup
          editRecord={currentData}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        maxWidth="md"
        fullWidth={true}
        title={`Payroll Configuration - ${currentData?.name}`}
        openPopup={openTemplate}
        setOpenPopup={setOpenTemplate}
      >
        <EmployeePayroleTemplate emp={true} createEmp={false} setOpenTemplate={setOpenTemplate} openTemplate={openTemplate} currentData={currentData} />
      </Popup>
      <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
        <MaterialTable
          onRowClick={(event, rowData) => {
            handleEdit(event, rowData);
          }}
          columns={[
            {
              title: "Prev.Emp ID",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "previous_employee_id",
              grouping: false,
              render: (rowData) => rowData.previous_employee_id || "NA",
            },
            {
              title: "Emp No",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "employee_no",
              grouping: false,
            },
            {
              title: "Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "name",
              grouping: false,
            },
            {
              title: "Email",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "office_email",
              grouping: false,
            },
            {
              title: "D.O.J",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "createdon",
              render: (rowData) => {
                return ConvertDateTime(rowData.doj);
              },
            },
            {
              title: "Onboarding", field: "empform_onboarding_status", headerStyle: { textAlign: "left" }, cellStyle: { padding: "10px 10px 10px 34px", textAlign: "left" },
              render: rowData => {
                return rowData.empform_onboarding_status ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                  : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
              }
            },

            {
              title: "Payroll Setup", field: "payroll_setup_flag", hidden: !['cvtmeepl', "xpohrmeepl", "altiio","adobemeepl", "kmkmeepl","paycmeepl","bpmeepl","nirahameepl"].includes(userData?.domain) || !(userData?.f_country_code === "IND"), headerStyle: { textAlign: "left" }, cellStyle: { padding: "10px 10px 10px 22px", textAlign: "left" },
              render: rowData => {
                return rowData.payroll_setup_flag ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePayrollSetupPopup(rowData);
                  }}
                />
                  : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)", cursor: "pointer" }} onClick={(e) => {
                    e.stopPropagation();
                    handlePayrollSetupPopup(rowData);
                  }} />
              }
            },
          ]}
          data={employees_options}
          options={{
            maxBodyHeight: "65vh",
            minBodyHeight: "65vh",
            rowStyle: { fontSize: "13px" },
            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
            sorting: true,
            search: true,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [25, 50],
            pageSize: 25,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: false,
            exportAllData: false,
            exportFileName: "Employees",
            addRowPosition: "first",
            selection: false,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: false,
          }}
          actions={[
            !inactive && {
              icon: () => <MoreVertIcon fontSize="small" />,
              tooltip: "Settings",
              onClick: (_event, rowData) => {
                handleClick(_event, rowData);
              },
            },
            {
              icon: () => (
                <CustomToggle
                  isChecked={inactive}
                  setIsChecked={handleToggle}
                  handleToggleChange={handleToggle}
                  Yestext={"Inactive"}
                  NoText={"Active"}
                  style={{
                    height: "32px",
                    padding: "0px",
                    width: "145px",
                    labelPadding: "1px",
                  }}
                />
              ),
              position: "row",
              isFreeAction: true,
              tooltip: inactive ? "Toggle to view active users" : "Toggle to view archived users",
            },
            {
              icon: "add",
              tooltip: "Add Employee",
              isFreeAction: true,
              onClick: (event) => {
                handleAdd();
              },
            },
          ]}
          title="Employees"
        />
        <Menu
          id="more-options-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "more-options-button",
          }}
        >
          <MenuItem onClick={(event, rowData) => {
            handleView(event, rowData);
          }}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              View Employee Detail
            </ListItemText>
          </MenuItem>
          {(currentData?.employee_id === userData?.employee_id) || (currentData?.core_hr_user_type === "Owner") ? (
            <MenuItem disabled>
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {inactive ? "Make Active" : "Make Inactive"}
              </ListItemText>
            </MenuItem>
          ) : (
            <MenuItem onClick={handleConfirmArchive}>
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {inactive ? "Make Active" : "Make Inactive"}
              </ListItemText>
            </MenuItem>
          )}

        </Menu>
        <ConfirmationDialog
          title={inactive ? "Make Active" : "Make Inactive"}
          content={
            inactive
              ? "Are you sure want to make active ?"
              : "Are you sure want to make Inactive ?"
          }
          openDialog={openDialog}
          closeDialog={setOpenDialog}
          popupTitle="Users"
          OkButtonText={inactive ? "Make Active" : "Make Inactive"}
          onSubmit={inactive ? handleUnArchive : handleArchive}
        />
      </Box>
    </>
  );
};

export default MyEmployeeGrid;
