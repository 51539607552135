// ** React Imports
import { Fragment, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { makeStyles } from "@mui/styles";
import { convertBase64, getUserData, } from '../../../Base/helper/baseFunctions';
import Loading from "../../../ATS/images/recruitment.gif";
import ErrorIcon from '@mui/icons-material/Error';
import { FormControl, FormLabel } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { UploadProfilePicAction } from '../../../../../store/ATS/Resume/Action';
import Warning from "../../../ATS/images/report.png";
import Popup from "../../../Base/views/Popup";
import { EmpPicPythonResetApiAction } from '../../../../../store/ATS/PythonApi/Action'
import ArrowBackButton from '../../../Base/views/ArrowBackButton';

const Alert = () => {
    const main = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    };
    const containerStyle = {
        backgroundColor: "#e0f7ff",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 15px",
        color: "#000",
        position: "relative",
        borderBottom: "1px solid #007bff",
        width: "70%"
    };

    const infoIconStyle = {
        color: "#007bff",
        marginRight: "10px",
        fontSize: "18px",
    };

    const textStyle = {
        margin: 0,
        flex: 1,
        color: "#1b1c21"
    };

    return (
        <div style={main}>
            <div style={containerStyle}>
                <span style={infoIconStyle}><ErrorIcon /></span>
                <p style={textStyle}>
                    1.The accepted formats are PNG and JPEG.<br />
                    2.Profile picture file names must be saved using the previous employee ID.</p>
            </div>
        </div>
    );
};

const uploadButtonStyles = {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#8b44ff",
    color: "#fff",
    borderRadius: "5px",
    cursor: "pointer",
    marginRight: "10px",
};
const EmployeePicUpload = ({ setStep }) => {
    const useStyles = makeStyles({
        files: {
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: '6px',
            padding: '0.625rem 0.6rem 0.625rem 1.5rem',
            border: '1px solid rgba(93, 89, 98, 0.14)',
            marginTop: '1.5625rem'
        },
        button: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1.5625rem',
            paddingBottom: '1.25rem'
        },
    });
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isParsing, GetSuccess } = useSelector(state => state.AtsReducer?.AtsPythonApiReducer)
    const { isUploading } = useSelector(state => state.AtsReducer?.AtsUploadReducer)
    const [files, setFiles] = useState([]);
    const [load, setLoad] = useState(false);
    const [show, setShow] = useState(false);
    const userData = getUserData();
    useEffect(() => {
        if (GetSuccess === "Success") {
            setLoad(false);
            setStep(1);
            dispatch(EmpPicPythonResetApiAction());
        }
    }, [GetSuccess]);
    const handleUpload = async () => {
        try {
            setFiles([]);
            let b64Files = [];
            let fileNames = [];
            let contentTypes = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const b64string = await convertBase64(file);
                const ext = file?.name?.split(".").at(-1);
                const content_type = ext;
                const filename = `${userData?.domain}/Stage/ProfilePic/` + file.name;
                b64Files.push(b64string);
                fileNames.push(filename);
                contentTypes.push(content_type);
            }
            const data = {
                files: fileNames,
                workspace_name: [userData?.domain],
                user_id: [userData?.ats_user_id],
            };
            dispatch(UploadProfilePicAction({ b64Files, fileNames, data, contentTypes }, dispatch));
            (!isUploading && !isParsing) && setFiles([])
        } catch (error) {
            console.error("Error during file upload: ", error);
        }
    };
    const handleFolderChange = async (event) => {
        setFiles([]);
        event.preventDefault();
        const selectedFiles = event.target.files;
        const filesArray = Array.from(selectedFiles);
        const validFiles = [];
        filesArray?.forEach((file) => {
            if (
                file.type === "image/png" || file.type === "image/jpeg"
            ) {
                validFiles.push(file);
            } else {
                setShow(true);
            }
        });
        setFiles(validFiles);
    };
    const handleRemoveAllFiles = () => {
        setFiles([])
    }
    const HandleClose = () => {
        setShow(false);
        setFiles([]);
    };
    return (
        <Fragment>
            <Popup
                maxWidth="xs"
                openPopup={show}
                setOpenPopup={setShow}
                close={false}
                hideDividers={true}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
                        <img src={Warning} alt="UnSupport File" width={"25%"} />
                    </Box>
                    <FormControl>
                        <FormLabel id="gender-label">
                            <Typography variant="h5" className="blink">
                                Invalid Image Type, Accepted image type is .png, .jpeg
                            </Typography>
                        </FormLabel>
                    </FormControl>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <Button
                            onClick={HandleClose}
                            variant="contained"
                            type="submit"
                            className="mpl-primary-btn profile-btn"
                            size="small"
                            sx={{
                                margin: "10px",
                            }}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </Popup>
            {
                (load || isParsing || isUploading) &&
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',  // Full height of the viewport
                    backgroundColor: '#f0f4f8'  // Optional: Set a background color
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '10px'
                    }}>
                        <Typography sx={{ mb: 2 }} variant='h4'>Employee data import has begun. We’ll notify you once it’s completed.</Typography>
                        <img
                            src={Loading}
                            alt="Loading..."
                            width={"150px"}
                            height={"150px"}
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                                padding: "10px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            }}
                        />
                    </div>
                </div>
            }
            {!(load || isParsing || isUploading) && (
                <>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ position: "relative" }}
                    >
                        <ArrowBackButton
                            onClick={() => setStep(1)}
                            sx={{ position: "absolute", left: 0 }}
                        />
                        <Typography
                            variant="h5"
                            sx={{ flexGrow: 1, textAlign: "center" }}
                        >
                            Employee Image Bulk Upload
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: ["column", "column", "row"],
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <input
                            type="file"
                            id="folderInput"
                            webkitdirectory=""
                            directory=""
                            onChange={handleFolderChange}
                            style={{ display: "none" }}
                        />
                        <label htmlFor="folderInput" style={uploadButtonStyles}>
                            Upload Folder
                        </label>
                    </Box>
                    <Alert />
                </>
            )}
            {files.length ? (
                <Fragment>
                    <Typography sx={{ display: 'flex', justifyContent: 'end', pr: '25px', pt: '26px', fontSize: '1.1rem', fontWeight: 700, color: 'black' }}>No of images : {files.length}</Typography>
                    <div className={classes.button}>
                        <Button style={{ marginRight: '0.85rem' }} color='error' variant='outlined' onClick={handleRemoveAllFiles}>
                            Remove All
                        </Button>
                        <Button style={uploadButtonStyles} variant='contained' onClick={() => {
                            setLoad(true);
                            handleUpload();
                        }}>Upload Images</Button>
                    </div>
                </Fragment>
            ) : null}
        </Fragment>
    )
}
export default EmployeePicUpload
